import React, { useContext, useState } from "react";
import ReactDatePicker from "react-datepicker";
import S3FileUpload from "react-s3";
import Axios from "axios";
import moment from "moment";
import vaultlogo from "../../static/images/logos/engagement.svg";
import { MainContext } from "../../context/MainContext";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import { renameFile } from "../../utils/Functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function EngagementNewEvent({ onClose, onSuccess }) {
  const { tostShowOn } = useContext(MainContext);
  const events = [
    {
      key: "email",
      question: "Whats Your Email? ",
      placeHolder: "Ex. example@example.com",
    },
    {
      key: "name",
      question: "Name Your Event",
      placeHolder: "Ex. Webinar For Brokers",
    },
    {
      key: "event_code",
      question: "Create Event Code",
      placeHolder: "Ex. Web-001",
    },
    {
      key: "time",
      question: "When The Event Starts",
      placeHolder: "MM/dd/yyyy hh:mm:ss aa",
    },
    {
      key: "duration",
      question: "How Long The Event Takes Time (Hrs)",
      placeHolder: "Ex. 2",
    },
    {
      key: "event_description",
      question: "Event Description",
      placeHolder: "Event Desc",
    },
    {
      key: "icon",
      question: "Icon Of The Event Link ",
      placeHolder: "Ex. https://www.example.com/icon.png",
      file: true,
      type: "image/*",
    },
    {
      key: "cover_photo",
      question: "Cover Photo Of Event Link ",
      placeHolder: "Ex. https://www.example.com/coveImage.png",
      file: true,
      type: "image/*",
    },
    {
      key: "promo_Vid",
      question: "Promo Video ",
      placeHolder: "Ex. https://www.example.com/video",
      file: true,
      type: "video/*",
    },
    {
      key: "event_link",
      question: "Event Link ",
      placeHolder: "Ex. https://www.example.com/event",
    },
  ];
  const [step, setStep] = useState(0);
  const [dataObj, setDataObj] = useState({
    time: moment().format("MM/DD/yyyy[,]hh:mm:ss A"),
  });
  const [dateObj, setDateObj] = useState(new Date());

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const initiateSendImage = async (e, step) => {
    setUploading(true);
    let file = e.target.files[0];
    if (file) {
      try {
        const fileName = file.name.replace(" ", "_");
        const fullFileName = `${Date.now().toString()}${fileName}`;
        file = renameFile(file, fullFileName);
        const options = {
          dirName: "uploads/",
          bucketName: "chatsgx",
          region: "us-east-2",
          accessKeyId: "AKIAQD4QYXWFTC6JX6YM",
          secretAccessKey: "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",
        };

        S3FileUpload.uploadFile(file, options)
          .then((resp) => {
            const { location } = resp;
            if (location) {
              setDataObj((data) => ({
                ...data,
                [events[step].key]: location,
              }));
            }
          })
          .finally(() => {
            setUploading(false);
          });
      } catch (error) {
        console.log("Error on FileUpload", error);
        setUploading(false);
      }
    }
  };

  function newEventCreate() {
    setLoading(true);
    Axios.post(
      "https://comms.globalxchange.io/coin/vault/service/calendar/event/create",
      dataObj
    )
      .then(({ data }) => {
        tostShowOn(data.status ? "Success" : data.message);
        onSuccess();
      })
      .finally(() => setLoading(false));
  }

  return (
    <div className="copyPubModal">
      <div
        className="overlay"
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <div className="modalCopy">
        <div className="head">
          <img src={vaultlogo} alt="" />
        </div>
        <div className="copyContent">
          <div className="stepLabel">{events[step].question}</div>
          <div className="step">Step {step + 1}</div>
          {step === 3 ? (
            <ReactDatePicker
              selected={dateObj}
              placeholder={events[step].placeHolder}
              onChange={(date) => {
                setDateObj(date);
                setDataObj((data) => ({
                  ...data,
                  time: moment(date).format("MM/dd/yyyy[,]hh:mm:ss A"),
                }));
              }}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy hh:mm:ss aa"
              showTimeInput
              className="inputValue"
            />
          ) : events[step].file ? (
            <>
              <input
                key={events[step].key}
                type="file"
                className="inputValue"
                onChange={(e) => {
                  e.persist();
                  initiateSendImage(e, step);
                }}
                accept={events[step].type}
                placeholder={events[step].placeHolder}
              />
              {uploading && <FontAwesomeIcon icon={faSpinner} spin />}
            </>
          ) : (
            <input
              type="text"
              className="inputValue"
              value={dataObj[events[step].key] || ""}
              onChange={(e) => {
                e.persist();
                setDataObj((data) => ({
                  ...data,
                  [events[step].key]: e.target.value,
                }));
              }}
              placeholder={events[step].placeHolder}
            />
          )}
          <div
            className="btnNextStep"
            onClick={() => {
              if (step < events.length - 1)
                !uploading && setStep((step) => step + 1);
              else newEventCreate();
            }}
          >
            {step < events.length - 1 ? "Next Step" : "Submit"}
          </div>
        </div>
        {loading && <LoadingAnim />}
      </div>
    </div>
  );
}

export default EngagementNewEvent;
