import { SearchAndFilter } from "../BrokerDashboard/index";
import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import BrandItem from "./BrandItem";
import BrandsListSidebar from "./BrandsListSidebar";
import { useBrandsList } from "../../queryHooks";

function BrandsList({ searchOn }) {
  const [brand, setBrand] = useState();
  const { data: brandsList, isLoading: brandsListLoading } = useBrandsList();

  // Searc Vars

  const [search, setSearch] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const [filteBrandName, setFilteBrandName] = useState("");

  return (
    <>
      {
        <div className="godsEyeMoreList">
          {searchOn && (
            <SearchAndFilter
              search={search}
              setSearch={setSearch}
              placeholder={"Search Industries"}
              filterBy={`${"Name Of Brand"}`}
              mainList={[
                {
                  key: "0",
                  label: "Function Performed By Search Bar",
                  switch: isFilter,
                  switchLabel: isFilter ? "Filter" : "Search",
                  switchClick: () => setIsFilter(!isFilter),
                },
                {
                  key: "1",
                  label: "Filter Range",
                  switch: currentPage,
                  switchLabel: currentPage ? "Current Page" : "Entire List",
                  switchClick: () => setCurrentPage(!currentPage),
                },
              ]}
              filterList={[
                {
                  key: "11",
                  label: "Name Of Market",
                  switch: filteBrandName,
                  switchLabel: filteBrandName ? "On" : "Off",
                  switchClick: () => setFilteBrandName(!filteBrandName),
                },
              ]}
            />
          )}
          <Scrollbars className={`moreListWrapper ${Boolean(brand)}`}>
            {brandsListLoading
              ? Array(6)
                  .fill("")
                  .map((_, i) => (
                    <div className="listItem">
                      <Skeleton alt="" className="icon" />
                      <Skeleton className="name" width={400} />
                      <Skeleton className="count" width={30} />
                    </div>
                  ))
              : brandsList
                  .filter((data) =>
                    data.name.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((data) => (
                    <BrandItem
                      key={data._id}
                      brand={data}
                      onClick={() => setBrand(data)}
                      selected={brand}
                    />
                  ))}
          </Scrollbars>
        </div>
      }
      {brand && <BrandsListSidebar onClose={() => setBrand()} brand={brand} />}
    </>
  );
}

export default BrandsList;
