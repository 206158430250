import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

import { FormatCurrency } from "../../utils/FunctionTools";
import guest from "../../components/../static/images//guest.jpg";
import CopyPublicationDetailModal from "../CopyPublicationDetailModal/CopyPublicationDetailModal";
import { TabsContext } from "../../context/TabsContext";

function WithdrawListItemAsList({ txn, onAction }) {
  const { newTab } = useContext(TabsContext);
  const [profile, setProfile] = useState({
    username: "",
    name: "",
    profile_img: "",
  });
  useEffect(() => {
    Axios.post("https://comms.globalxchange.io/get_affiliate_data_no_logs", {
      email: txn.email,
    }).then((res) => {
      const data = res.data[0];
      if (data) {
        setProfile({
          username: data.username,
          name: data.name,
          profile_img: data.profile_img,
        });
      }
    });
  }, []);
  const [more, setMore] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  return (
    <>
      <div className="userItemWithdraw">
        <img
          src={profile?.profile_img || guest}
          alt=""
          className="profileImg"
        />
        <div className="email">
          <span className="shEmail">{txn?.email}</span>
          <div className="shName">
            {profile && (profile.name || profile.username)}
          </div>
        </div>
        <div className="date">
          {moment(txn?.timestamp).format("h:mm:ss A [on] MMMM Do YYYY")}
        </div>
        <div className="value">
          <span className="shNative">
            {FormatCurrency(txn?.buy_amount, txn?.buy_coin)} {txn?.buy_coin}
          </span>
          <span className="shUsd">
            ${FormatCurrency(txn?.bankerCredit && txn?.bankerCredit?.usd_value)}
          </span>
        </div>
        <div className="actions">
          <div
            className="btnAction"
            onClick={() => {
              try {
                onAction(txn?._id, "complete");
              } catch (e) {}
            }}
          >
            Complete
          </div>
          <div
            className="btnAction"
            onClick={() => {
              try {
                onAction(txn?._id, "delete");
              } catch (e) {}
            }}
          >
            Delete
          </div>
          <div
            className="btnAction"
            onMouseEnter={() => setMore(true)}
            onMouseLeave={() =>
              setTimeout(() => {
                setMore(false);
              }, 100)
            }
          >
            More
            <FontAwesomeIcon icon={faCaretDown} />
            {more && (
              <div className="dropList">
                <div
                  className="drop"
                  onClick={() => {
                    newTab(`/withdrawals/${txn?._id}`);
                  }}
                >
                  Expand
                </div>
                <div className="drop" onClick={() => setCopyModal(true)}>
                  Copy
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {copyModal && (
        <CopyPublicationDetailModal
          publication={txn}
          onClose={() => setCopyModal(false)}
        />
      )}
    </>
  );
}

export default WithdrawListItemAsList;
