import React from "react";

function GodsEyeFilterDisplay({ isList, setIsList }) {
  return (
    <>
      <div className="godsEyeFilterDisplay">
        <div className="labelItm">Which Of The Views Do You Want To Use?</div>
        <div className="options">
          <div className={`option ${isList}`} onClick={() => setIsList(true)}>
            List
          </div>
          <div className={`option ${!isList}`} onClick={() => setIsList(false)}>
            Board
          </div>
        </div>
      </div>
      <div className="footerBtns">
        <div className="btnReset" onClick={() => {}}>
          Reset
        </div>
        <div className="btnSave" onClick={() => {}}>
          Save
        </div>
      </div>
    </>
  );
}

export default GodsEyeFilterDisplay;
