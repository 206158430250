import React from 'react'

import styles from './navtab.module.scss'

function NavbarTab({ logo, text, onClick, onClose, active, svg }) {
  return (
    <div className={`${styles.navBarWrapper} ${active && styles.true}`}>
      <div
        className={styles.navLogoText}
        onClick={() => {
          try {
            onClick()
          } catch (error) {}
        }}
      >
        {svg || <img src={logo} className={styles.navLogo} alt='' />}
        <span>{text}</span>
      </div>
      <div className={styles.divider} />
      {onClose instanceof Function && (
        <div
          className={styles.btnClose}
          onClick={() => {
            try {
              onClose()
            } catch (error) {}
          }}
        />
      )}
    </div>
  )
}

export default NavbarTab
