import React, { createContext, useState } from 'react'
import ToastMessage from '../LayoutComponents/ToastMessage/ToastMessage'

export const MainContext = createContext()
function MainContextProvider({ children }) {
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState('')
  const [subMessage, setSubMessage] = useState('')
  const [icon, setIcon] = useState('')
  function toastShow(message, submessage, icon) {
    setShow(true)
    setMessage(message)
    setSubMessage(submessage)
    setIcon(icon)
    setTimeout(() => {
      setShow(false)
      setMessage('')
      setSubMessage('')
      setIcon('')
    }, 2000)
  }

  return (
    <MainContext.Provider value={{ toastShow }}>
      {children}
      {show && (
        <ToastMessage icon={icon} textOne={message} textTwo={subMessage} />
      )}
    </MainContext.Provider>
  )
}
export default MainContextProvider
