import React, { useState, useEffect } from "react";
import BankerDetailJson from "./BankerDetailJson";
import BankerTransactionDetail from "./BankerTransactionDetail";
import BankerTransactionEnterId from "./BankerTransactionEnterId";

function BankerTransactions({ tabClick, hide }) {
  const [transaction, setTransaction] = useState(null);
  const [step, setStep] = useState(0);
  useEffect(() => {
    setStep(0);
    setTransaction(null);
  }, [tabClick]);
  const getContent = () => {
    if (transaction) {
      switch (step) {
        case 0:
          return <BankerTransactionDetail setStep={setStep} />;
        case 1:
          return <BankerDetailJson transaction={transaction} />;
        default:
          return <BankerTransactionDetail />;
      }
    } else {
      return (
        <BankerTransactionEnterId hide={hide} setTransaction={setTransaction} />
      );
    }
  };
  return <div className="bankerTransaction">{getContent()}</div>;
}

export default BankerTransactions;
