import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";

import react from "../../static/images/clipIcons/react.svg";
import expanded from "../../static/images/clipIcons/expanded.svg";
import circle from "../../static/images/clipIcons/circle.svg";
import Axios from "axios";

function BankerDetailJson({ transaction }) {
  const [expndedLog, setExpndedLog] = useState({});
  const [creditLog, setCreditLog] = useState({});
  const [debitLog, setDebitLog] = useState({});
  useEffect(() => {
    Axios.get(
      `https://comms.globalxchange.io/coin/vault/service/txn/status/logs?id=${transaction._id}`
    ).then(({ data }) => setExpndedLog(data));
    Axios.get(
      `https://comms.globalxchange.io/coin/vault/service/txn/summary/get?id=${
        transaction && transaction.userDebit && transaction.userDebit.id
      }`
    ).then(({ data }) => setDebitLog(data));
    Axios.get(
      `https://comms.globalxchange.io/coin/vault/service/txn/summary/get?id=${
        transaction && transaction.userDebit && transaction.bankerCredit.id
      }`
    ).then(({ data }) => setCreditLog(data));
  }, [transaction]);
  const [mainTransaction, setMainTransaction] = useState(transaction);
  return (
    <div className="jsonWrapper">
      <div className="viewOptions">
        <div
          className={`viewOptionType ${mainTransaction === transaction}`}
          onClick={() => setMainTransaction(transaction)}
        >
          <img src={react} alt="" />
          <span>Simplified Withdrawal Log</span>
        </div>
        <div
          className={`viewOptionType ${mainTransaction === expndedLog}`}
          onClick={() => setMainTransaction(expndedLog)}
        >
          <img src={expanded} alt="" />
          <span>Expanded Transaction Log</span>
        </div>
        <div
          className={`viewOptionType ${mainTransaction === creditLog}`}
          onClick={() => setMainTransaction(creditLog)}
        >
          <img src={circle} alt="" />
          <span>Banker Side</span>
        </div>
        <div
          className={`viewOptionType ${mainTransaction === debitLog}`}
          onClick={() => setMainTransaction(debitLog)}
        >
          <img src={circle} alt="" />
          <span>User Side</span>
        </div>
      </div>
      <ReactJson
        src={mainTransaction}
        collapseStringsAfterLength={50}
        displayDataTypes={false}
      />
    </div>
  );
}

export default BankerDetailJson;
