import React, { useState, useEffect, useContext } from "react";
// import NavTabs from "../NavTabs/NavTabs";
import { TabsContext } from "../../context/TabsContext";

function NavbarEngagement({
  contents,
  logoNtext,
  onChangeDropdown,
  onChangeTab,
  changeTab,
  publicationList,
  dropDownOpen,
  setDropDownOpen,
  search,
  setSearch,
  changeDrop,
}) {
  const [dropSelected, setDropSelected] = useState("");
  const [tabList, setTabList] = useState([]);
  const [tabSelected, setTabSelected] = useState("");
  const { navTabs } = useContext(TabsContext);

  useEffect(() => {
    setDropSelected(Object.keys(contents) && Object.keys(contents)[0]);
  }, [contents]);

  useEffect(() => {
    if (dropSelected) {
      setTabList(contents && contents[dropSelected]);
      // setTabSelected(
      //   contents && contents[dropSelected] && contents[dropSelected][0]
      // );
      onChangeDropdown(dropSelected);
    }
  }, [dropSelected, contents]);

  useEffect(() => {
    if (tabSelected) {
      onChangeTab(tabSelected);
    }
  }, [tabSelected]);

  useEffect(() => {
    if (changeTab) {
      setTabSelected(changeTab);
    }
  }, [changeTab]);
  useEffect(() => {
    if (changeDrop) {
      setDropSelected(changeDrop);
    }
  }, [changeDrop]);

  return (
    <nav>
      <div className="bets-dash-navbar">
        {/* <NavTabs /> */}
        {navTabs}
        <div
          className={`publication ${dropDownOpen}`}
          onClick={() => setDropDownOpen(!dropDownOpen)}
        >
          {dropDownOpen ? (
            <span>Publications</span>
          ) : (
            <>
              <img
                className="logoIcn"
                src={
                  dropSelected &&
                  publicationList &&
                  publicationList[dropSelected] &&
                  publicationList[dropSelected].profile_pic
                }
                alt=""
              />
              <span>{dropSelected}</span>
            </>
          )}
        </div>
      </div>
      <div className="nav-bottom">
        <div className="tab">
          {tabList.map((tabItm) => (
            <div
              className={"tab-itm " + (tabSelected === tabItm)}
              onClick={() => setTabSelected(tabItm)}
            >
              <h6>{tabItm}</h6>
            </div>
          ))}
        </div>
        {dropDownOpen && (
          <input
            type="text"
            className="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Publications.."
          />
        )}
      </div>
    </nav>
  );
}

export default NavbarEngagement;
