import React, { useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard, faCopy } from "@fortawesome/free-regular-svg-icons";
import Axios from "axios";

import { FormatCurrency } from "../../utils/Functions";
import GodsEyeRefreshSidebar from "../GodsEyeRefreshSidebar/GodsEyeRefreshSidebar";
import { ReactComponent as RefreshIcn } from "../../static/images/clipIcons/refresh.svg";
import { MainContext } from "../../context/MainContext";
import TradeInspector from "../TradeInspector/TradeInspector";

function Converter({ refresh, coinsList }) {
  const [search, setSearch] = useState("");
  const [type, setType] = useState("fiat");
  const [from, setFrom] = useState(true);
  const [fromCoin, setFromCoin] = useState();
  const [toCoin, setToCoin] = useState();
  const [rates, setRates] = useState();
  const [fromVal, setFromVal] = useState("");
  const [toVal, setToVal] = useState("");
  const [inverse, setInverse] = useState(false);

  const { tostShowOn } = useContext(MainContext);

  useEffect(() => {
    if (coinsList) {
      setFromCoin(coinsList?.filter((coin) => coin.coinSymbol === "USD")[0]);
      setToCoin(coinsList?.filter((coin) => coin.coinSymbol === "BTC")[0]);
    }
  }, [coinsList]);
  useEffect(() => {
    fromCoin &&
      toCoin &&
      Axios.get(
        `https://comms.globalxchange.io/forex/convert?buy=${toCoin.coinSymbol}&from=${fromCoin.coinSymbol}`
      ).then(({ data }) => {
        if (data.status) {
          setRates(data);
        }
      });
  }, [fromCoin, toCoin]);

  const fromChange = (e) => {
    const value = e.target.value;
    setFromVal(value);
    setToVal(
      (rates &&
        rates[`${fromCoin?.coinSymbol}_${toCoin?.coinSymbol}`.toLowerCase()]) *
        value
    );
  };
  const toChange = (e) => {
    const value = e.target.value;
    setToVal(value);
    setFromVal(
      (rates &&
        rates[`${toCoin?.coinSymbol}_${fromCoin?.coinSymbol}`.toLowerCase()]) *
        value
    );
  };

  const checkIsExpandValue = (num, coin) => {
    if (coin === "BTC" || coin === "ETH") {
      if (num && num < 0.0001) return true;
      else return false;
    } else {
      if (num && num < 0.01) return true;
      else return false;
    }
  };

  const [showTi, setShowTi] = useState(false);

  return (
    <div className="converter">
      <div className="values">
        <div className="group">
          <div className="label">
            {fromCoin?.coinName
              ? `From ${fromCoin?.coinName}`
              : "Select From Coin"}
          </div>
          <div className="inpWrap">
            <div className="imgWrap">
              <img src={fromCoin?.coinImage} alt="" />
            </div>
            <input
              type="text"
              className="inp"
              value={fromVal}
              onChange={fromChange}
              placeholder={FormatCurrency(0, fromCoin?.coinSymbol)}
            />
            <div
              className="imgWrap"
              onClick={() => {
                navigator.clipboard
                  .writeText(fromVal)
                  .then(() => tostShowOn("Copied To Clipboard"));
              }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </div>
            <div
              className="imgWrap"
              onClick={() => {
                navigator.clipboard.readText().then((text) => {
                  setFromVal(text);
                  fromChange({ target: { value: text.replace(/,/, "") } });
                });
              }}
            >
              <FontAwesomeIcon icon={faClipboard} />
            </div>
          </div>
        </div>
        <div className="group">
          <div className="label">
            {toCoin?.coinName ? `To ${toCoin?.coinName}` : "Select To Coin"}
          </div>
          <div className="inpWrap">
            <div className="imgWrap">
              <img src={toCoin?.coinImage} alt="" />
            </div>
            <input
              type="text"
              className="inp"
              value={toVal}
              onChange={toChange}
              placeholder={FormatCurrency(0, toCoin?.coinSymbol)}
            />
            <div
              className="imgWrap"
              onClick={() => {
                navigator.clipboard
                  .writeText(toVal)
                  .then(() => tostShowOn("Copied To Clipboard"));
              }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </div>
            <div
              className="imgWrap"
              onClick={() => {
                navigator.clipboard.readText().then((text) => {
                  setToVal(text);
                  toChange({ target: { value: text.replace(/,/, "") } });
                });
              }}
            >
              <FontAwesomeIcon icon={faClipboard} />
            </div>
          </div>
        </div>
        <div className="group">
          <div className="label">
            Current Rate For{" "}
            {inverse
              ? `${fromCoin?.coinSymbol}/${toCoin?.coinSymbol}`
              : `${toCoin?.coinSymbol}/${fromCoin?.coinSymbol}`}
            <span className="inv" onClick={() => setInverse(!inverse)}>
              {inverse ? "See Normal" : "See Inverse"}
            </span>
          </div>
          <div className="inpWrap">
            <div className="imgWrap">
              <RefreshIcn />
            </div>
            <input
              type="text"
              className="inp"
              onClick={() => setShowTi(true)}
              value={
                checkIsExpandValue(
                  rates &&
                    (inverse
                      ? rates[
                          `${fromCoin?.coinSymbol}_${toCoin?.coinSymbol}`.toLowerCase()
                        ]
                      : rates[
                          `${toCoin?.coinSymbol}_${fromCoin?.coinSymbol}`.toLowerCase()
                        ]),
                  inverse ? toCoin?.coinSymbol : fromCoin?.coinSymbol
                )
                  ? "Expand"
                  : FormatCurrency(
                      rates &&
                        (inverse
                          ? rates[
                              `${fromCoin?.coinSymbol}_${toCoin?.coinSymbol}`.toLowerCase()
                            ]
                          : rates[
                              `${toCoin?.coinSymbol}_${fromCoin?.coinSymbol}`.toLowerCase()
                            ]),
                      inverse ? toCoin?.coinSymbol : fromCoin?.coinSymbol
                    )
              }
              readOnly
            />
            <div className="imgWrap">
              <FontAwesomeIcon
                icon={faCopy}
                onClick={() => {
                  navigator.clipboard
                    .writeText(
                      FormatCurrency(
                        rates &&
                          (inverse
                            ? rates[
                                `${fromCoin?.coinSymbol}_${toCoin?.coinSymbol}`.toLowerCase()
                              ]
                            : rates[
                                `${toCoin?.coinSymbol}_${fromCoin?.coinSymbol}`.toLowerCase()
                              ]),
                        inverse ? toCoin?.coinSymbol : fromCoin?.coinSymbol
                      )
                    )
                    .then(() => tostShowOn("Copied To Clipboard"));
                }}
              />
            </div>
            <div className="imgWrap">
              <FontAwesomeIcon icon={faClipboard} />
            </div>
          </div>
        </div>
      </div>
      <div className="controlls">
        <div className="headTabBox">
          <div className={`tab ${from}`} onClick={() => setFrom(true)}>
            From
          </div>
          <div className={`tab ${!from}`} onClick={() => setFrom(false)}>
            To
          </div>
        </div>
        <div className="contentsIn">
          <div className="labelItm">
            Select {from ? "Quote" : "Base"} Currency
          </div>
          <div className="searchBox">
            <input
              type="text"
              name="coin"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={`Search ${type} Currencies`}
            />
          </div>
          <div className="searchBox">
            <div
              className={`btnOption ${type === "crypto"}`}
              onClick={() => setType("crypto")}
            >
              Crypto
            </div>
            <div
              className={`btnOption ${type === "fiat"}`}
              onClick={() => setType("fiat")}
            >
              Fiat
            </div>
          </div>
          <Scrollbars
            className="coinList"
            renderThumbHorizontal={() => <div />}
            renderThumbVertical={() => <div />}
            renderTrackHorizontal={() => <div />}
            renderTrackVertical={() => <div />}
          >
            {coinsList
              .filter((coin) => coin.type === type)
              .filter(
                (coin) =>
                  coin.coinName.toLowerCase().includes(search.toLowerCase()) ||
                  coin.coinSymbol.toLowerCase().includes(search.toLowerCase())
              )
              .map((coin) => (
                <div
                  className="coinItem"
                  key={coin._id}
                  onClick={() => {
                    if (from) {
                      setFromCoin(coin);
                    } else {
                      setToCoin(coin);
                    }
                  }}
                >
                  <img src={coin.coinImage} alt="" className="coin" />
                  <span>{coin?.coinName}</span>
                </div>
              ))}
          </Scrollbars>
        </div>
      </div>
      {refresh && <GodsEyeRefreshSidebar />}
      {showTi && (
        <TradeInspector
          setOpenModal={(val) => setShowTi(val)}
          tiObject={{
            lblOne: `Current Rate For ${
              inverse
                ? `${fromCoin?.coinSymbol}/${toCoin?.coinSymbol}`
                : `${toCoin?.coinSymbol}/${fromCoin?.coinSymbol}`
            }`,
            amtOne: inverse
              ? rates[
                  `${fromCoin?.coinSymbol}_${toCoin?.coinSymbol}`.toLowerCase()
                ]
              : rates[
                  `${toCoin?.coinSymbol}_${fromCoin?.coinSymbol}`.toLowerCase()
                ],
            coinOne: inverse ? toCoin?.coinSymbol : fromCoin?.coinSymbol,
          }}
        />
      )}
    </div>
  );
}

export default Converter;
