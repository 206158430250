import Axios from "axios";
import React, { useContext, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

import { MainContext } from "../../context/MainContext";
import back from "../../static/images/back.svg";
import close from "../../static/images/close.svg";

import editApp from "../../static/images/clipIcons/appData.svg";
import deleteApp from "../../static/images/refreshIcon/delete.svg";
import yesIcn from "../../static/images/clipIcons/yes.svg";
import noIcn from "../../static/images/clipIcons/no.svg";
import apiIcon from "../../static/images/clipIcons/apis.svg";
import LoadingAnim from "../LoadingAnim/LoadingAnim";

function MarketsListSidebar({ path, onClose }) {
  const { copyToClipboard, email, token, tostShowOn } = useContext(MainContext);
  const [step, setStep] = useState("");
  const [tab, setTab] = useState("Actions");
  const [loading, setLoading] = useState(false);

  function deletePath() {
    setLoading(true);
    Axios.post("https://comms.globalxchange.io/coin/investment/path/delete", {
      email: path?.path_created_by,
      token: token,
      path_id: path?.path_id,
    })
      .then(({ data }) => {
        if (data.status) {
          setStep("deleteSucces");
        } else {
          tostShowOn(data.message || "Something Went Wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getContent() {
    switch (true) {
      case tab === "About" && step === "API":
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <Scrollbars className="itemListScroll">
                <div className="endPointLabel">
                  Get API For Endorsement Market
                </div>
                <div className="valueBox">
                  <span>{`https://api`}</span>
                  <FontAwesomeIcon
                    icon={faCopy}
                    className="copyBtn"
                    onClick={() =>
                      copyToClipboard(`https://api`, "Endpoint For Path")
                    }
                  />
                </div>
              </Scrollbars>
            </div>
          </>
        );
      case tab === "About":
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm">
                Select One Of The Following Options
              </div>
              <Scrollbars className="coinList">
                <div className="coinItem" onClick={() => setStep("API")}>
                  <img
                    src={apiIcon}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>API</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );

      case tab === "Actions" && step === "deleteSucces":
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm m-auto">
                You Have Successfully Deleted Market{" "}
                {path?.token_profile_data?.coinName}. You Will Be Redirected To
                The Updated Markets List Automatically
              </div>
            </div>
          </>
        );

      case tab === "Actions" && step === "Delete":
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm">
                Select One Of The Following Actions
              </div>
              <Scrollbars className="coinList">
                <div className="coinItem" onClick={() => deletePath()}>
                  <img
                    src={yesIcn}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Yes</span>
                </div>
                <div className="coinItem" onClick={() => setStep("")}>
                  <img
                    src={noIcn}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Go Back</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );
      case tab === "Actions":
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm">
                Select One Of The Following Actions
              </div>
              <Scrollbars className="coinList">
                <div className="coinItem" onClick={() => setStep("")}>
                  <img
                    src={editApp}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Edit Token</span>
                </div>
                <div className="coinItem" onClick={() => setStep("Delete")}>
                  <img
                    src={deleteApp}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Delete Token</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );
      default:
        return;
    }
  }
  return (
    <div className="godsEyeUserFiter">
      <div className="headTabBox">
        <div
          className={`tab ${tab === "About"}`}
          onClick={() => {
            setTab("About");
            setStep("");
          }}
        >
          About
        </div>
        <div
          className={`tab ${tab === "Actions"}`}
          onClick={() => {
            setTab("Actions");
            setStep("");
          }}
        >
          Actions
        </div>
      </div>
      <div className="header">
        <div className="content">
          <div className="title">
            <img
              src={path?.token_profile_data?.coinImage}
              alt=""
              className="icon"
            />
            {path?.token_profile_data?.coinName}
          </div>
          <div className="breadCrumbs">
            <span
              onClick={() => {
                setStep("");
              }}
            >
              {tab}
            </span>{" "}
            {step ? (
              <>
                -&gt;&nbsp;
                <span>{step}</span>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        {step ? (
          <div
            className="backBtn"
            onClick={() => {
              setStep("");
            }}
          >
            <img src={back} alt="" />
          </div>
        ) : (
          ""
        )}
        <div
          className="backBtn"
          onClick={() => {
            onClose();
          }}
        >
          <img src={close} alt="" />
        </div>
      </div>
      {getContent()}
      {loading && <LoadingAnim />}
    </div>
  );
}

export default MarketsListSidebar;
