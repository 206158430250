import { SearchAndFilter } from "../BrokerDashboard/index";
import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import CommerceMarketplacesListSidebar from "./CommerceMarketplacesListSidebar";
import { useCommerceMarketplacesList } from "../../queryHooks";
import CommerceMarketplacesItem from "./CommerceMarketplacesItem";
import CommerceMarketplaceFooter from "./CommerceMarketplaceFooter";

function CommerceMarketplacesList({ searchOn }) {
  const [commercemarketplaces, setCommerceMarketplaces] = useState();

  // Searc Vars

  const [search, setSearch] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const [filteCommerceMarketplacesName, setFilteCommerceMarketplacesName] =
    useState("");

  // Footer Filter
  const [filterBy, setFilterBy] = useState("Industry");
  const [industry, setIndustry] = useState({});

  const {
    data: commercemarketplaceslist,
    isLoading: commercemarketplaceslistLoading,
  } = useCommerceMarketplacesList(industry?._id);
  return (
    <>
      {
        <div className="godsEyeMoreList">
          {searchOn && (
            <SearchAndFilter
              search={search}
              setSearch={setSearch}
              placeholder={"Search Commerce Marketplaces"}
              filterBy={`${"Username"}`}
              mainList={[
                {
                  key: "0",
                  label: "Function Performed By Search Bar",
                  switch: isFilter,
                  switchLabel: isFilter ? "Filter" : "Search",
                  switchClick: () => setIsFilter(!isFilter),
                },
                {
                  key: "1",
                  label: "Filter Range",
                  switch: currentPage,
                  switchLabel: currentPage ? "Current Page" : "Entire List",
                  switchClick: () => setCurrentPage(!currentPage),
                },
              ]}
              filterList={[
                {
                  key: "11",
                  label: "Username",
                  switch: filteCommerceMarketplacesName,
                  switchLabel: filteCommerceMarketplacesName ? "On" : "Off",
                  switchClick: () =>
                    setFilteCommerceMarketplacesName(
                      !filteCommerceMarketplacesName
                    ),
                },
              ]}
            />
          )}
          <Scrollbars className="moreListWrapper">
            {commercemarketplaceslistLoading
              ? Array(6)
                  .fill("")
                  .map((_, i) => (
                    <div className="listItem">
                      <Skeleton alt="" className="icon" />
                      <Skeleton className="name" width={400} />
                      <Skeleton className="count" width={30} />
                    </div>
                  ))
              : commercemarketplaceslist?.marketplace_data
                  .filter((commercemarketplaces) =>
                    commercemarketplaces?.name
                      ?.toLowerCase()
                      .includes(search.toLowerCase())
                  )
                  .map((commercemarketplaces) => (
                    <CommerceMarketplacesItem
                      key={commercemarketplaces._id}
                      commercemarketplaces={commercemarketplaces}
                      onClick={() =>
                        setCommerceMarketplaces(commercemarketplaces)
                      }
                      selected={commercemarketplaces}
                    />
                  ))}
          </Scrollbars>
          <CommerceMarketplaceFooter
            list={commercemarketplaceslist?.industries_data || []}
            setFilterBy={setFilterBy}
            filterBy={filterBy}
            filterTypes={["Industry"]}
            filter={industry}
            setFilter={setIndustry}
            count={commercemarketplaceslist?.marketplace_data?.length}
          />
        </div>
      }
      {/* {commercemarketplaces && (
        <CommerceMarketplacesListSidebar onClose={() => setCommerceMarketplaces()} commercemarketplaces={commercemarketplaces} />
      )} */}
    </>
  );
}

export default CommerceMarketplacesList;
