import { IconsViewSwitcher } from "../components/BrokerDashboard/index";
import React, { useEffect, useState } from "react";

import MainLayout from "../layouts/MainLayout";
import NavbarGodsEye from "../layouts/Navbar/NavbarGodsEye";

import industries from "../static/images/clipIcons/industries.svg";
import manufactures from "../static/images/clipIcons/manufactures.svg";
import specialists from "../static/images/clipIcons/specialists.svg";
import endorsement from "../static/images/clipIcons/endorsement.svg";

import { ReactComponent as RefreshIcn } from "../static/images/clipIcons/refresh.svg";
import { useHistory, useParams } from "react-router-dom";
import ViralMoreList from "../components/ViralMoreList";
import MallsList from "../components/MallsList";
import MarketplacesList from "../components/MarketplacesList";

const images = {
  "Endorsement Marketplace": endorsement,
  Specialists: specialists,
  Industries: industries,
  Manufacturers: manufactures,
};

function ViralPage({ hide }) {
  const { txnId } = useParams(); //To Set Tab
  const history = useHistory();
  const [tabs, setTabs] = useState([
    "Affiliates",
    "Brands",
    "Endorsements",
    "Endorsement Markets",
    "More",
    "Selected List",
  ]);
  const [list, setList] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [search, setSearch] = useState("");

  const [searchOn, setSearchOn] = useState(false);

  const [tabSelected, setTabSelected] = useState("Selected List");

  const [tabMore, setTabMore] = useState("");
  useEffect(() => {
    if (txnId && txnId !== tabSelected) {
      if (tabs.includes(txnId)) setTabSelected(txnId);
      else setTabMore(txnId);
    }
  }, [txnId]);

  useEffect(() => {
    if (tabMore) {
      setTabs((tabs) => {
        tabs.pop();
        tabs.push(tabMore);
        return tabs;
      });
      setTabSelected(tabMore);
    }
  }, [tabMore]);

  function getContent() {
    switch (true) {
      case tabSelected === "Endorsement Markets":
        return <MallsList setList={setList} list={list} searchOn={searchOn} />;
      case tabSelected === "Endorsement Marketplace":
        return (
          <MarketplacesList setList={setList} list={list} searchOn={searchOn} />
        );
      case tabSelected === "Selected List" || tabSelected === "More":
        return <ViralMoreList setTabMore={setTabMore} />;
      default:
        break;
    }
  }

  return (
    <MainLayout active={"Viral"} hide={hide}>
      <NavbarGodsEye
        tabs={tabs}
        tabSelected={tabSelected}
        setTabSelected={(tab) => {
          history.push(`/viral/${tab}`);
        }}
        dropDownOpen={false}
        search={search}
        setSearch={setSearch}
        viewSwitcher={
          <IconsViewSwitcher
            listIcn={list}
            boardIcn={<RefreshIcn />}
            board={refresh}
            onBoardClick={() => {
              if (refresh) setRefresh(false);
              else {
                setRefresh(true);
                setList(false);
              }
            }}
            list={list}
            onListClick={() => {
              if (list) setList(false);
              else {
                setRefresh(false);
              }
            }}
            search={searchOn}
            onSearchClick={() => setSearchOn(!searchOn)}
          />
        }
        tabClick={(tabItm) => {}}
        tabLastGroup
        tabIcn={images[tabSelected]}
      />
      <div className="marketsView">{getContent()}</div>
    </MainLayout>
  );
}

export default ViralPage;
