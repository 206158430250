import React, { useContext } from "react";
import Scrollbars from "react-custom-scrollbars";
import { MainContext } from "../../context/MainContext";
import apiIcn from "../../static/images/refreshIcon/api.svg";
import appIcn from "../../static/images/refreshIcon/app.svg";
import pageIcn from "../../static/images/refreshIcon/page.svg";
import tokenIcn from "../../static/images/refreshIcon/token.svg";

function GodsEyeRefreshSidebar() {
  const { setRefreshPage, validateToken } = useContext(MainContext);
  return (
    <div className="godsEyeUserFiter">
      <div className="headTabs">
        <div className="tab true">Refresh</div>
        <div className="tab disable">Action 1</div>
        <div className="tab disable">Action 2</div>
        <div className="tab disable">Action 3</div>
      </div>
      <div className="godsEyeFilterCurrency">
        <div className="labelItm">What Do You Want To Refresh?</div>
        <Scrollbars className="coinList">
          <div
            className="coinItem"
            onClick={() => {
              setRefreshPage("society");
              setTimeout(() => {
                setRefreshPage("");
              }, 100);
            }}
          >
            <img src={pageIcn} alt="" className="coin" />
            <span>This Page</span>
          </div>
          <div
            className="coinItem"
            onClick={() => {
              window.location.reload();
            }}
          >
            <img src={appIcn} alt="" className="coin" />
            <span>Entire App</span>
          </div>
          <div
            className="coinItem"
            onClick={() => {
              validateToken();
            }}
          >
            <img src={tokenIcn} alt="" className="coin" />
            <span>Check Token Status</span>
          </div>
          <div className="coinItem disable">
            <img src={apiIcn} alt="" className="coin" />
            <span>Specific API</span>
          </div>
        </Scrollbars>
      </div>
      <div className="footerBtns">
        <div className="btnReset">Reset</div>
        <div className="btnSave">Save</div>
      </div>
    </div>
  );
}

export default GodsEyeRefreshSidebar;
