import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import OtpInput from "react-otp-input";

import { MainContext } from "../context/MainContext";
import vaultLogo from "../static/images/logos/nvestLogo.svg";
import LoadingPage from "../components/LoadingPage/LoadingPage";

function CapitalLoginPage() {
  const history = useHistory();
  const [logged, setLogged] = useState(false);
  const [emailid, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { login, tostShowOn } = useContext(MainContext);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [twoFaPin, setTwoFaPin] = useState();
  const loginvalidate = (e) => {
    e.preventDefault();
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailid)) {
      setLoading(true);
      Axios.post("https://gxauth.apimachine.com/gx/user/login", {
        email: emailid,
        password,
        totp_code: twoFaPin?.toString(),
      })
        .then((response) => {
          const { data } = response;
          if (data.status) {
            login(emailid, data.accessToken, data.idToken);
            setLogged(true);
          } else if (data.mfa) {
            setMfaEnabled(true);
          } else {
            tostShowOn(data.message);
          }
        })
        .catch((error) => {
          tostShowOn(error.message ? error.message : "Some Thing Went Wrong!");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      tostShowOn("Enter Valid EmailId");
    }
  };
  return (
    <div className="loginPage">
      <div className="loginWrap">
        <img src={vaultLogo} alt="" className="logo" />
        <form className="login-form mx-5" onSubmit={loginvalidate}>
          {mfaEnabled ? (
            <>
              <div className="twoFaLabel">
                Enter The Code On Your Google Authenticator
              </div>
              <OtpInput
                containerStyle="otp-input-wrapper"
                value={twoFaPin}
                onChange={(otp) => setTwoFaPin(otp)}
                numInputs={6}
                separator={<span> </span>}
                inputStyle="otp-input"
              />
            </>
          ) : logged ? (
            <div className="succesMsg">
              Capitalizing The Next Era Of Human Civilization Will Be Available
              Shortly. Remember Rome Wasn’t Built In A Day
            </div>
          ) : (
            <>
              <div className="group">
                <input
                  type="text"
                  name="email"
                  value={emailid}
                  onChange={(e) => setEmailId(e.target.value)}
                  required="required"
                />
                <span className="highlight" />
                <span className="bar" />
                <label>Email</label>
              </div>
              <div className="group">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required="required"
                />
                <span className="highlight" />
                <span className="bar" />
                <FontAwesomeIcon
                  className="eye"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  icon={showPassword ? faEyeSlash : faEye}
                />
                <label>Password</label>
              </div>
            </>
          )}
          <div className="group">
            {logged ? (
              <button
                type="reset"
                className="btn btn-darkblue mb-5"
                onClick={() => history.push("/")}
              >
                Home Page
              </button>
            ) : (
              <button
                type="submit"
                disabled={loading}
                className="btn btn-darkblue mb-5"
              >
                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Login"}
              </button>
            )}
          </div>
        </form>
        {loading && <LoadingPage />}
      </div>
    </div>
  );
}

export default CapitalLoginPage;
