import React, { useContext, useState } from "react";
import Axios from "axios";
import JsonWebToken from "jsonwebtoken";
import Scrollbars from "react-custom-scrollbars";
import { useHistory } from "react-router";
import cloudUploadIcon from "../../static/images/postClipArt/cloudUpload.svg";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import { MainContext } from "../../context/MainContext";
import loadingGif from "../../static/animations/loading.gif";
import { useMutation } from "react-query";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faSpinner } from "@fortawesome/free-solid-svg-icons";
import CountryList from "./CountryList";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

async function registerEmail(email) {
  const { data } = await axios.post(
    "https://comms.globalxchange.io/gxb/app/gxlive/user/signup",
    {
      email: email,
    }
  );
  return data;
}

function NewEntrepreneur() {
  const history = useHistory();
  const { tostShowOn, email, token } = useContext(MainContext);
  const [step, setStep] = useState("");
  const [loading, setLoading] = useState(false);

  // Form Variables
  const [etEmail, setEtEmail] = useState("");
  const [icon, setIcon] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");
  const [name, setName] = useState("");
  const [primaryColor, setPrimaryColor] = useState("");
  const [country, setCountry] = useState("");
  const [stdCode, setStdCode] = useState("");
  const [number, setNumber] = useState("");
  const [supportEmail, setSupportEmail] = useState("");
  const [foundingDate, setFoundingDate] = useState("");
  const [colorCode, setColorCode] = useState("");

  const [iconLoading, setIconLoading] = useState(false);
  const [coverPhotoLoading, setCoverPhotoLoading] = useState(false);

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
    setLoading(false);
  };

  const {
    mutate: registerEmailMutate,
    data: registerEmailData,
    reset: resetRegisterEmail,
  } = useMutation(registerEmail, {});

  function getContent() {
    switch (step) {
      case "success":
        setTimeout(() => {
          history.push("/xid");
          window.location.reload();
        }, 2000);
        return (
          <div className="newConglomerate">
            <div className="succesView">
              <div className="labelItm">
                You Have Successfully Created {name}. You Will Be Redirected To
                The Updated Entrepronuer Categoroes List Automatically
              </div>
            </div>
          </div>
        );
      case "countryList":
        return (
          <div className="newConglomerate">
            <CountryList onClose={() => setStep("")} setCountry={setCountry} />
          </div>
        );
      default:
        return (
          <>
            <div className="newConglomerate">
              <Scrollbars
                className="scrollForm"
                renderTrackHorizontal={() => <div />}
                renderThumbHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
                renderThumbVertical={() => <div />}
              >
                <div className="name">Enter Email For Entrepreneur</div>
                <div className="inputWrap">
                  <input
                    type="text"
                    className="text"
                    placeholder="Enter Email"
                    value={etEmail}
                    onChange={(e) => setEtEmail(e.target.value)}
                  />
                  <div
                    className="btnCheck"
                    onClick={() => {
                      registerEmailData?.status
                        ? resetRegisterEmail()
                        : registerEmailMutate(etEmail);
                    }}
                  >
                    {registerEmailData?.status ? (
                      <FontAwesomeIcon icon={faSpinner} />
                    ) : (
                      "Register"
                    )}
                  </div>
                  {registerEmailData?.status ? (
                    <div className={`statusBar false`} />
                  ) : (
                    ""
                  )}
                  {registerEmailData?.status ? (
                    <div className={`status false`}>
                      {registerEmailData === "Succesfully Signed Up"
                        ? "This User Has Been Made Into An Entrepreneur"
                        : "This User Is Already An Entrepreneur"}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="name">Create Brand Name</div>
                <div className="inputWrap">
                  <input
                    type="text"
                    className="text"
                    placeholder="Enter Brand Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="name">Banker Country</div>
                {country?.Name ? (
                  <div className="user" onClick={() => setStep("countryList")}>
                    <img className="dp" src={country?.Flag} alt="" />
                    <div className="userDetail">
                      <div className="name">{country?.Name}</div>
                      <div className="email">+{country?.CountryCode}</div>
                    </div>
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                ) : (
                  <div
                    className="inputWrap"
                    onClick={() => setStep("countryList")}
                  >
                    <input
                      type="text"
                      className="text"
                      placeholder="Select The Country For The Banker"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}
                <div className="name">Upload Branding Material</div>
                <div className="filesUpload">
                  <label className="fileInp icon">
                    <img
                      className={`${Boolean(icon)}`}
                      src={iconLoading ? loadingGif : icon || cloudUploadIcon}
                      alt=""
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setIcon, setIconLoading);
                      }}
                      accept="image/*"
                    />
                    <div className="text">Coloured Icon</div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                  <label className="fileInp cover">
                    <img
                      className={`${Boolean(coverPhoto)}`}
                      src={
                        coverPhotoLoading
                          ? loadingGif
                          : coverPhoto || cloudUploadIcon
                      }
                      alt=""
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setCoverPhoto, setCoverPhotoLoading);
                      }}
                      accept="image/*"
                    />
                    <div className="text">Cover Photo</div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                  <label
                    className="fileInp icon"
                    style={{
                      visibility: "hidden",
                    }}
                  />
                </div>
                <div className="name">Enter Brand Support Email</div>
                <div className="inputWrap">
                  <input
                    type="text"
                    className="text"
                    placeholder="Enter Brand Support Email"
                    value={supportEmail}
                    onChange={(e) => setSupportEmail(e.target.value)}
                  />
                </div>
                <div className="name">Enter Brand Support Number</div>
                <div className="inputWrap">
                  <input
                    type="text"
                    className="text stdCode"
                    placeholder="00"
                    value={stdCode}
                    onChange={(e) => setStdCode(e.target.value)}
                  />
                  <input
                    type="text"
                    className="text"
                    placeholder="000-000-000"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
                <div className="name">Enter Brand Founding Date</div>
                <div className="inputWrap">
                  <input
                    type="text"
                    className="text"
                    placeholder="Enter Brand Founding Date"
                    value={foundingDate}
                    onChange={(e) => setFoundingDate(e.target.value)}
                  />
                </div>
                <div className="name">Enter Colour Code</div>
                <div className="inputWrap">
                  <input
                    type="text"
                    className="text"
                    placeholder="Enter Colour Code"
                    value={primaryColor}
                    onChange={(e) => setPrimaryColor(e.target.value)}
                  />
                  <div
                    className="color"
                    style={{ width: "10%", background: `#${primaryColor}` }}
                  />
                </div>
                <div className="space"></div>
              </Scrollbars>
            </div>
            <div className="ftBtns">
              <div className="newField" onClick={() => {}}>
                New Field
              </div>
              <div className="btnSubmit" onClick={() => validate(true)}>
                Submit
              </div>
            </div>
          </>
        );
    }
  }

  function addEntrepronuer() {
    setLoading(true);
    Axios.post(
      "https://comms.globalxchange.io/gxb/app/gxlive/user/operator/create",
      {
        email,
        token,
        brand_name: name,
        brand_logo: icon,
        brand_cover: coverPhoto,
        brand_country: country?.Name,
        brand_email: supportEmail,
        brand_phone: `${stdCode}${number}`,
        founding_date: foundingDate,
        other_data: {
          colour_code: primaryColor,
        },
      }
    )
      .then(({ data }) => {
        if (data.status === false) {
          tostShowOn(data.message || "API Error");
        } else setStep("success");
      })
      .catch((error) => {
        tostShowOn(error?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  }

  function validate() {
    if (
      (icon,
      coverPhoto,
      name,
      supportEmail,
      etEmail,
      stdCode,
      number,
      foundingDate,
      primaryColor)
    ) {
      addEntrepronuer();
    } else {
      tostShowOn("All Fields Are Mandatory");
    }
  }

  return (
    <>
      {getContent()}
      {loading && <LoadingAnim />}{" "}
    </>
  );
}

export default NewEntrepreneur;
