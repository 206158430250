import React, { useState } from "react";

function GodsEyeFilterDisplay({
  isUname,
  setIsUname,
  itemPerPage,
  setItemPerPage,
}) {
  const [newVal, setNewVal] = useState("");
  return (
    <>
      <div className="godsEyeFilterDisplay">
        <div className="labelItm">What Is Primary Identifier?</div>
        <div className="options">
          <div
            className={`option ${!isUname}`}
            onClick={() => setIsUname(false)}
          >
            Name
          </div>
          <div className={`option ${isUname}`} onClick={() => setIsUname(true)}>
            Username
          </div>
        </div>
        <div className="labelItm">
          How Many Users Do You Want To See Per Page
        </div>
        <div className="box">
          <div className="label">Current</div>
          <div className="value">{itemPerPage}</div>
        </div>
        <div className="box">
          <div className="label">New</div>
          <input
            className="value"
            value={newVal}
            onChange={(e) => setNewVal(e.target.value)}
          />
        </div>
      </div>
      <div className="footerBtns">
        <div className="btnReset" onClick={() => setItemPerPage(100)}>
          Reset
        </div>
        <div className="btnSave" onClick={() => setItemPerPage(newVal)}>
          Save
        </div>
      </div>
    </>
  );
}

export default GodsEyeFilterDisplay;
