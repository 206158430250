import React, { useEffect, useRef } from 'react'
import styles from './mainLayout.module.scss'

function MainLayout({
  children,
  className,
  hide,
  sidebar,
  navbar,
  primaryColor = '#334480',
  secondaryColor = '#555770'
}) {
  const ref = useRef()
  useEffect(() => {
    ref.current.style.setProperty('--primary-color', primaryColor)
    ref.current.style.setProperty('--secondary-color', secondaryColor)
    return () => {}
  }, [primaryColor])
  return (
    <div
      ref={ref}
      className={`${styles.mainLayout} ${hide && styles.hide} ${className}`}
    >
      {sidebar}
      <div className={`${styles.siteLayout} ${className}Layout`}>
        {navbar}
        {children}
      </div>
    </div>
  )
}

export default MainLayout
