import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";

function OperatorList({ setOperator, onClose }) {
  const [operators, setOperators] = useState([]);
  const [operatorLoading, setOperatorLoading] = useState(true);
  useEffect(() => {
    Axios.get("https://comms.globalxchange.io/gxb/app/gxlive/user/operator/get")
      .then(({ data }) => {
        setOperators(data.operators);
      })
      .finally(() => setOperatorLoading(false));
  }, []);
  const [search, setSearch] = useState("");
  return (
    <Fragment>
      <div className="titleOp">Select GXLive Operator</div>
      <div className="searchWrap">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Operator....|"
        />
      </div>
      <Scrollbars className="searchList">
        {operatorLoading
          ? Array(6)
              .fill("")
              .map((_, i) => (
                <div className="user" key={i}>
                  <Skeleton className="dp" circle />
                  <div className="userDetail">
                    <Skeleton className="name" width={200} />
                    <Skeleton className="email" width={200} />
                  </div>
                </div>
              ))
          : operators
              .filter(
                (operator) =>
                  operator.brand_name
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  operator.email.toLowerCase().includes(search.toLowerCase())
              )
              .map((operator) => (
                <div
                  className="user"
                  key={operator._id}
                  onClick={() => {
                    setOperator(operator);
                    onClose();
                  }}
                >
                  <img className="dp" src={operator.brand_logo} alt="" />
                  <div className="userDetail">
                    <div className="name">{operator.brand_name}</div>
                    <div className="email">{operator.email}</div>
                  </div>
                </div>
              ))}
        <div className="space"></div>
      </Scrollbars>
    </Fragment>
  );
}

export default OperatorList;
