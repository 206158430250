import React, { useContext, useState } from "react";
import Scrollbars from "react-custom-scrollbars";

import apiIcn from "../../static/images/refreshIcon/api.svg";
import appIcn from "../../static/images/refreshIcon/app.svg";
import pageIcn from "../../static/images/refreshIcon/page.svg";
import tokenIcn from "../../static/images/refreshIcon/token.svg";
import deleteIcn from "../../static/images/refreshIcon/delete.svg";
import Axios from "axios";
import { FormatNumber } from "../../utils/Functions";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import { MainContext } from "../../context/MainContext";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

function CentralBankSidebar({ coinSelected, coinsList }) {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [step, setStep] = useState("");
  const [from, setFrom] = useState(false);
  const [coinType, setCoinType] = useState("crypto");
  const [secondCoinList, setsecondCoinList] = useState([]);
  const [exchDetail, setExchDetail] = useState();
  const [spread, setSpread] = useState("");
  const [loading, setLoading] = useState(false);
  const { tostShowOn } = useContext(MainContext);
  const [coinSelect, setCoinSelect] = useState();

  const getDetails = (secondCoin) => {
    setCoinSelect(secondCoin);
    if (coinSelected && secondCoin) {
      setLoading(true);
      Axios.get(
        `https://comms.globalxchange.io/forex/getdetails?buy=${
          from ? secondCoin.coinSymbol : coinSelected.coinSymbol
        }&from=${from ? coinSelected.coinSymbol : secondCoin.coinSymbol}`
      )
        .then(({ data }) => {
          if (data.status) {
            setExchDetail(data);
            setStep("changeSpread");
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const changeFeeSingle = async (secondCoin) => {
    const { data } = await Axios.post(
      "https://comms.globalxchange.io/update_forex_percentage",
      {
        field: `${from ? secondCoin?.coinSymbol : coinSelected?.coinSymbol}_${
          from ? coinSelected?.coinSymbol : secondCoin?.coinSymbol
        }_per`.toLowerCase(),
        value: parseFloat(spread),
      }
    );
    if (data.status) {
      tostShowOn(
        `Percent Changed ${secondCoin?.coinSymbol}/${coinSelected.coinSymbol}`
      );
    } else {
      tostShowOn(data.message);
    }
  };
  const changeFee = async () => {
    setLoading(true);
    for (const coin of secondCoinList) {
      await changeFeeSingle(coin);
    }
    setLoading(false);
    history.push(`/centralbank/${coinSelected.type}`);
    window.location.reload();
  };

  function getContent() {
    switch (step) {
      case "EditPrimarySpread":
        return (
          <>
            <div className="godsEyeFilterCurrency ">
              <div className="labelItm">
                Select The Role {coinSelected?.coinName} Will Play In The Pair
              </div>
              <Scrollbars className="coinList">
                <div
                  className="coinItem"
                  onClick={() => {
                    setStep("SelectSecondaryCoin");
                    setFrom(true);
                  }}
                >
                  <img src={pageIcn} alt="" className="coin" />
                  <span>From {coinSelected?.coinName}</span>
                </div>
                <div
                  className="coinItem"
                  onClick={() => {
                    setStep("SelectSecondaryCoin");
                    setFrom(false);
                  }}
                >
                  <img src={appIcn} alt="" className="coin" />
                  <span>To {coinSelected?.coinName}</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );
      case "SelectSecondaryCoin":
        return (
          <>
            <div className="godsEyeFilterCurrency coinsList ">
              <div className="labelItm">
                Select {from ? "Base" : "Quote"} Currency
              </div>
              <div className="searchWraper">
                <input
                  type="text"
                  name="coin"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={`Search ${coinType} Currencies`}
                />
              </div>
              <div className="searchWraper">
                <div
                  className={`btnOption ${coinType === "crypto"}`}
                  onClick={() => setCoinType("crypto")}
                >
                  Crypto
                </div>
                <div
                  className={`btnOption ${coinType === "fiat"}`}
                  onClick={() => setCoinType("fiat")}
                >
                  Fiat
                </div>
              </div>
              <Scrollbars className="coinList">
                {coinsList
                  .filter((coin) => coin.coinSymbol !== coinSelected.coinSymbol)
                  .filter((coin) => coin.type === coinType)
                  .filter(
                    (coin) =>
                      coin.coinName
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      coin.coinSymbol
                        .toLowerCase()
                        .includes(search.toLowerCase())
                  )
                  .map((coin) => (
                    <div
                      className="coinItem"
                      key={coin._id}
                      onClick={() => {
                        if (secondCoinList.includes(coin)) {
                          setsecondCoinList(
                            secondCoinList.filter((coins) => coin !== coins)
                          );
                        } else {
                          setsecondCoinList([...secondCoinList, coin]);
                        }
                      }}
                    >
                      <img src={coin.coinImage} alt="" className="coin" />
                      <span>{coin?.coinName}</span>
                      <input
                        readOnly
                        type="checkbox"
                        checked={secondCoinList.includes(coin)}
                      />
                    </div>
                  ))}
              </Scrollbars>
              <div
                className="btnChangeFee"
                onClick={() =>
                  secondCoinList[0] && getDetails(secondCoinList[0])
                }
              >
                Change Fee
              </div>
            </div>
          </>
        );
      case "changeSpread":
        return (
          <>
            <div className="detailEdit">
              <div className="coinShow">
                <div className="from">
                  <span>From</span>
                  <Scrollbars className="scrollView">
                    {from ? (
                      <div className="box">
                        <img src={coinSelected.coinImage} alt="" />
                        <div className="coinName">{coinSelected.coinName}</div>
                      </div>
                    ) : (
                      secondCoinList.map((coin) => (
                        <div
                          className={`box ${coinSelect === coin}`}
                          onClick={() => getDetails(coin)}
                        >
                          <img src={coin.coinImage} alt="" />
                          <div className="coinName">{coin.coinName}</div>
                        </div>
                      ))
                    )}
                  </Scrollbars>
                </div>
                <div className="to">
                  <span>To</span>
                  <Scrollbars className="scrollView">
                    {from ? (
                      secondCoinList.map((coin) => (
                        <div
                          className={`box ${coinSelect === coin}`}
                          onClick={() => getDetails(coin)}
                        >
                          <img src={coin.coinImage} alt="" />
                          <div className="coinName">{coin.coinName}</div>
                        </div>
                      ))
                    ) : (
                      <div className="box">
                        <img src={coinSelected.coinImage} alt="" />
                        <div className="coinName">{coinSelected.coinName}</div>
                      </div>
                    )}
                  </Scrollbars>
                </div>
              </div>
              <div className="name">Current Spread</div>
              <div className="inpWrap">
                <input
                  type="text"
                  value={`${FormatNumber(exchDetail.gx_percent, 2)} %`}
                  readOnly
                />
              </div>
              <div className="name">New Spread</div>
              <div className="inpWrap">
                <input
                  type="text"
                  value={spread}
                  onChange={(e) => setSpread(e.target.value)}
                  placeholder="1.00 %"
                />
                <div className="btSpread" onClick={() => setSpread(0.0)}>
                  0.00%
                </div>
                <div
                  className="btSpread"
                  onClick={() => setSpread(exchDetail.gx_percent / 2)}
                >
                  50.00%
                </div>
              </div>
            </div>
            <div className="btnChangeFee" onClick={() => changeFee()}>
              Change Fee
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="godsEyeFilterCurrency ">
              <div className="labelItm">
                Select One Of The Following Actions
              </div>
              <Scrollbars className="coinList">
                <div className="coinItem">
                  <img src={pageIcn} alt="" className="coin" />
                  <span>Adjust Interest Rates</span>
                </div>
                <div className="coinItem">
                  <img src={appIcn} alt="" className="coin" />
                  <span>Create Bond</span>
                </div>
                <div
                  className="coinItem"
                  onClick={() => setStep("EditPrimarySpread")}
                >
                  <img src={tokenIcn} alt="" className="coin" />
                  <span>Edit Primary Spread</span>
                </div>
                <div className="coinItem">
                  <img src={apiIcn} alt="" className="coin" />
                  <span>Change Price Data Source</span>
                </div>
                <div className="coinItem">
                  <img src={deleteIcn} alt="" className="coin" />
                  <span>Delete Asset</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );
    }
  }
  return (
    <div className="godsEyeUserFiter cenralBankSidebar">
      <div className="headCoin">
        <img src={coinSelected.coinImage} alt="" />
        <div className="nameNdetail">
          <div className="name">{coinSelected.coinName}</div>
          <div className="spread">
            <span onClick={() => setStep("")}>{coinSelected.coinName}</span>&gt;
            <span onClick={() => setStep("")}>Actions</span>
            {step && (
              <>
                &gt; <span>{step}</span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="headTabBox">
        <div className="btBack" onClick={() => setStep("")}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div className="tab false">About</div>
        <div className="tab true">Actions</div>
      </div>
      {getContent()}
      {loading && <LoadingAnim />}
    </div>
  );
}

export default CentralBankSidebar;
