import React, { useState, useEffect, useCallback } from "react";
import Scrollbars from "react-custom-scrollbars";
import Axios from "axios";
import UpdateDepositModal from "../UpdateDepositModal/UpdateDepositModal";
import WithdrawListItemAsList from "../WithdrawListItem/WithdrawListItemAsList";
import Skeleton from "react-loading-skeleton";
import CopyPublicationDetailModal from "../CopyPublicationDetailModal/CopyPublicationDetailModal";
import BankerTxnSidebar from "../BankerTxnSidebar/BankerTxnSidebar";
import { SearchAndFilter } from "../BrokerDashboard/index";
import BankerTxnColumnn from "../BankerTxnColoumn/BankerTxnColoumn";

function BankerWithdraw({
  selectedBanker,
  isList,
  searchOn,
  filteron,
  setFilterOn,
  txnSelected,
  setTxnSelected,
}) {
  const [transactionList, setTransactionList] = useState([]);
  const [appCode, setAppCode] = useState("");
  const [coinSymbol, setCoinSymbol] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);
  const [toCopy, setToCopy] = useState(false);

  const updateTxnList = useCallback(() => {
    setLoading(true);
    Axios.get(
      `https://comms.globalxchange.io/coin/vault/service/txns/deposit/get?${
        appCode ? "&app_code=" + appCode : ""
      }${coinSymbol ? "&coin=" + coinSymbol : ""}${
        status ? "&status=" + status : ""
      }${
        selectedBanker &&
        selectedBanker.bankerTag &&
        `&banker=${selectedBanker.bankerTag}`
      }`
    )
      .then((res) => {
        const { data } = res;
        if (data.status) {
          setTransactionList(data.txns);
        }
      })
      .finally(() => setLoading(false));
  }, [appCode, coinSymbol, status, selectedBanker]);

  useEffect(() => {
    updateTxnList();
  }, [appCode, coinSymbol, status, selectedBanker, updateTxnList]);

  const [search, setSearch] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const [filterEmail, setFilterEmail] = useState(false);
  const [toDelete, setToDelete] = useState("");
  const [type, setType] = useState("");

  const [upline, setUpline] = useState(false);
  const [colSelected, setColSelected] = useState("");
  const [initiatedTotal, setInitiatedTotal] = useState(0);
  const [completedTotal, setCompletedTotal] = useState(0);
  const [cancelledTotal, setCancelledTotal] = useState(0);
  const [allTotal, setAllTotal] = useState(0);

  useEffect(() => {
    let init = 0;
    transactionList
      .filter(
        (txn) =>
          txn.current_step_data && txn.current_step_data.status === "Initiated"
      )
      .forEach((txn) => {
        init += txn?.usd_value || 0;
      });
    setInitiatedTotal(init);

    let completed = 0;
    transactionList
      .filter(
        (txn) =>
          txn.current_step_data && txn.current_step_data.status === "Completed"
      )
      .forEach((txn) => {
        completed += txn?.usd_value || 0;
      });
    setCompletedTotal(completed);

    let cancelled = 0;
    transactionList
      .filter(
        (txn) =>
          txn.current_step_data && txn.current_step_data.status === "Cancelled"
      )
      .forEach((txn) => {
        cancelled += txn?.usd_value || 0;
      });
    setCancelledTotal(cancelled);

    let all = 0;
    transactionList.forEach((txn) => {
      all += txn?.usd_value || 0;
    });
    setAllTotal(all);
  }, [transactionList]);

  useEffect(() => {
    if (!filteron) {
      setTxnSelected();
    }
  }, [filteron, setTxnSelected]);
  useEffect(() => {
    if (txnSelected) {
      setFilterOn(true);
    }
  }, [setFilterOn, txnSelected]);

  const [coinList, setCoinList] = useState([]);
  const [coinLoading, setCoinLoading] = useState(false);
  useEffect(() => {
    setCoinLoading(true);
    Axios.post("https://comms.globalxchange.io/coin/vault/service/coins/get", {
      app_code: "ice",
    })
      .then((res) => {
        const { data } = res;
        if (data.status) {
          const { coins_data } = data;
          setCoinList(coins_data);
        }
      })
      .finally(() => setCoinLoading(false));
  }, []);

  return (
    <div className="withdrawalViewContent">
      {searchOn && (
        <SearchAndFilter
          search={search}
          setSearch={setSearch}
          placeholder={"Search All Withdrawals"}
          filterBy={`${"Email"}`}
          mainList={[
            {
              key: "0",
              label: "Function Performed By Search Bar",
              switch: isFilter,
              switchLabel: isFilter ? "Filter" : "Search",
              switchClick: () => setIsFilter(!isFilter),
            },
            {
              key: "1",
              label: "Filter Range",
              switch: currentPage,
              switchLabel: currentPage ? "Current Page" : "Entire List",
              switchClick: () => setCurrentPage(!currentPage),
            },
          ]}
          filterList={[
            {
              key: "11",
              label: "Email",
              switch: filterEmail,
              switchLabel: filterEmail ? "On" : "Off",
              switchClick: () => setFilterEmail(!filterEmail),
            },
          ]}
        />
      )}
      <div className={`withdrawListNFilter`}>
        {isList ? (
          <Scrollbars
            autoHide
            className="withdrawListWrapperAsList"
            renderView={(props) => <div {...props} className="withdrawList" />}
          >
            {loading
              ? [1, 2, 3, 4, 5, 6, 7, 8].map(() => (
                  <div className="userItemWithdraw">
                    <Skeleton circle height={50} width={50} />
                    <div className="email">
                      <Skeleton />
                    </div>
                    <div className="date">
                      <Skeleton />
                    </div>
                    <div className="value">
                      <Skeleton />
                    </div>
                    <div className="actions">
                      <div className="btnAction">
                        <Skeleton />
                      </div>
                      <div className="btnAction">
                        <Skeleton />
                      </div>
                      <div className="btnAction">
                        <Skeleton />
                      </div>
                    </div>
                  </div>
                ))
              : transactionList
                  .filter((txn) =>
                    txn.email.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((txn) => (
                    <WithdrawListItemAsList
                      key={txn._id}
                      txn={txn}
                      onAction={(id, type) => {
                        setToDelete(id);
                        setType(type);
                      }}
                    />
                  ))}
          </Scrollbars>
        ) : (
          <Scrollbars
            autoHide
            className="withdrawListWrapper"
            renderView={(props) => <div {...props} className="withdrawList" />}
          >
            <BankerTxnColumnn
              isDeposit
              txnSelected={txnSelected}
              setTxnSelected={setTxnSelected}
              colSelected={colSelected}
              setColSelected={setColSelected}
              coinList={coinList}
              coinLoading={coinLoading}
              loading={loading}
              transactionList={transactionList}
              search={search}
              columnTotal={initiatedTotal}
              column="Initiated"
            />
            <BankerTxnColumnn
              isDeposit
              txnSelected={txnSelected}
              setTxnSelected={setTxnSelected}
              colSelected={colSelected}
              setColSelected={setColSelected}
              coinList={coinList}
              coinLoading={coinLoading}
              loading={loading}
              transactionList={transactionList}
              search={search}
              columnTotal={completedTotal}
              column="Completed"
            />
            <BankerTxnColumnn
              isDeposit
              txnSelected={txnSelected}
              setTxnSelected={setTxnSelected}
              colSelected={colSelected}
              setColSelected={setColSelected}
              coinList={coinList}
              coinLoading={coinLoading}
              loading={loading}
              transactionList={transactionList}
              search={search}
              columnTotal={cancelledTotal}
              column="Cancelled"
            />
            <BankerTxnColumnn
              isDeposit
              txnSelected={txnSelected}
              setTxnSelected={setTxnSelected}
              colSelected={colSelected}
              setColSelected={setColSelected}
              coinList={coinList}
              coinLoading={coinLoading}
              loading={loading}
              transactionList={transactionList}
              search={search}
              columnTotal={allTotal}
              column="All"
            />
          </Scrollbars>
        )}
        {txnSelected && (
          <BankerTxnSidebar
            txnSelected={txnSelected}
            upline={upline}
            setUpline={setUpline}
            setType={setType}
            setToDelete={setToDelete}
            no="update"
            yes="credit"
          />
        )}
      </div>
      {toDelete && (
        <UpdateDepositModal
          txnId={toDelete}
          type={type}
          onClose={() => setToDelete("")}
          bankerMail={selectedBanker && selectedBanker.email}
          onSuccess={() => updateTxnList()}
        />
      )}
      {toCopy && (
        <CopyPublicationDetailModal
          publication={toCopy}
          onClose={() => setToCopy(false)}
          onSuccess={() => updateTxnList()}
        />
      )}
    </div>
  );
}

export default BankerWithdraw;
