import React from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import MainContextProvider from "./context/MainContext";
import TabsContextProvider from "./context/TabsContext";
import Routes from "./Routes";
import "./static/scss/master.scss";
import "./components/BrokerDashboard/index";
import MoreTabContextProvider from "./context/MoreTabContext";

const queryClient = new QueryClient();
function AppBadVault() {
  return (
    <QueryClientProvider client={queryClient}>
      <MainContextProvider>
        <BrowserRouter>
          <TabsContextProvider>
            <MoreTabContextProvider>
              <Routes />
            </MoreTabContextProvider>
          </TabsContextProvider>
        </BrowserRouter>
      </MainContextProvider>
    </QueryClientProvider>
  );
}

export default AppBadVault;
