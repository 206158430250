import React, { useContext, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import back from "../../static/images/back.svg";
import close from "../../static/images/close.svg";
import editApp from "../../static/images/clipIcons/appData.svg";
import userIcon from "../../static/images/clipIcons/userIcon.svg";
import deleteApp from "../../static/images/refreshIcon/delete.svg";
import bondIcon from "../../static/images/clipIcons/bondIcon.svg";
import plusIcon from "../../static/images/clipIcons/plus.svg";
import closeIcon from "../../static/images/clipIcons/no.svg";
import UserList from "./UserList";
import axios from "axios";
import { useMutation } from "react-query";
import { MainContext } from "../../context/MainContext";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import BondsList from "./BondsList";

async function addingUsersFn(params) {
  const { data } = await axios.post(
    "https://comms.globalxchange.io/coin/iced/banker/custom/bond/user/list/add",
    params
  );
  return data;
}
async function addingBondsFn(params) {
  const { data } = await axios.post(
    "https://comms.globalxchange.io/coin/iced/banker/custom/bond/add/list",
    params
  );
  return data;
}

function TokenAppSidebar({ bondTiersSelected, onClose, getBondList }) {
  const { token } = useContext(MainContext);
  const [step, setStep] = useState("");
  const [subStep, setSubStep] = useState("");
  const [tab, setTab] = useState("Actions");

  const [addingUsers, setAddingUsers] = useState([]);
  const [addingBondList, setAddingBondList] = useState([]);

  const {
    mutate: addingUsersMutate,
    // data: addingUsersData,
    // reset: resetAddingUsers,
    isLoading: addingUserLoading,
  } = useMutation(addingUsersFn, {
    onSuccess: () => {
      getBondList();
    },
  });

  const {
    mutate: addingBondsMutate,
    // data: addingUsersData,
    // reset: resetAddingUsers,
    isLoading: addingBondsLoading,
  } = useMutation(addingBondsFn, {
    onSuccess: () => {
      getBondList();
    },
  });

  function getContent() {
    switch (true) {
      case tab === "Actions" &&
        step === "Edit Tier Bonds" &&
        subStep === "Add New":
        return (
          <>
            <BondsList
              email={bondTiersSelected?.email}
              addingBonds={addingBondList}
              setAddingBonds={setAddingBondList}
            />
            <div className="footerBtns">
              <div className="btnReset" onClick={() => setSubStep("")}>
                Go Back
              </div>
              <div
                className="btnSave"
                onClick={() => {
                  addingBondsMutate({
                    list_id: bondTiersSelected?.list_id,
                    bond_template_ids: addingBondList.map(
                      (bond) => bond?.bond_template_id
                    ),
                    email: bondTiersSelected?.email,
                    token: token,
                  });
                }}
              >
                Done Selecting
              </div>
            </div>
          </>
        );
      case tab === "Actions" && step === "Edit Tier Bonds":
        return (
          <>
            <div className="godsEyeFilterCurrency" style={{ paddingTop: 0 }}>
              <div className="labelItm">
                Select One Of The Following Actions
              </div>
              <Scrollbars className="coinList">
                <div
                  className="coinItem"
                  onClick={() => {
                    setSubStep("Add New");
                  }}
                >
                  <img
                    src={plusIcon}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Add New Bond</span>
                </div>
                <div className="coinItem" onClick={() => setStep("")}>
                  <img
                    src={closeIcon}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Remove Existing Bond</span>
                </div>
              </Scrollbars>
            </div>
            <div className="footerBtns">
              <div
                className="btnReset"
                style={{
                  flex: 1,
                }}
                onClick={() => {
                  setStep("");
                  setSubStep("");
                }}
              >
                Go Back
              </div>
            </div>
          </>
        );

      case tab === "Actions" &&
        step === "Edit Tier Users" &&
        subStep === "Add New":
        return (
          <>
            <UserList
              addingUsers={addingUsers}
              setAddingUsers={setAddingUsers}
              appCode={bondTiersSelected?.app_code}
            />
            <div className="footerBtns">
              <div className="btnReset" onClick={() => setSubStep("")}>
                Go Back
              </div>
              <div
                className="btnSave"
                onClick={() => {
                  addingUsersMutate({
                    email: bondTiersSelected?.email,
                    token: token,
                    list_id: bondTiersSelected?.list_id,
                    email_list: addingUsers.map((usr) => usr.email),
                  });
                }}
              >
                Done Selecting
              </div>
            </div>
          </>
        );
      case tab === "Actions" && step === "Edit Tier Users":
        return (
          <>
            <div className="godsEyeFilterCurrency" style={{ paddingTop: 0 }}>
              <div className="labelItm">
                Select One Of The Following Actions
              </div>
              <Scrollbars className="coinList">
                <div
                  className="coinItem"
                  onClick={() => {
                    setSubStep("Add New");
                  }}
                >
                  <img
                    src={plusIcon}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Add New User</span>
                </div>
                <div className="coinItem" onClick={() => setStep("")}>
                  <img
                    src={closeIcon}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Remove Existing User</span>
                </div>
              </Scrollbars>
            </div>
            <div className="footerBtns">
              <div
                className="btnReset"
                style={{
                  flex: 1,
                }}
                onClick={() => {
                  setStep("");
                  setSubStep("");
                }}
              >
                Go Back
              </div>
            </div>
          </>
        );
      case tab === "Actions":
        return (
          <>
            <div className="godsEyeFilterCurrency" style={{ paddingTop: 0 }}>
              <div className="labelItm">
                Select One Of The Following Actions
              </div>
              <Scrollbars className="coinList">
                <div
                  className="coinItem"
                  onClick={() => {
                    setStep("Edit");
                  }}
                >
                  <img
                    src={editApp}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Edit Tier Data</span>
                </div>
                <div
                  className="coinItem"
                  onClick={() => setStep("Edit Tier Users")}
                >
                  <img
                    src={userIcon}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Edit Tier Users</span>
                </div>
                <div
                  className="coinItem"
                  onClick={() => setStep("Edit Tier Bonds")}
                >
                  <img
                    src={bondIcon}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Edit Tier Bonds</span>
                </div>
                <div
                  className="coinItem"
                  onClick={() => {
                    setStep("Delete");
                  }}
                >
                  <img
                    src={deleteApp}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Delete Tier</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );
      default:
        return;
    }
  }
  return (
    <div
      className="godsEyeUserFiter"
      style={{
        position: step === "Edit" ? "unset" : "",
      }}
    >
      <div className="headTabBox">
        <div
          className={`tab ${tab === "About"}`}
          onClick={() => {
            setTab("About");
            setStep("");
          }}
        >
          About
        </div>
        <div
          className={`tab ${tab === "Actions"}`}
          onClick={() => {
            setTab("Actions");
            setStep("");
          }}
        >
          Actions
        </div>
      </div>
      <div className="header">
        <div className="content">
          <div className="title">
            <img src={bondTiersSelected?.icon} alt="" className="icon" />
            {bondTiersSelected?.name}
          </div>
          <div className="breadCrumbs">
            <span
              onClick={() => {
                setStep("");
                setSubStep("");
              }}
            >
              {tab}
            </span>{" "}
            {step ? (
              <>
                -&gt;&nbsp;
                <span
                  onClick={() => {
                    setSubStep("");
                  }}
                >
                  {step}
                </span>
              </>
            ) : (
              ""
            )}
            {subStep ? (
              <>
                -&gt;&nbsp;
                <span>{subStep}</span>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        {step ? (
          <div
            className="backBtn"
            onClick={() => {
              setStep("");
            }}
          >
            <img src={back} alt="" />
          </div>
        ) : (
          ""
        )}
        <div
          className="backBtn"
          onClick={() => {
            onClose();
          }}
        >
          <img src={close} alt="" />
        </div>
      </div>
      {getContent()}
      {addingUserLoading && <LoadingAnim />}
    </div>
  );
}

export default TokenAppSidebar;
