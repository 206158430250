import React, { useEffect, useState } from "react";
import NewArticle from "./NewArticle";
import NewPostLander from "./NewPostLander";
import NewVideoPost from "./NewVideoPost";

function NewPost({ publication, pType }) {
  const [postType, setPostType] = useState("");
  useEffect(() => {
    setPostType(pType);
  }, [pType]);
  const getContent = () => {
    switch (postType) {
      case "video":
        return <NewVideoPost publication={publication} />;
      case "article":
        return <NewArticle publication={publication} />;
      default:
        return <NewPostLander setPostType={setPostType} />;
    }
  };
  return <div className="newPost">{getContent()}</div>;
}

export default NewPost;
