import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid/dist";
import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { MainContext } from "../../context/MainContext";
import { FormatCurrency } from "../../utils/Functions";
import guest from "../../static/images//guest.jpg";
import LoadingAnim from "../LoadingAnim/LoadingAnim";

function AdminCredit({ userSelected, onBack, onClose }) {
  const { token, tostShowOn } = useContext(MainContext);
  const [appSelected, setAppSelected] = useState();
  const [coinSelected, setCoinSelected] = useState();
  const [coinAmt, setCoinAmt] = useState();
  const [note, setNote] = useState();
  const [txnType, setTxnType] = useState("debit");
  const [step, setStep] = useState("");
  const [search, setSearch] = useState("");
  const [userApps, setUserApps] = useState([]);
  const [coinList, setCoinList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState();
  useEffect(() => {
    setSearch("");
  }, [step]);
  useEffect(() => {
    Axios.get(
      `https://comms.globalxchange.io/gxb/apps/registered/user?email=${userSelected?.userData?.email}`
    ).then((res) => {
      const { data } = res;
      if (data.status) {
        setUserApps(data.userApps);
      }
    });
    return () => {};
  }, [userSelected]);
  useEffect(() => {
    appSelected &&
      Axios.post(
        "https://comms.globalxchange.io/coin/vault/service/coins/get",
        {
          app_code: appSelected?.app_code,
          profile_id: appSelected?.profile_id,
        }
      ).then(({ data }) => {
        if (data.status) {
          setCoinList(data.coins_data);
        } else {
          setCoinList([]);
        }
      });
  }, [appSelected]);

  async function debiOrCredit() {
    setLoading(true);
    const data =
      txnType === "credit"
        ? {
            token: token,
            email: userSelected?.userData?.email,
            app_code: appSelected?.app_code,
            profile_id: appSelected?.profile_id,
            value: parseFloat(coinAmt),
            coin: coinSelected?.coinSymbol,
            identifier: uuidv4(),
            credit_from: note,
            txn_metadata: {
              admin_credit: true,
            },
          }
        : {
            token: token,
            email: userSelected?.userData?.email,
            app_code: appSelected?.app_code,
            profile_id: appSelected?.profile_id,
            value: parseFloat(coinAmt),
            coin: coinSelected?.coinSymbol,
            identifier: uuidv4(),
            payment_for: note,
          };
    const resOne = await Axios.post(
      "https://comms.globalxchange.io/coin/vault/service/encode/decode/data",
      {
        data: data,
        encode: true,
      }
    );
    const dataOne = resOne.data;
    if (dataOne?.status) {
      const resTwo = await Axios.post(
        `https://comms.globalxchange.io/coin/vault/service/${txnType}`,
        {
          data: dataOne.data,
        }
      );
      if (resTwo?.data.status) {
        setStep("success");
        setResData(resTwo?.data);
      } else {
        tostShowOn(resTwo?.data.message, "Error");
      }
    }
    setLoading(false);
  }

  function getContent() {
    switch (step) {
      case "success":
        return (
          <>
            <Scrollbars
              className="formArea"
              renderTrackVertical={() => <div />}
              renderTrackHorizontal={() => <div />}
              renderThumbVertical={() => <div />}
              renderThumbHorizontal={() => <div />}
            >
              <div className="group">
                <div className="labl fwn">
                  {`You Have Successfully ${txnType}ed ${userSelected?.userData?.name}`}
                </div>
              </div>
              <div className="group my-5">
                <div className="labl">Previous Balance</div>
                <div className="boxValue aic big">
                  <img src={coinSelected?.coinImage} alt="" />
                  <span>{coinSelected?.coinName}</span>{" "}
                  <span className="value">
                    {FormatCurrency(
                      resData?.current_balance,
                      coinSelected?.coinSymbol
                    )}
                  </span>
                </div>
              </div>
              <div className="group my-5">
                <div className="labl">New Balance</div>
                <div className="boxValue aic big">
                  <img src={coinSelected?.coinImage} alt="" />
                  <span>{coinSelected?.coinName}</span>{" "}
                  <span className="value">
                    {FormatCurrency(
                      resData?.updated_balance,
                      coinSelected?.coinSymbol
                    )}
                  </span>
                </div>
              </div>
            </Scrollbars>
          </>
        );
      case "selectCoin":
        return (
          <>
            <div className="godsEyeFilterCurrency ">
              <div className="labelItm">
                Which Of {userSelected?.userData?.name}’s {appSelected.app_name}{" "}
                Vaults Are You {txnType}ing?
              </div>
              <input
                type="text"
                className="searchBox mb20"
                placeholder="Search Vaults"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Scrollbars className="coinList">
                {coinList
                  ?.filter(
                    (coin) =>
                      coin.coinName
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      coin.coinSymbol
                        .toLowerCase()
                        .includes(search.toLowerCase())
                  )
                  .map((coin) => (
                    <div
                      className="coinItem"
                      onClick={() => {
                        setCoinSelected(coin);
                        setStep("");
                      }}
                    >
                      <img src={coin.coinImage} alt="" className="coin" />
                      <span>{coin.coinName}</span>
                      <div className="value">
                        <span className="native">
                          {FormatCurrency(coin.coinValue, coin.coinSymbol)}
                        </span>
                        <span className="usd">
                          ${FormatCurrency(coin.coinValueUSD)}
                        </span>
                      </div>
                    </div>
                  ))}
              </Scrollbars>
            </div>
          </>
        );
      case "selectApp":
        return (
          <>
            <div className="godsEyeFilterCurrency ">
              <div className="labelItm">
                Which Of {userSelected?.userData?.name}’s Apps Are You {txnType}
                ing?
              </div>
              <input
                type="text"
                className="searchBox mb20"
                placeholder="Search Registered Apps"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Scrollbars className="coinList">
                {userApps
                  ?.filter(
                    (app) =>
                      app.app_name
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      app.app_code.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((app) => (
                    <div
                      className="coinItem"
                      onClick={() => {
                        setAppSelected(app);
                        setStep("");
                      }}
                    >
                      <img src={app.app_icon} alt="" className="coin" />
                      <span>{app.app_name}</span>
                    </div>
                  ))}
              </Scrollbars>
            </div>
          </>
        );
      default:
        return (
          <>
            <Scrollbars
              className="formArea"
              renderTrackVertical={() => <div />}
              renderTrackHorizontal={() => <div />}
              renderThumbVertical={() => <div />}
              renderThumbHorizontal={() => <div />}
            >
              <div className="group">
                <div className="labl">Type Of Transaction</div>
                <div className="box">
                  <div
                    className={`debit ${txnType === "debit"}`}
                    onClick={() => setTxnType("debit")}
                  >
                    <FontAwesomeIcon icon={faCaretDown} className="down" />{" "}
                    Debit
                  </div>
                  <div
                    className={`credit ${txnType === "credit"}`}
                    onClick={() => setTxnType("credit")}
                  >
                    <FontAwesomeIcon icon={faCaretUp} className="up" /> Credit
                  </div>
                </div>
              </div>
              <div className="group">
                <div className="labl">Select App</div>
                <div
                  className="boxValue aic"
                  onClick={() => setStep("selectApp")}
                >
                  {appSelected ? (
                    <>
                      <img src={appSelected.app_icon} alt="" />
                      <span>{appSelected.app_name}</span>{" "}
                    </>
                  ) : (
                    <span className="select">Select App</span>
                  )}
                </div>
              </div>
              <div className="group">
                <div className="labl">Select Vault</div>
                <div
                  className="boxValue aic"
                  onClick={() => setStep("selectCoin")}
                >
                  {coinSelected ? (
                    <>
                      <img src={coinSelected.coinImage} alt="" />
                      <span>{coinSelected.coinName}</span>{" "}
                      <span className="value">
                        {FormatCurrency(
                          coinSelected.coinValue,
                          coinSelected.coinSymbol
                        )}
                      </span>
                    </>
                  ) : (
                    <span className="select">Select Vault</span>
                  )}
                </div>
              </div>
              <div className="group">
                <div className="labl">
                  How Much {coinSelected?.coinName || "_________"} Needs To Be{" "}
                  {txnType}ed
                </div>
                <div className="boxValue">
                  <input
                    type="text"
                    value={coinAmt}
                    placeholder="0.0000"
                    onChange={(e) => setCoinAmt(e.target.value)}
                  />
                  {coinSelected && (
                    <div className="coinDetail">
                      <img src={coinSelected.coinImage} alt="" />
                      <span>{coinSelected.coinName}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="group">
                <div className="labl">Notes Regarding The {txnType}</div>
                <div className="boxValue">
                  <input
                    type="text"
                    placeholder="Enter Reason Here...."
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </div>
              </div>
            </Scrollbars>
            <div className="btCredit" onClick={debiOrCredit}>
              {txnType}
            </div>
          </>
        );
    }
  }

  return (
    <>
      {getContent()}
      {loading && <LoadingAnim />}
    </>
  );
}

export default AdminCredit;
