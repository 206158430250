import React, { useEffect, useState } from "react";
import back from "../../static/images/back.svg";
import close from "../../static/images/close.svg";
import copy from "../../static/images/clipIcons/copy.svg";
import classNames from "./tokenAppEdit.module.scss";
import axios from "axios";
import moment from "moment";

function TokenAppEdit({ tokenAppSelected, onBack }) {
  const [readOnly, setReadOnly] = useState(true);
  const [tokenAppData, setTokenAppData] = useState();
  const [socialList, setSocialList] = useState([]);
  useEffect(() => {
    Promise.all([
      axios.get(
        `https://comms.globalxchange.io/gxb/apps/get?app_code=${tokenAppSelected?.app_code}`
      ),
      axios.get(
        `https://comms.globalxchange.io/gxb/apps/get/colors?app_code=${tokenAppSelected?.app_code}`
      ),
    ]).then((res) => {
      const data = res?.[0]?.data?.apps?.[0];
      data.colorData = res?.[1]?.data?.Apps?.[0]?.colors;
      setSocialList(res?.[0]?.data?.appSocialPlatforms);
      setTokenAppData(data);
    });
    return () => {};
  }, [tokenAppSelected]);

  return (
    <div className={classNames.editView}>
      <div className={classNames.header}>
        <div className={classNames.detail}>
          <img
            src={tokenAppSelected.app_icon}
            alt=""
            className={classNames.icon}
          />
          <div className={classNames.texts}>
            <div className={classNames.title}>{tokenAppSelected.app_name}</div>
            <div className={classNames.subTitle}>
              Created By
              <img src={tokenAppSelected?.operatorData?.brand_logo} alt="" />
              <span>{tokenAppSelected?.operatorData?.brand_name}</span>
            </div>
          </div>
        </div>
        <div className={classNames.backBtn} onClick={onBack}>
          <img src={back} alt="" />
        </div>
        <div className={classNames.backBtn} onClick={() => {}}>
          <img src={close} alt="" />
        </div>
      </div>
      <div className={classNames.appContent}>
        <div className={classNames.inpRow}>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>App ID:</div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?._id}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>App Name:</div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.app_name}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>App Code:</div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.app_code}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
        </div>
        <div className={classNames.inpRow}>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>Profile Start Code:</div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.profile_start_code}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>GXNative App:</div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.GXNativeapp}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>Mobile App:</div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.mobileApp}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
        </div>
        <div className={classNames.inpRow}>
          <div className={`${classNames.inpGrp}`}>
            <div className={classNames.label}>Short Description:</div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.short_description}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
        </div>
        <div className={classNames.inpRow}>
          <div className={`${classNames.inpGrp}`}>
            <div className={classNames.label}>Long Description:</div>
            <div className={classNames.inpWrap}>
              <textarea
                type="text"
                className={classNames.textarea}
                cols={30}
                defaultValue={tokenAppData?.long_description}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
        </div>
        <div className={classNames.inpRow}>
          <div
            className={`${classNames.inpGrp} ${classNames.color} ${classNames.w3}`}
          >
            <div className={classNames.label}>Colour Codes Basic:</div>
            <div className={classNames.inpWrap}>
              <div
                className={classNames.colorBar}
                style={{
                  backgroundColor: `#${tokenAppData?.color_codes?.[0]?.replace(
                    "#",
                    ""
                  )}`,
                }}
              />
              <input
                type="text"
                className={classNames.input}
                cols={30}
                defaultValue={tokenAppData?.color_codes?.[0]}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
            <div className={classNames.inpWrap}>
              <div
                className={classNames.colorBar}
                style={{
                  backgroundColor: `#${tokenAppData?.color_codes?.[1]?.replace(
                    "#",
                    ""
                  )}`,
                }}
              />
              <input
                type="text"
                className={classNames.input}
                cols={30}
                defaultValue={tokenAppData?.color_codes?.[1]}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
        </div>
        <div className={classNames.inpRow}>
          <div
            className={`${classNames.inpGrp} ${classNames.color} ${classNames.w3}`}
          >
            <div className={classNames.label}>Main Colour Code:</div>
            <div className={classNames.inpWrap}>
              <div
                className={classNames.colorBar}
                style={{
                  backgroundColor: `#${tokenAppData?.colorData?.mainColor?.replace(
                    "#",
                    ""
                  )}`,
                }}
              />
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.colorData?.mainColor}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
          <div
            className={`${classNames.inpGrp} ${classNames.color} ${classNames.w3}`}
          >
            <div className={classNames.label}>Text Colour Code:</div>
            <div className={classNames.inpWrap}>
              <div
                className={classNames.colorBar}
                style={{
                  backgroundColor: `#${tokenAppData?.colorData?.textColor?.replace(
                    "#",
                    ""
                  )}`,
                }}
              />
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.colorData?.textColor}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
          <div
            className={`${classNames.inpGrp} ${classNames.color} ${classNames.w3}`}
          >
            <div className={classNames.label}>Background Colour Code:</div>
            <div className={classNames.inpWrap}>
              <div
                className={classNames.colorBar}
                style={{
                  backgroundColor: `#${tokenAppData?.colorData?.backgroundColor?.replace(
                    "#",
                    ""
                  )}`,
                }}
              />
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.colorData?.backgroundColor}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
        </div>
        <div className={classNames.inpRow}>
          <div
            className={`${classNames.inpGrp} ${classNames.color} ${classNames.w3}`}
          >
            <div className={classNames.label}>Colour Codes Basic:</div>
            <div className={classNames.inpWrap}>
              <div
                className={classNames.colorBar}
                style={{
                  backgroundColor: `#${tokenAppData?.colorData?.borderColor?.replace(
                    "#",
                    ""
                  )}`,
                }}
              />
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.colorData?.borderColor}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
        </div>
        <div className={classNames.inpRow}>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>User Label:</div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.user_label}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>Website:</div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.website}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
          <div className={`${classNames.inpGrp} ${classNames.w3}`} />
        </div>
        <div className={classNames.inpRow}>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>App Icon:</div>
            <div className={classNames.appIconWrap}>
              <img src={tokenAppData?.app_icon} alt="" />
            </div>
          </div>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>Cover Photo:</div>
            <div className={classNames.coverPhotoWrap}>
              <img src={tokenAppData?.cover_photo} alt="" />
            </div>
          </div>
          <div className={`${classNames.inpGrp} ${classNames.w3}`} />
        </div>
        <div className={classNames.inpRow}>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>Registration Link: </div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.registration_link}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>IOS Link:</div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.ios_app_link}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>Android Link:</div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.android_app_link}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
        </div>
        <div className={classNames.inpRow}>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>Mac Link: </div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.mac_app_link}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>Windows Link: </div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.windows_app_link}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>Linux Link: </div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.linux_app_link}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
        </div>
        <div className={classNames.cardGroup}>
          <div className={classNames.label}>Categories:</div>
          {tokenAppData?.categories?.map((category) => (
            <div className={classNames.card}>
              <img src={category.icon} alt="" className={classNames.icon} />
              <div className={classNames.texts}>
                <div className={classNames.title}>
                  {category?.name}
                  <img src={copy} alt="" className={classNames.inpIcon} />
                </div>
                <div className={classNames.subTitle}>
                  {category?.category_id}
                  <img src={copy} alt="" className={classNames.inpIcon} />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={`${classNames.cardGroup} ${classNames.w2}`}>
          <div className={classNames.label}>Social Media Links:</div>
          {Array.isArray(socialList) &&
            socialList.map((social) => (
              <div className={`${classNames.card}`} key={social?._id}>
                <img src={social?.icon} alt="" className={classNames.icon} />
                <div className={classNames.texts}>
                  <div className={classNames.title}>
                    {social?.name}
                    <img src={copy} alt="" className={classNames.inpIcon} />
                  </div>
                  <div className={classNames.subTitle}>
                    {tokenAppData?.socialApps?.[social?.platform_code]?.link ||
                      "No Link"}
                    <img src={copy} alt="" className={classNames.inpIcon} />
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className={classNames.inpRow}>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>
              Liquid Interest Rate Differential:
            </div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.interest_rate_differential}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>
              Bonds Interest Rate Differential:
            </div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.iced_interest_rate_differential}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>
              Inverse Interest Rate Differential:
            </div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.inverse_ird}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
        </div>
        <div className={classNames.inpRow}>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>
              Affiliate Interest Rate Differential:
            </div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.iird_broker}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
        </div>
        <div className={classNames.inpRow}>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>SEFCoin Cashback</div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={tokenAppData?.sefcoin_cashback}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
        </div>
        <div className={classNames.inpRow}>
          <div className={`${classNames.inpGrp}`}>
            <div className={classNames.label}>App Creation Date:</div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={moment(tokenAppData?.timestamp).format(
                  "[Created On] MMMM Do YYYY [At] hh:mm:ss A"
                )}
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
        </div>
        <div className={classNames.cardGroup}>
          <div className={classNames.label}>Parent Conglomerate:</div>
          <div className={classNames.card}>
            <img
              src={tokenAppData?.groupData?.icon}
              alt=""
              className={classNames.icon}
            />
            <div className={classNames.texts}>
              <div className={classNames.title}>
                {tokenAppData?.groupData?.groupname}
                <img src={copy} alt="" className={classNames.inpIcon} />
              </div>
              <div className={classNames.subTitle}>
                {tokenAppData?.group_id}
                <img src={copy} alt="" className={classNames.inpIcon} />
              </div>
            </div>
          </div>
        </div>
        <div className={classNames.inpRow}>
          <div className={`${classNames.inpGrp} ${classNames.w3}`}>
            <div className={classNames.label}>Custom Emails:</div>
            <div className={classNames.inpWrap}>
              <input
                type="text"
                className={classNames.input}
                defaultValue={
                  tokenAppData?.custom_brand_registration?.toLowerCase() ===
                  "yes"
                }
                readOnly={readOnly}
              />
              <img src={copy} alt="" className={classNames.inpIcon} />
            </div>
          </div>
        </div>
        <div className={`${classNames.cardGroup} ${classNames.w3} `}>
          <div className={classNames.label}>Assets:</div>
          <div
            className={`${classNames[tokenAppData?.coins === "gxCoins"]} ${
              classNames.card
            }`}
          >
            <div className={classNames.texts}>
              <div className={classNames.smTitle}>Supports All GX Assets</div>
            </div>
          </div>
          <div
            className={`${classNames[tokenAppData?.coins !== "gxCoins"]} ${
              classNames.card
            }`}
          >
            <div className={classNames.texts}>
              <div className={classNames.smTitle}>Custom Asset List</div>
            </div>
          </div>
          <div
            className={classNames.card}
            style={{
              visibility: "hidden",
            }}
          />
        </div>
        <div className={`${classNames.cardGroup} ${classNames.w3} `}>
          <div className={classNames.label}>
            Custom Asset Interest Rate Differentials:
          </div>
          <div
            className={`${classNames[!tokenAppData?.ird_coins?.length]} ${
              classNames.card
            }`}
          >
            <div className={classNames.texts}>
              <div className={classNames.smTitle}>
                All Assets Have Default IRD
              </div>
            </div>
          </div>
          <div
            className={`${
              classNames[Boolean(tokenAppData?.ird_coins?.length)]
            } ${classNames.card}`}
          >
            <div className={classNames.texts}>
              <div className={classNames.smTitle}>Custom IRD Asset List</div>
            </div>
          </div>
          <div
            className={classNames.card}
            style={{
              visibility: "hidden",
            }}
          />
        </div>
        <div className={`${classNames.cardGroup} ${classNames.w3} `}>
          <div className={classNames.label}>Exchange Fees:</div>
          <div
            className={`${
              classNames[
                !Object.entries(tokenAppData?.pathFeesData || {})?.length
              ]
            } ${classNames.card}`}
          >
            <div className={classNames.texts}>
              <div className={classNames.smTitle}>
                All Pairs Have Default Fees
              </div>
            </div>
          </div>
          <div
            className={`${
              classNames[
                Boolean(
                  Object.entries(tokenAppData?.pathFeesData || {})?.length
                )
              ]
            } ${classNames.card}`}
          >
            <div className={classNames.texts}>
              <div className={classNames.smTitle}>Custom Exchange Fees</div>
            </div>
          </div>
          <div
            className={classNames.card}
            style={{
              visibility: "hidden",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default TokenAppEdit;
