import React, { useState } from "react";
import MailSlurp from "mailslurp-client";
import Scrollbars from "react-custom-scrollbars";

import InboxSidebar from "./InboxSidebar";
import { useEffect } from "react";
import GodsEyeRefreshSidebar from "../GodsEyeRefreshSidebar/GodsEyeRefreshSidebar";

const API_KEY =
  "86cee2f39d56b3b5a6b2e4c827cc1382d1be6bad16a9d35cd0e659ef9272d02c";

function BlockCheckInboxes({ list, setList, searchOn, refresh }) {
  const mailslurp = new MailSlurp({
    apiKey: API_KEY,
  });

  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [emailList, setEmailList] = useState([]);
  const [mailSelected, setMailSelected] = useState();

  useEffect(() => {
    if (!list) setMailSelected();
  }, [list]);

  useEffect(() => {
    if (mailSelected) setList(true);
  }, [mailSelected]);

  useEffect(() => {
    mailslurp
      .getAllInboxes(page, undefined, undefined, search || "blockcheck.io")
      .then((data) => {
        setTotalPage(data.totalPages);
        setEmailList(data.content);
      });
  }, [page, search]);

  return (
    <>
      {searchOn && (
        <div className="userCtrls">
          <input
            className="serchInp"
            type="text"
            placeholder="Search MailId"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {/* <div className="moreFilter">More Filters</div> */}
        </div>
      )}
      <div className="blockCheckInbox">
        <Scrollbars className="inboxList">
          {emailList.map((email) => (
            <div
              className={`inboxItm ${email === mailSelected}`}
              key={email.id}
              onClick={() => setMailSelected(email)}
            >
              <span className="mailId">{email.emailAddress}</span>
            </div>
          ))}
        </Scrollbars>
        {mailSelected && <InboxSidebar mailSelected={mailSelected} />}
        {refresh && <GodsEyeRefreshSidebar />}
      </div>
    </>
  );
}

export default BlockCheckInboxes;
