import React, { useContext, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import Axios from "axios";
import { useHistory } from "react-router-dom";

import { MainContext } from "../../context/MainContext";
import back from "../../static/images/back.svg";
import close from "../../static/images/close.svg";
import apiIcon from "../../static/images/clipIcons/apis.svg";
import addApp from "../../static/images/clipIcons/addApp.svg";
import editApp from "../../static/images/clipIcons/appData.svg";
import deleteApp from "../../static/images/refreshIcon/delete.svg";
import yesIcn from "../../static/images/clipIcons/yes.svg";
import noIcn from "../../static/images/clipIcons/no.svg";
import tradesIcn from "../../static/images/clipIcons/trades.svg";
import fireIcn from "../../static/images/clipIcons/fire.svg";
import LoadingAnim from "../LoadingAnim/LoadingAnim";

function TerminalAppsSidebar({ terminal, onClose }) {
  const history = useHistory();
  const { copyToClipboard, token, tostShowOn } = useContext(MainContext);
  const [step, setStep] = useState("");
  const [tab, setTab] = useState("Actions");
  const [loading, setLoading] = useState(false);
  function deteteTerminal() {
    setLoading(true);
    Axios.delete(`https://api.terminals.dev/terminal/withId/${terminal?.id}`, {
      headers: {
        email: terminal?.ter_user?.email,
        token: token,
      },
    })
      .then(({ data }) => {
        setStep("deleteSucces");
      })
      .catch((err) => {
        tostShowOn(err.response.data.message || "Something Went Wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getContent() {
    switch (true) {
      case tab === "About" && step === "API":
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <Scrollbars className="itemListScroll">
                <div className="endPointLabel">Get API For Terminal App</div>
                <div className="valueBox">
                  <span>{`https://api.terminals.dev/terminal/byid/${terminal?.id}`}</span>
                  <FontAwesomeIcon
                    icon={faCopy}
                    className="copyBtn"
                    onClick={() =>
                      copyToClipboard(
                        `https://api.terminals.dev/terminal/byid/${terminal?.id}`,
                        "Endpoint For Terminal App"
                      )
                    }
                  />
                </div>
              </Scrollbars>
            </div>
          </>
        );
      case tab === "About":
        return (
          <>
            <div className="godsEyeFilterCurrency" style={{ paddingTop: 0 }}>
              <div className="labelItm">
                Select One Of The Following Options
              </div>
              <Scrollbars className="coinList">
                <div className="coinItem" onClick={() => setStep("API")}>
                  <img
                    src={apiIcon}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>API</span>
                </div>
                <div className="coinItem" onClick={() => setStep("")}>
                  <img
                    src={tradesIcn}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>See Pairs</span>
                </div>
                <div className="coinItem" onClick={() => setStep("")}>
                  <img
                    src={fireIcn}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Terminal Stats</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );
      // case tab === "Actions" && step === "Edit":
      case tab === "Actions" && step === "deleteSucces":
        setTimeout(() => {
          history.push("/society/Terminal Apps");
          window.location.reload();
        }, 2000);
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm m-auto">
                You Have Successfully Deleted {terminal.name}. You Will Be
                Redirected To The Updated Terminal List Automatically
              </div>
            </div>
          </>
        );
      case tab === "Actions" && step === "Delete":
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm">
                Are You Sure You Want To Delete ${terminal.name}?
              </div>
              <Scrollbars className="coinList">
                <div className="coinItem" onClick={() => deteteTerminal()}>
                  <img
                    src={yesIcn}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Yes</span>
                </div>
                <div className="coinItem" onClick={() => setStep("")}>
                  <img
                    src={noIcn}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Go Back</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );
      case tab === "Actions":
        return (
          <>
            <div className="godsEyeFilterCurrency" style={{ paddingTop: 0 }}>
              <div className="labelItm">
                Select One Of The Following Actions
              </div>
              <Scrollbars className="coinList">
                <div className="coinItem" onClick={() => setStep("Edit")}>
                  <img
                    src={editApp}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Edit Terminal App</span>
                </div>
                <div className="coinItem" onClick={() => setStep("")}>
                  <img
                    src={addApp}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Add New Pairs</span>
                </div>
                <div className="coinItem" onClick={() => setStep("Delete")}>
                  <img
                    src={deleteApp}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Delete Terminal</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );
      default:
        return;
    }
  }
  return (
    <div className="godsEyeUserFiter">
      <div className="headTabBox">
        <div
          className={`tab ${tab === "About"}`}
          onClick={() => {
            setTab("About");
            setStep("");
          }}
        >
          About
        </div>
        <div
          className={`tab ${tab === "Actions"}`}
          onClick={() => {
            setTab("Actions");
            setStep("");
          }}
        >
          Actions
        </div>
      </div>
      <div className="header">
        <div className="content">
          <div className="title">
            <img
              style={{ height: "40px", width: "auto" }}
              src={terminal?.full_logo_coloured}
              alt=""
              className="icon"
            />
            {/* {terminal.name} */}
          </div>
          <div className="breadCrumbs">
            <span
              onClick={() => {
                setStep("");
              }}
            >
              {tab === "Actions"
                ? `${terminal.name} Actions`
                : `About ${terminal.name}`}
            </span>{" "}
            {step ? (
              <>
                -&gt;&nbsp;
                <span>{step}</span>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        {step ? (
          <div
            className="backBtn"
            onClick={() => {
              setStep("");
            }}
          >
            <img src={back} alt="" />
          </div>
        ) : (
          ""
        )}
        <div
          className="backBtn"
          onClick={() => {
            onClose();
          }}
        >
          <img src={close} alt="" />
        </div>
      </div>
      {getContent()}
      {loading ? <LoadingAnim /> : ""}
    </div>
  );
}

export default TerminalAppsSidebar;
