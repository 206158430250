import axios from "axios";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import classNames from "./tokenAppsCategories.module.scss";

function TokenAppsCategories({ search, list, setList }) {
  const [loading, setLoading] = useState(true);
  const [appsList, setAppsList] = useState([]);
  useEffect(() => {
    setLoading(true);
    axios
      .get("https://comms.globalxchange.io/gxb/apps/category/get")
      .then(({ data }) => {
        if (data.status) {
          setAppsList(data.categories);
        } else {
          setAppsList([]);
        }
      })
      .finally(() => setLoading(false));
  }, []);
  const [tokenAppsCategorieselected, setTokenAppsCategorieselected] =
    useState();
  return (
    <div className={classNames.tokenAppsCategories}>
      <Scrollbars
        className={classNames.appsScrollbar}
        renderThumbHorizontal={() => <div />}
        renderThumbVertical={() => <div />}
        renderTrackHorizontal={() => <div />}
        renderTrackVertical={() => <div />}
      >
        <div
          className={`${classNames.header} ${
            classNames[Boolean(tokenAppsCategorieselected)]
          }`}
        >
          <span className={classNames.name}>Name</span>
          <span className={classNames.totalAssets}>Total Apps</span>
        </div>
        {loading
          ? Array(10)
              .fill("")
              .map((_, i) => (
                <div className={classNames.appItem}>
                  <div className={classNames.name}>
                    <Skeleton className={classNames.icon} circle />
                    <div className={classNames.texts}>
                      <Skeleton className={classNames.title} width={160} />
                      <Skeleton className={classNames.subTitle} width={130} />
                    </div>
                  </div>
                  <div className={classNames.totalAssets}>
                    <div className={classNames.texts}>
                      <Skeleton className={classNames.title} width={130} />
                    </div>
                  </div>
                </div>
              ))
          : appsList
              ?.filter((app) =>
                app.name.toLowerCase().includes(search.toLowerCase())
              )
              ?.map((app) => (
                <AppItem
                  app={app}
                  selected={app === tokenAppsCategorieselected}
                  onClick={() => setTokenAppsCategorieselected(app)}
                  sidebarOpened={Boolean(tokenAppsCategorieselected)}
                />
              ))}
      </Scrollbars>
    </div>
  );
}

function AppItem({ app, onClick, selected, sidebarOpened }) {
  return (
    <div
      className={`${classNames[selected]} ${classNames.appItem} ${
        classNames[sidebarOpened ? "open" : ""]
      }`}
      onClick={onClick}
    >
      <div className={classNames.name}>
        <img src={app.icon} alt="" className={classNames.icon} />
        <div className={classNames.texts}>
          <div className={classNames.title}>{app.name}</div>
          <div className={classNames.subTitle}>{app.category_id}</div>
        </div>
      </div>
      <div className={classNames.totalAssets}>
        <div className={classNames.texts}>
          <div className={classNames.title}>{0}</div>
        </div>
      </div>
    </div>
  );
}

export default TokenAppsCategories;
