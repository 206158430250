import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";

function PartnerList({ setPartner, onClose }) {
  const [partners, setPartners] = useState([]);
  const [partnersLoading, setPartnersLoading] = useState(true);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setPartnersLoading(true);
    axios
      .get("https://accountingtool.apimachine.com/getlist-of-institutes")
      .then(({ data }) => {
        setPartners(data?.data);
      })
      .finally(() => setPartnersLoading(false));
  }, []);

  return (
    <Fragment>
      <div className="titleOp">Select Partnering Institution</div>
      <div className="searchWrap">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Partnering Institution....|"
        />
      </div>
      <Scrollbars className="searchList">
        {partnersLoading
          ? Array(6)
              .fill("")
              .map((_, i) => (
                <div className="user" key={i}>
                  <Skeleton className="dp" circle />
                  <div className="userDetail">
                    <Skeleton className="name" width={200} />
                  </div>
                </div>
              ))
          : partners
              .filter(
                (partner) =>
                  partner?.institute_name
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  partner?.short_name
                    ?.toLowerCase()
                    .includes(search.toLowerCase())
              )
              .map((partner) => (
                <div
                  className="user"
                  key={partner._id}
                  onClick={() => {
                    setPartner(partner);
                    onClose();
                  }}
                >
                  <img className="dp" src={partner?.profile_image} alt="" />
                  <div className="userDetail">
                    <div className="name">{partner?.short_name}</div>
                  </div>
                </div>
              ))}
        <div className="space"></div>
      </Scrollbars>
    </Fragment>
  );
}

export default PartnerList;
