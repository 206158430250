import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import { useHistory, useParams } from "react-router-dom";

import { MainContext } from "../../context/MainContext";
import vaultLogo from "../../static/images/logos/civilisationFullLogo.svg";
import iconPaste from "../../static/images/clipIcons/paste.svg";
import iconSearch from "../../static/images/clipIcons/search.svg";
import LoadingAnim from "../LoadingAnim/LoadingAnim";

function BankerTransactionEnterId({ setTransaction, hide }) {
  const history = useHistory();
  const { txnId } = useParams();
  const [search, setSearch] = useState("");
  const { tostShowOn } = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const getData = (search) => {
    setLoading(true);
    Axios.get(
      `https://comms.globalxchange.io/coin/vault/service/txn/status/logs?id=${search}`
    )
      .then(({ data }) => {
        if (data.status && data.txn) setTransaction(data.txn);
        else tostShowOn("No Records Found");
      })
      .catch((err) => tostShowOn(err.message || "Something Went Wrong"))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (txnId && !hide) {
      getData(txnId);
      history.push("/withdrawals");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txnId]);
  return (
    <div className="moneyMarkets">
      <img src={vaultLogo} className="moneyMarketLogo" alt="" />
      <label className="searchWrapper">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search Any Transaction ID..."
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              if (search) getData(search);
              else tostShowOn("Enter A Valid Transaction ID.");
            }
          }}
        />
        <img
          src={iconPaste}
          onClick={() => {
            navigator.clipboard
              .readText()
              .then((clipText) => setSearch(clipText));
          }}
          alt=""
        />
        <img
          src={iconSearch}
          alt=""
          onClick={() => {
            if (search) getData(search);
            else tostShowOn("Enter A Valid Transaction ID.");
          }}
        />
      </label>
      {loading && <LoadingAnim />}
    </div>
  );
}

export default BankerTransactionEnterId;
