import React from 'react'

import { ReactComponent as BoardsIcon } from '../../images/clipIcons/boards.svg'
import { ReactComponent as ListIcon } from '../../images/clipIcons/list.svg'
import { ReactComponent as SearchIcon } from '../../images/clipIcons/search.svg'
import { ReactComponent as CloseIcon } from '../../images/clipIcons/close.svg'
import styles from './iconsViewSwitcher.module.scss'

function IconsViewSwitcher({
  listIcn,
  boardIcn,
  searchIcn,
  list,
  board,
  search,
  onSearchClick,
  onBoardClick,
  onListClick
}) {
  return (
    <div className={styles.viewSwitcher}>
      <div
        className={`${styles.btSwitchView} ${board && styles.true}`}
        onClick={() => {
          try {
            onBoardClick()
          } catch (error) {}
        }}
      >
        {boardIcn || <BoardsIcon />}
      </div>
      <div
        className={`${styles.btSwitchView} ${list && styles.true}`}
        onClick={() => {
          try {
            onListClick()
          } catch (error) {}
        }}
      >
        {listIcn || <ListIcon />}
      </div>
      <div
        className={`${styles.btSwitchView} ${search && styles.true}`}
        onClick={() => {
          try {
            onSearchClick()
          } catch (error) {}
        }}
      >
        {searchIcn || (search ? <CloseIcon /> : <SearchIcon />)}
      </div>
    </div>
  )
}

export default IconsViewSwitcher
