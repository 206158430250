import React, { useContext, useEffect, useState } from "react";
import JsonWebToken from "jsonwebtoken";
import Axios from "axios";
import RichTextEditorComponent from "../RichTextEditorComponent/RichTextEditorComponent";
import NewNavbar from "../NewNavbar/NewNavbar";
import NewCategory from "../NewCategory/NewCategory";
import cloudUpload from "../../static/images/postClipArt/cloudUpload.svg";
import Scrollbars from "react-custom-scrollbars";
import { MainContext } from "../../context/MainContext";
import NavbarItem from "./NavbarItem";
import CategoryItem from "./CategoryItem";
import CopyPublicationDetailModal from "../CopyPublicationDetailModal/CopyPublicationDetailModal";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

function NewArticle({ publication }) {
  const { tostShowOn } = useContext(MainContext);
  const [thubnailLink, setThubnailLink] = useState("");
  const [thumbLoading, setThumbLoading] = useState(false);
  const uploadImage = async (e) => {
    setThumbLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setThubnailLink(data.payload.url);
    setThumbLoading(false);
  };

  const [iconLink, setIconLink] = useState("");
  const [iconLoading, setIconLoading] = useState(false);
  const uploadIcon = async (e) => {
    setIconLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setIconLink(data.payload.url);
    setIconLoading(false);
  };

  const [mediaLink, setMediaLink] = useState("");
  const [mediaLoading, setMediaLoading] = useState(false);
  const uploadMedia = async (e) => {
    setMediaLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setMediaLink(data.payload.url);
    setMediaLoading(false);
  };

  const [categoryList, setCategoryList] = useState([]);
  const [navbarList, setNavbarList] = useState([]);
  const getNavBars = () => {
    Axios.get(
      `https://fxagency.apimachine.com/navbar/publication/${publication._id}`
    ).then(({ data }) => {
      if (data.status) setNavbarList(data.data || []);
    });
  };
  const getCategories = () => {
    Axios.get(
      `https://fxagency.apimachine.com/category/publication/${publication._id}`
    ).then(({ data }) => {
      if (data.status) setCategoryList(data.data || []);
    });
  };
  useEffect(() => {
    getNavBars();
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publication]);

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [categoryType, setCategoryType] = useState([]);
  const [navbarId, setNavbarId] = useState("");

  const [newNav, setNewNav] = useState(false);
  const [newCategory, setNewCategory] = useState(false);
  const [article, setArticle] = useState("");
  const postArticle = () => {
    Axios.post("https://fxagency.apimachine.com/article", {
      categoryType: categoryType,
      navbar_id: navbarId,
      application_id: publication.fxa_app_id,
      email: publication.email,
      title: title,
      image: thubnailLink,
      desc: desc,
      icon: iconLink,
      media: mediaLink,
      article: article,
    }).then(({ data }) => {
      if (data.status) {
        tostShowOn("Article Post Added");
      }
    });
  };

  const [navDataToUpdate, setNavDataToUpdate] = useState("");
  const [categoryDataToUpdate, setCategoryDataToUpdate] = useState("");

  return (
    <>
      <div className="newVideoWrapper">
        <div className="postSection">
          <div className="topVideoDetails">
            <div className="videoHolder rte">
              <RichTextEditorComponent onChange={(str) => setArticle(str)} />
            </div>
            <div className="videoDetails">
              <input
                type="text"
                className="inpTitle"
                placeholder="Enter Title "
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <textarea
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                name=""
                className="textArea"
                placeholder="Description"
              />
              <label className="uploadThumbnail">
                <input type="file" className="d-none" onChange={uploadImage} />
                <img src={cloudUpload} className="cloudUpload" alt="" />
                {thumbLoading
                  ? "Uploading"
                  : thubnailLink
                  ? "Image Uploaded"
                  : "Upload Image"}
              </label>
              <label className="uploadThumbnail">
                <input type="file" className="d-none" onChange={uploadIcon} />
                <img src={cloudUpload} className="cloudUpload" alt="" />
                {iconLoading
                  ? "Uploading"
                  : iconLink
                  ? "Icon Uploaded"
                  : "Upload Icon"}
              </label>{" "}
              <label className="uploadThumbnail">
                <input type="file" className="d-none" onChange={uploadMedia} />
                <img src={cloudUpload} className="cloudUpload" alt="" />
                {mediaLoading
                  ? "Uploading"
                  : mediaLink
                  ? "Media Uploaded"
                  : "Upload Media"}
              </label>
            </div>
          </div>
          <div className="separator"></div>
          <div className="categoriesNLists">
            <div className="categoryList">
              <div className="head">
                <div className="title">Categories</div>
                <div className="btnNew" onClick={() => setNewCategory(true)}>
                  New
                </div>
              </div>
              <Scrollbars className="catScrlWrap" autoHide>
                {categoryList.map((category) => (
                  <CategoryItem
                    key={category._id}
                    setCategoryType={setCategoryType}
                    category={category}
                    categoryType={categoryType}
                    OnEdit={(category) => {
                      setCategoryDataToUpdate(category);
                      setNewCategory(true);
                    }}
                    onDelete={() => {
                      setCategoryDataToUpdate({
                        _id: category._id,
                        status: "inactive",
                      });
                      setNewCategory(true);
                    }}
                    onCopy={() => setCategoryDataToUpdate(category)}
                  />
                ))}
              </Scrollbars>
            </div>
            <div className="categoryList">
              <div className="head">
                <div className="title">Lists</div>
                <div className="btnNew" onClick={() => setNewNav(true)}>
                  New
                </div>
              </div>
              <Scrollbars className="catScrlWrap" autoHide>
                {navbarList.map((navbar) => (
                  <NavbarItem
                    key={navbar._id}
                    navbar={navbar}
                    navbarId={navbarId}
                    setNavbarId={setNavbarId}
                    OnEdit={(navbar) => {
                      setNavDataToUpdate(navbar);
                      setNewNav(true);
                    }}
                    onDelete={() => {
                      setNavDataToUpdate({
                        _id: navbar._id,
                        status: "inactive",
                      });
                      setNewNav(true);
                    }}
                    onCopy={() => setNavDataToUpdate(navbar)}
                  />
                ))}
              </Scrollbars>
            </div>
          </div>
          <div className="post" onClick={() => postArticle()}>
            Post
          </div>
        </div>
      </div>
      {newNav && (
        <NewNavbar
          navData={navDataToUpdate}
          publication={publication}
          onSuccess={() => {
            setNewNav(false);
            getNavBars();
            setNavDataToUpdate(false);
          }}
          onClose={() => {
            setNewNav(false);
            setNavDataToUpdate(false);
          }}
        />
      )}
      {newCategory && (
        <NewCategory
          catData={categoryDataToUpdate}
          publication={publication}
          onSuccess={() => {
            setNewCategory(false);
            getCategories();
            setCategoryDataToUpdate(false);
          }}
          onClose={() => {
            setNewCategory(false);
            setCategoryDataToUpdate(false);
          }}
        />
      )}
      {!newCategory && categoryDataToUpdate && (
        <CopyPublicationDetailModal
          publication={categoryDataToUpdate}
          onClose={() => setCategoryDataToUpdate(false)}
        />
      )}
      {!newNav && navDataToUpdate && (
        <CopyPublicationDetailModal
          publication={navDataToUpdate}
          onClose={() => setNavDataToUpdate(false)}
        />
      )}
    </>
  );
}

export default NewArticle;
