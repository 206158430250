import React, { useState } from "react";
import marketsIcon from "../static/images/clipIcons/marketsIcon.svg";
import pasteIcon from "../static/images/clipIcons/paste.svg";
import searchIcon from "../static/images/clipIcons/search.svg";
import mainLogo from "../static/images/logos/civilisationFullLogo.svg";
import vaultFullLogo from "../static/images/logos/vaultFullLogo.svg";
import connectLogo from "../static/images/logos/connectLogo.svg";
import moneyMarkets from "../static/images/logos/moneyMarkets.svg";
import terminalsLogo from "../static/images/logos/terminalsLogo.svg";
import blockcheckLogo from "../static/images/logos/blockcheckLogo.svg";
import brokerLogo from "../static/images/logos/brokerLogo.svg";
import mallsLogo from "../static/images/logos/mallsLogo.svg";
import Scrollbars from "react-custom-scrollbars";
import { useHistory, useParams } from "react-router-dom";

const apps = {
  connect: {
    logo: connectLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  blockcheck: {
    logo: blockcheckLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  moneymarkets: {
    logo: moneyMarkets,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  vault: {
    logo: vaultFullLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  terminals: {
    logo: terminalsLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  brokerapp: {
    logo: brokerLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  malls: {
    logo: mallsLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
};

function RecieptDetailPage() {
  const history = useHistory();
  const { app, detail } = useParams();
  const [appSelected, setAppSelected] = useState(app);
  return (
    <div className="recieptDetailPage">
      <Scrollbars
        className="sidebar"
        renderThumbHorizontal={() => <div />}
        renderThumbVertical={() => <div />}
        renderTrackHorizontal={() => <div />}
        renderTrackVertical={() => <div />}
      >
        {Object.keys(apps).map((key) => (
          <div
            key={key}
            className={`app ${appSelected === key}`}
            onClick={() => {
              setAppSelected(key);
              history.push(`/sp/${key}/${detail}`);
            }}
          >
            <img src={apps[key].logo} alt="" />
          </div>
        ))}
      </Scrollbars>
      <div className="bondOverview">
        <div className="overView">
          <div className="searchWrapper">
            <img src={marketsIcon} alt="" className="m-0" />
            <input type="text" placeholder="Search Any Asset Hash..." />
            <img src={pasteIcon} alt="" />
            <img src={searchIcon} alt="" />
          </div>
          <div className="bondTitle">
            Bitcoin Bond - 5f6d5cf05c3dd95bbc6f7354
          </div>
          <div className="bondValues">
            <div className="item">
              <div className="label">Payments Till Date</div>
              <div className="value">155</div>
            </div>
            <div className="item">
              <div className="label">Total Paid Interest</div>
              <div className="value">0.0790 BTC | $1,950.06 USD</div>
            </div>
            <div className="item">
              <div className="label">Total Remaining Interest</div>
              <div className="value">0.0142 BTC | $678.53 USD</div>
            </div>
          </div>
          <div className="bondTitle">Interest Rate Logs</div>
          <div
            className="earnings"
            style={{
              position: "relative",
              overflow: "hidden",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              className="vaultsView"
              style={{
                position: "absolute",
                inset: 0,
                overflow: "scroll",
                marginRight: 0,
                marginBottom: 0,
              }}
            >
              <div className="day">Today</div>
              <div className="vaults-itm">
                <img
                  src="https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/bitcoin.png"
                  alt=""
                />
                <div className="name-n-date mr-auto">
                  <div className="name" />
                  <div className="date">February 26th 2021 at 1:00:13 PM</div>
                </div>
                <div className="credit false">
                  <span className="expand">Expand</span>
                  <span className="value">0.0005</span>
                </div>
              </div>
              <div className="day">Yesterday</div>
              <div className="vaults-itm">
                <img
                  src="https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/bitcoin.png"
                  alt=""
                />
                <div className="name-n-date mr-auto">
                  <div className="name" />
                  <div className="date">February 25th 2021 at 1:00:11 PM</div>
                </div>
                <div className="credit false">
                  <span className="expand">Expand</span>
                  <span className="value">0.0005</span>
                </div>
              </div>
              <div className="day">February 24th 2021</div>
              <div className="vaults-itm">
                <img
                  src="https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/bitcoin.png"
                  alt=""
                />
                <div className="name-n-date mr-auto">
                  <div className="name" />
                  <div className="date">February 24th 2021 at 1:00:11 PM</div>
                </div>
                <div className="credit false">
                  <span className="expand">Expand</span>
                  <span className="value">0.0005</span>
                </div>
              </div>
              <div className="day">February 23rd 2021</div>
              <div className="vaults-itm">
                <img
                  src="https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/bitcoin.png"
                  alt=""
                />
                <div className="name-n-date mr-auto">
                  <div className="name" />
                  <div className="date">February 23rd 2021 at 1:00:11 PM</div>
                </div>
                <div className="credit false">
                  <span className="expand">Expand</span>
                  <span className="value">0.0005</span>
                </div>
              </div>
              <div className="day">February 22nd 2021</div>
              <div className="vaults-itm">
                <img
                  src="https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/bitcoin.png"
                  alt=""
                />
                <div className="name-n-date mr-auto">
                  <div className="name" />
                  <div className="date">February 22nd 2021 at 1:00:16 PM</div>
                </div>
                <div className="credit false">
                  <span className="expand">Expand</span>
                  <span className="value">0.0005</span>
                </div>
              </div>
              <div className="day">February 21st 2021</div>
              <div className="vaults-itm">
                <img
                  src="https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/bitcoin.png"
                  alt=""
                />
                <div className="name-n-date mr-auto">
                  <div className="name" />
                  <div className="date">February 21st 2021 at 1:00:12 PM</div>
                </div>
                <div className="credit false">
                  <span className="expand">Expand</span>
                  <span className="value">0.0005</span>
                </div>
              </div>
              <div className="day">February 20th 2021</div>
              <div className="vaults-itm">
                <img
                  src="https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/bitcoin.png"
                  alt=""
                />
                <div className="name-n-date mr-auto">
                  <div className="name" />
                  <div className="date">February 20th 2021 at 1:00:11 PM</div>
                </div>
                <div className="credit false">
                  <span className="expand">Expand</span>
                  <span className="value">0.0005</span>
                </div>
              </div>
              <div className="day">February 19th 2021</div>
              <div className="vaults-itm">
                <img
                  src="https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/bitcoin.png"
                  alt=""
                />
                <div className="name-n-date mr-auto">
                  <div className="name" />
                  <div className="date">February 19th 2021 at 1:00:11 PM</div>
                </div>
                <div className="credit false">
                  <span className="expand">Expand</span>
                  <span className="value">0.0005</span>
                </div>
              </div>
              <div className="day">February 18th 2021</div>
              <div className="vaults-itm">
                <img
                  src="https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/bitcoin.png"
                  alt=""
                />
                <div className="name-n-date mr-auto">
                  <div className="name" />
                  <div className="date">February 18th 2021 at 1:00:11 PM</div>
                </div>
                <div className="credit false">
                  <span className="expand">Expand</span>
                  <span className="value">0.0005</span>
                </div>
              </div>
              <div className="day">February 17th 2021</div>
              <div className="vaults-itm">
                <img
                  src="https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/bitcoin.png"
                  alt=""
                />
                <div className="name-n-date mr-auto">
                  <div className="name" />
                  <div className="date">February 17th 2021 at 1:00:12 PM</div>
                </div>
                <div className="credit false">
                  <span className="expand">Expand</span>
                  <span className="value">0.0005</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bondCertificate">
          <img src={mainLogo} alt="" className="logoMain" />
          <div className="header">
            <div className="label">Value Of Bond</div>
            <div className="value">
              <span className="def">0.5096BTC</span>
              <span className="hov">$24,206.25</span>
            </div>
          </div>
          <div
            className="bondDetailScroll"
            style={{
              position: "relative",
              overflow: "hidden",
              width: "100%",
              height: "100%",
            }}
          >
            <div
              className="view"
              style={{
                position: "absolute",
                inset: 0,
                overflow: "scroll",
                marginRight: 0,
                marginBottom: 0,
              }}
            >
              <div className="listItem">Issuance Details</div>
              <div className="listItem">Redemption Details</div>
              <div className="listItem">Daily Earning Power</div>
              <div className="listItem">Term Earning Power</div>
              <div className="listItem">Interest Rate Mechanics</div>
              <div className="listItem">Fees</div>
              <div className="listItem">Net ROI</div>
            </div>
          </div>
          <div className="footerButtons">
            <div className="btnShare">Share</div>
            <div className="btnOwner true">Im The Owner</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecieptDetailPage;
