import React, { useContext } from "react";
import Scrollbars from "react-custom-scrollbars";
import { MoreTabContext } from "../../context/MoreTabContext";
import publicationIcon from "../../static/images/appLogos/publicationIcon.svg";
import publisherIcon from "../../static/images/appLogos/publisherIcon.svg";
import terminalApps from "../../static/images/appLogos/terminalApps.svg";

function GodsEyeMoreList({ setTabMore }) {
  const { terminalCount, publicationCount, publisherCount } =
    useContext(MoreTabContext);
  return (
    <div className="godsEyeMoreList">
      <Scrollbars className="moreListWrapper">
        <div
          className="listItem"
          onClick={() =>
            window.open("http://exchangefees.com/", "_blank").focus()
          }
        >
          <img
            src="https://exchangefees.com/android-chrome-512x512.png"
            alt=""
            className="icon"
          />
          <span className="name">ExchangeFees</span>
          <span className="count">0</span>
        </div>
        <div
          className="listItem"
          onClick={() =>
            window.open("https://virtualprospectus.com/", "_blank").focus()
          }
        >
          <img
            src="https://virtualprospectus.com/android-chrome-512x512.png"
            alt=""
            className="icon"
          />
          <span className="name">VirtualProspectus</span>
          <span className="count">0</span>
        </div>
        <div
          className="listItem"
          onClick={() => window.open("https://home.agency/", "_blank").focus()}
        >
          <img
            src="https://home.agency/android-chrome-512x512.png"
            alt=""
            className="icon"
          />
          <span className="name">HomeAgency</span>
          <span className="count">0</span>
        </div>
        <div
          className="listItem"
          onClick={() => window.open("https://barbers.com/", "_blank").focus()}
        >
          <img
            src="https://barbers.com/android-chrome-512x512.png"
            alt=""
            className="icon"
          />
          <span className="name">Barbers</span>
          <span className="count">0</span>
        </div>
        <div
          className="listItem"
          onClick={() =>
            window.open("https://cryptoinfluencers.com/", "_blank").focus()
          }
        >
          <img
            src="https://cryptoinfluencers.com/android-chrome-512x512.png"
            alt=""
            className="icon"
          />
          <span className="name">CryptoInfluencers</span>
          <span className="count">0</span>
        </div>
        <div
          className="listItem"
          onClick={() => window.open("https://atms.app/", "_blank").focus()}
        >
          <img
            src="https://atms.app/android-chrome-512x512.png"
            alt=""
            className="icon"
          />
          <span className="name">ATMs</span>
          <span className="count">0</span>
        </div>
        <div
          className="listItem"
          onClick={() =>
            window.open("https://accountants.io/", "_blank").focus()
          }
        >
          <img
            src="https://accountants.io/android-chrome-512x512.png"
            alt=""
            className="icon"
          />
          <span className="name">Accountants.io</span>
          <span className="count">0</span>
        </div>
        <div
          className="listItem"
          onClick={() =>
            window.open("https://lawyer.agency/", "_blank").focus()
          }
        >
          <img
            src="https://lawyer.agency/android-chrome-512x512.png"
            alt=""
            className="icon"
          />
          <span className="name">LawyerAgency</span>
          <span className="count">0</span>
        </div>
        <div
          className="listItem"
          onClick={() =>
            window.open("https://mlmexchange.com/", "_blank").focus()
          }
        >
          <img
            src="https://mlmexchange.com/android-chrome-512x512.png"
            alt=""
            className="icon"
          />
          <span className="name">MLM Exchange</span>
          <span className="count">0</span>
        </div>
        <div
          className="listItem"
          onClick={() =>
            window.open("https://cryptocoupon.com/", "_blank").focus()
          }
        >
          <img
            src="https://cryptocoupon.com/android-chrome-512x512.png"
            alt=""
            className="icon"
          />
          <span className="name">CryptoCoupon</span>
          <span className="count">0</span>
        </div>
        <div
          className="listItem"
          onClick={() =>
            window.open("https://opportunities.app/", "_blank").focus()
          }
        >
          <img
            src="https://opportunities.app/android-chrome-512x512.png"
            alt=""
            className="icon"
          />
          <span className="name">Opportunities</span>
          <span className="count">0</span>
        </div>
        <div
          className="listItem"
          onClick={() =>
            window.open("https://entrepreneurs.app/", "_blank").focus()
          }
        >
          <img
            src="https://entrepreneurs.app/android-chrome-512x512.png"
            alt=""
            className="icon"
          />
          <span className="name">Entrepreneurs</span>
          <span className="count">0</span>
        </div>
        <div
          className="listItem"
          onClick={() => {
            setTabMore("");
            setTimeout(() => {
              setTabMore("Publications");
            }, 200);
          }}
        >
          <img src={publicationIcon} alt="" className="icon" />
          <span className="name">Publications</span>
          <span className="count">{publicationCount}</span>
        </div>
        <div
          className="listItem"
          onClick={() => {
            setTabMore("");
            setTimeout(() => {
              setTabMore("Publishers");
            }, 200);
          }}
        >
          <img src={publisherIcon} alt="" className="icon" />
          <span className="name">Publishers</span>
          <span className="count">{publisherCount}</span>
        </div>
        <div
          className="listItem"
          onClick={() => {
            setTabMore("");
            setTimeout(() => {
              setTabMore("Terminal Apps");
            }, 200);
          }}
        >
          <img src={terminalApps} alt="" className="icon" />
          <span className="name">Terminal Apps</span>
          <span className="count">{terminalCount}</span>
        </div>
      </Scrollbars>
    </div>
  );
}

export default GodsEyeMoreList;
