import React, { useRef } from "react";
import OnOutsideClick from "../../utils/OnOutsideClick";

function MarketsItem({ path, onClick, selected }) {
  const btnRef = useRef();
  const outRef = useRef();

  OnOutsideClick(
    btnRef,
    () => {
      try {
        onClick();
      } catch (error) {}
    },
    outRef
  );

  return (
    <div className={`listItem ${path === selected}`} ref={outRef}>
      <div className="profile">
        <img
          src={path?.token_profile_data?.coinImage}
          alt=""
          className="icon"
        />
        <span className="name">{path?.token_profile_data?.coinName}</span>
      </div>
      <div className="createdBy">
        <span className="label">Created By</span>
        <img src={path.banker_metaData?.profilePicURL} alt="" className="icn" />
        <span className="value">{path.banker_metaData?.displayName}</span>
      </div>
    </div>
  );
}

export default MarketsItem;
