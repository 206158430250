import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import EnterPinUnlock from "../components/EnterPinUnlock/EnterPinUnlock";
import mainLogo from "../static/images/logos/civilisationIcon.svg";
import society from "../static/images/logos/society.svg";
import societyIcon from "../static/images/logos/societyIcon.svg";
import library from "../static/images/logos/library.svg";
import capital from "../static/images/logos/nvestCapital.svg";
import libraryIcon from "../static/images/logos/libraryIcon.svg";
import useWindowDimensions from "../utils/WindowSize";

function HomePage() {
  const { height } = useWindowDimensions();
  const history = useHistory();
  const [admin, setAdmin] = useState(false);
  const [toRoute, setToRoute] = useState("/sp");
  return (
    <div className="homePage" style={{ maxHeight: height }}>
      <div className="content">
        <img src={mainLogo} alt="" className="logo" />
        <div className="title">Civilization</div>
        <div className="subTitle">Welcome To The New World</div>
        <div className="btns">
          <div
            onClick={() => {
              setAdmin(true);
              setToRoute("/library");
            }}
            className="btnAdmin"
          >
            <img src={library} alt="" />
          </div>
          <div
            onClick={() => {
              history.push("/nvestlogin");
            }}
            className="btnCapital"
          >
            <img src={capital} alt="" />
          </div>
          <div
            onClick={() => {
              setAdmin(true);
              setToRoute("/sp");
            }}
            className="btnAdmin"
          >
            <img src={society} alt="" />
          </div>
        </div>
      </div>
      {admin && (
        <EnterPinUnlock
          logoParam={toRoute === "/sp" ? societyIcon : libraryIcon}
          onSucces={() => {
            history.push(toRoute);
            setAdmin(false);
          }}
          onClose={() => setAdmin(false)}
          local={"4444"}
        />
      )}
    </div>
  );
}

export default HomePage;
