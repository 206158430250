import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { FormatCurrency } from "../../utils/Functions";

function GodsEyeFilterCurrency({ setCoinSelected }) {
  const [coinList, setCoinList] = useState([]);
  useEffect(() => {
    Axios.post("https://comms.globalxchange.io/coin/vault/service/coins/get", {
      app_code: "ice",
    }).then((res) => {
      const { data } = res;
      if (data.status) {
        const { coins_data } = data;
        setCoinList(coins_data);
      }
    });
  }, []);
  const [search, setSearch] = useState("");
  return (
    <>
      <div className="godsEyeFilterCurrency">
        <div className="labelItm">
          What Currency Do You Want To See The Holdings?
        </div>
        <label className="searchBox">
          <input
            type="text"
            placeholder="Search Currencies"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <FontAwesomeIcon className="icon" icon={faSearch} />
        </label>
        <Scrollbars className="coinList">
          {coinList
            .filter(
              (coin) =>
                coin.coinName.toLowerCase().includes(search.toLowerCase()) ||
                coin.coinSymbol.toLowerCase().includes(search.toLowerCase())
            )
            .map((coin) => (
              <div
                className="coinItem"
                key={coin.coinSymbol}
                onClick={() => setCoinSelected(coin)}
              >
                <img src={coin?.coinImage} alt="" className="coin" />
                <span>{coin.coinName}</span>
                <span className="value">${FormatCurrency(coin.price.USD)}</span>
              </div>
            ))}
        </Scrollbars>
      </div>
      <div className="footerBtns">
        <div className="btnReset">Reset</div>
        <div className="btnSave">Save</div>
      </div>
    </>
  );
}

export default GodsEyeFilterCurrency;
