import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Navbar } from "../components/BrokerDashboard/index";

import MainLayout from "../layouts/MainLayout";
import BankerWithdraw from "../components/BankerWithdraw/BankerWithdraw";
import BankerDeposit from "../components/BankerDeposit/BankerDeposit";
import BankerTransactions from "../components/BankerTransactions/BankerTransactions";
import CopyPublicationDetailModal from "../components/CopyPublicationDetailModal/CopyPublicationDetailModal";
import GodsEyeRefreshSidebar from "../components/GodsEyeRefreshSidebar/GodsEyeRefreshSidebar";

import { ReactComponent as IconClose } from "../static/images/clipIcons/close.svg";
import { ReactComponent as RefreshIcn } from "../static/images/clipIcons/refresh.svg";

import IconsViewSwitcher from "../components/IconsViewSwitcher/IconsViewSwitcher";
import { TabsContext } from "../context/TabsContext";
import BankerTransactionFilter from "../components/BankerTransactionFilter/BankerTransactionFilter";
import BlockChainTabContent from "../components/BlockChainTabContent";

const tabs = ["Withdrawals", "Deposits", "Blockchain"];
function LiquidityPage({ hide }) {
  const { txnId } = useParams();
  const { navTabs } = useContext(TabsContext);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedBanker, setSelectedBanker] = useState({});

  const [bankers, setBankers] = useState([]);
  useEffect(() => {
    if (txnId && !hide) {
      setTabSelected("Transactions");
    }
  }, [txnId]);
  useEffect(() => {
    Axios.get("https://teller2.apimachine.com/admin/allBankers").then(
      ({ data }) => {
        if (data.status) {
          setBankers(data.data);
          const gxBanker = data.data.filter(
            (banker) => banker.bankerTag === "Global X Change"
          )[0];
          setSelectedBanker(gxBanker || data.data[0]);
        }
      }
    );
  }, []);

  const [filteron, setFilterOn] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isList, setIsList] = useState(false);
  const [searchOn, setSearchOn] = useState(false);

  const [tabClick, setTabClick] = useState(false);
  const [tabSelected, setTabSelected] = useState("Blockchain");
  const [txnSelected, setTxnSelected] = useState();

  const getContent = () => {
    switch (tabSelected) {
      case "Withdrawals":
        return (
          <BankerWithdraw
            isList={isList}
            selectedBanker={selectedBanker}
            searchOn={searchOn}
            filteron={filteron}
            setFilterOn={setFilterOn}
            txnSelected={txnSelected}
            setTxnSelected={setTxnSelected}
          />
        );
      case "Deposits":
        return (
          <BankerDeposit
            isList={isList}
            selectedBanker={selectedBanker}
            searchOn={searchOn}
            filteron={filteron}
            setFilterOn={setFilterOn}
            txnSelected={txnSelected}
            setTxnSelected={setTxnSelected}
          />
        );
      case "Transactions":
        return <BankerTransactions key={tabClick} hide={hide} />;
      case "Blockchain":
        return <BlockChainTabContent />;
      default:
        break;
    }
  };
  useEffect(() => {
    setTxnSelected();
  }, [tabSelected]);
  const [bankerSelected, setBankerSelected] = useState(null);

  return (
    <MainLayout active={"Liquidity"} hide={hide}>
      <Navbar
        navTabs={navTabs}
        tabs={tabs}
        tabSelected={tabSelected}
        setTabSelected={(tab) => {
          setTabSelected("");
          setTimeout(() => {
            setTabSelected(tab);
          }, 100);
        }}
        search={search}
        setSearch={setSearch}
        button={
          <div
            className={`publication ${dropDownOpen}`}
            onClick={() => setDropDownOpen(!dropDownOpen)}
          >
            {dropDownOpen ? (
              <span>Bankers</span>
            ) : (
              <>
                <img
                  className="logoIcn"
                  src={selectedBanker && selectedBanker.profilePicURL}
                  alt=""
                />
                <span>{selectedBanker && selectedBanker.bankerTag}</span>
              </>
            )}
          </div>
        }
        viewSwitcher={
          <IconsViewSwitcher
            listIcn={filteron && tabSelected !== "Blockchain" && <IconClose />}
            boardIcn={<RefreshIcn />}
            board={refresh}
            onBoardClick={() => {
              if (refresh) setRefresh(false);
              else {
                setRefresh(true);
                setFilterOn(false);
              }
            }}
            list={filteron && tabSelected !== "Blockchain"}
            onListClick={() => {
              if (filteron) setFilterOn(false);
              else {
                setRefresh(false);
                setFilterOn(true);
              }
            }}
            search={searchOn}
            onSearchClick={() => setSearchOn(!searchOn)}
          />
        }
        tabClick={() => {
          setTabClick(!tabClick);
        }}
        searchPlaceHolder=""
      />
      <div className="withdrawalView">
        {getContent()}
        {filteron && !txnSelected && tabSelected !== "Blockchain" && (
          <BankerTransactionFilter isList={isList} setIsList={setIsList} />
        )}
        {dropDownOpen && (
          <Scrollbars
            autoHide
            className="sideBarAppSelect"
            renderView={(props) => <div {...props} className="view" />}
          >
            {bankers
              .filter((banker) =>
                banker.bankerTag.toLowerCase().includes(search.toLowerCase())
              )
              .map((banker) => (
                <div
                  className="appPublication"
                  key={banker._id}
                  onClick={() => {
                    setDropDownOpen(false);
                    setSelectedBanker(banker);
                  }}
                >
                  <img src={banker.profilePicURL} alt="" className="pubLogo" />
                  <div className="nameNbtns">
                    <div className="name">{banker.bankerTag}</div>
                    <div className="btns">
                      <div
                        className="btnAction"
                        onClick={() => {
                          setBankerSelected(banker);
                        }}
                      >
                        Copy
                      </div>
                      <div className="btnAction">Expand</div>
                    </div>
                  </div>
                </div>
              ))}
          </Scrollbars>
        )}
        {bankerSelected && (
          <CopyPublicationDetailModal
            publication={bankerSelected}
            onClose={() => setBankerSelected(null)}
          />
        )}
        {refresh && <GodsEyeRefreshSidebar />}
      </div>
    </MainLayout>
  );
}

export default LiquidityPage;
