import { SearchAndFilter } from "../BrokerDashboard/index";
import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import ShoppersListSidebar from "./ShoppersListSidebar";
import { useShoppersList } from "../../queryHooks";
import ShopperItem from "./ShopperItem";

function ShoppersList({ searchOn }) {
  const [shopper, setShopper] = useState();

  const { data, isLoading: shopperslistLoading } = useShoppersList();

  // Searc Vars

  const [search, setSearch] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const [filteShopperName, setFilteShopperName] = useState("");

  return (
    <>
      {
        <div className="godsEyeMoreList">
          {searchOn && (
            <SearchAndFilter
              search={search}
              setSearch={setSearch}
              placeholder={"Search Shoppers"}
              filterBy={`${"Username"}`}
              mainList={[
                {
                  key: "0",
                  label: "Function Performed By Search Bar",
                  switch: isFilter,
                  switchLabel: isFilter ? "Filter" : "Search",
                  switchClick: () => setIsFilter(!isFilter),
                },
                {
                  key: "1",
                  label: "Filter Range",
                  switch: currentPage,
                  switchLabel: currentPage ? "Current Page" : "Entire List",
                  switchClick: () => setCurrentPage(!currentPage),
                },
              ]}
              filterList={[
                {
                  key: "11",
                  label: "Username",
                  switch: filteShopperName,
                  switchLabel: filteShopperName ? "On" : "Off",
                  switchClick: () => setFilteShopperName(!filteShopperName),
                },
              ]}
            />
          )}
          <Scrollbars className="moreListWrapper">
            {shopperslistLoading
              ? Array(6)
                  .fill("")
                  .map((_, i) => (
                    <div className="listItem">
                      <Skeleton alt="" className="icon" />
                      <Skeleton className="name" width={400} />
                      <Skeleton className="count" width={30} />
                    </div>
                  ))
              : data?.platformUser_data
                  ?.filter((shopper) =>
                    shopper.user_name
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  )
                  .map((shopper) => (
                    <ShopperItem
                      key={shopper._id}
                      shopper={shopper}
                      onClick={() => setShopper(shopper)}
                      selected={shopper}
                    />
                  ))}
          </Scrollbars>
        </div>
      }
      {shopper && (
        <ShoppersListSidebar onClose={() => setShopper()} shopper={shopper} />
      )}
    </>
  );
}

export default ShoppersList;
