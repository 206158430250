import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";

function CoinList({ setCoinSymbol, onClose, setTokenDetail }) {
  const [coins, setCoins] = useState([]);
  const [notCoins, setNotCoins] = useState([]);
  const [coinLoading, setCoinLoading] = useState(true);
  useEffect(() => {
    Axios.get("https://comms.globalxchange.io/coin/vault/get/all/coins")
      .then(({ data }) => {
        data.status && setCoins(data.coins);
      })
      .finally(() => setCoinLoading(false));
    Axios.get(
      "https://comms.globalxchange.io/gxb/app/gxlive/operator/appgroups/not/assigned/coins/get"
    )
      .then(({ data }) => {
        data.status && setNotCoins(data.coins);
      })
      .finally(() => setCoinLoading(false));
  }, []);
  const [search, setSearch] = useState("");
  const [isAll, setIsAll] = useState(false);
  return (
    <Fragment>
      <div className="titleOp">Select A Token</div>
      <div className="searchWrap">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Tokens....|"
        />
      </div>
      <div className="headTabs">
        <div className={`tab ${isAll}`} onClick={() => setIsAll(true)}>
          All Assets
        </div>
        <div className={`tab ${!isAll}`} onClick={() => setIsAll(false)}>
          Not Used
        </div>
      </div>
      <Scrollbars className="searchList">
        {coinLoading
          ? Array(6)
              .fill("")
              .map((_, i) => (
                <div className="user" key={i}>
                  <Skeleton className="dp" circle />
                  <div className="userDetail">
                    <Skeleton className="name" width={200} />
                  </div>
                </div>
              ))
          : (isAll ? coins : notCoins)
              .filter(
                (coin) =>
                  coin.coinName.toLowerCase().includes(search.toLowerCase()) ||
                  coin.coinSymbol.toLowerCase().includes(search.toLowerCase())
              )
              .map((coin) => (
                <div
                  className="user"
                  key={coin._id}
                  onClick={() => {
                    setCoinSymbol(coin.coinSymbol);
                    setTokenDetail(coin);
                    onClose();
                  }}
                >
                  <img className="dp" src={coin.coinImage} alt="" />
                  <div className="userDetail">
                    <div className="name">{coin.coinName}</div>
                  </div>
                </div>
              ))}
        <div className="space"></div>
      </Scrollbars>
    </Fragment>
  );
}

export default CoinList;
