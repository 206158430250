import Axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import Scrollbars from "react-custom-scrollbars/lib/Scrollbars";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { MainContext } from "../../context/MainContext";
import back from "../../static/images/back.svg";

function ConglomeratesListSidebarAddApps({ conglomerate, setLoading, onBack }) {
  const history = useHistory();
  const [appList, setAppList] = useState([]);
  const [appLoading, setAppLoading] = useState(false);
  const [selectedApps, setSelectedApps] = useState([]);
  useEffect(() => {
    setAppLoading(true);
    Axios.get(
      "https://comms.globalxchange.io/gxb/app/gxlive/operator/appgroups/not/assigned/apps/get"
    )
      .then((res) => {
        const { data } = res;
        if (data.status) {
          const { Apps } = data;
          setAppList(Apps);
        }
      })
      .finally(() => setAppLoading(false));
  }, []);

  const [search, setSearch] = useState("");
  const { email, token, tostShowOn } = useContext(MainContext);
  const [step, setStep] = useState("");
  function addApps() {
    setLoading(true);
    Axios.post(
      "https://comms.globalxchange.io/gxb/app/gxlive/operator/appgroups/add/apps",
      {
        email,
        token,
        group_id: conglomerate.group_id,
        app_codes: selectedApps,
      }
    )
      .then(({ data }) => {
        if (data.status) {
          setStep("addSucces");
        } else {
          tostShowOn(data.message || "Something Went Wrong");
        }
      })
      .finally(() => setLoading(false));
  }

  function getContent() {
    switch (step) {
      case "addSucces":
        setTimeout(() => {
          history.push("/ventures/Conglomerates");
          window.location.reload();
        }, 2000);
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm m-auto">
                You Have Successfully Add {selectedApps.length} Apps To{" "}
                {conglomerate.groupname}. You Will Be Redirected To The Updated
                Conglomerates List Automatically
              </div>
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm">
                Select Apps To Add To {conglomerate.groupname}
              </div>
              <div className="searchBox">
                <input
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search Apps...|"
                />
              </div>

              <Scrollbars autoHide className="itemListScroll">
                {appLoading
                  ? Array(6)
                      .fill("")
                      .map(() => <Skeleton className="item" />)
                  : appList
                      .filter(
                        (app) =>
                          app.app_code
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          app.app_name
                            .toLowerCase()
                            .includes(search.toLowerCase())
                      )
                      .map((app) => (
                        <div
                          className="item"
                          onClick={() => {
                            if (selectedApps.includes(app.app_code)) {
                              setSelectedApps(
                                selectedApps.filter(
                                  (apps) => app.app_code !== apps
                                )
                              );
                            } else {
                              setSelectedApps([...selectedApps, app.app_code]);
                            }
                          }}
                        >
                          <img className="coin" src={app.app_icon} alt="" />
                          <span>{app.app_name}</span>
                          <input
                            className="checkbox"
                            readOnly
                            type="checkbox"
                            checked={selectedApps.includes(app.app_code)}
                          ></input>
                        </div>
                      ))}
              </Scrollbars>
            </div>
            <div className="footerBtns">
              <div className="btnSee" onClick={addApps}>
                Add {selectedApps.length} Apps
              </div>
            </div>
          </>
        );
    }
  }

  return <>{getContent()}</>;
}

export default ConglomeratesListSidebarAddApps;
