import React, { useRef } from "react";
import OnOutsideClick from "../../utils/OnOutsideClick";

function MarketplacesItem({ marketplaces, onClick, selected }) {
  const btnRef = useRef();
  const outRef = useRef();

  OnOutsideClick(
    btnRef,
    () => {
      try {
        onClick();
      } catch (error) {}
    },
    outRef
  );

  return (
    <div className={`listItem ${marketplaces === selected}`} ref={outRef}>
      <div className="profile">
        <img src={marketplaces.icon} alt="" className="icon" />
        <span className="name">{marketplaces.name}</span>
      </div>
      <span className="count">{marketplaces?.usersCount} Stores</span>
      <div
        className="btnWebsite"
        ref={btnRef}
        onClick={() =>
          window
            .open(
              marketplaces?.additional_data?.website.includes("http")
                ? marketplaces?.additional_data?.website
                : `https://${marketplaces?.additional_data?.website}`,
              "_blank"
            )
            .focus()
        }
      >
        Website
      </div>
    </div>
  );
}

export default MarketplacesItem;
