import React, { useRef } from "react";
import OnOutsideClick from "../../utils/OnOutsideClick";

function PublisherItem({ publisher, onClick }) {
  const btnRef = useRef();
  const outRef = useRef();

  OnOutsideClick(
    btnRef,
    () => {
      try {
        onClick();
      } catch (error) {}
    },
    outRef
  );

  return (
    <div className="listItem" ref={outRef}>
      <div className="profile">
        <img src={publisher.profile_pic} alt="" className="icon" />
        <span className="name">{publisher.name}</span>
      </div>
      <span className="count">{publisher.country}</span>
      <div
        className="btnWebsite"
        ref={btnRef}
        onClick={() =>
          window
            .open(
              publisher.website.includes("http")
                ? publisher.website
                : `https://${publisher.website}`,
              "_blank"
            )
            .focus()
        }
      >
        Website
      </div>
    </div>
  );
}

export default PublisherItem;
