import React from "react";

function GodsEyeListStats({ totalUsers, appSelected }) {
  return (
    <div className="userStats">
      <div className="userStatsTitle">
        Total {appSelected?.app_code && appSelected.app_name} Users
      </div>
      <div className="userStatsLabel">List Name</div>
      <div className="userCountBox">
        <div className="label">Total Users</div>
        <div className="value">{totalUsers}</div>
      </div>
    </div>
  );
}

export default GodsEyeListStats;
