import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import JsonWebToken from "jsonwebtoken";
import Scrollbars from "react-custom-scrollbars";
import { MainContext } from "../../context/MainContext";
import countryCodes from "../../utils/countryCodes.json";
import { renameFile } from "../../utils/Functions";
import cloudUpload from "../../static/images/postClipArt/cloudUpload.svg";
import refreshIcn from "../../static/images/clipIcons/refresh.svg";
import LoadingAnim from "../LoadingAnim/LoadingAnim";

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

function SetInvestmentPath({ onClose, onBack }) {
  const { appList, token, tostShowOn } = useContext(MainContext);
  const [search, setSearch] = useState("");
  const [step, setStep] = useState("selectApp");
  const [appSelected, setAppSelected] = useState();
  const [coinSelected, setCoinSelected] = useState();
  const [bankerSelected, setBankerSelected] = useState();
  const [countrySelected, setCountrySelected] = useState();
  const [coinList, setCoinList] = useState([]);
  const [bankers, setBankers] = useState([]);

  const [coinName, setCoinName] = useState("");
  const [coinSymbol, setCoinSymbol] = useState("");
  const [totalVolume, setTotalVolume] = useState("");
  const [offeringPrice, setOfferingPrice] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Axios.get("https://teller2.apimachine.com/admin/allBankers").then(
      ({ data }) => {
        if (data.status) {
          setBankers(data.data);
        }
      }
    );
  }, []);
  useEffect(() => {
    if (appSelected?.app_code) {
      Axios.post(
        "https://comms.globalxchange.io/coin/vault/service/coins/get",
        {
          app_code: appSelected?.app_code,
        }
      ).then(({ data }) => {
        if (data.status) {
          setCoinList(data.coins_data);
        }
      });
    }
  }, [appSelected]);

  const [thubnailLink, setThubnailLink] = useState("");
  const [thumbLoading, setThumbLoading] = useState(false);
  const uploadImage = async (e) => {
    setThumbLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setThubnailLink(data.payload.url);
    setThumbLoading(false);
  };

  function updateData() {
    setLoading(true);
    Axios.post("https://comms.globalxchange.io/gxb/apps/investment/path/set", {
      email: appSelected?.created_by, // APP OWNER EMAIL
      token: token, // APP OWNER TOKEN
      app_code: appSelected?.app_code,
      asset: coinSelected?.coinSymbol,
      banker: bankerSelected?.bankerTag, // BANKER TAG
      country: countrySelected,
      token_symbol: coinSymbol,
      token_profile_data: {
        coinName: coinName,
        coinSymbol: coinSymbol,
        coinImage: thubnailLink,
      },
      token_totalSupply: totalVolume,
      token_price: offeringPrice,
    })
      .then(({ data }) => {
        if (data.status) {
          window?.location && window.location.reload();
        }
        tostShowOn(data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getContent() {
    switch (step) {
      case "selectApp":
        return (
          <div className="godsEyeFilterCurrency ">
            <div className="labelItm">
              Select App Codes For Your Equity Sale?
            </div>
            <input
              type="text"
              className="searchBox mb20"
              placeholder="Search Through Apps"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Scrollbars className="coinList">
              {appList
                ?.filter(
                  (app) =>
                    app.app_name.toLowerCase().includes(search.toLowerCase()) ||
                    app.app_code.toLowerCase().includes(search.toLowerCase())
                )
                .map((app) => (
                  <div
                    className="coinItem"
                    onClick={() => {
                      setAppSelected(app);
                      setStep("selectCoin");
                    }}
                  >
                    <img src={app.app_icon} alt="" className="coin" />
                    <span>{app.app_name}</span>
                  </div>
                ))}
            </Scrollbars>
          </div>
        );
      case "selectCoin":
        return (
          <div className="godsEyeFilterCurrency ">
            <div className="labelItm">Select Asset For Equity Sale</div>
            <input
              type="text"
              className="searchBox mb20"
              placeholder="Search Through Assets"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Scrollbars className="coinList">
              {coinList
                ?.filter(
                  (coin) =>
                    coin.coinName
                      .toLowerCase()
                      .includes(search.toLowerCase()) ||
                    coin.coinSymbol.toLowerCase().includes(search.toLowerCase())
                )
                .map((coin) => (
                  <div
                    className="coinItem"
                    key={coin._id}
                    onClick={() => {
                      setCoinSelected(coin);
                      setStep("selectBanker");
                    }}
                  >
                    <img src={coin.coinImage} alt="" className="coin" />
                    <span>{coin.coinName}</span>
                  </div>
                ))}
            </Scrollbars>
          </div>
        );
      case "selectBanker":
        return (
          <div className="godsEyeFilterCurrency ">
            <div className="labelItm">Select Banker For Equity Sale</div>
            <input
              type="text"
              className="searchBox mb20"
              placeholder="Search Through Bankers"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Scrollbars className="coinList">
              {bankers
                ?.filter((banker) =>
                  banker.bankerTag.toLowerCase().includes(search.toLowerCase())
                )
                .map((banker) => (
                  <div
                    className="coinItem"
                    key={banker._id}
                    onClick={() => {
                      setBankerSelected(banker);
                      setStep("selectCountry");
                    }}
                  >
                    <img src={banker.profilePicURL} alt="" className="coin" />
                    <span>{banker.bankerTag}</span>
                  </div>
                ))}
            </Scrollbars>
          </div>
        );
      case "selectCountry":
        return (
          <div className="godsEyeFilterCurrency ">
            <div className="labelItm">Select Country</div>
            <input
              type="text"
              className="searchBox mb20"
              placeholder="Search Through Countries"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Scrollbars className="coinList">
              {countryCodes &&
                Object.keys(countryCodes)
                  ?.filter((countryCode) =>
                    countryCodes[countryCode]
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  )
                  .map((countryCode) => (
                    <div
                      className="coinItem"
                      key={countryCode}
                      onClick={() => {
                        setCountrySelected(countryCodes[countryCode]);
                        setStep("finalForm");
                      }}
                    >
                      <img
                        src={`https://flagcdn.com/${countryCode}.svg`}
                        alt=""
                        className="coin"
                      />
                      <span>{countryCodes[countryCode]}</span>
                    </div>
                  ))}
            </Scrollbars>
          </div>
        );
      case "finalForm":
        return (
          <div className="godsEyeFilterCurrency">
            <Scrollbars
              className="coinList"
              renderTrackHorizontal={() => <div />}
              renderThumbHorizontal={() => <div />}
              renderTrackVertical={() => <div />}
              renderThumbVertical={() => <div />}
            >
              <div className="name">What Is The Name Of Coin</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  value={coinName}
                  placeholder="Ex. BrokerCoin"
                  onChange={(e) => setCoinName(e.target.value)}
                />
                <label className="fileInp">
                  <img
                    src={
                      thumbLoading ? refreshIcn : thubnailLink || cloudUpload
                    }
                    alt=""
                  />
                  <input type="file" onChange={uploadImage} accept="image/*" />
                </label>
              </div>
              <div className="name">What Is The Token Symbol</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  value={coinSymbol}
                  placeholder="Ex. BKRC"
                  onChange={(e) => setCoinSymbol(e.target.value)}
                />
              </div>
              <div className="name">What Is The Total Volume </div>
              <div className="inputWrap">
                <input
                  type="number"
                  className="text"
                  value={totalVolume}
                  placeholder="Ex. 100000"
                  onChange={(e) => setTotalVolume(e.target.value)}
                />
              </div>
              <div className="name">What Is The Offering Price</div>
              <div className="inputWrap">
                <input
                  type="number"
                  className="text"
                  value={offeringPrice}
                  placeholder="Ex. 100000"
                  onChange={(e) => setOfferingPrice(e.target.value)}
                />
                <div className="coinSym">{coinSelected?.coinSymbol}</div>
              </div>
              <div className="btnSubmit" onClick={updateData}>
                Submit
              </div>
            </Scrollbars>
          </div>
        );
      default:
        break;
    }
  }

  return (
    <>
      <div className="userHeads">
        <img src={appSelected?.app_icon} alt="" />
        <div className="detail">
          <div className="name">{appSelected?.app_name}</div>
          <div className="email">
            <span
              onClick={() => {
                try {
                  onClose();
                } catch (error) {}
              }}
            >
              Apps
            </span>{" "}
            -&gt;{" "}
            <span
              onClick={() => {
                try {
                  onBack();
                } catch (error) {}
              }}
            >
              Actions
            </span>{" "}
            -&gt; <span>Edit Cap Table</span>
          </div>
        </div>
      </div>
      {getContent()}
      {loading && <LoadingAnim />}
    </>
  );
}

export default SetInvestmentPath;
