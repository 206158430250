import moment from "moment";
import React, { createRef, useRef } from "react";
import guest from "../../static/images//guest.jpg";
import OnOutsideClick from "../../utils/OnOutsideClick";
import { FormatCurrency } from "../../utils/FunctionTools";

function ShopperItem({ shopper, onClick, selected }) {
  const elRefs = useRef([]);

  elRefs.current = Array(0)
    .fill()
    .map((_, i) => elRefs.current[i] || createRef());

  const refOut = useRef();
  OnOutsideClick(
    undefined,
    () => {
      try {
        onClick();
      } catch (error) {}
    },
    refOut,
    elRefs
  );
  return (
    <div
      className={`userItem ${!selected || shopper === selected}`}
      ref={refOut}
      onClick={() => {}}
    >
      <img src={shopper?.profile_pic || guest} alt="" className="profileImg" />
      <div className="nameEmail">
        <span className="name">{shopper?.user_name}</span>
        <span className="email">{shopper?.email}</span>
      </div>
      <div className="nameEmail sm">
        <span className="name">{moment().diff(moment(), "days")} Days</span>
        <span className="email">{moment().format("MMMM Do YYYY")}</span>
      </div>
      <div className="nameEmail sm hideOpen">
        <span className="name">
          {shopper?.marketplaces.length} Marketplaces
        </span>
        <span className="email">Click Here To See</span>
      </div>
      <div className="nameEmail sm hideOpen">
        <span className="name">${FormatCurrency()} </span>
        <span className="email">Total Spending</span>
      </div>
    </div>
  );
}

export default ShopperItem;
