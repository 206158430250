import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";

import appdataIcn from "../../static/images/clipIcons/appData.svg";
import deleteIcn from "../../static/images/refreshIcon/delete.svg";
import regNewIcn from "../../static/images/clipIcons/regNew.svg";
import cashIcn from "../../static/images/clipIcons/cash.svg";
import walletIcn from "../../static/images/clipIcons/wallet.svg";
import apiIcn from "../../static/images/clipIcons/apis.svg";
import yesIcn from "../../static/images/clipIcons/yes.svg";
import noIcn from "../../static/images/clipIcons/no.svg";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import AdminCredit from "./AdminCredit";
import NewAppRegister from "./NewAppRegister";
import back from "../../static/images/back.svg";
import close from "../../static/images/close.svg";
import guest from "../../static/images//guest.jpg";
import config from "../../static/images/clipIcons/config.svg";
import adminPermission from "../../static/images/clipIcons/adminPermission.svg";
import blockList from "../../static/images/clipIcons/blockList.svg";

function GodsEyeUsersSidebar({ setUserDataToExpand, userSelected, onClose }) {
  const [step, setStep] = useState("");
  const [stepIn, setStepIn] = useState("");
  const [tab, setTab] = useState("Actions");
  const [loading, setLoading] = useState(false);
  function deleteApp() {
    setLoading(true);
  }
  function getContent() {
    switch (true) {
      case tab === "Actions" && step === "Register New App":
        return (
          <NewAppRegister
            userSelected={userSelected}
            onBack={() => setStep("")}
            onClose={() => onClose()}
          />
        );
      case tab === "Actions" && step === "Adjust Settings":
        return (
          <>
            <div className="godsEyeFilterCurrency ">
              <div className="labelItm">
                Select One Of The Following Actions
              </div>
              <Scrollbars
                className="coinList"
                renderThumbHorizontal={() => <div />}
                renderThumbVertical={() => <div />}
                renderTrackHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
              >
                <div
                  className="coinItem"
                  onClick={() => setStepIn("Admin Permissions")}
                >
                  <img src={adminPermission} alt="" className="coin" />
                  <span>Admin Permissions</span>
                </div>
                <div
                  className="coinItem"
                  onClick={() => setStepIn("Blocklist")}
                >
                  <img src={blockList} alt="" className="coin" />
                  <span>Blocklist</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );

      case tab === "Actions" &&
        step === "Transact" &&
        stepIn === "Admin Credit/Debit":
        return (
          <AdminCredit
            userSelected={userSelected}
            onBack={() => setStep("")}
            onClose={() => onClose()}
          />
        );
      case tab === "Actions" && step === "Transact":
        return (
          <>
            <div className="godsEyeFilterCurrency ">
              <div className="labelItm">
                Select One Of The Following Actions
              </div>
              <Scrollbars
                className="coinList"
                renderThumbHorizontal={() => <div />}
                renderThumbVertical={() => <div />}
                renderTrackHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
              >
                <div
                  className="coinItem"
                  onClick={() => setStepIn("Admin Credit/Debit")}
                >
                  <img src={cashIcn} alt="" className="coin" />
                  <span>Admin Credit/Debit</span>
                </div>
                <div className="coinItem" onClick={() => setStepIn("")}>
                  <img src={cashIcn} alt="" className="coin" />
                  <span>Trade</span>
                </div>
                <div className="coinItem" onClick={() => setStepIn("")}>
                  <img src={cashIcn} alt="" className="coin" />
                  <span>Connect</span>
                </div>
                <div className="coinItem" onClick={() => setStepIn("")}>
                  <img src={cashIcn} alt="" className="coin" />
                  <span>Vault</span>
                </div>
                <div className="coinItem" onClick={() => setStepIn("")}>
                  <img src={cashIcn} alt="" className="coin" />
                  <span>External Withdrawal</span>
                </div>
                <div className="coinItem" onClick={() => setStepIn("")}>
                  <img src={cashIcn} alt="" className="coin" />
                  <span>External Deposit</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );
      case tab === "Actions" && step === "Delete":
        return (
          <>
            <div className="godsEyeFilterCurrency ">
              <div className="labelItm">
                Are You Sure You Want To Delete {userSelected?.userData?.name}
              </div>
              <Scrollbars className="coinList">
                <div className="coinItem" onClick={() => deleteApp()}>
                  <img src={yesIcn} alt="" className="coin" />
                  <span>Confirm</span>
                </div>
                <div className="coinItem" onClick={() => setStep()}>
                  <img src={noIcn} alt="" className="coin" />
                  <span>Never Mind</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );
      case tab === "Actions":
        return (
          <>
            <div className="godsEyeFilterCurrency ">
              <div className="labelItm">
                Select One Of The Following Actions
              </div>
              <Scrollbars
                className="coinList"
                renderThumbHorizontal={() => <div />}
                renderThumbVertical={() => <div />}
                renderTrackHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
              >
                <div className="coinItem" onClick={() => {}}>
                  <img src={appdataIcn} alt="" className="coin" />
                  <span>Edit Founder Profile</span>
                </div>
                <div
                  className="coinItem"
                  onClick={() => setStep("Register New App")}
                >
                  <img src={regNewIcn} alt="" className="coin" />
                  <span>Register For New App</span>
                </div>
                <div className="coinItem" onClick={() => setStep("Transact")}>
                  <img src={walletIcn} alt="" className="coin" />
                  <span>Transact</span>
                </div>
                <div
                  className="coinItem"
                  onClick={() => setStep("Adjust Settings")}
                >
                  <img src={config} alt="" className="coin" />
                  <span>Adjust Settings</span>
                </div>
                <div
                  className="coinItem"
                  onClick={() => setUserDataToExpand(userSelected)}
                >
                  <img src={apiIcn} alt="" className="coin" />
                  <span>User API’s</span>
                </div>
                <div className="coinItem" onClick={() => setStep("Delete")}>
                  <img src={deleteIcn} alt="" className="coin" />
                  <span>Delete User</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );

      default:
    }
  }
  return (
    <div className="godsEyeUserFiter userSidebar">
      <div className="headTabBox">
        <div
          className={`tab ${tab === "About"}`}
          onClick={() => {
            setTab("About");
            setStep("");
          }}
        >
          About
        </div>
        <div
          className={`tab ${tab === "Actions"}`}
          onClick={() => {
            setTab("Actions");
            setStep("");
          }}
        >
          Actions
        </div>
      </div>
      <div className="header">
        <div className="content">
          <div className="title">
            <img
              src={userSelected?.userData?.profile_img || guest}
              alt=""
              className="icon"
            />
            {userSelected?.userData?.name}
          </div>
          <div className="breadCrumbs">
            <span
              onClick={() => {
                setStep("");
                setStepIn("");
              }}
            >
              {tab === "Actions" ? `Actions` : `About`}
            </span>{" "}
            {step ? (
              <>
                -&gt;&nbsp;
                <span
                  onClick={() => {
                    setStepIn("");
                  }}
                >
                  {step}
                </span>
              </>
            ) : (
              ""
            )}
            {stepIn ? (
              <>
                -&gt;&nbsp;
                <span>{stepIn}</span>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        {step ? (
          <div
            className="backBtn"
            onClick={() => {
              setStep("");
            }}
          >
            <img src={back} alt="" />
          </div>
        ) : (
          ""
        )}
        <div
          className="backBtn"
          onClick={() => {
            onClose();
          }}
        >
          <img src={close} alt="" />
        </div>
      </div>
      {getContent()}
      {loading && <LoadingAnim />}
    </div>
  );
}

export default GodsEyeUsersSidebar;
