import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import back from "../../static/images/back.svg";
import close from "../../static/images/close.svg";
import wallet from "../../static/images/clipIcons/wallet.svg";
import editApp from "../../static/images/clipIcons/appData.svg";
import deleteApp from "../../static/images/refreshIcon/delete.svg";
import TokenAppEdit from "./TokenAppEdit";

function TokenAppSidebar({ tokenAppSelected, onClose }) {
  const [step, setStep] = useState("");
  const [tab, setTab] = useState("Actions");

  function getContent() {
    switch (true) {
      case tab === "Actions" && step === "Edit":
        return (
          <TokenAppEdit
            tokenAppSelected={tokenAppSelected}
            onBack={() => setStep("")}
          />
        );
      case tab === "Actions":
        return (
          <>
            <div className="godsEyeFilterCurrency" style={{ paddingTop: 0 }}>
              <div className="labelItm">
                Select One Of The Following Actions
              </div>
              <Scrollbars className="coinList">
                <div
                  className="coinItem"
                  onClick={() => {
                    setStep("Edit");
                  }}
                >
                  <img
                    src={editApp}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Edit App Data</span>
                </div>
                <div className="coinItem" onClick={() => setStep("")}>
                  <img
                    src={wallet}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Edit Currencies</span>
                </div>
                <div
                  className="coinItem"
                  onClick={() => {
                    setStep("Delete");
                  }}
                >
                  <img
                    src={deleteApp}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>Delete App</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );
      default:
        return;
    }
  }
  return (
    <div
      className="godsEyeUserFiter"
      style={{
        position: step === "Edit" ? "unset" : "",
      }}
    >
      <div className="headTabBox">
        <div
          className={`tab ${tab === "About"}`}
          onClick={() => {
            setTab("About");
            setStep("");
          }}
        >
          About
        </div>
        <div
          className={`tab ${tab === "Actions"}`}
          onClick={() => {
            setTab("Actions");
            setStep("");
          }}
        >
          Actions
        </div>
      </div>
      <div className="header">
        <div className="content">
          <div className="title">
            <img src={tokenAppSelected.app_icon} alt="" className="icon" />
            {tokenAppSelected.app_name}
          </div>
          <div className="breadCrumbs">
            <span
              onClick={() => {
                setStep("");
              }}
            >
              {tab}
            </span>{" "}
            {step ? (
              <>
                -&gt;&nbsp;
                <span>{step}</span>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        {step ? (
          <div
            className="backBtn"
            onClick={() => {
              setStep("");
            }}
          >
            <img src={back} alt="" />
          </div>
        ) : (
          ""
        )}
        <div
          className="backBtn"
          onClick={() => {
            onClose();
          }}
        >
          <img src={close} alt="" />
        </div>
      </div>
      {getContent()}
    </div>
  );
}

export default TokenAppSidebar;
