import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { useBondsList } from "../../queryHooks";
import { FormatCurrency, FormatNumber } from "../../utils/FunctionTools";

function BondsList({ addingBonds, setAddingBonds, email }) {
  const { data: bonds, isLoading: bondsLoading } = useBondsList(email);
  const [search, setSearch] = useState("");
  return (
    <div
      style={{
        flex: 1,
        height: 0,
        display: "flex",
        flexDirection: "column",
        margin: "0 20px",
      }}
    >
      <div className="titleOp">Select Users To Add</div>
      <div className="searchWrap">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Bonds By Asset....|"
        />
      </div>
      <Scrollbars className="searchList">
        {bondsLoading
          ? Array(6)
              .fill("")
              .map((_, i) => (
                <div className="user" key={i}>
                  <Skeleton className="dp" circle />
                  <div className="userDetail">
                    <Skeleton className="name" width={200} />
                    <Skeleton className="email" width={200} />
                  </div>
                </div>
              ))
          : bonds?.custom_bonds
              ?.filter(
                (bond) =>
                  bond?.coinsData?.[0]?.coin
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  bonds?.currenciesData?.[bond?.coinsData?.[0]?.coin]?.name
                    ?.toLowerCase()
                    .includes(search.toLowerCase())
              )
              .map((bond) => (
                <div
                  className="bond"
                  key={bond._id}
                  onClick={() => {
                    setAddingBonds((addingUsers) =>
                      addingUsers?.map((bnd) => bnd._id).includes(bond?._id)
                        ? addingUsers.filter((bnd) => bnd?._id !== bond?._id)
                        : [...addingUsers, bond]
                    );
                  }}
                >
                  <div className="coinDetail">
                    <img
                      className="dp"
                      src={
                        bonds?.currenciesData?.[bond?.coinsData?.[0]?.coin]
                          ?.icon
                      }
                      alt=""
                    />
                    <div className="name">
                      {
                        bonds?.currenciesData?.[bond?.coinsData?.[0]?.coin]
                          ?.name
                      }
                    </div>
                    <input
                      readOnly
                      style={{ marginLeft: "auto" }}
                      type="checkbox"
                      checked={addingBonds
                        ?.map((bnd) => bnd._id)
                        .includes(bond?._id)}
                    ></input>
                  </div>
                  <div className="bondValues">
                    <span className="cost">
                      {FormatCurrency(
                        bond?.coinsData?.[0]?.bondCost,
                        bond?.coinsData?.[0]?.coin
                      )}{" "}
                      {bond?.coinsData?.[0]?.coin}
                    </span>
                    <span className="intrst">
                      {FormatNumber(bond?.daily_interest_rate, 3)}%/Day
                    </span>
                    <span className="days">
                      {FormatNumber(bond?.days)} Days
                    </span>
                  </div>
                </div>
              ))}
        <div className="space"></div>
      </Scrollbars>
    </div>
  );
}

export default BondsList;
