import { SearchAndFilter } from "../BrokerDashboard/index";
import React, { useContext, useEffect, useState, useRef } from "react";
import Scrollbars from "react-custom-scrollbars";
import { MainContext } from "../../context/MainContext";
import GodsEyeAppsSidebar from "../GodsEyeAppsSidebar/GodsEyeAppsSidebar";
import AppItem from "./AppItem";

function GodsIoApps({ searchOn, list, setList }) {
  const { appList } = useContext(MainContext);
  const [search, setSearch] = useState("");
  const [appSelected, setAppSelected] = useState();
  const ref = useRef();

  useEffect(() => {
    if (appSelected) setList(true);
    else setList(false);
  }, [appSelected, setList]);

  useEffect(() => {
    if (!list) setAppSelected();
  }, [list]);

  useEffect(() => {
    if (searchOn) {
      setTimeout(() => {
        ref?.current && ref.current.focus();
      }, 200);
    }
  }, [searchOn]);

  // Filter Variables
  const [currentPage, setCurrentPage] = useState(true);
  const [isFilter, setIsFilter] = useState(false);
  const [filterAppCode, setFilterAppCode] = useState(false);
  const [filterAppName, setFilterAppName] = useState(true);

  return (
    <div className="godsIoApps">
      {searchOn && (
        <SearchAndFilter
          search={search}
          setSearch={setSearch}
          placeholder="Search App"
          filterBy={`${filterAppName ? "App Name ," : ""}${
            filterAppCode ? "App Code" : ""
          }`}
          mainList={[
            {
              key: "0",
              label: "Function Performed By Search Bar",
              switch: isFilter,
              switchLabel: isFilter ? "Filter" : "Search",
              switchClick: () => setIsFilter(!isFilter),
            },
            {
              key: "1",
              label: "Filter Range",
              switch: false,
              switchLabel: currentPage ? "Current Page" : "Entire List",
              switchClick: () => setCurrentPage(!currentPage),
            },
          ]}
          filterList={[
            {
              key: "10",
              label: "App Code",
              switch: filterAppCode,
              switchLabel: filterAppCode ? "On" : "Off",
              switchClick: () => setFilterAppCode(!filterAppCode),
            },
            {
              key: "11",
              label: "App Name",
              switch: filterAppName,
              switchLabel: filterAppName ? "On" : "Off",
              switchClick: () => setFilterAppName(!filterAppName),
            },
          ]}
        />
      )}
      <div className={`appsContent ${Boolean(appSelected)}`}>
        <Scrollbars
          renderTrackHorizontal={() => <div />}
          renderThumbHorizontal={() => <div />}
          renderTrackVertical={() => <div />}
          renderThumbVertical={() => <div />}
          className="appList"
        >
          {appList
            ?.filter(
              (app) =>
                (filterAppCode &&
                  app.app_code.toLowerCase().includes(search.toLowerCase())) ||
                (filterAppName &&
                  app.app_name.toLowerCase().includes(search.toLowerCase()))
            )
            .map((app) => (
              <AppItem
                key={app._id}
                app={app}
                appSelected={appSelected}
                setAppSelected={setAppSelected}
              />
            ))}
          <div
            className="fxClose"
            onClick={() => {
              setList(false);
              setAppSelected();
            }}
          />
        </Scrollbars>
        {appSelected && (
          <GodsEyeAppsSidebar
            appSelected={appSelected}
            onClose={() => {
              setList(false);
              setAppSelected();
            }}
          />
        )}
      </div>
    </div>
  );
}

export default GodsIoApps;
