import React, { createRef, useContext, useRef } from "react";
import moment from "moment";
import guest from "../../static/images//guest.jpg";
import { FormatCurrency } from "../../utils/Functions";
import { MainContext } from "../../context/MainContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import OnOutsideClick from "../../utils/OnOutsideClick";

function GodsEyeUserItem({
  user,
  onClick,
  coinSelected,
  isUname,
  userSelected,
}) {
  const { copyToClipboard } = useContext(MainContext);
  const elRefs = useRef([]);

  elRefs.current = Array(8)
    .fill()
    .map((_, i) => elRefs.current[i] || createRef());

  const refOut = useRef();
  OnOutsideClick(
    undefined,
    () => {
      try {
        onClick();
      } catch (error) {}
    },
    refOut,
    elRefs
  );
  console.log(`userss`, user, user?.appsData?.appsData[0]);
  return (
    <div
      className={`userItem ${!userSelected || user === userSelected}`}
      ref={refOut}
      onClick={() => {}}
    >
      <img
        src={user?.userData?.profile_img || guest}
        alt=""
        className="profileImg"
      />
      <div className="nameEmail">
        <span className="name">
          {isUname ? user?.userData?.username : user?.userData?.name}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[0]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(
                `${isUname ? user?.userData?.username : user?.userData?.name}`,
                `${isUname ? "Username" : "Name"} Copied`
              );
            }}
          />
        </span>
        <span className="email">
          {user?.userData?.email}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[1]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(`${user?.userData?.email}`, `Email Copied`);
            }}
          />
        </span>
      </div>
      <div className="nameEmail sm">
        <span className="name">
          {moment().diff(moment(user?.userData?.date), "days")} Days
          <FontAwesomeIcon
            forwardedRef={elRefs.current[2]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(
                `${moment().diff(moment(user?.userData?.date), "days")} Days`,
                "Days Copied"
              );
            }}
          />
        </span>
        <span className="email">
          {moment(user?.userData?.date).format("MMMM Do YYYY")}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[3]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(
                `${moment(user?.userData?.date).format("MMMM Do YYYY")}`,
                "Date Copied"
              );
            }}
          />
        </span>
      </div>
      <div className="nameEmail sm hideOpen">
        <span className="name">
          {user?.appsData?.appsData[0]?.app_name}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[4]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(
                user?.appsData?.appsData[0]?.app_name,
                `App Name Copied`
              );
            }}
          />
        </span>
        <span className="email">App Code</span>
      </div>
      <div className="nameEmail hideOpen">
        <span className="name">
          {isUname ? user?.refUserData?.username : user?.refUserData?.name}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[4]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(
                `${
                  isUname
                    ? user?.refUserData?.username
                    : user?.refUserData?.name
                }`,
                `${isUname ? "Username" : "Name"} Copied`
              );
            }}
          />
        </span>
        <span className="email">
          {user?.refUserData?.email}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[5]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(`${user?.refUserData?.email}`, `Email Copied`);
            }}
          />
        </span>
      </div>
      <div className="nameEmail sm hideOpen">
        <span className="name">
          {coinSelected.coinSymbol === "USD" && "$"}
          {FormatCurrency(
            user?.total_holdings / coinSelected.price.USD,
            coinSelected.coinSymbol
          )}{" "}
          {coinSelected.coinSymbol !== "USD" && coinSelected.coinSymbol}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[6]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(
                `${coinSelected.coinSymbol === "USD" && "$"}${FormatCurrency(
                  user?.total_holdings / coinSelected.price.USD,
                  coinSelected.coinSymbol
                )} ${
                  coinSelected.coinSymbol !== "USD" && coinSelected.coinSymbol
                }`,
                "Total Holdings Copied"
              );
            }}
          />
        </span>
        <span className="email">Total Holdings</span>
      </div>
      <div className="nameEmail sm hideOpen">
        <span className="name">
          {coinSelected.coinSymbol === "USD" && "$"}
          {FormatCurrency(
            user?.liquid_holdings / coinSelected.price.USD,
            coinSelected.coinSymbol
          )}{" "}
          {coinSelected.coinSymbol !== "USD" && coinSelected.coinSymbol}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[7]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(
                `${coinSelected.coinSymbol === "USD" && "$"}${FormatCurrency(
                  user?.liquid_holdings / coinSelected.price.USD,
                  coinSelected.coinSymbol
                )} ${
                  coinSelected.coinSymbol !== "USD" && coinSelected.coinSymbol
                }`,
                "Liquid Holdings Copied"
              );
            }}
          />
        </span>
        <span className="email">Liquid Holdings</span>
      </div>
    </div>
  );
}

export default GodsEyeUserItem;
