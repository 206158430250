import React, { useContext, useState } from "react";
import Axios from "axios";
import JsonWebToken from "jsonwebtoken";
import Scrollbars from "react-custom-scrollbars";
import { useHistory } from "react-router";
import cloudUploadIcon from "../../static/images/postClipArt/cloudUpload.svg";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import { MainContext } from "../../context/MainContext";
import loadingGif from "../../static/animations/loading.gif";
import yesIcn from "../../static/images/clipIcons/yes.svg";
import noIcn from "../../static/images/clipIcons/no.svg";
import guest from "../../static/images//guest.jpg";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

export const getUserDetail = async (email, username) => {
  const { data } = await Axios.get(
    `https://comms.globalxchange.io/user/profile/data/get?${
      email ? "&email=" + email : ""
    }${username ? "&username=" + username : ""}`
  );
  return data.usersData[0];
};

function NewShopper() {
  const history = useHistory();
  const { tostShowOn } = useContext(MainContext);
  const [step, setStep] = useState("");
  const [loading, setLoading] = useState(false);

  // Form Variables
  const [icon, setIcon] = useState("");
  const [email, setEmail] = useState("");
  const [uname, setUname] = useState("");

  const [iconLoading, setIconLoading] = useState(false);

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
    setLoading(false);
  };
  const [userData, setUserData] = useState({});
  const checkEmail = async (email, username) => {
    let userData = {};
    try {
      if (EMAIL_REGEX.test(email)) {
        userData = (await getUserDetail(email, username)) || {};
        if (!userData?.email)
          userData.error = "Sorry We Could Not Find That User";
      } else userData.error = "Invalid Email Id";
    } catch (error) {
      userData.error = "Sorry We Could Not Find That User";
    }
    setUserData(userData);
  };

  function getContent() {
    switch (step) {
      case "success":
        setTimeout(() => {
          history.push("/commerce/Shoppers");
          window.location.reload();
        }, 2000);
        return (
          <div className="newConglomerate">
            <div className="succesView">
              <div className="labelItm">
                You Have Successfully Created {uname}. You Will Be Redirected To
                The Updated Shopper List Automatically
              </div>
            </div>
          </div>
        );
      default:
        return (
          <>
            <div className="newConglomerate">
              <Scrollbars
                className="scrollForm"
                renderTrackHorizontal={() => <div />}
                renderThumbHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
                renderThumbVertical={() => <div />}
              >
                <div className="name">Enter Existing GX Email</div>
                <div className="inputWrap">
                  <input
                    type="email"
                    className="text"
                    placeholder="Enter GX Email"
                    value={userData?.error || email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div
                    className="btnCheck"
                    onClick={() =>
                      userData.error ? setUserData({}) : checkEmail(email)
                    }
                  >
                    {userData?.error ? "Reset" : "Check"}
                  </div>
                </div>

                {userData?.email && (
                  <div
                    className="user"
                    style={{ marginTop: 0, flex: "0 0 80px", paddingRight: 0 }}
                  >
                    <img
                      className="dp"
                      src={userData?.dynamic[0]?.data?.profile_img || guest}
                      alt=""
                    />
                    <div className="userDetail">
                      <div className="name">
                        {userData?.hardCoded[0]?.data?.username}
                      </div>
                      <div className="email">
                        {userData?.hardCoded[0]?.data?.email}
                      </div>
                    </div>
                    <div className="btnYesNo" style={{ marginLeft: "auto" }}>
                      <img src={yesIcn} alt="" />
                    </div>
                    <div className="btnYesNo" onClick={() => setUserData({})}>
                      <img src={noIcn} alt="" />
                    </div>
                  </div>
                )}
                <div className="name">Create A Username</div>
                <div className="inputWrap">
                  <input
                    type="email"
                    className="text"
                    placeholder="Enter Username Here"
                    value={uname}
                    onChange={(e) => setUname(e.target.value)}
                  />
                  <div className="btnCheck">Check</div>
                </div>
                <div className="name">Upload Branding Material</div>
                <div className="filesUpload">
                  <label className="fileInp icon">
                    <img
                      className={`${Boolean(icon)}`}
                      src={iconLoading ? loadingGif : icon || cloudUploadIcon}
                      alt=""
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setIcon, setIconLoading);
                      }}
                      accept="image/*"
                    />
                    <div className="text">Colored Icon</div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                </div>
                <div className="space"></div>
              </Scrollbars>
            </div>
            <div className="ftBtns">
              <div
                className="newField"
                style={{ opacity: 0.4 }}
                onClick={() => {}}
              >
                New Field
              </div>
              <div className="btnSubmit" onClick={() => validate(true)}>
                Submit
              </div>
            </div>
          </>
        );
    }
  }

  function addShopper() {
    setLoading(true);
    Axios.post("https://commerce.apimachine.com/marketplaces/platform/users", {
      email: email,
      user_name: uname,
      profile_pic: icon,
    })
      .then(({ data }) => {
        tostShowOn("New Shopper Added");
        if (data.status === false) {
          tostShowOn(data.message || "API Error");
        } else setStep("success");
      })
      .catch((error) => {
        tostShowOn(error?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  }

  function validate() {
    if (email && icon && uname) {
      addShopper();
    } else {
      tostShowOn("All Fields Are Mandatory");
    }
  }

  return (
    <>
      {getContent()}
      {loading && <LoadingAnim />}{" "}
    </>
  );
}

export default NewShopper;
