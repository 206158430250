import React from "react";
import apiClip from "../../static/images/postClipArt/api.svg";
import profileClip from "../../static/images/postClipArt/profile.svg";

function BankerTransactionDetail({ setStep }) {
  return (
    <div className="bankerTransactionDetail">
      <div className="apiProfile" onClick={() => setStep(1)}>
        <img src={apiClip} alt="" />
        <h5>API</h5>
      </div>
      <div className="apiProfile">
        <img src={profileClip} alt="" />
        <h5>Profile</h5>
      </div>
    </div>
  );
}

export default BankerTransactionDetail;
