import { SearchAndFilter } from "../BrokerDashboard/index";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import ConglomerateItem from "./ConglomerateItem";
import ConglomeratesListSidebar from "./ConglomeratesListSidebar";

function ConglomeratesList({ list, setList, searchOn }) {
  const [conglomerateslist, setConglomeratesList] = useState([]);
  const [conglomerateslistLoading, setConglomeratesListLoading] =
    useState(true);
  const [conglomerate, setConglomerate] = useState();
  useEffect(() => {
    Axios.get(
      "https://comms.globalxchange.io/gxb/app/gxlive/operator/app/groups/get"
    )
      .then(({ data }) => {
        setConglomeratesList(data.groups);
      })
      .finally(() => setConglomeratesListLoading(false));
  }, []);

  //
  useEffect(() => {
    if (conglomerate) setList(true);
    else setList(false);
  }, [conglomerate, setList]);

  useEffect(() => {
    if (!list) setConglomerate();
  }, [list]);

  // Searc Vars

  const [search, setSearch] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const [filteConglomerateName, setFilteConglomerateName] = useState("");

  return (
    <>
      {
        <div className="godsEyeMoreList">
          {searchOn && (
            <SearchAndFilter
              search={search}
              setSearch={setSearch}
              placeholder={"Search Conglomerate"}
              filterBy={`${"Name Of Group"}`}
              mainList={[
                {
                  key: "0",
                  label: "Function Performed By Search Bar",
                  switch: isFilter,
                  switchLabel: isFilter ? "Filter" : "Search",
                  switchClick: () => setIsFilter(!isFilter),
                },
                {
                  key: "1",
                  label: "Filter Range",
                  switch: currentPage,
                  switchLabel: currentPage ? "Current Page" : "Entire List",
                  switchClick: () => setCurrentPage(!currentPage),
                },
              ]}
              filterList={[
                {
                  key: "11",
                  label: "Name Of Group",
                  switch: filteConglomerateName,
                  switchLabel: filteConglomerateName ? "On" : "Off",
                  switchClick: () =>
                    setFilteConglomerateName(!filteConglomerateName),
                },
              ]}
            />
          )}
          <Scrollbars className="moreListWrapper">
            {conglomerateslistLoading
              ? Array(6)
                  .fill("")
                  .map((_, i) => (
                    <div className="listItem">
                      <Skeleton alt="" className="icon" />
                      <Skeleton className="name" width={400} />
                      <Skeleton className="count" width={30} />
                    </div>
                  ))
              : conglomerateslist
                  .filter((data) =>
                    data.groupname.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((data) => (
                    <ConglomerateItem
                      key={data._id}
                      conglomerate={data}
                      onClick={() => setConglomerate(data)}
                      selected={conglomerate}
                    />
                  ))}
          </Scrollbars>
        </div>
      }
      {conglomerate && (
        <ConglomeratesListSidebar
          onClose={() => setConglomerate()}
          conglomerate={conglomerate}
        />
      )}
    </>
  );
}

export default ConglomeratesList;
