import { SearchAndFilter } from "../BrokerDashboard/index";
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import CopyPublicationDetailModal from "../CopyPublicationDetailModal/CopyPublicationDetailModal";
import GodsEyeUserFilter from "../GodsEyeUserFilter/GodsEyeUserFilter";
import GodsEyeUsersSidebar from "../GodsEyeUsersSidebar/GodsEyeUsersSidebar";
import GodsEyeUserItem from "./GodsEyeUserItem";

function GodsEyeUsers({
  appSelected,
  setUserDataToExpand,
  setListLength,
  searchOn,
  list,
  page,
  setPage,
  setList,
}) {
  const [totalUsers, setTotalUsers] = useState(0);
  const [userList, setUserList] = useState([]);
  const [toCopy, setToCopy] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(8);
  const [coinSelected, setCoinSelected] = useState({
    coinSymbol: "USD",
    price: { USD: 1 },
  });
  const [isUname, setIsUname] = useState(false);
  const [itemPerPage, setItemPerPage] = useState(100);
  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(true);
  const ref = useRef();
  useEffect(() => {
    if (searchOn) {
      setTimeout(() => {
        ref?.current && ref.current.focus();
      }, 200);
    }
  }, [searchOn]);
  useEffect(() => {
    setPage(1);
  }, [itemPerPage, setPage]);
  useEffect(() => {
    setUserList([]);
    setLoading(true);
    let itms = itemPerPage;
    if (!currentPage && totalUsers) {
      itms = totalUsers + 1;
    }
    appSelected &&
      Axios.get(
        `https://comms.globalxchange.io/coin/vault/service/users/holdings/data/get?${
          appSelected.app_code ? "app_code=" + appSelected.app_code : ""
        }&skip=${(page - 1) * itemPerPage}&limit=${itms}`
      )
        .then((res) => {
          const { data } = res;
          if (data.status) {
            setUserList(data.users);
            setListLength(data.users.length);
            setTotalUsers(data.totalUsersCount);
          }
        })
        .finally(() => {
          setLoading(false);
        });
  }, [appSelected, itemPerPage, page, setListLength, currentPage]);
  const [userSelected, setUserSelected] = useState();
  useEffect(() => {
    if (userSelected) setList(true);
    else setList(false);
  }, [userSelected, setList]);
  useEffect(() => {
    if (!list) setUserSelected();
  }, [list]);

  // Filter Variables
  const [filterEmail, setFilterEmail] = useState(true);
  const [filterUsername, setFilterUsername] = useState(true);
  const [filterUplineMail, setFilterUplineMail] = useState(false);
  const [filterUplinename, setFilterUplinename] = useState(false);
  return (
    <>
      <div className="godsEyeUsers">
        {searchOn && (
          <SearchAndFilter
            search={search}
            setSearch={setSearch}
            placeholder={`Type In Any Users From ${appSelected.app_name}.....`}
            filterBy={`${filterEmail ? "Email ," : ""}${
              filterUsername ? "Username ," : ""
            }${filterUplineMail ? "Upline Email ," : ""}${
              filterUplinename ? "Upline Username" : ""
            }`}
            mainList={[
              {
                key: "0",
                label: "Function Performed By Search Bar",
                switch: isFilter,
                switchLabel: isFilter ? "Filter" : "Search",
                switchClick: () => setIsFilter(!isFilter),
              },
              {
                key: "1",
                label: "Filter Range",
                switch: currentPage,
                switchLabel: currentPage ? "Current Page" : "Entire List",
                switchClick: () => setCurrentPage(!currentPage),
              },
            ]}
            filterList={[
              {
                key: "10",
                label: "Username",
                switch: filterUsername,
                switchLabel: filterUsername ? "On" : "Off",
                switchClick: () => setFilterUsername(!filterUsername),
              },
              {
                key: "11",
                label: "Email",
                switch: filterEmail,
                switchLabel: filterEmail ? "On" : "Off",
                switchClick: () => setFilterEmail(!filterEmail),
              },
              {
                key: "12",
                label: "Upline Email",
                switch: filterUplineMail,
                switchLabel: filterUplineMail ? "On" : "Off",
                switchClick: () => setFilterUplineMail(!filterUplineMail),
              },
              {
                key: "13",
                label: "Upline Username",
                switch: filterUplinename,
                switchLabel: filterUplinename ? "On" : "Off",
                switchClick: () => setFilterUplinename(!filterUplinename),
              },
            ]}
          />
        )}
        <Scrollbars
          autoHide
          className="userListWrapper"
          renderView={(props) => (
            <div {...props} className={`userList ${Boolean(userSelected)}`} />
          )}
          onScrollFrame={(data) => {
            data.top === 1 && setCount((count) => count + 10);
          }}
          key={`${
            userList.filter(
              (user) =>
                (filterUsername &&
                  user?.userData?.name
                    ?.toLowerCase()
                    .includes(search.toLowerCase())) ||
                (filterEmail &&
                  user?.userData?.email
                    ?.toLowerCase()
                    .includes(search.toLowerCase())) ||
                (filterUplineMail &&
                  user?.refUserData?.email
                    ?.toLowerCase()
                    .includes(search.toLowerCase())) ||
                (filterUplinename &&
                  user?.refUserData?.name
                    ?.toLowerCase()
                    .includes(search.toLowerCase()))
            ).length
          }`}
        >
          {loading
            ? Array.from(Array(10).keys()).map((key) => (
                <div className="userItem" key={key}>
                  <Skeleton circle width={50} height={50} />
                  <div className="nameEmail">
                    <span className="name">
                      <Skeleton />
                    </span>
                    <span className="email">
                      <Skeleton />
                    </span>
                  </div>
                  <div className="time">
                    <Skeleton />
                  </div>
                  <div className="balance">
                    <Skeleton />
                  </div>
                  <div className="btnActions">
                    <Skeleton className="btnAction" />
                    <Skeleton className="btnAction" />
                    <Skeleton className="btnAction" />
                  </div>
                </div>
              ))
            : userList
                .filter(
                  (user) =>
                    (filterUsername &&
                      user?.userData?.name
                        ?.toLowerCase()
                        .includes(search.toLowerCase())) ||
                    (filterEmail &&
                      user?.userData?.email
                        ?.toLowerCase()
                        .includes(search.toLowerCase())) ||
                    (filterUplineMail &&
                      user?.refUserData?.email
                        ?.toLowerCase()
                        .includes(search.toLowerCase())) ||
                    (filterUplinename &&
                      user?.refUserData?.name
                        ?.toLowerCase()
                        .includes(search.toLowerCase()))
                )
                .slice(0, count)
                .map((user) => (
                  <GodsEyeUserItem
                    key={user._id}
                    coinSelected={coinSelected}
                    userSelected={userSelected}
                    isUname={isUname}
                    user={user}
                    onClick={() => {
                      setUserSelected(user);
                    }}
                  />
                ))}
          <div
            className="fxClose"
            onClick={() => {
              setList(false);
              setUserSelected();
            }}
          />
        </Scrollbars>
        {toCopy && (
          <CopyPublicationDetailModal
            publication={toCopy}
            onClose={() => setToCopy(false)}
          />
        )}
      </div>
      {list &&
        (userSelected ? (
          <GodsEyeUsersSidebar
            setUserDataToExpand={setUserDataToExpand}
            userSelected={userSelected}
            onClose={() => {
              setUserSelected();
              setList(false);
            }}
          />
        ) : (
          <GodsEyeUserFilter
            setCoinSelected={setCoinSelected}
            isUname={isUname}
            setIsUname={setIsUname}
            itemPerPage={itemPerPage}
            setItemPerPage={setItemPerPage}
            totalUsers={totalUsers}
            appSelected={appSelected}
          />
        ))}
    </>
  );
}

export default GodsEyeUsers;
