import React, { useRef } from "react";
import OnOutsideClick from "../../utils/OnOutsideClick";

function ConglomerateItem({ conglomerate, onClick, selected }) {
  const btnRef = useRef();
  const outRef = useRef();

  OnOutsideClick(
    btnRef,
    () => {
      try {
        onClick();
      } catch (error) {}
    },
    outRef
  );

  return (
    <div className={`listItem ${conglomerate === selected}`} ref={outRef}>
      <div className="profile">
        <img src={conglomerate.icon} alt="" className="icon" />
        <span className="name">{conglomerate.groupname}</span>
      </div>
      <span className="count">{conglomerate.apps?.length || 0} Apps</span>
      <span className="count" style={{ marginLeft: 100 }}>
        {conglomerate.brands?.length || 0} Brands
      </span>
      <div
        className="btnWebsite"
        ref={btnRef}
        onClick={() =>
          window
            .open(
              conglomerate.website.includes("http")
                ? conglomerate.website
                : `https://${conglomerate.website}`,
              "_blank"
            )
            .focus()
        }
      >
        Website
      </div>
    </div>
  );
}

export default ConglomerateItem;
