import React, { useState, useEffect } from "react";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";

import AssetFedBondsSidebar from "./AssetFedBondsSidebar";
import Skeleton from "react-loading-skeleton";

function AssetFedBonds({ search }) {
  const [coins, setCoins] = useState([]);
  const [coinToChange, setCoinToChange] = useState({});
  const [loading, setLoading] = useState(false);
  const getRates = () => {
    setLoading(true);
    Axios.get("https://comms.globalxchange.io/coin/vault/get/all/coins")
      .then((res) => {
        const { data } = res;
        if (data.status) {
          setCoins(data.coins);
        }
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getRates();
  }, []);
  return (
    <div className={`wrapperContent ${Boolean(coinToChange.coinName)}`}>
      <Scrollbars
        autoHide
        className="scrollWrap"
        renderView={(props) => <div {...props} className="scrollView" />}
      >
        {loading
          ? Array(6)
              .fill("")
              .map(() => (
                <div className="listItem">
                  <Skeleton width={40} height={40} circle />
                  <Skeleton className="name" width={320} />
                </div>
              ))
          : coins
              .filter(
                (coin) =>
                  coin.coinName.toLowerCase().includes(search.toLowerCase()) ||
                  coin.coinSymbol.toLowerCase().includes(search.toLowerCase())
              )
              .map((coin) => (
                <div
                  key={coin.coinSymbol}
                  className={`listItem ${coin === coinToChange}`}
                  onClick={() =>
                    setCoinToChange(coin === coinToChange ? {} : coin)
                  }
                >
                  <img src={coin?.coinImage} alt="" />
                  <div className="name">{coin?.coinName}</div>
                </div>
              ))}
      </Scrollbars>
      {Boolean(coinToChange.coinName) && (
        <AssetFedBondsSidebar coinToChange={coinToChange} getRates={getRates} />
      )}
    </div>
  );
}

export default AssetFedBonds;
