import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { useUserList } from "../../queryHooks";

function UserList({ addingUsers, setAddingUsers, appCode }) {
  const { data: users, isLoading: usersLoading } = useUserList(appCode);
  const [search, setSearch] = useState("");
  return (
    <div
      style={{
        flex: 1,
        height: 0,
        display: "flex",
        flexDirection: "column",
        margin: "0 20px",
      }}
    >
      <div className="titleOp">Select Users To Add</div>
      <div className="searchWrap">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Users By Email....|"
        />
      </div>
      <Scrollbars className="searchList">
        {usersLoading
          ? Array(6)
              .fill("")
              .map((_, i) => (
                <div className="user" key={i}>
                  <Skeleton className="dp" circle />
                  <div className="userDetail">
                    <Skeleton className="name" width={200} />
                    <Skeleton className="email" width={200} />
                  </div>
                </div>
              ))
          : users
              .filter(
                (user) =>
                  user?.name?.toLowerCase().includes(search.toLowerCase()) ||
                  user?.email?.toLowerCase().includes(search.toLowerCase())
              )
              .map((user) => (
                <div
                  className="user"
                  key={user._id}
                  onClick={() => {
                    setAddingUsers((addingUsers) =>
                      addingUsers?.map((usr) => usr._id).includes(user?._id)
                        ? addingUsers.filter((usr) => usr?._id !== user?._id)
                        : [...addingUsers, user]
                    );
                  }}
                >
                  <img className="dp" src={user.profile_img} alt="" />
                  <div className="userDetail">
                    <div className="name">{user.name}</div>
                    <div className="email">{user.email}</div>
                  </div>
                  <input
                    readOnly
                    style={{ marginLeft: "auto" }}
                    type="checkbox"
                    checked={addingUsers
                      ?.map((usr) => usr._id)
                      .includes(user?._id)}
                  ></input>
                </div>
              ))}
        <div className="space"></div>
      </Scrollbars>
    </div>
  );
}

export default UserList;
