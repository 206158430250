import Axios from "axios";
import React, { useContext, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { MainContext } from "../../context/MainContext";
import editApp from "../../static/images/clipIcons/appData.svg";
import deleteApp from "../../static/images/refreshIcon/delete.svg";
import yesIcn from "../../static/images/clipIcons/yes.svg";
import noIcn from "../../static/images/clipIcons/no.svg";
import addApp from "../../static/images/clipIcons/addApp.svg";
import backIcon from "../..//static/images/back.svg";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import close from "../../static/images/close.svg";
import back from "../../static/images/back.svg";
import { useHistory } from "react-router-dom";
import ConglomeratesListSidebarAddApps from "./ConglomeratesListSidebarAddApps";
import ConglomeratesListSidebarAllApps from "./ConglomeratesListSidebarAllApps";
import ConglomeratesListSidebarEdit from "./ConglomeratesListSidebarEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

function ConglomeratesListSidebar({ conglomerate, onClose }) {
  const { email, token, tostShowOn, copyToClipboard } = useContext(MainContext);
  const [step, setStep] = useState("");
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("Actions");
  const history = useHistory();

  function deleteConglomerate() {
    setLoading(true);
    Axios.post(
      "https://comms.globalxchange.io/gxb/app/gxlive/operator/app/groups/delete",
      {
        email: email,
        token: token,
        group_id: conglomerate.group_id,
      }
    )
      .then(({ data }) => {
        if (data.status) {
          setStep("deleteSucces");
        } else {
          tostShowOn(data.message || "Something Went Wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getContent() {
    switch (true) {
      case tab === "About":
        return (
          <div className="godsEyeFilterCurrency">
            <div className="labelItm">Select One Of The Following Actions</div>
            {step === "API" ? (
              <Scrollbars className="coinList">
                <div
                  className="coinItem"
                  onClick={() =>
                    copyToClipboard(
                      `https://comms.globalxchange.io/gxb/app/gxlive/operator/app/groups/get?group_id=${conglomerate.group_id}`,
                      "Api Link Copied"
                    )
                  }
                >
                  <span className="nozoom" style={{ whiteSpace: "nowrap" }}>
                    https://comms.globalxchange.io/gxb/app/gxlive/operator/app/groups/get?group_id=
                    {conglomerate.group_id}
                  </span>
                  <FontAwesomeIcon icon={faCopy} />
                </div>
              </Scrollbars>
            ) : (
              <Scrollbars className="coinList">
                <div className="coinItem" onClick={() => setStep("API")}>
                  <img src={editApp} alt="" className="coin" />
                  <span>API</span>
                </div>
                <div
                  className="coinItem disable"
                  onClick={() => setStep("GUI")}
                >
                  <img src={addApp} alt="" className="coin" />
                  <span>GUI</span>
                </div>
              </Scrollbars>
            )}
          </div>
        );
      case tab === "Actions" && step === "deleteSucces":
        setTimeout(() => {
          history.push("/ventures/Conglomerates");
          window.location.reload();
        }, 2000);
        return (
          <>
            <div className="userHeads">
              <img src={conglomerate?.profilePicURL} alt="" />
              <div className="detail">
                <div className="name">{conglomerate?.displayName}</div>
                <div className="email">
                  <span
                    onClick={() => {
                      try {
                        onClose();
                      } catch (error) {}
                    }}
                  >
                    Conglomerates
                  </span>{" "}
                  -&gt;{" "}
                  <span
                    onClick={() => {
                      setStep("");
                    }}
                  >
                    Actions
                  </span>{" "}
                  -&gt; <span>Delete</span>
                </div>
              </div>
            </div>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm m-auto">
                You Have Successfully Deleted Conglomerates{" "}
                {conglomerate?.groupname}. You Will Be Redirected To The Updated
                Conglomerates List Automatically
              </div>
            </div>
            <div className="footerBtns">
              <div
                className="btnReset"
                onClick={() => {
                  try {
                    onClose();
                  } catch (error) {}
                }}
              >
                Close
              </div>
              <div className="btnSave">Share</div>
            </div>
          </>
        );
      case tab === "Actions" && step === "Delete":
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm">
                Are You Sure You Want To Delete {conglomerate?.groupname}?
              </div>
              <Scrollbars className="coinList">
                <div className="coinItem" onClick={() => deleteConglomerate()}>
                  <img src={yesIcn} alt="" className="coin" />
                  <span>Confirm</span>
                </div>
                <div className="coinItem" onClick={() => setStep("")}>
                  <img src={noIcn} alt="" className="coin" />
                  <span>No</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );
      case tab === "Actions" && step === "Add Apps":
        return (
          <ConglomeratesListSidebarAddApps
            conglomerate={conglomerate}
            setLoading={setLoading}
            onBack={() => setStep("")}
          />
        );
      case tab === "Actions" && step === "All Apps":
        return (
          <ConglomeratesListSidebarAllApps
            conglomerate={conglomerate}
            setLoading={setLoading}
          />
        );
      case tab === "Actions" && step === "Edit":
        return (
          <ConglomeratesListSidebarEdit
            conglomerate={conglomerate}
            setLoading={setLoading}
            onBack={() => setStep("")}
            onClose={onClose}
          />
        );
      case tab === "Actions":
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm">
                Select One Of The Following Actions
              </div>
              <Scrollbars className="coinList">
                <div className="coinItem" onClick={() => setStep("Edit")}>
                  <img src={editApp} alt="" className="coin" />
                  <span>Edit Conglomerate</span>
                </div>
                <div className="coinItem" onClick={() => setStep("Add Apps")}>
                  <img src={addApp} alt="" className="coin" />
                  <span>Add Apps</span>
                </div>
                <div className="coinItem" onClick={() => setStep("Delete")}>
                  <img src={deleteApp} alt="" className="coin" />
                  <span>Delete Conglomerate</span>
                </div>
              </Scrollbars>
            </div>
            <div className="footerBtns">
              <div
                className="btnSee"
                onClick={() => {
                  setStep("All Apps");
                }}
              >
                See Apps
              </div>
            </div>
          </>
        );
      default:
        return;
    }
  }

  return (
    <div className="godsEyeUserFiter">
      <div className="headTabBox">
        <div
          className={`tab ${tab === "About"}`}
          onClick={() => {
            setTab("About");
            setStep("");
          }}
        >
          About
        </div>
        <div
          className={`tab ${tab === "Actions"}`}
          onClick={() => {
            setTab("Actions");
            setStep("");
          }}
        >
          Actions
        </div>
      </div>
      <div className="header">
        <div className="content">
          <div className="title">
            <img
              style={{ height: "40px", width: "auto" }}
              src={conglomerate.icon}
              alt=""
              className="icon"
            />
            {conglomerate.groupname}
          </div>
          <div className="breadCrumbs">
            <span
              onClick={() => {
                setStep("");
              }}
            >
              {tab === "Actions"
                ? `${conglomerate.groupname} Actions`
                : `About ${conglomerate.groupname}`}
            </span>{" "}
            {step ? (
              <>
                -&gt;&nbsp;
                <span>{step}</span>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        {step ? (
          <div
            className="backBtn"
            onClick={() => {
              setStep("");
            }}
          >
            <img src={back} alt="" />
          </div>
        ) : (
          ""
        )}
        <div
          className="backBtn"
          onClick={() => {
            onClose();
          }}
        >
          <img src={close} alt="" />
        </div>
      </div>
      {getContent()}
      {loading && <LoadingAnim />}
    </div>
  );
}

export default ConglomeratesListSidebar;
