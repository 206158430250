import React from "react";

function JusticeFilter({ filter, setFilter, search, setSearch, type }) {
  function getType() {
    switch (type) {
      case "content":
        return "Content Type";
      case "user":
        return "User Types";
      case "userProfile":
        return "User Profiles";
      case "apps":
        return "Apps";
      default:
        return "";
    }
  }
  return (
    <div className="viewingAs">
      <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        type="text"
        className="searchInp"
        placeholder="Search"
      />
      <div
        className={`btnType ${filter === "Type"}`}
        onClick={() => setFilter("Type")}
      >
        {getType()}
      </div>
      <div
        className={`btnType ${filter === "Filter Config"}`}
        onClick={() => setFilter("Filter Config")}
      >
        Filter Config
      </div>
    </div>
  );
}

export default JusticeFilter;
