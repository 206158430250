import Axios from "axios";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import CopyPublicationDetailModal from "../components/CopyPublicationDetailModal/CopyPublicationDetailModal";
import EngagementEvents from "../components/EngagementEvents/EngagementEvents";
import NewPost from "../components/NewPostCopmonents/Index";
import NewPublication from "../components/NewPublication/NewPublication";
import MainLayout from "../layouts/MainLayout";
import NavbarEngagement from "../layouts/Navbar/NavbarEngagement";
import engage from "../static/images/sidebarIcons/engage.svg";
import create from "../static/images/sidebarIcons/create.svg";
import { useParams } from "react-router-dom";

function EngagementPage({ hide }) {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const { txnId } = useParams(); //To pass new type Parameter
  const [publicationList, setPublicationList] = useState([]);
  const [publicationListContent, setPublicationListContent] = useState({});
  const [selectedPublication, setSelectedPublication] = useState({});
  function getPubList() {
    Axios.get("https://fxagency.apimachine.com/publication").then(
      ({ data }) => {
        if (data.status) {
          let pLObj = {};
          let pLdata = {};
          data.data.forEach((publication) => {
            pLdata[publication.name] = [
              "Feed",
              "Articles",
              "Videos",
              "New Post",
              "Events",
            ];
            pLObj[publication.name] = publication;
          });
          setPublicationListContent(pLdata);
          setPublicationList(pLObj);
        }
      }
    );
  }

  useEffect(getPubList, []);
  const [newPost, setNewPost] = useState("");
  const [tabSelected, setTabSelected] = useState("");

  const [newEvent, setNewEvent] = useState(true);
  useEffect(() => {
    setNewEvent(true);
  }, [tabSelected]);

  useEffect(() => {
    switch (txnId) {
      case "video":
      case "article":
        setTabSelected("New Post");
        break;
      case "events": {
        setTabSelected("Events");
        setNewEvent(true);
        break;
      }
      default:
        break;
    }
  }, [txnId]);

  const getContent = (tab) => {
    switch (tab) {
      case "New Post":
        return <NewPost publication={selectedPublication} pType={txnId} />;
      case "Events":
        return (
          <EngagementEvents newEvent={newEvent} setNewEvent={setNewEvent} />
        );
      default:
        break;
    }
  };
  const [search, setSearch] = useState("");
  const [pubSelected, setPubSelected] = useState(null);
  const [pubSelectedUpdate, setPubSelectedUpdate] = useState(null);
  const [changeDrop, setChangeDrop] = useState("");
  const [newPublicationOpen, setNewPublicationOpen] = useState(false);

  return (
    <MainLayout
      hide={hide}
      active={"Engagement"}
      onNew={() => {
        if (tabSelected !== "Events") {
          setNewPost("New Post");
          setTimeout(() => {
            setNewPost("");
          }, 500);
        } else {
          setNewEvent(true);
        }
      }}
    >
      <NavbarEngagement
        logoNtext={
          <div className="navLogoText">
            <img src={engage} className="navLogo" alt="" />
            <span>Engagement</span>
          </div>
        }
        contents={publicationListContent}
        onChangeDropdown={(drop) => {
          setSelectedPublication(publicationList && publicationList[drop]);
        }}
        onChangeTab={(tab) => {
          setTabSelected("");
          setTimeout(() => {
            setTabSelected(tab);
          }, 100);
        }}
        newTag="Post"
        changeTab={newPost}
        publicationList={publicationList}
        dropDownOpen={dropDownOpen}
        setDropDownOpen={setDropDownOpen}
        search={search}
        setSearch={setSearch}
        changeDrop={changeDrop}
      />
      <div className="engagementPageContent">
        {getContent(tabSelected)}
        {dropDownOpen && (
          <Scrollbars
            autoHide
            className="sideBarAppSelect"
            renderView={(props) => <div {...props} className="view" />}
          >
            <div
              className="appPublication"
              onClick={() => {
                setNewPublicationOpen(true);
              }}
            >
              <img src={create} alt="" className="pubLogo" />
              <div className="nameNbtns">
                <div className="name">New Publication</div>
              </div>
            </div>
            {Object.keys(publicationList)
              .filter((pubName) =>
                pubName.toLowerCase().includes(search.toLowerCase())
              )
              .map((pubName) => (
                <div
                  className="appPublication"
                  key={pubName}
                  onClick={() => {
                    setDropDownOpen(false);
                    setChangeDrop(pubName);
                  }}
                >
                  <img
                    src={publicationList[pubName].profile_pic || engage}
                    alt=""
                    className="pubLogo"
                  />
                  <div className="nameNbtns">
                    <div className="name">{pubName}</div>
                    <div className="btns">
                      <div
                        className="btnAction"
                        onClick={() => {
                          setPubSelected(publicationList[pubName]);
                        }}
                      >
                        Copy
                      </div>
                      <div
                        className="btnAction"
                        onClick={() => {
                          setPubSelectedUpdate(publicationList[pubName]);
                        }}
                      >
                        Edit
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Scrollbars>
        )}
        {pubSelected && (
          <CopyPublicationDetailModal
            publication={pubSelected}
            onClose={() => setPubSelected(null)}
          />
        )}
        {newPublicationOpen && (
          <NewPublication
            onSuccess={() => setNewPublicationOpen(false)}
            onClose={() => setNewPublicationOpen(false)}
          />
        )}
        {pubSelectedUpdate && (
          <NewPublication
            pubData={pubSelectedUpdate}
            onSuccess={() => {
              getPubList();
              setPubSelectedUpdate(false);
            }}
            onClose={() => setPubSelectedUpdate(false)}
          />
        )}
      </div>
    </MainLayout>
  );
}

export default EngagementPage;
