import {
  IconsViewSwitcher,
  Navbar,
  SearchAndFilter,
} from "../components/BrokerDashboard/index";
import React, { useContext, useState } from "react";

import { ReactComponent as IconClose } from "../static/images/clipIcons/close.svg";
import { ReactComponent as RefreshIcn } from "../static/images/clipIcons/refresh.svg";

import { TabsContext } from "../context/TabsContext";
import MainLayoutComponent from "../layouts/MainLayout";
import XIDApps from "../components/XIDApps/XIDApps";
import GodsEyeRefreshSidebar from "../components/GodsEyeRefreshSidebar/GodsEyeRefreshSidebar";
import TokenApps from "../components/TokenApps";
import TokenAppsCategories from "../components/TokenAppsCategories";

const tabs = ["TokenApps", "Data Structure", "Categories"];

function XIDPage({ hide }) {
  const { navTabs } = useContext(TabsContext);
  const [search, setSearch] = useState("");

  const [searchOn, setSearchOn] = useState(false);

  const [tabClick, setTabClick] = useState(false);
  const [tabSelected, setTabSelected] = useState("TokenApps");
  // Filter
  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const [filterNameGroup, setFilterNameGroup] = useState(true);

  const [list, setList] = useState(false);
  const [refresh, setRefresh] = useState(false);

  function getTokenApps() {
    switch (tabSelected) {
      case "Categories":
        return <TokenAppsCategories search={search} />;
      case "Data Structure":
        return <XIDApps search={search} list={list} setList={setList} />;
      default:
        return <TokenApps search={search} list={list} setList={setList} />;
    }
  }

  return (
    <MainLayoutComponent active={"TokenApps"} hide={hide}>
      <Navbar
        navTabs={navTabs}
        tabs={tabs}
        tabSelected={tabSelected}
        setTabSelected={(tab) => {
          setTabSelected("");
          setTimeout(() => {
            setTabSelected(tab);
          }, 100);
        }}
        search={search}
        setSearch={setSearch}
        viewSwitcher={
          <IconsViewSwitcher
            listIcn={list && <IconClose />}
            boardIcn={<RefreshIcn />}
            board={refresh}
            onBoardClick={() => {
              if (refresh) setRefresh(false);
              else {
                setRefresh(true);
                setList(false);
              }
            }}
            list={list}
            onListClick={() => {
              if (list) setList(false);
              else {
                setRefresh(false);
              }
            }}
            search={searchOn}
            onSearchClick={() => setSearchOn(!searchOn)}
          />
        }
        tabClick={() => {
          setTabClick(!tabClick);
        }}
        searchPlaceHolder=""
      />
      {searchOn && (
        <SearchAndFilter
          search={search}
          setSearch={setSearch}
          placeholder={"Search XID"}
          filterBy={`${"Name Of Group"}`}
          mainList={[
            {
              key: "0",
              label: "Function Performed By Search Bar",
              switch: isFilter,
              switchLabel: isFilter ? "Filter" : "Search",
              switchClick: () => setIsFilter(!isFilter),
            },
            {
              key: "1",
              label: "Filter Range",
              switch: currentPage,
              switchLabel: currentPage ? "Current Page" : "Entire List",
              switchClick: () => setCurrentPage(!currentPage),
            },
          ]}
          filterList={[
            {
              key: "11",
              label: "Name Of Group",
              switch: filterNameGroup,
              switchLabel: filterNameGroup ? "On" : "Off",
              switchClick: () => setFilterNameGroup(!filterNameGroup),
            },
          ]}
        />
      )}
      <div className="xidView">
        {getTokenApps()}
        {refresh && <GodsEyeRefreshSidebar />}
      </div>
    </MainLayoutComponent>
  );
}

export default XIDPage;
