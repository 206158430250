import React, { useContext, useState } from "react";
import JsonWebToken from "jsonwebtoken";
import Scrollbars from "react-custom-scrollbars";
import cloudUploadIcon from "../../static/images/postClipArt/cloudUpload.svg";
import Axios from "axios";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import { MainContext } from "../../context/MainContext";
import { useHistory } from "react-router";
import back from "../../static/images/back.svg";
import close from "../../static/images/close.svg";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

function NewBankerCategory({ onClose, onBack }) {
  const history = useHistory();
  const { tostShowOn, email, token } = useContext(MainContext);
  const [step, setStep] = useState("");
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [categoryTag, setCategoryTag] = useState("");
  const [icon, setIcon] = useState("");
  const [description, setDescription] = useState("");
  const [subCategory, setSubCategory] = useState("");

  const uploadImage = async (e, setImage) => {
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
  };

  function getContent() {
    switch (step) {
      case "addSuccess":
        setTimeout(() => {
          history.push("/society/Bankers");
          window.location.reload();
        }, 2000);
        return (
          <div className="succesView">
            <div className="labelItm">
              You Have Successfully Add {name}. You Will Be Redirected To The
              Updated Bankers List Automatically
            </div>
          </div>
        );
      default:
        return (
          <Scrollbars
            className="scrollForm"
            renderTrackHorizontal={() => <div />}
            renderThumbHorizontal={() => <div />}
            renderTrackVertical={() => <div />}
            renderThumbVertical={() => <div />}
          >
            <div className="name">Name The Banker Category</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Name Here"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="name">Name The Category Tag</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Tag Here"
                value={categoryTag}
                onChange={(e) => setCategoryTag(e.target.value)}
              />
            </div>
            <div className="name">Upload Branding Material</div>
            <div className="filesUpload last">
              <label className="fileInp icon">
                <img
                  className={`${Boolean(icon)}`}
                  src={icon || cloudUploadIcon}
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setIcon);
                  }}
                  accept="image/*"
                />
                <div className="text">Category Icon</div>
              </label>
            </div>
            <div className="name">Enter The Categories Description</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Description Here"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="name">Subcategory</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Subcategory Here"
                value={subCategory}
                onChange={(e) => setSubCategory(e.target.value)}
              />
            </div>
            <div className="space"></div>
          </Scrollbars>
        );
    }
  }

  function addBankerCategory() {
    setLoading(true);
    Axios.post(
      "https://teller2.apimachine.com/banker/newCategory",
      {
        name: name,
        categoryTag: categoryTag,
        icon: icon,
        description: description,
        subCategory: subCategory,
      },
      {
        headers: {
          email,
          token,
        },
      }
    )
      .then(({ data }) => {
        if (data.status) {
          setStep("addSuccess");
        } else {
          tostShowOn(data.message);
        }
      })
      .finally(() => setLoading(false));
  }

  function validate() {
    if (name && categoryTag && icon && description && subCategory) {
      addBankerCategory();
    } else {
      tostShowOn("All Fields Are Mandatory");
    }
  }

  return (
    <>
      <div className="newConglomerate">{getContent()}</div>
      <div className="btnSubmit" onClick={validate}>
        Submit Conglomerate
      </div>
      {loading && <LoadingAnim />}{" "}
    </>
  );
}

export default NewBankerCategory;
