import React from "react";

import logo from "../../static/images/logos/civilisationFullLogo.svg";

function TradeInspector({ setOpenModal, tiObject }) {
  return (
    <div className="transactionInspectorWrapper tradeInspectorWrapper">
      <div
        className="overlay-ti"
        role="button"
        tabIndex="-1"
        onClick={() => setOpenModal(false)}
      />
      <div className="ti-content tradeInspector">
        <div className="ti-head">
          <img src={logo} alt="" /> Transaction Inspector
        </div>
        <div className="ti-detail">
          {tiObject?.lblOne && (
            <div className="ti-row">
              <div className="label">{tiObject.lblOne}</div>
              <div className="value">
                {tiObject.amtOne.toFixed(12).replace(/\.?0+$/, "")}{" "}
                {tiObject.coinOne}
              </div>
            </div>
          )}
          {tiObject?.lblTwo && (
            <div className="ti-row">
              <div className="label">{tiObject.lblTwo}</div>
              <div className="value">
                {tiObject.amtTwo.toFixed(12).replace(/\.?0+$/, "")}{" "}
                {tiObject.coinTwo}
              </div>
            </div>
          )}
          {tiObject?.lblThree && (
            <div className="ti-row">
              <div className="label">{tiObject.lblThree}</div>
              <div className="value">
                {tiObject.amtThree.toFixed(12).replace(/\.?0+$/, "")}{" "}
                {tiObject.coinThree}
              </div>
            </div>
          )}
          {tiObject?.lblFour && (
            <div className="ti-row">
              <div className="label">{tiObject.lblFour}</div>
              <div className="value">
                {tiObject.amtFour.toFixed(12).replace(/\.?0+$/, "")}{" "}
                {tiObject.coinFour}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TradeInspector;
