import Axios from "axios";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { IconsViewSwitcher } from "../components/BrokerDashboard/index";

import ActiveApps from "../components/ActiveApps/ActiveApps";
import CopyPublicationDetailModal from "../components/CopyPublicationDetailModal/CopyPublicationDetailModal";
import GodsEyeEye from "../components/GodsEyeEye/GodsEyeEye";
import GodsEyeUserDetail from "../components/GodsEyeUsers/GodsEyeUserDetail";
import GodsEyeUsers from "../components/GodsEyeUsers/GodsEyeUsers";
import MainLayout from "../layouts/MainLayout";
import NavbarGodsEye from "../layouts/Navbar/NavbarGodsEye";
import left from "../static/images/clipIcons/left.svg";
import right from "../static/images/clipIcons/right.svg";
import { ReactComponent as RefreshIcn } from "../static/images/clipIcons/refresh.svg";
import GodsEyeRefreshSidebar from "../components/GodsEyeRefreshSidebar/GodsEyeRefreshSidebar";
import BankerList from "../components/BankerList/BankerList";
import FoundersList from "../components/FoundersList/FoundersList";
import GodsEyeMoreList from "../components/GodsEyeMoreList/GodsEyeMoreList";
import { useHistory, useParams } from "react-router-dom";
import PublisherList from "../components/PublisherList";
import PublicationList from "../components/PublicationList";
import TerminalAppList from "../components/TerminalAppList";

import publicationIcon from "../static/images/appLogos/publicationIcon.svg";
import publisherIcon from "../static/images/appLogos/publisherIcon.svg";
import terminalApps from "../static/images/appLogos/terminalApps.svg";
import { ReactComponent as IconClose } from "../static/images/clipIcons/close.svg";

const images = {
  Publications: publicationIcon,
  Publishers: publisherIcon,
  "Terminal Apps": terminalApps,
};
function GodsEyePage({ hide }) {
  const [tabs, setTabs] = useState([
    "Citizens",
    "Bankers",
    "Entrepreneurs",
    "More",
    "Selected List",
  ]);
  const history = useHistory();
  const { txnId } = useParams(); //To Set Tab
  const [searchOn, setSearchOn] = useState(false);
  const [tabSelected, setTabSelected] = useState("Citizens");
  const [search, setSearch] = useState("");
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [userDataToExpand, setUserDataToExpand] = useState(false);

  const [appList, setAppList] = useState([]);
  const [appSelected, setAppSelected] = useState({
    app_name: "All Apps",
    app_code: undefined,
  });
  const [toCopy, setToCopy] = useState(false);
  const [loadingAppList, setLoadingAppList] = useState(true);
  const [listLength, setListLength] = useState(0);

  useEffect(() => {
    setLoadingAppList(true);
    Axios.get("https://comms.globalxchange.io/gxb/apps/get")
      .then(({ data }) => {
        if (data.status) {
          setAppList(data.apps);
          // setAppSelected(
          //   data.apps.filter((app) => app.app_code === "broker_app")[0] ||
          //     data.apps[0]
          // );
        }
      })
      .finally(() => setLoadingAppList(false));
  }, []);

  const [page, setPage] = useState(1);
  const [list, setList] = useState("");
  const [refresh, setRefresh] = useState("");
  const [tabMore, setTabMore] = useState("");

  useEffect(() => {
    if (txnId && txnId !== tabSelected) {
      if (tabs.includes(txnId)) setTabSelected(txnId);
      else setTabMore(txnId);
    }
  }, [txnId]);

  useEffect(() => {
    if (tabMore) {
      setTabs((tabs) => {
        tabs.pop();
        tabs.push(tabMore);
        return tabs;
      });
      setTabSelected(tabMore);
      history.push(`/society/${tabMore}`);
    }
  }, [tabMore]);

  const getTabContent = (tabSelected) => {
    switch (true) {
      case tabSelected === "Citizens" && !appSelected:
        return (
          <ActiveApps
            setAppSelected={setAppSelected}
            setDropDownOpen={setDropDownOpen}
            appList={appList}
            searchOn={searchOn}
            loadingAppList={loadingAppList}
          />
        );
      case tabSelected === "Citizens":
        return (
          <GodsEyeUsers
            appSelected={appSelected}
            setUserDataToExpand={(val) => {
              setUserDataToExpand(val);
              setTabSelected("Data");
            }}
            searchOn={searchOn}
            list={list}
            page={page}
            setPage={setPage}
            setListLength={setListLength}
            setList={setList}
          />
        );
      case tabSelected === "Bankers":
        return <BankerList list={list} setList={setList} searchOn={searchOn} />;
      case tabSelected === "Entrepreneurs":
        return (
          <FoundersList list={list} setList={setList} searchOn={searchOn} />
        );
      case tabSelected === "Eye":
        return <GodsEyeEye />;
      case tabSelected === "Data":
        return <GodsEyeUserDetail userDataToExpand={userDataToExpand} />;
      case tabSelected === "More":
        return <GodsEyeMoreList setTabMore={setTabMore} />;
      case tabSelected === "Publications":
        return <PublicationList setList={setList} list={list} />;
      case tabSelected === "Publishers":
        return <PublisherList setList={setList} list={list} />;
      case tabSelected === "Terminal Apps":
        return <TerminalAppList setList={setList} list={list} />;
      default:
        break;
    }
  };

  return (
    <MainLayout active={"Society"} hide={hide}>
      <NavbarGodsEye
        tabs={tabs}
        tabSelected={tabSelected}
        setTabSelected={(tab) => {
          history.push(`/society/${tab}`);
        }}
        dropDownOpen={false}
        search={search}
        setSearch={setSearch}
        viewSwitcher={
          <>
            {tabSelected === "Citizens" && appSelected?.app_name && (
              <div className="pager">
                <img
                  className={`${page !== 1}`}
                  src={right}
                  alt=""
                  onClick={() => {
                    if (page !== 1) setPage(page - 1);
                  }}
                />
                <span className="pageNo">Page {page}</span>
                <img
                  className={`${listLength === 100}`}
                  src={left}
                  alt=""
                  onClick={() => {
                    if (listLength === 100) setPage(page + 1);
                  }}
                />
              </div>
            )}
            <IconsViewSwitcher
              listIcn={<IconClose />}
              boardIcn={<RefreshIcn />}
              board={refresh}
              onBoardClick={() => {
                if (refresh) setRefresh(false);
                else {
                  setRefresh(true);
                }
              }}
              list={list}
              onListClick={() => {
                if (list) setList(false);
                else {
                  if (tabSelected === "Citizens") setList(true);
                  setRefresh(false);
                }
              }}
              search={searchOn}
              onSearchClick={() => setSearchOn(!searchOn)}
            />
          </>
        }
        button={
          <div
            className={`publication `}
            onClick={() => setAppSelected(undefined)}
          >
            {!appSelected ? (
              <span>Nothing Selected</span>
            ) : (
              <>
                <img
                  className="logoIcn"
                  src={appSelected && appSelected.app_icon}
                  alt=""
                />
                <span>{appSelected && appSelected.app_name}</span>
              </>
            )}
          </div>
        }
        tabClick={(tabItm) => {
          if (tabItm !== "Data") {
            setUserDataToExpand(false);
          }
        }}
        tabLastGroup
        tabIcn={images[tabSelected]}
      />
      <div className="withdrawalView">
        {getTabContent(tabSelected)}
        {refresh && <GodsEyeRefreshSidebar />}
        {dropDownOpen && (
          <Scrollbars
            autoHide
            className="sideBarAppSelect"
            renderView={(props) => <div {...props} className="view" />}
          >
            {loadingAppList
              ? Array.from(Array(10).keys()).map((key) => (
                  <div className="appPublication" key={key}>
                    <Skeleton width={60} height={60} />
                    <div className="nameNbtns">
                      <Skeleton className="name" />
                      <div className="btns">
                        <Skeleton className="btnAction" />
                        <Skeleton className="btnAction" />
                      </div>
                    </div>
                  </div>
                ))
              : appList
                  .filter(
                    (app) =>
                      app.app_name
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      app.app_code.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((app) => (
                    <div
                      className="appPublication"
                      key={app._id}
                      onClick={() => {
                        setAppSelected(app);
                        setDropDownOpen(false);
                      }}
                    >
                      <img src={app.app_icon} alt="" className="pubLogo" />
                      <div className="nameNbtns">
                        <div className="name">{app.app_name}</div>
                        <div className="btns">
                          <div
                            className="btnAction"
                            onClick={() => setToCopy(app)}
                          >
                            Copy
                          </div>
                          <div className="btnAction">Expand</div>
                        </div>
                      </div>
                    </div>
                  ))}
          </Scrollbars>
        )}
        {toCopy && (
          <CopyPublicationDetailModal
            publication={toCopy}
            onClose={() => setToCopy(false)}
          />
        )}
      </div>
    </MainLayout>
  );
}

export default GodsEyePage;
