import React, { useRef } from "react";
import OnOutsideClick from "../../utils/OnOutsideClick";

function IndustryItem({ industry, onClick, selected }) {
  const btnRef = useRef();
  const outRef = useRef();

  OnOutsideClick(
    btnRef,
    () => {
      try {
        onClick();
      } catch (error) {}
    },
    outRef
  );
  return (
    <div className={`listItem ${industry === selected}`} ref={outRef}>
      <div className="profile">
        <img src={industry.icon} alt="" className="icon" />
        <span className="name">{industry.name}</span>
      </div>
      <span className="count hidOnSelect" style={{ marginLeft: "auto" }}>
        {industry?.marketplaceData?.marketplace_count} MarketPlaces
      </span>
    </div>
  );
}

export default IndustryItem;
