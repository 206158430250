import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid/dist";

import assetsLogoFull from "../static/images/logos/assetsLogo.svg";
import iconBank from "../static/images/sidebarIcons/bank.svg";
import engage from "../static/images/sidebarIcons/engage.svg";
import assetsLogoText from "../static/images/sidebarIcons/society.svg";
import game from "../static/images/sidebarIcons/game.svg";
import bankersLogo from "../static/images/sidebarIcons/bankersLogo.svg";
import counselIcon from "../static/images/sidebarIcons/counsel.svg";
import teamforceIcon from "../static/images/sidebarIcons/teamforce.svg";
import blockIcon from "../static/images/clipIcons/blockIcon.svg";
import xidIcon from "../static/images/clipIcons/xid.svg";
import marketsIcon from "../static/images/sidebarIcons/marketsIcon.svg";
import commereceIcon from "../static/images/clipIcons/commerece.svg";
import venturesIcon from "../static/images/clipIcons/venturesIcon.svg";
import businessIcon from "../static/images/sidebarIcons/business.svg";
import viralIcon from "../static/images/sidebarIcons/viral.svg";
import { Fragment } from "react";
import { MainContext } from "./MainContext";

export const TabsContext = createContext();

function TabsContextProvider({ children }) {
  const history = useHistory();
  const { allApps } = useContext(MainContext);
  const [tabs, setTabs] = useState([{ location: "/", key: "0" }]);
  const [tabIndex, setTabIndex] = useState(0);
  const { pathname } = useLocation();

  useEffect(() => {
    let tempArr = tabs.slice();
    tempArr[tabIndex] = { ...tempArr[tabIndex], location: pathname };
    setTabs(tempArr);
  }, [pathname, setTabs]);

  function newTab(to = "/society") {
    setTabIndex(tabs.length);
    setTabs((tabs) => [...tabs, { location: to, key: uuid() }]);
    history.push(to);
  }
  function removeTab(i) {
    setTabs((tabs) => tabs.filter((v, index) => index !== i));
    let indexTemp = tabIndex - 1 || 0;
    if (indexTemp < 1) indexTemp = 0;
    history.push(tabs[indexTemp]);
    setTabIndex(indexTemp);
  }
  function getNavHead(tab) {
    switch (true) {
      case /\/Ecosystems/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <div className="navLogoText">
              <img src={game} className="navLogo" alt="" />
              <span>Ecosystems</span>
            </div>
            <div className="divider" />
          </Fragment>
        );
      case /\/businesses/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <div className="navLogoText">
              <img src={businessIcon} className="navLogo" alt="" />
              <span>Businesses</span>
            </div>
            <div className="divider" />
          </Fragment>
        );
      case /\/apps\/ice/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <div className="navLogoText">
              <img src={assetsLogoFull} className="navLogo" alt="" />
              <span>Assets.io</span>
            </div>
            <div className="divider" />
          </Fragment>
        );
      case /\/apps\/counsel/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <div className="navLogoText">
              <img src={counselIcon} className="navLogo" alt="" />
              <span style={{ color: "#334480" }}>Justice</span>
            </div>
            <div className="divider" />
          </Fragment>
        );
      case /\/apps\/*/.test(tab.location):
        const appCode = tab.location.replace(/\/apps\//, "");
        return (
          <Fragment key={tab.key}>
            <div className="navLogoText">
              <img
                src={allApps && allApps[appCode]?.app_icon}
                className="navLogo"
                alt=""
              />
              <span>{allApps && allApps[appCode]?.app_name}</span>
            </div>
            <div className="divider" />
          </Fragment>
        );
      case /\/markets/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <div className="navLogoText">
              <img src={marketsIcon} className="navLogo" alt="" />
              <span>Markets</span>
            </div>
            <div className="divider" />
          </Fragment>
        );
      case /\/commerce/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <div className="navLogoText">
              <img src={commereceIcon} className="navLogo" alt="" />
              <span>Commerce</span>
            </div>
            <div className="divider" />
          </Fragment>
        );
      case /\/ventures/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <div className="navLogoText">
              <img src={venturesIcon} className="navLogo" alt="" />
              <span>Ventures</span>
            </div>
            <div className="divider" />
          </Fragment>
        );
      case /\/viral/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <div className="navLogoText">
              <img src={viralIcon} className="navLogo" alt="" />
              <span>Viral</span>
            </div>
            <div className="divider" />
          </Fragment>
        );
      case /\/Teamforce/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <div className="navLogoText">
              <img src={teamforceIcon} className="navLogo" alt="" />
              <span style={{ color: "#F45D48" }}>Teamforce</span>
            </div>
            <div className="divider" />
          </Fragment>
        );
      case /\/xid/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <div className="navLogoText">
              <img src={xidIcon} className="navLogo" alt="" />
              <span>TokenApps</span>
            </div>
            <div className="divider" />
          </Fragment>
        );
      case /\/liquidity/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <div className="navLogoText">
              <img src={bankersLogo} className="navLogo" alt="" />
              <span>Liquidity</span>
            </div>
            <div className="divider" />
          </Fragment>
        );
      case /\/content/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <div className="navLogoText">
              <img src={engage} className="navLogo" alt="" />
              <span>Engagement</span>
            </div>
            <div className="divider" />
          </Fragment>
        );
      case /\/centralbank/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <div className="navLogoText">
              <img src={iconBank} className="navLogo" alt="" />
              <span>Central Bank</span>
            </div>
            <div className="divider" />
          </Fragment>
        );
      case /\/blockcheck/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <div className="navLogoText">
              <img src={blockIcon} className="navLogo" alt="" />
              <span>EmailChains</span>
            </div>
            <div className="divider" />
          </Fragment>
        );
      case /\/society/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <div className="navLogoText">
              <img src={assetsLogoText} className="navLogo" alt="" />
              <span>Society</span>
            </div>
            <div className="divider" />
          </Fragment>
        );
      case "/" === tab.location:
        return (
          <Fragment key={tab.key}>
            <div className="navLogoText">
              <img src={assetsLogoText} className="navLogo" alt="" />
              <span>Society</span>
            </div>
            <div className="divider" />
          </Fragment>
        );
      default:
        break;
    }
  }

  const navTabs = (
    <div className="navList">
      {tabs.map((tab, i) => (
        <div
          key={tab.key}
          className={`navBarWrapper ${i === tabIndex}`}
          onClick={() => {
            setTabIndex(i);
            history.push(tab.location);
          }}
        >
          {getNavHead(tab)}
          {tabs && tabs.length && tabs.length > 1 && (
            <div
              className="btnClose"
              onClick={() =>
                setTimeout(() => {
                  removeTab(i);
                }, 200)
              }
            />
          )}
        </div>
      ))}
      <div className="addNav" onClick={() => newTab()} />
    </div>
  );

  return (
    <TabsContext.Provider
      value={{
        tabs,
        setTabs,
        tabIndex,
        setTabIndex,
        navTabs,
        newTab,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
}

export default TabsContextProvider;
