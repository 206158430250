import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import {
  faCaretDown,
  faCaretUp,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import vaultlogo from "../../static/images/logos/civilisationFullLogo.svg";
import { MainContext } from "../../context/MainContext";
import Skeleton from "react-loading-skeleton";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import blockChain from "../../static/images/appLogos/blockChain.svg";
import etherScan from "../../static/images/appLogos/etherScan.svg";

function DeleteWithdrawalModal({
  onClose,
  txnId,
  bankerMail,
  type,
  onSuccess,
}) {
  const { tostShowOn, token } = useContext(MainContext);
  const [dropOpen, setDropOpen] = useState(false);
  const [paths, setPaths] = useState({});
  const [valueLoading, setValueLoading] = useState(true);
  const [transactionData, setTransactionData] = useState({});
  const [additionalData, setAdditionalData] = useState({});
  const getValues = async () => {
    setValueLoading(true);
    const { data } = await Axios.get(
      `https://comms.globalxchange.io/coin/vault/service/path/withdraw/txn/get?id=${txnId}`
    );
    if (data.status) {
      let txnData = data.txns && data.txns[0];
      setTransactionData(txnData);
      const res = await Axios.get(
        `https://comms.globalxchange.io/coin/vault/service/payment/paths/get?path_id=${txnData.path_id}`
      );
      if (res.data.status && res.data.paths && res.data.paths[0]) {
        setPaths(res.data.paths[0]);
      }
    }
    setValueLoading(false);
  };

  useEffect(() => {
    getValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txnId]);
  const [steps, setSteps] = useState({});
  const [selectedStep, setSelectedStep] = useState();
  useEffect(() => {
    if (paths && paths.total_steps) {
      setSteps(paths.total_steps);
      let lastKey = Object.keys(paths.total_steps).slice().pop();
      if (type !== "delete") {
        lastKey = Object.keys(paths.total_steps)[
          Object.keys(paths.total_steps).length - 2
        ];
      }
      setSelectedStep({
        ...paths.total_steps[lastKey],
        key: lastKey,
      });
    }
  }, [paths]);
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [showRes, setShowRes] = useState(false);
  const onUpdateClick = async () => {
    setLoading(true);
    let endPointUrl = "";
    let dataToPass = {};
    switch (type) {
      case "delete":
        endPointUrl =
          "https://comms.globalxchange.io/coin/vault/service/admin/cancel/withdraw/txn";
        dataToPass = {
          admin_email: bankerMail,
          token: token,
          id: txnId,
          step: selectedStep && selectedStep.key,
          additional_data: { additionalData },
          reason_terminated: note,
        };
        break;

      default:
        endPointUrl =
          "https://comms.globalxchange.io/coin/vault/service/admin/update/withdraw/txn/status";
        dataToPass = {
          admin_email: bankerMail,
          token: token,
          id: txnId,
          step: selectedStep && selectedStep.key,
          additional_data: { ...additionalData, note },
        };
        break;
    }
    const { data } = await Axios.post(endPointUrl, dataToPass);
    if (data.status) {
      await getValues();
      setShowRes(true);
    } else {
      tostShowOn(data.message);
    }
    setLoading(false);
    onSuccess();
  };
  const [hover, setHover] = useState("");
  const [newKey, setNewKey] = useState("");
  return (
    <div className="deleteWithdraw">
      <div
        className="overlay"
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <div className="modalCopy">
        <div className="head">
          <img src={vaultlogo} alt="" />
        </div>
        {showRes ? (
          <>
            <div className="deleteContentRes">
              <div className="labelDel">Your Transaction Has Been Updated</div>
              <div className="steps">
                <div className="step ">
                  <div className="stepLabel">Previous Step</div>
                  <div className="stepValue disable">Coming Soon</div>
                </div>
                <div className="step">
                  <div className="stepLabel">Current Step</div>
                  <div className="stepValue">
                    {transactionData &&
                      transactionData.current_step_data &&
                      transactionData.current_step_data.status}
                  </div>
                </div>
              </div>
              <div className="labelDel">
                Individual Transaction Indentifiers
              </div>
              <div className="transactionValues">
                <div className="transaction">
                  <div className="label">User Side</div>
                  <div
                    className="value"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        transactionData &&
                          transactionData.cancelledData &&
                          transactionData.cancelledData.userCreditId
                      );
                    }}
                  >
                    {transactionData &&
                      transactionData.cancelledData &&
                      transactionData.cancelledData.userCreditId}
                  </div>
                </div>
                <div className="transaction">
                  <div className="label">Banker Side</div>
                  <div
                    className="value"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        transactionData &&
                          transactionData.cancelledData &&
                          transactionData.cancelledData.bankerDebitId
                      );
                    }}
                  >
                    {transactionData &&
                      transactionData.cancelledData &&
                      transactionData.cancelledData.bankerDebitId}
                  </div>
                </div>
              </div>
            </div>
            <div className="footer">
              <a
                href={`https://etherscan.io/tx/${transactionData.sent_hash}`}
                target="_blamk"
                className={`btnFoot ${!hover || hover === 2}`}
                onMouseEnter={() => setHover(2)}
                onMouseLeave={() => setHover("")}
              >
                <img src={etherScan} alt="" />
              </a>
              <a
                href={`https://blockchain.com/btc/tx/${transactionData.sent_hash}`}
                target="_blamk"
                className={`btnFoot ${!hover || hover === 1}`}
                onMouseEnter={() => setHover(1)}
                onMouseLeave={() => setHover("")}
              >
                <img src={blockChain} alt="" />
              </a>
              <div
                className={`btnFoot ${!hover || hover === 3}`}
                onMouseEnter={() => setHover(3)}
                onMouseLeave={() => setHover("")}
              >
                <span>Other</span>
              </div>
            </div>
          </>
        ) : (
          <div className="deleteContent">
            <div className="labelDel">
              Select The New Step For This Transaction?
            </div>
            <div className="btnDropdown" onClick={() => setDropOpen(!dropOpen)}>
              {valueLoading ? (
                <Skeleton width={300} />
              ) : (
                <span>
                  <span>{selectedStep && selectedStep.key}</span> |{" "}
                  <span>{selectedStep && selectedStep.status}</span>
                </span>
              )}
              <FontAwesomeIcon icon={dropOpen ? faCaretUp : faCaretDown} />
              {dropOpen && (
                <div className="dropList">
                  {Object.keys(steps).map((step) => (
                    <div
                      className="dropItem"
                      onClick={() => {
                        setSelectedStep({
                          ...steps[step],
                          key: step,
                        });
                      }}
                    >
                      {step} | {steps[step].status}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="notesWrapper">
              <textarea
                className={`ipNotes ${Boolean(note)}`}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                id="ipNotes"
              ></textarea>
              <label htmlFor="ipNotes">Enter Notes</label>
            </div>
            {Object.keys(additionalData).map((key) => (
              <div className="notesWrapper sub" key={key}>
                <textarea
                  className={`ipNotes ${Boolean(additionalData[key])}`}
                  value={additionalData[key]}
                  onChange={(e) => {
                    e.persist();
                    setAdditionalData((prevData) => ({
                      ...prevData,
                      [key]: e.target.value,
                    }));
                  }}
                  id="ipNotes"
                />
                <label htmlFor="ipNotes">Enter {key.replace(/_/, " ")}</label>
              </div>
            ))}
            <div className="addKey">
              <FontAwesomeIcon
                icon={faPlus}
                onClick={() => {
                  if (newKey) {
                    const key = newKey.replace(/ /, "_");
                    setAdditionalData((pevData) => ({
                      ...pevData,
                      [key]: "",
                    }));
                  }
                  setNewKey("");
                }}
              />
              <input
                type="text"
                placeholder="Add New Field"
                value={newKey}
                onChange={(e) => setNewKey(e.target.value)}
              />
            </div>
            <div className="btnUpdate" onClick={onUpdateClick}>
              Update
            </div>
          </div>
        )}
        {loading && <LoadingAnim />}
      </div>
    </div>
  );
}

export default DeleteWithdrawalModal;
