import React, { useContext, useEffect, useState } from "react";
import JsonWebToken from "jsonwebtoken";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";

import cloudUpload from "../../static/images/postClipArt/cloudUpload.svg";
import brainFull from "../../static/images/logos/brainFull.svg";
import vidieoPlayIcon from "../../static/images/videoIcons/vidieoPlayIcon.svg";
import videoHolder from "../../static/images/videoIcons/videoHolder.svg";
import { MainContext } from "../../context/MainContext";
import NewNavbar from "../NewNavbar/NewNavbar";
import NewCategory from "../NewCategory/NewCategory";
import CategoryItem from "./CategoryItem";
import NavbarItem from "./NavbarItem";
import CopyPublicationDetailModal from "../CopyPublicationDetailModal/CopyPublicationDetailModal";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

function NewVideoPost({ publication }) {
  const { tostShowOn } = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [video, setVideo] = useState({ preview: "", raw: "" });
  const [videoId, setVideoId] = useState("");
  const [response, setResponse] = useState({});
  const handleChange = async (e) => {
    setResponse({});
    setVideo({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0],
    });
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const file = renameFile(e.target.files[0], fileName);
    const formData = new FormData();
    formData.append("files", file);

    const pathInsideBrain = "root/";

    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    const response = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-video?email=${emailDev}&path=${pathInsideBrain}&token=${token}&name=${file.name}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    if (response.data.payload.video_id)
      setVideoId(response.data.payload.video_id);
    setLoading(false);
  };
  const [thubnailLink, setThubnailLink] = useState("");
  const [thumbLoading, setThumbLoading] = useState(false);
  const uploadImage = async (e) => {
    setThumbLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setThubnailLink(data.payload.url);
    setThumbLoading(false);
  };
  const [categoryList, setCategoryList] = useState([]);
  const [navbarList, setNavbarList] = useState([]);
  const getNavBars = () => {
    Axios.get(
      `https://fxagency.apimachine.com/navbar/publication/${publication._id}`
    ).then(({ data }) => {
      if (data.status) setNavbarList(data.data || []);
    });
  };
  const getCategories = () => {
    Axios.get(
      `https://fxagency.apimachine.com/category/publication/${publication._id}`
    ).then(({ data }) => {
      if (data.status) setCategoryList(data.data || []);
    });
  };
  useEffect(() => {
    getNavBars();
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publication]);

  const [action, setAction] = useState("");
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [categoryType, setCategoryType] = useState([]);
  const [navbarId, setNavbarId] = useState("");
  const postVideo = () => {
    Axios.post("https://fxagency.apimachine.com/video", {
      categoryType: categoryType,
      navbar_id: navbarId,
      application_id: publication.fxa_app_id,
      email: publication.email,
      title: title,
      image: thubnailLink,
      video: videoId,
      desc: desc,
    }).then(({ data }) => {
      if (data.status) {
        tostShowOn("Video Post Added");
        setResponse(data.data);
        setVideoId("");
        setAction("");
      }
    });
  };
  const [inpVideoId, setInpVideoId] = useState("");
  const [newNav, setNewNav] = useState(false);
  const [newCategory, setNewCategory] = useState(false);

  const [navDataToUpdate, setNavDataToUpdate] = useState("");
  const [categoryDataToUpdate, setCategoryDataToUpdate] = useState("");

  return (
    <>
      <div className="newVideoWrapper">
        {action === "post" ? (
          <div className="postSection">
            <div className="topVideoDetails">
              <div className="videoHolder">
                <img className="imgHolder" src={videoHolder} alt="" />
                <video
                  className="videoContent"
                  src={video.preview}
                  autoPlay
                  controls
                />
              </div>
              <div className="videoDetails">
                <input
                  type="text"
                  className="inpTitle"
                  placeholder="Enter Video Title "
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <textarea
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  name=""
                  className="textArea"
                  placeholder="Video Description"
                />
                <label className="uploadThumbnail">
                  <input
                    type="file"
                    className="d-none"
                    onChange={uploadImage}
                  />
                  <img src={cloudUpload} className="cloudUpload" alt="" />
                  {thumbLoading
                    ? "Uploading"
                    : thubnailLink
                    ? "Thumbnail Uploaded"
                    : "Upload Video Thumbnail"}
                </label>
              </div>
            </div>
            <div className="separator"></div>
            <div className="categoriesNLists">
              <div className="categoryList">
                <div className="head">
                  <div className="title">Categories</div>
                  <div className="btnNew" onClick={() => setNewCategory(true)}>
                    New
                  </div>
                </div>
                <Scrollbars className="catScrlWrap" autoHide>
                  {categoryList.map((category) => (
                    <CategoryItem
                      key={category._id}
                      setCategoryType={setCategoryType}
                      category={category}
                      categoryType={categoryType}
                      OnEdit={(category) => {
                        setCategoryDataToUpdate(category);
                        setNewCategory(true);
                      }}
                      onDelete={() => {
                        setCategoryDataToUpdate({
                          _id: category._id,
                          status: "inactive",
                        });
                        setNewCategory(true);
                      }}
                      onCopy={() => setCategoryDataToUpdate(category)}
                    />
                  ))}
                </Scrollbars>
              </div>
              <div className="categoryList">
                <div className="head">
                  <div className="title">Lists</div>
                  <div className="btnNew" onClick={() => setNewNav(true)}>
                    New
                  </div>
                </div>
                <Scrollbars className="catScrlWrap" autoHide>
                  {navbarList.map((navbar) => (
                    <NavbarItem
                      key={navbar._id}
                      navbar={navbar}
                      navbarId={navbarId}
                      setNavbarId={setNavbarId}
                      OnEdit={(navbar) => {
                        setNavDataToUpdate(navbar);
                        setNewNav(true);
                      }}
                      onDelete={() => {
                        setNavDataToUpdate({
                          _id: navbar._id,
                          status: "inactive",
                        });
                        setNewNav(true);
                      }}
                      onCopy={() => setNavDataToUpdate(navbar)}
                    />
                  ))}
                </Scrollbars>
              </div>
            </div>
            <div className="post" onClick={postVideo}>
              Post
            </div>
          </div>
        ) : (
          <>
            <div className="brainSection">
              <label className="btnBrain">
                <img src={brainFull} alt="" />
                <input
                  type="file"
                  hidden
                  name=""
                  id=""
                  accept="video/*"
                  onChange={(e) => {
                    if (!loading) handleChange(e);
                  }}
                />
              </label>
              <div className="btnLabel">
                {videoId
                  ? "Video Uploaded"
                  : loading
                  ? "Uploading Video"
                  : "One Time Use"}
              </div>
            </div>
            <>
              {videoId ? (
                <div className="brainSection uploaded">
                  <div className="videoCard">
                    <img
                      src={vidieoPlayIcon}
                      alt=""
                      className="videoPlaceholder"
                    />
                    <div className="textContent">
                      <div className="title">{video.raw.name}</div>
                      <div className="value">{videoId}</div>
                      <div className="buttons">
                        <div
                          className="btnWhite"
                          onClick={() => {
                            navigator.clipboard.writeText(videoId).then(() => {
                              tostShowOn("Video Id Copied To Clipboard");
                            });
                          }}
                        >
                          Copy ID
                        </div>
                        <div
                          className="btnBlue"
                          onClick={() => setAction("post")}
                        >
                          Post
                        </div>
                        <div className="btnWhite">Open</div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="brainSection disable">
                  <label className="btnBrain">
                    <img src={brainFull} alt="" />
                  </label>
                  <div className="btnLabel">Connect Your Brain</div>
                </div>
              )}
            </>
          </>
        )}
      </div>
      {action !== "post" && !videoId && !loading && (
        <div className="enterVideoId">
          <input
            type="text"
            placeholder="Enter Transmitter ID"
            value={inpVideoId}
            onChange={(e) => setInpVideoId(e.target.value)}
          />
          <div
            className="btnContinue"
            onClick={() => {
              if (inpVideoId) {
                setVideoId(inpVideoId);
                setAction("post");
              }
            }}
          >
            Proceed
          </div>
        </div>
      )}
      {newNav && (
        <NewNavbar
          navData={navDataToUpdate}
          publication={publication}
          onSuccess={() => {
            setNewNav(false);
            getNavBars();
            setNavDataToUpdate(false);
          }}
          onClose={() => {
            setNewNav(false);
            setNavDataToUpdate(false);
          }}
        />
      )}
      {newCategory && (
        <NewCategory
          catData={categoryDataToUpdate}
          publication={publication}
          onSuccess={() => {
            setNewCategory(false);
            getCategories();
            setCategoryDataToUpdate(false);
          }}
          onClose={() => {
            setNewCategory(false);
            setCategoryDataToUpdate(false);
          }}
        />
      )}
      {!newCategory && categoryDataToUpdate && (
        <CopyPublicationDetailModal
          publication={categoryDataToUpdate}
          onClose={() => setCategoryDataToUpdate(false)}
        />
      )}
      {!newNav && navDataToUpdate && (
        <CopyPublicationDetailModal
          publication={navDataToUpdate}
          onClose={() => setNavDataToUpdate(false)}
        />
      )}
    </>
  );
}

export default NewVideoPost;
