import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import MainLayout from "../layouts/MainLayout";
import NavbarWithdrawals from "../layouts/Navbar/NavbarWithdrawals";

import boards from "../static/images/clipIcons/boards.svg";
import list from "../static/images/clipIcons/list.svg";
import JusticeFilter from "../components/JusticeFilter/JusticeFilter";
import JusticeContent from "../components/JusticeContent/JusticeContent";

const tabs = ["Collections", "Page 2"];
function CounselPage({ hide }) {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedBanker, setSelectedBanker] = useState({});

  const [isList, setIsList] = useState(true);
  const viewSwitcher = (
    <div className="viewSwitcher">
      <div
        className={`btSwitchView ${!isList}`}
        // onClick={() => setIsList(false)}
      >
        <img src={boards} alt="" /> Boards
      </div>
      <div className={`btSwitchView ${isList}`} onClick={() => setIsList(true)}>
        <img src={list} alt="" /> List
      </div>
    </div>
  );

  const [tabClick, setTabClick] = useState(false);
  const [tabSelected, setTabSelected] = useState("Collections");

  const [filter, setFilter] = useState("Type");
  const [type, setType] = useState("content");

  return (
    <MainLayout active={"Counsel"} hide={hide}>
      <NavbarWithdrawals
        tabs={tabs}
        changeTab={tabSelected}
        onChangeTab={(tab) => setTabSelected(tab)}
        onNew={() => {}}
        dropDownOpen={dropDownOpen}
        search={search}
        setSearch={setSearch}
        viewSwitcher={viewSwitcher}
        button={
          <div
            className={`publication ${dropDownOpen}`}
            onClick={() => setDropDownOpen(!dropDownOpen)}
          >
            {dropDownOpen ? (
              <span>Bankers</span>
            ) : (
              <>
                <img
                  className="logoIcn"
                  src={selectedBanker && selectedBanker.profilePicURL}
                  alt=""
                />
                <span>{selectedBanker && selectedBanker.bankerTag}</span>
              </>
            )}
          </div>
        }
        tabClick={() => setTabClick(!tabClick)}
      />
      <JusticeFilter
        filter={filter}
        setFilter={setFilter}
        search={search}
        setSearch={setSearch}
        type={type}
      />
      <JusticeContent
        filter={filter}
        type={type}
        setType={setType}
        setFilter={setFilter}
      />
    </MainLayout>
  );
}

export default CounselPage;
