import React from "react";
import Lottie from "react-lottie";

import * as animationData from "../../static/animations/lock-eye-open.json";

function LoadingAnim() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="landingLottie">
      <div className="m-auto">
        <Lottie options={defaultOptions} height={150} width={150} />
      </div>
    </div>
  );
}

export default LoadingAnim;
