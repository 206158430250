import { IconsViewSwitcher, Navbar } from "../components/BrokerDashboard/index";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { ReactComponent as IconClose } from "../static/images/clipIcons/close.svg";
import { ReactComponent as RefreshIcn } from "../static/images/clipIcons/refresh.svg";

import BlockCheckInboxes from "../components/BlockCheckInboxes/BlockCheckInboxes";

import { TabsContext } from "../context/TabsContext";
import MainLayoutComponent from "../layouts/MainLayout";

const tabs = ["Inbox"];

function BlockCheckPage({ hide }) {
  const { navTabs } = useContext(TabsContext);
  const history = useHistory();
  const [search, setSearch] = useState("");

  const [isList, setIsList] = useState(true);
  const [searchOn, setSearchOn] = useState(false);

  const [tabClick, setTabClick] = useState(false);
  const [tabSelected, setTabSelected] = useState("Inbox");

  const [list, setList] = useState(false);
  const [refresh, setRefresh] = useState(false);

  return (
    <MainLayoutComponent active={"BlockCheck"} hide={hide}>
      <Navbar
        navTabs={navTabs}
        tabs={tabs}
        tabSelected={tabSelected}
        setTabSelected={(tab) => {
          setTabSelected("");
          setTimeout(() => {
            setTabSelected(tab);
          }, 100);
        }}
        search={search}
        setSearch={setSearch}
        viewSwitcher={
          <IconsViewSwitcher
            listIcn={list && <IconClose />}
            boardIcn={<RefreshIcn />}
            board={refresh}
            onBoardClick={() => {
              if (refresh) setRefresh(false);
              else {
                setRefresh(true);
                setList(false);
              }
            }}
            list={list}
            onListClick={() => {
              if (list) setList(false);
              else {
                setRefresh(false);
              }
            }}
            search={searchOn}
            onSearchClick={() => setSearchOn(!searchOn)}
          />
        }
        tabClick={() => {
          setTabClick(!tabClick);
        }}
        searchPlaceHolder=""
      />
      <BlockCheckInboxes
        list={list}
        setList={setList}
        searchOn={searchOn}
        refresh={refresh}
      />
    </MainLayoutComponent>
  );
}

export default BlockCheckPage;
