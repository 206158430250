import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SidebarClassic } from "../../components/BrokerDashboard/index";

import society from "../../static/images/sidebarIcons/society.svg";
import iconBank from "../../static/images/sidebarIcons/bank.svg";
import blockIcon from "../../static/images/clipIcons/blockIcon.svg";
import xidIcon from "../../static/images/clipIcons/xid.svg";
import marketsIcon from "../../static/images/sidebarIcons/marketsIcon.svg";
import commereceIcon from "../../static/images/clipIcons/commerece.svg";
import venturesIcon from "../../static/images/clipIcons/venturesIcon.svg";
import firms from "../../static/images/sidebarIcons/firms.svg";
import viral from "../../static/images/sidebarIcons/viral.svg";
import neurons from "../../static/images/sidebarIcons/neurons.svg";
import communications from "../../static/images/sidebarIcons/communications.svg";
import employment from "../../static/images/sidebarIcons/employment.svg";
import publishers from "../../static/images/sidebarIcons/publishers.svg";
import streaming from "../../static/images/sidebarIcons/streaming.svg";
import business from "../../static/images/sidebarIcons/business.svg";

import { ReactComponent as IconEngage } from "../../static/images/sidebarIcons/engage.svg";
import bankerIcon from "../../static/images/sidebarIcons/bankersLogo.svg";
import iconCreate from "../../static/images/sidebarIcons/create.svg";
import { MainContext } from "../../context/MainContext";
import logo from "../../static/images/logos/societyIcon.svg";
import logoText from "../../static/images/logos/society.svg";

function Sidebar({ active, onNew }) {
  const history = useHistory();
  const { login, profilePic, profileName, email, appList } =
    useContext(MainContext);
  const [appMenu, setAppMenu] = useState([]);
  useEffect(() => {
    if (appList) {
      let appsMenu = [];
      appList.forEach((app) => {
        appsMenu.push({
          name: app.app_name,
          icon: app.app_icon,
          onClick: () => history.push(`/apps/${app.app_code}`),
        });
      });
      setAppMenu(appsMenu);
    }
  }, [appList, history]);
  return (
    <SidebarClassic
      logo={logo}
      logoText={logoText}
      email={email}
      onLogout={() => login()}
      profilePic={profilePic}
      profileName={profileName}
      active={active}
      groupOneName="Grow"
      groupTwoName="Protocols"
      groupThreeName="Apps"
      addBtn={iconCreate}
      addBtnOnClick={() => onNew()}
      groupOneList={[
        {
          name: "Society",
          icon: society,
          onClick: () => history.push("/society"),
        },
        {
          name: "Markets",
          icon: marketsIcon,
          onClick: () => history.push("/markets"),
        },
        {
          name: "Commerce",
          icon: commereceIcon,
          onClick: () => history.push("/commerce"),
        },
        {
          name: "Ventures",
          icon: venturesIcon,
          onClick: () => history.push("/ventures"),
        },
        {
          name: "Firms",
          icon: firms,
          onClick: () => history.push("/commerce"),
        },
        {
          name: "Viral",
          icon: viral,
          onClick: () => history.push("/viral"),
        },
        {
          name: "Businesses",
          icon: business,
          onClick: () => history.push("/businesses"),
        },
        {
          name: "Neurons",
          icon: neurons,
          onClick: () => history.push("/commerce"),
        },
        {
          name: "Communications",
          icon: communications,
          onClick: () => history.push("/commerce"),
        },
        {
          name: "Employment",
          icon: employment,
          onClick: () => history.push("/commerce"),
        },
        {
          name: "Publishers",
          icon: publishers,
          onClick: () => history.push("/commerce"),
        },
        {
          name: "Streaming",
          icon: streaming,
          onClick: () => history.push("/commerce"),
        },
        // {
        //   name: "Assets.io",
        //   icon: assetsLogo,
        //   onClick: () => history.push("/apps/ice"),
        // },
        // {
        //   name: "New",
        //   svg: <IconCreate />,
        //   onClick: () => onNew(),
        //   sticky: true,
        // },
      ]}
      groupTwoList={[
        {
          name: "Liquidity",
          icon: bankerIcon,
          onClick: () => history.push("/liquidity"),
        },
        {
          name: "Central Bank",
          icon: iconBank,
          onClick: () => history.push("/centralbank"),
        },
        {
          name: "TokenApps",
          icon: xidIcon,
          onClick: () => history.push("/xid"),
        },
        {
          name: "EmailChains",
          icon: blockIcon,
          onClick: () => history.push("/blockcheck"),
        },
        {
          name: "Engagement",
          svg: <IconEngage />,
          onClick: () => history.push("/content"),
        },
      ]}
      groupThreeList={[]}
      groupThreeFunction={{
        name: "View All",
        onClick: () => history.push("/Apps"),
      }}
    />
  );
}

export default Sidebar;
