import React, { useContext, useState, useEffect } from "react";
import JsonWebToken from "jsonwebtoken";
import Scrollbars from "react-custom-scrollbars";
import cloudUploadIcon from "../../static/images/postClipArt/cloudUpload.svg";
import CoinList from "../NewConglomerate/CoinList";
import OperatorList from "../NewConglomerate/OperatorList";
import Axios from "axios";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import { MainContext } from "../../context/MainContext";
import { useHistory } from "react-router";
import loadingGif from "../../static/animations/loading.gif";
import back from "../../static/images/back.svg";
import WebsiteInput from "../WebsiteInput";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

function ConglomeratesListSidebarEdit({
  onClose,
  onBack,
  conglomerate,
  // setLoading,
}) {
  const history = useHistory();
  const { tostShowOn, email, token } = useContext(MainContext);
  const [step, setStep] = useState("");
  const [loading, setLoading] = useState(false);

  const [operator, setOperator] = useState("");
  const [groupName, setGroupName] = useState("");
  const [icon, setIcon] = useState("");
  const [logo, setLogo] = useState("");
  const [whiteIcon, setWhiteIcon] = useState("");
  const [whiteLogo, setWhiteLogo] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");
  const [description, setDescription] = useState("");
  const [website, setWebsite] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [websiteTitle, setWebsiteTitle] = useState("");
  const [websiteDescription, setWebsiteDescription] = useState("");
  const [tokenDetail, setTokenDetail] = useState({});

  const [iconLoading, setIconLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [whiteIconLoading, setWhiteIconLoading] = useState(false);
  const [whiteLogoLoading, setWhiteLogoLoading] = useState(false);
  const [coverPhotoLoading, setCoverPhotoLoading] = useState(false);

  function setOperaratorWithId(operator_id) {
    Axios.get(
      `https://comms.globalxchange.io/gxb/app/gxlive/user/operator/get`
    ).then(({ data }) => {
      if (data?.status) {
        setOperator(
          data?.operators.filter((op) => op.operator_id === operator_id)[0]
        );
      }
    });
  }
  function setCoinWithSymbol(coinSymbol) {
    Axios.get("https://comms.globalxchange.io/coin/vault/get/all/coins").then(
      ({ data }) => {
        data.status &&
          setTokenDetail(
            data.coins.filter((coin) => coin.coinSymbol === coinSymbol)[0]
          );
      }
    );
  }

  useEffect(() => {
    setOperaratorWithId(conglomerate?.operator_id);
    setGroupName(conglomerate?.groupname);
    setIcon(conglomerate?.icon);
    setLogo(conglomerate?.logo);
    setWhiteIcon(conglomerate?.white_icon);
    setWhiteLogo(conglomerate?.white_logo);
    setCoverPhoto(conglomerate?.cover_photo);
    setDescription(conglomerate?.description);
    setWebsite(conglomerate?.website?.slice().replace("https://", ""));
    setTokenSymbol(conglomerate?.token);
    setCoinWithSymbol(conglomerate?.token);
    setPrimaryColor(conglomerate?.other_data?.colorcode);
    setSecondaryColor(conglomerate?.other_data?.colourcode2);
    setWebsiteTitle(conglomerate?.other_data?.websitetitle);
    setWebsiteDescription(conglomerate?.other_data?.websitedescription);
  }, [conglomerate]);

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
    setLoading(false);
  };

  function getContent() {
    switch (step) {
      case "operator":
        return (
          <OperatorList setOperator={setOperator} onClose={() => setStep("")} />
        );
      case "coin":
        return (
          <CoinList
            setCoinSymbol={setTokenSymbol}
            onClose={() => setStep("")}
            setTokenDetail={setTokenDetail}
          />
        );

      default:
        return (
          <Scrollbars
            className="scrollForm"
            renderTrackHorizontal={() => <div />}
            renderThumbHorizontal={() => <div />}
            renderTrackVertical={() => <div />}
            renderThumbVertical={() => <div />}
          >
            <div className="name">Select GXLive Operator</div>
            {operator ? (
              <div className="user" onClick={() => setStep("operator")}>
                <img className="dp" src={operator?.brand_logo} alt="" />
                <div className="userDetail">
                  <div className="name">{operator?.brand_name}</div>
                  <div className="email">{operator?.email}</div>
                </div>
              </div>
            ) : (
              <div className="inputWrap" onClick={() => setStep("operator")}>
                <div className="label">Click Here To Select An Operator</div>
              </div>
            )}
            <div className="name">Name The Conglomerate</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Name Here"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
            </div>
            <div className="name">Upload Branding Material</div>
            <div className="filesUpload">
              <label className="fileInp icon">
                <img
                  className={`${Boolean(icon)}`}
                  src={iconLoading ? loadingGif : icon || cloudUploadIcon}
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setIcon, setIconLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">Colored Icon</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
              <label className="fileInp icon">
                <img
                  className={`white ${Boolean(whiteIcon)}`}
                  src={
                    whiteIconLoading ? loadingGif : whiteIcon || cloudUploadIcon
                  }
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setWhiteIcon, setWhiteIconLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">White Icon</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
              <label className="fileInp cover">
                <img
                  className={`${Boolean(coverPhoto)}`}
                  src={
                    coverPhotoLoading
                      ? loadingGif
                      : coverPhoto || cloudUploadIcon
                  }
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setCoverPhoto, setCoverPhotoLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">Cover Photo</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
            </div>
            <div className="filesUpload last">
              <label className="fileInp fullLogo">
                <img
                  className={`${Boolean(logo)}`}
                  src={logoLoading ? loadingGif : logo || cloudUploadIcon}
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setLogo, setLogoLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">Colored Logo</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
              <label className="fileInp fullLogo">
                <img
                  className={`white ${Boolean(whiteLogo)}`}
                  src={
                    whiteLogoLoading ? loadingGif : whiteLogo || cloudUploadIcon
                  }
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setWhiteLogo, setWhiteLogoLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">White Logo</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
            </div>
            <div className="name">Enter The Conglomerate’s Website</div>
            <WebsiteInput website={website} setWebsite={setWebsite} />
            <div className="name">Describe The Conglomerate</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Description Here"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="name">Attach A Token</div>
            {tokenSymbol ? (
              <div className="user" onClick={() => setStep("coin")}>
                <img className="dp" src={tokenDetail?.coinImage} alt="" />
                <div className="userDetail">
                  <div className="name">{tokenDetail?.coinName}</div>
                  <div className="email">{tokenSymbol}</div>
                </div>
              </div>
            ) : (
              <div className="inputWrap" onClick={() => setStep("coin")}>
                <div className="label">Click Here To Select A Token</div>
              </div>
            )}
            <div className="name">Enter Primary Color Code</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Code Here..."
                value={primaryColor}
                onChange={(e) => setPrimaryColor(e.target.value)}
              />
              <div
                className="color"
                style={{ width: "10%", background: `#${primaryColor}` }}
              />
            </div>
            <div className="name">Enter Secondary Color Code</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Code Here..."
                value={secondaryColor}
                onChange={(e) => setSecondaryColor(e.target.value)}
              />
              <div
                className="color"
                style={{ width: "10%", background: `#${secondaryColor}` }}
              />
            </div>

            <div className="name">Website Title</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Website Title"
                value={websiteTitle}
                onChange={(e) => setWebsiteTitle(e.target.value)}
              />
            </div>
            <div className="name">Website Description</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Description Here"
                value={websiteDescription}
                onChange={(e) => setWebsiteDescription(e.target.value)}
              />
            </div>
            <div className="space"></div>
          </Scrollbars>
        );
    }
  }

  function addConglomerate() {
    setLoading(true);
    Axios.post(
      "https://comms.globalxchange.io/gxb/app/gxlive/operator/edit/app/group",
      {
        email,
        token,
        group_id: conglomerate.group_id,
        update_data: {
          operator_id:
            operator === conglomerate?.operator_id
              ? undefined
              : operator.operator_id,
          groupname:
            groupName === conglomerate?.groupname ? undefined : groupName,
          icon: icon === conglomerate?.icon ? undefined : icon,
          logo: logo === conglomerate?.logo ? undefined : logo,
          white_icon:
            whiteIcon === conglomerate?.white_icon ? undefined : whiteIcon,
          white_logo:
            whiteLogo === conglomerate?.white_logo ? undefined : whiteLogo,
          cover_photo:
            coverPhoto === conglomerate?.cover_photo ? undefined : coverPhoto,
          description:
            description === conglomerate?.description ? undefined : description,
          website: website === conglomerate?.website ? undefined : website,
          tokenSymbol:
            tokenSymbol === conglomerate?.token ? undefined : tokenSymbol,
          other_data:
            primaryColor !== conglomerate?.other_data?.colorcode ||
            secondaryColor !== conglomerate?.other_data?.colourcode2 ||
            websiteTitle !== conglomerate?.other_data?.websitetitle ||
            websiteDescription !== conglomerate?.other_data?.websitedescription
              ? {
                  colorcode: primaryColor,
                  colourcode2: secondaryColor,
                  websitetitle: websiteTitle,
                  websitedescription: websiteDescription,
                }
              : undefined,
        },
      }
    )
      .then(({ data }) => {
        if (data.status) {
          tostShowOn("Conglomerate Updated");
          history.push("/ventures/Conglomerates");
          window.location.reload();
        } else {
          tostShowOn(data.message);
        }
      })
      .finally(() => setLoading(false));
  }

  function validate() {
    if (
      operator &&
      groupName &&
      icon &&
      logo &&
      whiteIcon &&
      whiteLogo &&
      coverPhoto &&
      description &&
      website &&
      tokenSymbol &&
      primaryColor &&
      secondaryColor
    ) {
      addConglomerate();
    } else {
      tostShowOn("All Fields Are Mandatory");
    }
  }

  return (
    <>
      <div className="newConglomerate">{getContent()}</div>
      <div className="btnSubmit" onClick={validate}>
        Update Conglomerate
      </div>
      {loading && <LoadingAnim />}{" "}
    </>
  );
}

export default ConglomeratesListSidebarEdit;
