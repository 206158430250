import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

function NavbarItem({
  navbarId,
  navbar,
  setNavbarId,
  onExpand,
  onDelete,
  OnEdit,
  onCopy,
}) {
  const [moreDropOpen, setMoreDropOpen] = useState(false);
  return (
    <div
      className={`catItem ${navbarId.includes(navbar._id)}`}
      key={navbar._id}
      onClick={(e) => {
        if (e.ctrlKey) {
          setNavbarId((cat) => [...cat, navbar._id]);
        } else {
          setNavbarId([navbar._id]);
        }
      }}
    >
      <img src={navbar.icon} alt="" />
      <div className="catTexts">
        <div className="name">{navbar.navTitle}</div>
        <div className="desc">{navbar.desc}</div>
      </div>
      <div
        className="btnAction more"
        onMouseEnter={() => setMoreDropOpen(true)}
        onMouseLeave={() => {
          setTimeout(() => {
            setMoreDropOpen(false);
          }, 100);
        }}
        onClick={() => setMoreDropOpen(!moreDropOpen)}
      >
        More
        <FontAwesomeIcon icon={faCaretDown} />
        {moreDropOpen && (
          <div className="dropList">
            <div
              className="dropItm"
              onClick={() => {
                try {
                  onExpand();
                } catch (error) {}
              }}
            >
              Expand
            </div>
            <div
              className="dropItm"
              onClick={() => {
                try {
                  onDelete(navbar);
                } catch (error) {}
              }}
            >
              Delete
            </div>
            <div
              className="dropItm"
              onClick={() => {
                try {
                  OnEdit(navbar);
                } catch (error) {}
              }}
            >
              Edit
            </div>
            <div
              className="dropItm"
              onClick={() => {
                try {
                  onCopy();
                } catch (error) {}
              }}
            >
              Copy
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NavbarItem;
