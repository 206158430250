import React, { createRef, useRef } from "react";
import OnOutsideClick from "../../utils/OnOutsideClick";

function TerminalItem({ terminal, onClick }) {
  const elRefs = useRef([]);

  elRefs.current = Array(2)
    .fill("")
    .map((_, i) => elRefs.current[i] || createRef());
  const refOut = useRef();
  OnOutsideClick(undefined, onClick, refOut, elRefs);

  return (
    <div className="listItemTerminal" ref={refOut}>
      <div className="imgWrap">
        <img src={terminal?.full_logo_coloured} alt="" className="icon" />
      </div>
      <span className="fund">
        {terminal?.is_investment_path ? "Fund Terminal" : "Non-Fund Terminal"}
      </span>
      <span className="email">{terminal?.ter_user?.email}</span>
      <div
        className="btnTerminal"
        onClick={() => {
          window.open(`https://terminals.app/#/${terminal.id}`, "_blank");
        }}
        ref={elRefs.current[0]}
      >
        Terminal
      </div>
      <div
        className="btnWebsite"
        onClick={() => {
          window.open(
            `${terminal.website.includes("http") ? "" : "https://"}${
              terminal.website
            }`,
            "_blank"
          );
        }}
        ref={elRefs.current[1]}
      >
        Website
      </div>
    </div>
  );
}

export default TerminalItem;
