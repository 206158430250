import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import vaultlogo from "../../static/images/logos/engagement.svg";
import { MainContext } from "../../context/MainContext";
import LoadingAnim from "../LoadingAnim/LoadingAnim";

function NewPublication({ onClose, onSuccess, pubData }) {
  const { tostShowOn, email } = useContext(MainContext);
  const [events, setEvents] = useState([
    {
      key: "bos_email",
      question: "BOS Email? ",
      placeHolder: "Ex. example@example.com",
    },
    {
      key: "name",
      question: "Name Publication",
      placeHolder: "Ex. AssetsIo Class",
    },
    {
      key: "email",
      question: "Email? ",
      placeHolder: "Ex. example@example.com",
    },
    {
      key: "description",
      question: "Description",
      placeHolder: "Ex. Desc",
    },
    {
      key: "app_code",
      question: "App Codes",
      placeHolder: "Ex. ice",
    },
  ]);
  const [step, setStep] = useState(0);
  const [dataObj, setDataObj] = useState({
    email,
    bos_email: email,
  });

  const [updateId, setUpdateId] = useState(false);
  const [appId, setAppId] = useState([""]);
  useEffect(() => {
    if (pubData) {
      setUpdateId(pubData._id);
      const newData = {
        ...pubData,
      };
      delete newData.createdAt;
      delete newData.updatedAt;
      delete newData._id;
      delete newData.__v;
      setDataObj(newData);
      setEvents([
        {
          key: "status",
          question: "Status? ",
          placeHolder: "Ex. active/inactive",
        },
        ...events,
      ]);
      setAppId(newData.app_code);
    }
  }, [pubData]);

  const [loading, setLoading] = useState(false);

  function newEventCreate() {
    setLoading(true);
    if (updateId) {
      Axios.put(`https://fxagency.apimachine.com/publisher/${updateId}`, {
        ...dataObj,
        app_code: appId,
      })
        .then(({ data }) => {
          tostShowOn(data.status ? "Success" : data.message);
          onSuccess();
        })
        .finally(() => setLoading(false));
    } else {
      Axios.post("https://fxagency.apimachine.com/publication/new", {
        ...dataObj,
        app_code: appId,
      })
        .then(({ data }) => {
          tostShowOn(data.status ? "Success" : data.message);
          onSuccess();
        })
        .finally(() => setLoading(false));
    }
  }
  const [appIndex, setAppIndex] = useState(0);
  return (
    <div className="copyPubModal">
      <div
        className="overlay"
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <div className="modalCopy">
        <div className="head">
          <img src={vaultlogo} alt="" />
        </div>
        <div className="copyContent">
          <div className="stepLabel">{events[step].question}</div>
          <div className="step">Step {step + 1}</div>
          {step === events.length - 1 ? (
            <>
              {appId.map((app, i) => (
                <input
                  type="text"
                  className="inputValue"
                  value={app || ""}
                  onChange={(e) => {
                    e.persist();
                    setAppId((appId) => {
                      let appCode = appId.slice();
                      appCode[i] = e.target.value;
                      return appCode;
                    });
                  }}
                  placeholder={events[step].placeHolder}
                />
              ))}
              {appId[appIndex] && (
                <div
                  className="btnNextStep w-100 text-center"
                  onClick={() => {
                    setAppIndex((index) => index + 1);
                    setAppId((appId) => [...appId, ""]);
                  }}
                >
                  Add App
                </div>
              )}
            </>
          ) : (
            <input
              type="text"
              className="inputValue"
              value={dataObj[events[step].key] || ""}
              onChange={(e) => {
                e.persist();
                setDataObj((data) => ({
                  ...data,
                  [events[step].key]: e.target.value,
                }));
              }}
              placeholder={events[step].placeHolder}
            />
          )}
          <div
            className="btnNextStep"
            onClick={() => {
              if (step < events.length - 1) setStep((step) => step + 1);
              else newEventCreate();
            }}
          >
            {step < events.length - 1 ? "Next Step" : "Submit"}
          </div>
          {loading && <LoadingAnim />}
        </div>
      </div>
    </div>
  );
}

export default NewPublication;
