import Axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import Scrollbars from "react-custom-scrollbars/lib/Scrollbars";
import { useHistory } from "react-router-dom";

import yesIcn from "../../static/images/clipIcons/yes.svg";
import noIcn from "../../static/images/clipIcons/no.svg";

import { MainContext } from "../../context/MainContext";

function ConglomeratesListSidebarAllApps({ conglomerate, setLoading }) {
  const history = useHistory();
  const [appList, setAppList] = useState([]);
  const [selectedApp, setSelectedApp] = useState();
  useEffect(() => {
    setAppList(conglomerate?.apps);
  }, [conglomerate]);

  const [search, setSearch] = useState("");
  const { email, token, tostShowOn } = useContext(MainContext);
  const [step, setStep] = useState("");

  function deleteApps() {
    setLoading(true);
    Axios.post(
      "https://comms.globalxchange.io/gxb/app/gxlive/operator/appgroups/remove/apps",
      {
        email,
        token,
        group_id: conglomerate.group_id,
        app_codes: [selectedApp.app_code],
      }
    )
      .then(({ data }) => {
        if (data.status) {
          setStep("deleteSucces");
        } else {
          tostShowOn(data.message || "Something Went Wrong");
        }
      })
      .finally(() => setLoading(false));
  }
  function getContent() {
    switch (step) {
      case "deleteConfirm":
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm">
                Are You Sure You Want To Delete {selectedApp?.app_name} From{" "}
                {conglomerate?.groupname}?
              </div>
              <Scrollbars className="coinList">
                <div className="coinItem" onClick={() => deleteApps()}>
                  <img src={yesIcn} alt="" className="coin" />
                  <span>Confirm</span>
                </div>
                <div className="coinItem" onClick={() => setStep("")}>
                  <img src={noIcn} alt="" className="coin" />
                  <span>No</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );

      case "deleteSucces":
        setTimeout(() => {
          history.push("/ventures/Conglomerates");
          window.location.reload();
        }, 2000);
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm m-auto">
                You Have Successfully Deleted {selectedApp.app_name} From{" "}
                {conglomerate.groupname}. You Will Be Redirected To The Updated
                Conglomerates List Automatically
              </div>
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm">{conglomerate.groupname}’s Apps</div>
              <div className="searchBox">
                <input
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search Apps...|"
                />
              </div>

              <Scrollbars autoHide className="itemListScroll">
                {appList
                  .filter(
                    (app) =>
                      app.app_code
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      app.app_name.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((app) => (
                    <div className="item">
                      <img className="coin" src={app.app_icon} alt="" />
                      <span>{app.app_name}</span>
                      <svg
                        className="delete"
                        viewBox="0 0 29 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          setSelectedApp(app);
                          setStep("deleteConfirm");
                        }}
                      >
                        <path
                          d="M0.5 14.5C0.5 6.78052 6.78052 0.5 14.5 0.5C22.2195 0.5 28.5 6.78052 28.5 14.5C28.5 22.2195 22.2195 28.5 14.5 28.5C6.78052 28.5 0.5 22.2195 0.5 14.5Z"
                          fill="white"
                          stroke="#464B4E"
                        />
                        <path
                          d="M15.8025 14.7685L20.2578 10.3132C20.5436 10.0275 20.5437 9.56489 20.2578 9.27912C19.9721 8.99333 19.5095 8.99326 19.2237 9.2791L14.7684 13.7344L10.3131 9.27912C10.0274 8.99335 9.56476 8.99326 9.27899 9.27907C8.9932 9.56484 8.99317 10.0274 9.27899 10.3132L13.7343 14.7685L9.27897 19.2238C8.99384 19.5089 8.99384 19.9728 9.27897 20.2579C9.56471 20.5437 10.0273 20.5438 10.3131 20.2579L14.7684 15.8026L19.2237 20.2579C19.5094 20.5437 19.972 20.5437 20.2577 20.2579C20.5435 19.9722 20.5436 19.5096 20.2578 19.2238L15.8025 14.7685Z"
                          fill="#464B4E"
                        />
                      </svg>
                    </div>
                  ))}
              </Scrollbars>
            </div>
          </>
        );
    }
  }

  return <>{getContent()}</>;
}

export default ConglomeratesListSidebarAllApps;
