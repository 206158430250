import React, { useRef } from "react";
import OnOutsideClick from "../../utils/OnOutsideClick";

function BrandItem({ brand, onClick, selected }) {
  const btnRef = useRef();
  const outRef = useRef();

  OnOutsideClick(
    btnRef,
    () => {
      try {
        onClick();
      } catch (error) {}
    },
    outRef
  );
  return (
    <div className={`listItem ${brand === selected}`} ref={outRef}>
      <div className="profile" style={{ flex: "0 0 30%" }}>
        <img src={brand.colored_icon} alt="" className="icon" />
        <span className="name">{brand.name}</span>
      </div>
      <div className="profile" style={{ flex: "0 0 30%" }}>
        <img src={brand.parent_groupData?.icon} alt="" className="icon" />
        <span className="name">{brand.parent_groupData?.groupname}</span>
      </div>
      <div className="btnWebsite" style={{ minWidth: 200 }}>
        API
      </div>
      <div
        className="btnWebsite"
        ref={btnRef}
        onClick={() => window.open(brand?.website, "_blank").focus()}
        style={{ minWidth: 200 }}
      >
        Website
      </div>
    </div>
  );
}

export default BrandItem;
