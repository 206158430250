import React, { useEffect, useState } from "react";
import RichTextEditor from "react-rte";
import {
  getTextAlignClassName,
  getTextAlignStyles,
} from "react-rte/lib/lib/blockStyleFunctions";

function RichTextEditorComponent({ onChange }) {
  const [textValue, setTextValue] = useState(RichTextEditor.createEmptyValue());
  useEffect(() => {
    try {
      onChange(
        textValue.toString("html", { blockStyleFn: getTextAlignStyles })
      );
    } catch (error) {}
  }, [textValue]);
  return (
    <RichTextEditor
      className="richTextEditor"
      blockStyleFn={getTextAlignClassName}
      value={textValue}
      onChange={(value) => {
        setTextValue(value);
      }}
    />
  );
}

export default RichTextEditorComponent;
