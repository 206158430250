import { SearchAndFilter } from "../BrokerDashboard/index";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { FormatNumber } from "../../utils/FunctionTools";
import classNames from "./bondTiersList.module.scss";
import BondTiersListidebar from "./BondTiersListidebar";

function BondTiersList({ searchOn, list, setList }) {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [bondTiersList, setBondTiersList] = useState([]);

  function getBondList() {
    setLoading(true);
    axios
      .get(
        "https://comms.globalxchange.io/coin/iced/banker/custom/bond/user/list/get"
      )
      .then(({ data }) => {
        if (data.status) {
          setBondTiersList(data.data);
        } else {
          setBondTiersList([]);
        }
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getBondList();
  }, []);

  const [bondTiersSelected, setBondTiersSelected] = useState();
  return (
    <div className={classNames.bondTiersList}>
      <div className={classNames.bondTiersListCol}>
        {searchOn && (
          <SearchAndFilter
            search={search}
            setSearch={setSearch}
            placeholder="Type In Any Bond Tiers....."
            filterBy={`Coin`}
            mainList={[]}
            filterList={[]}
          />
        )}
        <Scrollbars
          className={classNames.appsScrollbar}
          renderThumbHorizontal={() => <div />}
          renderThumbVertical={() => <div />}
          renderTrackHorizontal={() => <div />}
          renderTrackVertical={() => <div />}
        >
          <div
            className={`${classNames.header} ${
              classNames[Boolean(bondTiersSelected)]
            }`}
          >
            <span className={classNames.name}>Name</span>
            <span className={classNames.app}>App</span>
            <span className={classNames.banker}>Banker</span>
            <span className={classNames.type}>Tier Type</span>
            <span className={classNames.count}>Bonds</span>
            <span className={classNames.userCount}>Users</span>
          </div>
          {loading
            ? Array(10)
                .fill("")
                .map((_, i) => (
                  <div className={classNames.bondTiersItem}>
                    <div className={classNames.name}>
                      <Skeleton className={classNames.icon} circle />
                      <div className={classNames.texts}>
                        <Skeleton className={classNames.title} width={160} />
                        <Skeleton className={classNames.subTitle} width={130} />
                      </div>
                    </div>
                    <div className={classNames.app}>
                      <Skeleton className={classNames.icon} circle />
                      <div className={classNames.texts}>
                        <Skeleton className={classNames.title} width={160} />
                        <Skeleton className={classNames.subTitle} width={130} />
                      </div>
                    </div>
                    <div className={classNames.banker}>
                      <div className={classNames.texts}>
                        <Skeleton className={classNames.title} width={130} />
                        <Skeleton className={classNames.subTitle} width={100} />
                      </div>
                    </div>
                    <div className={classNames.count}>
                      <div className={classNames.texts}>
                        <Skeleton className={classNames.title} width={130} />
                      </div>
                    </div>
                    <div className={classNames.userCount}>
                      <div className={classNames.texts}>
                        <Skeleton className={classNames.title} width={130} />
                      </div>
                    </div>
                  </div>
                ))
            : bondTiersList
                ?.filter((bondTier) =>
                  bondTier.name.toLowerCase().includes(search.toLowerCase())
                )
                ?.map((bondTier) => (
                  <BondTiersItem
                    bondTier={bondTier}
                    selected={bondTier === bondTiersSelected}
                    onClick={() => setBondTiersSelected(bondTier)}
                    sidebarOpened={Boolean(bondTiersSelected)}
                  />
                ))}
        </Scrollbars>
      </div>
      {bondTiersSelected && (
        <BondTiersListidebar
          bondTiersSelected={bondTiersSelected}
          onClose={() => setBondTiersSelected()}
          getBondList={getBondList}
        />
      )}
    </div>
  );
}

function BondTiersItem({ bondTier, onClick, selected, sidebarOpened }) {
  return (
    <div
      className={`${classNames[selected]} ${classNames.bondTiersItem} ${
        classNames[sidebarOpened ? "open" : ""]
      }`}
      onClick={onClick}
    >
      <div className={classNames.name}>
        <img src={bondTier.icon} alt="" className={classNames.icon} />
        <div className={classNames.texts}>
          <div className={classNames.title}>{bondTier.name}</div>
          <div className={classNames.subTitle}>{bondTier.list_id}</div>
        </div>
      </div>
      <div className={classNames.app}>
        <img
          src={bondTier?.appData?.app_icon}
          alt=""
          className={classNames.icon}
        />
        <div className={classNames.texts}>
          <div className={classNames.title}>{bondTier?.appData?.app_name}</div>
          <div className={classNames.subTitle}>{bondTier.app_code}</div>
        </div>
      </div>
      <div className={classNames.banker}>
        <img
          src={bondTier?.bankerData?.profilePicURL}
          alt=""
          className={classNames.icon}
        />
        <div className={classNames.texts}>
          <div className={classNames.title}>
            {bondTier?.bankerData?.displayName}
          </div>
          <div className={classNames.subTitle}>
            {bondTier?.bankerData?.bankerTag}
          </div>
        </div>
      </div>
      <div className={classNames.type}>
        <div className={classNames.texts}>
          <div className={classNames.title}>{bondTier.listType}</div>
        </div>
      </div>
      <div className={classNames.count}>
        <div className={classNames.texts}>
          <div className={classNames.title}>
            {FormatNumber(bondTier?.connected_bond_templates_count)}
          </div>
        </div>
      </div>
      <div className={classNames.userCount}>
        <div className={classNames.texts}>
          <div className={classNames.title}>
            {FormatNumber(bondTier?.users_count)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BondTiersList;
