import React from 'react'
import NavbarComponent from './LayoutComponents/Navbar/Navbar'
import ViewSwitcherComponent from './LayoutComponents/ViewSwitcher/ViewSwitcher'
import NavbarTabComponent from './LayoutComponents/NavbarTab/NavbarTab'
import MainLayoutComponent from './LayoutComponents/MainLayout/MainLayout'
import SidebarComponent from './LayoutComponents/Sidebar/Sidebar'
import SidebarClassicComponent from './LayoutComponents/SidebarClassic/SidebarClassic'
import IconsViewSwitcherComponent from './LayoutComponents/IconsViewSwitcher/IconsViewSwitcher'
import ToastMessageComponent from './LayoutComponents/ToastMessage/ToastMessage'
import MainContextProvider, { MainContext } from './context/MainContext'
import SearchAndFilterComponent from './LayoutComponents/SearchAndFilter/SearchAndFilter'

export const MainLayout = (props) => {
  return <MainLayoutComponent {...props} />
}

export const Navbar = (props) => {
  return <NavbarComponent {...props} />
}

export const NavbarTab = (props) => {
  return <NavbarTabComponent {...props} />
}

export const Sidebar = (props) => {
  return <SidebarComponent {...props} />
}

export const SidebarClassic = (props) => {
  return <SidebarClassicComponent {...props} />
}

export const ViewSwitcher = (props) => {
  return <ViewSwitcherComponent {...props} />
}

export const IconsViewSwitcher = (props) => {
  return <IconsViewSwitcherComponent {...props} />
}

export const ToastMessage = (props) => {
  return <ToastMessageComponent {...props} />
}

export const SearchAndFilter = (props) => {
  return <SearchAndFilterComponent {...props} />
}

export const ToastContextProvider = (props) => {
  return <MainContextProvider {...props} />
}

export const ToastContext = (props) => {
  return <MainContext {...props} />
}
