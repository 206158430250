import React, { useContext, useState, useEffect } from "react";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";
import { FormatNumber } from "../../utils/FunctionTools";
import AssetLiquidity from "./AssetLiquidity";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import { MainContext } from "../../context/MainContext";
import AssetFedBonds from "./AssetFedBonds";
import Skeleton from "react-loading-skeleton";

const validNumber = new RegExp(/^\d*\.?\d*$/);

function AssetsIoFed() {
  const [liquidity, setLiquidity] = useState("Liquid");
  const [search, setSearch] = useState("");
  const [coinsLoading, setCoinsLoading] = useState(false);
  const [coins, setCoins] = useState([]);
  const { email, token, tostShowOn } = useContext(MainContext);
  const getRates = () => {
    setCoinsLoading(true);
    Axios.get("https://comms.globalxchange.io/coin/iced/get/liquid/interest")
      .then((res) => {
        const { data } = res;
        if (data.status) {
          setCoins(data.interest_rates);
        }
      })
      .finally(() => setCoinsLoading(false));
  };
  useEffect(() => {
    getRates();
  }, []);
  const [coinToChange, setCoinToChange] = useState({});
  const [isDaily, setIsDaily] = useState(true);
  const [newRate, setNewRate] = useState("");
  const changeNewRate = (e) => {
    if (validNumber.test(e.target.value) || e.target.value === "") {
      setNewRate(e.target.value);
    }
  };
  const [loading, setLoading] = useState(false);
  const declareNewRates = () => {
    setLoading(true);
    Axios.post(
      "https://comms.globalxchange.io/coin/iced/update/liquid/interest",
      {
        email: email, // Enter the email of the admin who is calling this funtion.
        token: token, // Enter the authentication token of the "email" of the admin who is calling this function
        interestRate: newRate / (isDaily ? 1 : 365), // Enter the desire daily interest rate.
        coin: coinToChange.coin, // Enter the currency for which this update is to apply
      }
    )
      .then((res) => {
        const { data } = res;
        tostShowOn(data.message);
        getRates();
      })
      .finally(() => {
        setLoading(false);
        setCoinToChange({});
      });
  };

  function renderContent() {
    switch (liquidity) {
      case "Liquid":
        return (
          <div className={`wrapperContent ${Boolean(coinToChange.coin)}`}>
            <Scrollbars
              autoHide
              className="scrollWrap"
              renderView={(props) => <div {...props} className="scrollView" />}
            >
              {coinsLoading
                ? Array(6)
                    .fill("")
                    .map(() => (
                      <div className="listItem">
                        <Skeleton width={40} height={40} circle />
                        <Skeleton className="name" width={220} />
                        <Skeleton className="percent" width={100} />
                        <Skeleton className="btnChange" width={120} />
                      </div>
                    ))
                : coins
                    .filter(
                      (coin) =>
                        coin.coin
                          .toLowerCase()
                          .includes(search.toLowerCase()) ||
                        coin?.coin_metdata?.coinName
                          .toLowerCase()
                          .includes(search.toLowerCase())
                    )
                    .map((coin) => (
                      <div
                        key={coin._id}
                        className={`listItem ${coin === coinToChange}`}
                      >
                        <img
                          src={coin.coin_metdata && coin.coin_metdata.coinImage}
                          alt=""
                        />
                        <div className="name">
                          {coin.coin_metdata && coin.coin_metdata.coinName}
                        </div>
                        <div className="percent">
                          <span>{FormatNumber(coin.interest_rate, 4)}%</span>
                          &nbsp;|&nbsp;
                          <span>
                            {FormatNumber(coin.interest_rate * 365, 2)}%
                          </span>
                        </div>
                        <div
                          className="btnChange"
                          onClick={() => setCoinToChange(coin)}
                        >
                          Change
                        </div>
                      </div>
                    ))}
            </Scrollbars>
            <div className="fedralReserve">
              <div className="head">
                <div className="title">Federal Reserve</div>
                <div className="subTitle">
                  Updating Liquid Interest Rates For INR
                </div>
              </div>
              <div className="group">
                <div className="label">
                  Current Interest Rate
                  <div className="btnSwitch">
                    <span
                      className={`${isDaily}`}
                      onClick={() => setIsDaily(true)}
                    >
                      Daily
                    </span>
                    &nbsp;|&nbsp;
                    <span
                      className={`${!isDaily}`}
                      onClick={() => setIsDaily(false)}
                    >
                      Annual
                    </span>
                  </div>
                </div>
                <input
                  type="text"
                  readOnly
                  value={FormatNumber(
                    coinToChange.interest_rate * (isDaily ? 1 : 365),
                    isDaily ? 4 : 2
                  )}
                  placeholder="0.00%"
                />
              </div>
              <div className="group">
                <div className="label">Interest Rate Change</div>
                <input type="text" placeholder="0.00%" />
              </div>
              <div className="group">
                <div className="label">New Interest Rate</div>
                <input
                  type="text"
                  placeholder="0.00%"
                  value={newRate}
                  onChange={changeNewRate}
                />
              </div>
              <div className="btnDeclareRate" onClick={declareNewRates}>
                Declare New Rates
              </div>
            </div>
          </div>
        );
      case "Bonds":
        return <AssetFedBonds search={search} />;
      default:
        break;
    }
  }

  return (
    <div className="assetIoFed">
      <AssetLiquidity
        liquidity={liquidity}
        setLiquidity={setLiquidity}
        search={search}
        setSearch={setSearch}
      />
      {loading ? (
        <div className="wrapperContent">
          <LoadingAnim />
        </div>
      ) : (
        renderContent()
      )}
    </div>
  );
}

export default AssetsIoFed;
