import React from "react";

function WebsiteInput({
  website,
  setWebsite,
  placeholder = "Enter Website Here",
}) {
  return (
    <div className="inputWrap">
      <div className="btnCheck left">https://</div>
      <input
        type="text"
        className="text"
        placeholder={placeholder}
        value={website?.slice().replace("https://", "")}
        onChange={(e) => setWebsite(`https://${e.target.value}`)}
      />
    </div>
  );
}

export default WebsiteInput;
