import React from "react";
import { IconsViewSwitcher } from "../BrokerDashboard/index";

function IconsViewSwitchers({
  listIcn,
  boardIcn,
  searchIcn,
  list,
  board,
  search,
  onSearchClick,
  onBoardClick,
  onListClick,
}) {
  return (
    <IconsViewSwitcher
      listIcn={listIcn}
      boardIcn={boardIcn}
      searchIcn={searchIcn}
      list={list}
      board={board}
      search={search}
      onSearchClick={onSearchClick}
      onBoardClick={onBoardClick}
      onListClick={onListClick}
    />
  );
}

export default IconsViewSwitchers;
