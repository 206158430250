import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

function CategoryItem({
  setCategoryType,
  category,
  categoryType,
  onExpand,
  onDelete,
  OnEdit,
  onCopy,
}) {
  const [moreDropOpen, setMoreDropOpen] = useState(false);
  return (
    <div
      className={`catItem ${categoryType.includes(category._id)}`}
      onClick={(e) => {
        if (e.ctrlKey) {
          setCategoryType((cat) => [...cat, category._id]);
        } else {
          setCategoryType([category._id]);
        }
      }}
    >
      <img src={category.thumbnail} alt="" />
      <div className="catTexts">
        <div className="name">{category.title}</div>
        <div className="desc">{category.categoryType}</div>
      </div>
      <div
        className="btnAction more"
        onMouseEnter={() => setMoreDropOpen(true)}
        onMouseLeave={() => {
          setTimeout(() => {
            setMoreDropOpen(false);
          }, 100);
        }}
        onClick={() => setMoreDropOpen(!moreDropOpen)}
      >
        More
        <FontAwesomeIcon icon={faCaretDown} />
        {moreDropOpen && (
          <div className="dropList">
            <div
              className="dropItm"
              onClick={() => {
                try {
                  onExpand();
                } catch (error) {}
              }}
            >
              Expand
            </div>
            <div
              className="dropItm"
              onClick={() => {
                try {
                  onDelete(category);
                } catch (error) {}
              }}
            >
              Delete
            </div>
            <div
              className="dropItm"
              onClick={() => {
                try {
                  OnEdit(category);
                } catch (error) {}
              }}
            >
              Edit
            </div>
            <div
              className="dropItm"
              onClick={() => {
                try {
                  onCopy();
                } catch (error) {}
              }}
            >
              Copy
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CategoryItem;
