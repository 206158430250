import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";

function CountryList({ setCountry, onClose }) {
  const [countries, setCountries] = useState([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setCountriesLoading(true);
    axios
      .get(
        "https://storeapi.apimachine.com/dynamic/InstaCryptoPurchase/Countrydem?key=b6459026-2535-434e-bc4c-893fae5fc87d"
      )
      .then(({ data }) => {
        setCountries(data?.data);
      })
      .finally(() => setCountriesLoading(false));
  }, []);

  return (
    <Fragment>
      <div className="titleOp">Select Country</div>
      <div className="searchWrap">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Country....|"
        />
      </div>
      <Scrollbars className="searchList">
        {countriesLoading
          ? Array(6)
              .fill("")
              .map((_, i) => (
                <div className="user" key={i}>
                  <Skeleton className="dp" circle />
                  <div className="userDetail">
                    <Skeleton className="name" width={200} />
                  </div>
                </div>
              ))
          : countries
              .filter((country) =>
                country?.formData?.Name?.toLowerCase().includes(
                  search.toLowerCase()
                )
              )
              .map((country) => (
                <div
                  className="user"
                  key={country.Key}
                  onClick={() => {
                    setCountry(country.formData);
                    onClose();
                  }}
                >
                  <img className="dp" src={country?.formData?.Flag} alt="" />
                  <div className="userDetail">
                    <div className="name">{country?.formData?.Name}</div>
                  </div>
                </div>
              ))}
        <div className="space"></div>
      </Scrollbars>
    </Fragment>
  );
}

export default CountryList;
