import React, { useState } from "react";
import AssetsIoFed from "../components/AssetsIoFed/AssetsIoFed";
import MainLayout from "../layouts/MainLayout";
import Navbars from "../layouts/Navbar/Navbars";
import assetsLogoFull from "../static/images/logos/assetsLogoFull.svg";

const contentsList = {
  "Back End": ["Overview", "Fed"],
};

function AssetsIo({ hide }) {
  const [dropdownSelected, setDropdownSelected] = useState("");
  const [tabSelected, setTabSelected] = useState("");
  const contents = {
    Fed: <AssetsIoFed />,
  };

  console.log(dropdownSelected); //To Remove

  const logoNtext = (
    <div className="navLogoText">
      <img src={assetsLogoFull} className="navLogo" alt="" />
    </div>
  );

  return (
    <>
      <MainLayout active="Assets.io" hide={hide}>
        <Navbars
          logoNtext={logoNtext}
          contents={contentsList}
          onChangeDropdown={(drop) => {
            setDropdownSelected(drop);
          }}
          onChangeTab={(tab) => {
            setTabSelected(tab);
          }}
        />
        {contents[tabSelected]}
      </MainLayout>
    </>
  );
}

export default AssetsIo;
