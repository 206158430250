import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../context/MainContext";
import { FormatNumber } from "../../utils/FunctionTools";
import CreateBond from "./CreateBond";

function AssetFedBondsSidebar({ coinToChange, getRates }) {
  const { email, token, tostShowOn } = useContext(MainContext);
  const [isPrimary, setIsPrimary] = useState(true);
  const [coinData, setCoinData] = useState({});
  const [updateObj, setUpdateObj] = useState(false);
  const [baseLow, setBaseLow] = useState(false);
  function getBondData() {
    Axios.get(
      `https://comms.globalxchange.io/coin/iced/admin/get/data?coin=${coinToChange.coinSymbol}`
    ).then(({ data }) => {
      if (data.status) {
        let config = {};
        if (Array.isArray(data.config_data) && data.config_data.length) {
          data.config_data.forEach((con) => {
            config = { ...config, ...con };
          });
          setCoinData(config);
        } else {
          setCoinData({});
        }
      }
    });
  }
  useEffect(() => {
    getBondData();
  }, [coinToChange]);
  function updateRates(key) {
    if (key === "fee") {
      configureFee();
    } else {
      Axios.post(
        "https://comms.globalxchange.io/coin/iced/admin/contract/interest/rate/compressor",
        {
          email: email,
          token: token,
          coin: coinToChange.coinSymbol,
          base_compression_rate: parseFloat(coinData.base_compression_rate),
          base_velocity: parseFloat(coinData.base_velocity),
          acceleration: parseFloat(coinData.acceleration),
          base_interest_rate: parseFloat(coinData.base_interest_rate),
          longevity_bonus_rate: parseFloat(coinData.longevity_bonus_rate),
        }
      )
        .then(({ data }) => {
          tostShowOn(data?.message);
          if (data.status) {
            try {
              getRates();
            } catch (error) {}
          }
        })
        .finally(() => setUpdateObj(false));
    }
  }
  function configureFee() {
    Axios.post(
      "https://comms.globalxchange.io/coin/iced/admin/contract/daily/fee/set",
      {
        email: email,
        token: token,
        coin: coinToChange.coinSymbol,
        fee: parseFloat(coinData.fee),
      }
    ).then(({ data }) => {
      tostShowOn(data.message);
      if (data.status) {
        try {
          getRates();
        } catch (error) {}
      }
    });
  }

  return (
    <div className="assetFedBondSidebar">
      <div className="head">
        <img src={coinToChange.coinImage} alt="" className="coinImage" />
        <div className="texts">
          <span className="title">{coinToChange.coinName}</span>
          <span className="sub">Central Bank</span>
        </div>
        <div
          className="btnStandard"
          onClick={() => setIsPrimary((val) => !val)}
        >
          {isPrimary ? "Standard" : "Secondary"}
        </div>
      </div>
      <div className="tabs">
        <div className="tab true">Simulate</div>
        <div className="tab">Structure</div>
        <div className="tab">Stats</div>
        <div className="tab">Other</div>
      </div>
      {updateObj ? (
        <div className="sidebarContent">
          <FontAwesomeIcon
            icon={faTimes}
            className="closeBtn"
            onClick={() => setUpdateObj(false)}
          />
          <div className="colTitle">
            <div className="title">Update {updateObj?.label}</div>
            <div className="subTitle">{coinToChange?.coinName} Bond</div>
          </div>
          <div className="colGroup">
            <div className="rowLabel">
              <span className="label">Current</span>
            </div>
            <div className="cntRow">
              <div className="boxWrap">
                <input
                  type="text"
                  placeholder="0.00"
                  readOnly
                  defaultValue={coinData[updateObj?.key]}
                />
                <div className="percent">%</div>
              </div>
            </div>
          </div>
          <div className="colGroup">
            <div className="rowLabel">
              <span className="label">New</span>
            </div>
            <div className="cntRow">
              <div className="boxWrap">
                <input
                  type="text"
                  placeholder="0.00"
                  value={coinData[updateObj?.key]}
                  onChange={(e) =>
                    setCoinData({
                      ...coinData,
                      [updateObj.key]: e.target.value,
                    })
                  }
                />
                <div className="percent">%</div>
              </div>
            </div>
          </div>
          <div className="cntRow">
            <div className="boxWrap simulate">
              <div className="value" onClick={() => updateRates(updateObj.key)}>
                Update
              </div>
            </div>
          </div>
        </div>
      ) : Object.keys(coinData).length === 0 &&
        coinData.constructor === Object ? (
        <CreateBond
          coinToChange={coinToChange}
          getRates={getRates}
          getBondData={getBondData}
        />
      ) : isPrimary ? (
        <div className="sidebarContent">
          <div className="cntRow">
            <div className="boxWrap">
              <input type="text" placeholder="0.00" />
              <div className="label">Days</div>
            </div>
            <div className="boxWrap">
              <input type="text" placeholder="0.00" />
              <div className="label">Bonds</div>
            </div>
          </div>
          <div className="rowLabel">
            <span className="label">
              {baseLow ? "Compression Rate Low" : "Compression Rate"}
              <span
                className="edit"
                onClick={() =>
                  setUpdateObj(
                    baseLow
                      ? {
                          key: "base_rateBTX_lower",
                          label: "Compression Rate Low",
                        }
                      : {
                          key: "base_compression_rate",
                          label: "Compression Rate",
                        }
                  )
                }
              >
                Edit
              </span>
            </span>
            <span className="label">
              Broker Fees
              <span
                className="edit"
                onClick={() =>
                  setUpdateObj({
                    key: "fee",
                    label: "Broker Fees",
                  })
                }
              >
                Edit
              </span>
            </span>
          </div>
          <div className="cntRow">
            <div className="boxWrap">
              <div
                className={`value ${!baseLow}`}
                onClick={() => setBaseLow(false)}
              >
                {FormatNumber(coinData.base_compression_rate, 2)}%
              </div>
              <div
                className={`value ${baseLow}`}
                onClick={() => setBaseLow(true)}
              >
                {FormatNumber(coinData.base_rateBTX_lower, 2)}%
              </div>
            </div>
            <div className="boxWrap">
              <div className="value">{FormatNumber(coinData.fee, 2)}%</div>
            </div>
          </div>
          <div className="rowLabel">
            <span className="label">
              Base Velocity
              <span
                className="edit"
                onClick={() =>
                  setUpdateObj({
                    key: "base_velocity",
                    label: "Base Velocity",
                  })
                }
              >
                Edit
              </span>
            </span>
            <span className="label">Select App</span>
          </div>
          <div className="cntRow">
            <div className="boxWrap">
              <div className="value">
                {FormatNumber(coinData.base_velocity, 2)}%
              </div>
            </div>
            <div className="boxWrap">
              <div className="value">Neutral</div>
            </div>
          </div>
          <div className="rowLabel">
            <span className="label">
              Acceleration
              <span
                className="edit"
                onClick={() =>
                  setUpdateObj({
                    key: "acceleration",
                    label: "Acceleration",
                  })
                }
              >
                Edit
              </span>
            </span>
          </div>
          <div className="cntRow">
            <div className="boxWrap">
              <div className="value">
                {FormatNumber(coinData.acceleration, 2)}
              </div>
            </div>
            <div className="boxWrap simulate">
              <div className="value">Simulate</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="sidebarContent">
          <div className="cntRow">
            <div className="boxWrap">
              <input type="text" placeholder="0.00" />
              <div className="label">Days</div>
            </div>
            <div className="boxWrap">
              <input type="text" placeholder="0.00" />
              <div className="label">Bonds</div>
            </div>
          </div>
          <div className="colGroup">
            <div className="rowLabel">
              <span className="label">
                Base Interest Rate
                <span
                  className="edit"
                  onClick={() =>
                    setUpdateObj({
                      key: "base_interest_rate",
                      label: "Base Interest Rate",
                    })
                  }
                >
                  Edit
                </span>
              </span>
              <span className="label">
                Longevity Bonus Rate
                <span
                  className="edit"
                  onClick={() =>
                    setUpdateObj({
                      key: "longevity_bonus_rate",
                      label: "Longevity Bonus Rate",
                    })
                  }
                >
                  Edit
                </span>
              </span>
            </div>
            <div className="cntRow">
              <div className="boxWrap">
                <div className="value">
                  {FormatNumber(coinData.base_interest_rate, 2)}%
                </div>
              </div>
              <div className="boxWrap">
                <div className="value">
                  {FormatNumber(coinData.longevity_bonus_rate, 2)}%
                </div>
              </div>
            </div>
          </div>
          <div className="cntRow">
            <div className="boxWrap simulate">
              <div className="value">Simulate</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AssetFedBondsSidebar;
