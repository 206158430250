import React, { useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { MoreTabContext } from "../../context/MoreTabContext";
import TerminalAppsSidebar from "./TerminalAppsSidebar";
import TerminalItem from "./TerminalItem";

function TerminalAppList({ list, setList }) {
  const [terminal, setTerminal] = useState();
  const { terminalapplist, terminalapplistLoading } =
    useContext(MoreTabContext);
  //
  useEffect(() => {
    if (terminal) setList(true);
    else setList(false);
  }, [terminal, setList]);

  useEffect(() => {
    if (!list) setTerminal();
  }, [list]);
  return (
    <>
      <div className="godsEyeMoreList">
        <Scrollbars className="moreListWrapper">
          {terminalapplistLoading
            ? Array(6)
                .fill("")
                .map((_, i) => (
                  <div className="listItem">
                    <Skeleton alt="" className="icon" />
                    <Skeleton className="name" width={400} />
                    <Skeleton className="count" width={30} />
                  </div>
                ))
            : terminalapplist.map((ter) => (
                <TerminalItem
                  key={ter._id}
                  terminal={ter}
                  selected={terminal}
                  onClick={() => setTerminal(ter)}
                />
              ))}
        </Scrollbars>
      </div>
      {terminal && (
        <TerminalAppsSidebar
          onClose={() => setTerminal()}
          terminal={terminal}
        />
      )}
    </>
  );
}

export default TerminalAppList;
