import { faEllipsisV, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import classNames from "./blockChainTabContent.module.scss";
import { useBlockChainData, useErcCoinsList } from "../../queryHooks";
import { FormatCurrency } from "../../utils/FunctionTools";
import BlockUserItem from "./BlockUserItem";
import Skeleton from "react-loading-skeleton";

function BlockChainTabContent() {
  const { data: ercCoins } = useErcCoinsList();
  const { data: allCrypto } = useErcCoinsList(true);
  const [coinSelected, setCoinSelected] = useState({});
  const { isLoading: blockDataLoading, data: blockChainData } =
    useBlockChainData(coinSelected?.coinSymbol);
  const [filterOpen, setFilterOpen] = useState(false);
  const [ethCoin, setEthCoin] = useState({});
  useEffect(() => {
    const eth =
      allCrypto?.filter((coin) => coin?.coinSymbol === "ETH")[0] || {};
    setEthCoin(eth);
    if (!coinSelected?.coinSymbol) setCoinSelected(eth);
  }, [allCrypto, coinSelected.coinSymbol]);
  const [search, setSearch] = useState("");
  return (
    <div className={classNames.blockChainTabContent}>
      <Scrollbars className={classNames.coinList}>
        <div
          className={`${classNames.coin} ${
            classNames[ethCoin === coinSelected]
          }`}
          onClick={() => setCoinSelected(ethCoin)}
        >
          <img src={ethCoin?.coinImage} alt="" />
          <span>{ethCoin?.coinName}</span>
        </div>
        {ercCoins?.map((coin) => (
          <div
            key={coin.coinSymbol}
            className={`${classNames.coin} ${
              classNames[coin === coinSelected]
            }`}
            onClick={() => setCoinSelected(coin)}
          >
            <img src={coin?.coinImage} alt="" />
            <span>{coin?.coinName}</span>
          </div>
        ))}
      </Scrollbars>
      <div className={classNames.coinDetail}>
        <div className={classNames.listHeadData}>
          <div className={classNames.userCounts}>
            {blockChainData?.usersCount || 0} Users
          </div>
          <div className={classNames.assetsOnNetwork}>Assets On Network</div>
          <div className={classNames.assetsAmounts}>
            <div className={classNames.asset}>
              {FormatCurrency(
                blockChainData?.total_network_amount,
                coinSelected?.coinSymbol
              )}
              <div className={classNames.coin}>
                <img src={coinSelected?.coinImage} alt="" />
                <span>{coinSelected?.coinSymbol}</span>
              </div>
            </div>
            <div className={classNames.asset}>
              $
              {FormatCurrency(
                blockChainData?.total_network_amount * coinSelected?.usd_price,
                "USD"
              )}
              <div className={classNames.coin}>
                <img
                  src="https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/dollar.png"
                  alt=""
                />
                <span>USD</span>
              </div>
            </div>
          </div>
          <input
            type="text"
            className={classNames.search}
            placeholder="Fitler Users..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div
            className={classNames.btnOpenClose}
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <FontAwesomeIcon icon={filterOpen ? faTimes : faEllipsisV} />
          </div>
        </div>
        {filterOpen && (
          <div className={classNames.filterRow}>
            <div className={classNames.label}>App</div>
            <div className={classNames.value}>All Apps</div>
            <div className={classNames.label}>Query User</div>
            <div className={classNames.value}>All Users</div>
          </div>
        )}
        <Scrollbars className={classNames.userList}>
          {blockDataLoading
            ? Array(10)
                .fill("")
                .map(() => (
                  <div className={classNames.userItem}>
                    <div className={classNames.user}>
                      <Skeleton circle className={classNames.profileImg} />
                      <div className={classNames.nameEmail}>
                        <Skeleton className={classNames.name} width={300} />
                        <Skeleton className={classNames.email} width={300} />
                      </div>
                    </div>
                    <div
                      className={classNames.app}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Skeleton className={classNames.appIcon} />
                      <Skeleton className={classNames.appName} />
                    </div>
                    <div className={classNames.addressWrap}>
                      <Skeleton className={classNames.address} />
                    </div>
                    <div
                      className={classNames.assetsAmounts}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Skeleton className={classNames.asset} />
                      <Skeleton className={classNames.asset} />
                    </div>
                  </div>
                ))
            : blockChainData?.result
                ?.filter(
                  (user) =>
                    user?.email?.toLowerCase().includes(search.toLowerCase()) ||
                    user?.name?.toLowerCase().includes(search.toLowerCase())
                )
                .map((user) => (
                  <BlockUserItem
                    user={user}
                    coin={coinSelected}
                    key={`${user.email}-${user.app_code}`}
                  />
                ))}
        </Scrollbars>
      </div>
    </div>
  );
}

export default BlockChainTabContent;
