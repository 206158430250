import React, { createContext, useEffect, useState } from "react";
import Axios from "axios";
import { ToastMessage } from "../components/BrokerDashboard/index";
import vaultIcon from "../static/images/clipIcons/vaults.svg";
export const MainContext = createContext();

function MainContextProvider({ children }) {
  const [refreshPage, setRefreshPage] = useState("");
  const [email, setEmail] = useState(
    localStorage.getItem("LoginAccount") || ""
  );
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("AccessToken") || ""
  );
  const [token, setToken] = useState(localStorage.getItem("Token") || "");

  useEffect(() => {
    localStorage.setItem("LoginAccount", email);
  }, [email]);
  useEffect(() => {
    localStorage.setItem("AccessToken", accessToken);
  }, [accessToken]);
  useEffect(() => {
    localStorage.setItem("Token", token);
  }, [token]);

  const login = (paramEmail, paramAccessToken, paramToken) => {
    setEmail(paramEmail);
    setAccessToken(paramAccessToken);
    setToken(paramToken);
  };

  const [profilePic, setProfilePic] = useState("");
  const [profileName, setProfileName] = useState("");
  function validateToken() {
    Axios.post("https://comms.globalxchange.io/coin/verifyToken", {
      email,
      token: token,
    }).then((res) => (res.data.status ? "" : login("", "", "")));
  }
  useEffect(() => {
    if (email && token) {
      validateToken();
      Axios.get(
        `https://comms.globalxchange.io/user/details/get?email=${email}`
      ).then((res) => {
        const { data } = res;
        if (data.status) {
          setProfileName(data.user.name);
          setProfilePic(data.user.profile_img);
        }
      });
    }
  }, [email, token]);

  const [rates, setRates] = useState({});
  useEffect(() => {
    Axios.get("https://comms.globalxchange.io/coin/getCmcPrices").then(
      (res) => {
        const { data } = res;
        setRates({ ...data, USD: 1 });
      }
    );
  }, []);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [subMessage, setSubMessage] = useState("");
  const [icon, setIcon] = useState(vaultIcon);
  function tostShowOn(message, submessage, icon) {
    setShow(true);
    setMessage(message);
    setSubMessage(submessage);
    setIcon(icon || vaultIcon);
    setTimeout(() => {
      setShow(false);
      setMessage("");
      setSubMessage("");
      setIcon("");
    }, 2000);
  }
  const [allApps, setAllApps] = useState();
  const [appList, setAppList] = useState([]);
  function getApps() {
    Axios.get("https://comms.globalxchange.io/gxb/apps/get").then(
      ({ data }) => {
        if (data.status) {
          setAppList(data.apps);
          let apps = {};
          data.apps.forEach((app) => {
            apps[app.app_code] = app;
          });
          setAllApps(apps);
        }
      }
    );
  }
  useEffect(() => {
    getApps();
  }, []);

  function copyToClipboard(value, title) {
    navigator.clipboard.writeText(value).then(() => {
      tostShowOn(value, title);
    });
  }

  return (
    <MainContext.Provider
      value={{
        login,
        email,
        token,
        profilePic,
        profileName,
        rates,
        tostShowOn,
        refreshPage,
        setRefreshPage,
        validateToken,
        allApps,
        appList,
        getApps,
        copyToClipboard,
      }}
    >
      {children}
      {show && (
        <ToastMessage icon={icon} textOne={subMessage} textTwo={message} />
      )}
    </MainContext.Provider>
  );
}

export default MainContextProvider;
