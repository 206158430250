import React, { useState, useEffect, useContext } from "react";
import { TabsContext } from "../../context/TabsContext";
// import NavTabs from "../NavTabs/NavTabs";

function NavbarGodsEye({
  tabs,
  tabSelected,
  setTabSelected,
  dropDownOpen,
  search,
  setSearch,
  button,
  viewSwitcher,
  tabClick,
  tabLastGroup,
  tabIcn,
}) {
  const { navTabs } = useContext(TabsContext);

  return (
    <nav>
      <div className="bets-dash-navbar">
        {/* <NavTabs /> */}
        {navTabs}
        {button}
      </div>
      <div className="nav-bottom">
        <div className={`tab ${tabLastGroup}`}>
          {tabs.map((tabItm, i) => (
            <div
              key={`${tabItm}${i}`}
              className={"tab-itm " + (tabSelected === tabItm)}
              onClick={() => {
                setTabSelected(tabItm);
                tabClick(tabItm);
              }}
            >
              {tabLastGroup && i === tabs?.length - 1 && tabIcn && (
                <img src={tabIcn} alt="" />
              )}
              <h6>{tabItm}</h6>
            </div>
          ))}
        </div>
        {viewSwitcher}
        {dropDownOpen && (
          <input
            type="text"
            className="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Apps.."
          />
        )}
      </div>
    </nav>
  );
}

export default NavbarGodsEye;
