import React, { useContext, useEffect, useState } from "react";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import vaultlogo from "../../static/images/logos/civilisationFullLogo.svg";
import ReactJson from "react-json-view";
import { MainContext } from "../../context/MainContext";

function CopyPublicationDetailModal({ publication, onClose }) {
  const { tostShowOn } = useContext(MainContext);
  const [dropOpen, setDropOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState("name");
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (publication.name) {
      setSelectedKey("name");
    } else if (publication.bankerTag) {
      setSelectedKey("bankerTag");
    } else {
      setSelectedKey(Object.keys(publication)[0]);
    }
  }, []);
  const [showDetail, setShowDetail] = useState(false);
  return (
    <div className="copyPubModal">
      <div
        className="overlay"
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <div className="modalCopy">
        <div className="head">
          <img src={vaultlogo} alt="" />
        </div>
        <div className="copyContent">
          {showDetail ? (
            <ReactJson src={publication} />
          ) : (
            <>
              <div className="cpLabel">Select The Field You Want To See</div>
              <div className="btnDrop">
                <label className="btnDropdown">
                  <div
                    className="backDrop"
                    onClick={() => setDropOpen(!dropOpen)}
                  />
                  {dropOpen ? (
                    <input
                      type="text"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  ) : (
                    <span onClick={() => setDropOpen(!dropOpen)}>
                      {selectedKey?.slice().replace(/_/g, " ")}
                    </span>
                  )}
                  <FontAwesomeIcon
                    icon={dropOpen ? faCaretUp : faCaretDown}
                    onClick={() => setDropOpen(!dropOpen)}
                  />
                  {dropOpen && (
                    <div
                      className="dropList"
                      onClick={() => setDropOpen(!dropOpen)}
                    >
                      {Object.keys(publication)
                        .filter(
                          (key) =>
                            key
                              .slice()
                              .replace(/_/g, " ")
                              .toLowerCase()
                              .includes(search.toLowerCase()) ||
                            key.toLowerCase().includes(search.toLowerCase())
                        )
                        .map((key) => (
                          <div
                            className="dropItem"
                            onClick={() => setSelectedKey(key)}
                          >
                            {key.slice().replace(/_/g, " ")}
                          </div>
                        ))}
                    </div>
                  )}
                </label>
                <div
                  className="btnShow"
                  onClick={() => {
                    navigator.clipboard
                      .writeText(JSON.stringify(publication))
                      .then(() => {
                        tostShowOn(`All Values copied to clipboard`);
                      });
                    setShowDetail(true);
                  }}
                >
                  Show All
                </div>
              </div>
              <div className="res">
                <span className="resVal">
                  {typeof (publication && publication[selectedKey]) === "object"
                    ? JSON.stringify(publication && publication[selectedKey])
                    : publication && publication[selectedKey]}
                </span>
                <FontAwesomeIcon
                  className="icon"
                  icon={faCopy}
                  onClick={() => {
                    navigator.clipboard
                      .writeText(
                        typeof (publication && publication[selectedKey]) ===
                          "object"
                          ? JSON.stringify(
                              publication && publication[selectedKey]
                            )
                          : publication && publication[selectedKey]
                      )
                      .then(() => {
                        tostShowOn(
                          `Value of ${selectedKey} copied to clipboard`
                        );
                      });
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CopyPublicationDetailModal;
