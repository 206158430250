import { SearchAndFilter } from "../BrokerDashboard/index";
import React, { useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { MoreTabContext } from "../../context/MoreTabContext";
import IndustryItem from "./IndustryItem";
import IndustryListSidebar from "./IndustryListSidebar";

function IndustryList({ list, setList, searchOn }) {
  const [industry, setIndustry] = useState();
  const { industryList, industryListLoading } = useContext(MoreTabContext);
  //
  useEffect(() => {
    if (industry) setList(true);
    else setList(false);
  }, [industry, setList]);

  useEffect(() => {
    if (!list) setIndustry();
  }, [list]);

  // Searc Vars

  const [search, setSearch] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const [filteIndustryName, setFilteIndustryName] = useState("");

  return (
    <>
      {
        <div className="godsEyeMoreList">
          {searchOn && (
            <SearchAndFilter
              search={search}
              setSearch={setSearch}
              placeholder={"Search Industries"}
              filterBy={`${"Name Of Industry"}`}
              mainList={[
                {
                  key: "0",
                  label: "Function Performed By Search Bar",
                  switch: isFilter,
                  switchLabel: isFilter ? "Filter" : "Search",
                  switchClick: () => setIsFilter(!isFilter),
                },
                {
                  key: "1",
                  label: "Filter Range",
                  switch: currentPage,
                  switchLabel: currentPage ? "Current Page" : "Entire List",
                  switchClick: () => setCurrentPage(!currentPage),
                },
              ]}
              filterList={[
                {
                  key: "11",
                  label: "Name Of Market",
                  switch: filteIndustryName,
                  switchLabel: filteIndustryName ? "On" : "Off",
                  switchClick: () => setFilteIndustryName(!filteIndustryName),
                },
              ]}
            />
          )}
          <Scrollbars className={`moreListWrapper ${Boolean(industry)}`}>
            {industryListLoading
              ? Array(6)
                  .fill("")
                  .map((_, i) => (
                    <div className="listItem">
                      <Skeleton alt="" className="icon" />
                      <Skeleton className="name" width={400} />
                      <Skeleton className="count" width={30} />
                    </div>
                  ))
              : industryList
                  .filter((data) =>
                    data.name.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((data) => (
                    <IndustryItem
                      key={data._id}
                      industry={data}
                      onClick={() => setIndustry(data)}
                      selected={industry}
                    />
                  ))}
          </Scrollbars>
        </div>
      }
      {industry && (
        <IndustryListSidebar
          onClose={() => setIndustry()}
          industry={industry}
        />
      )}
    </>
  );
}

export default IndustryList;
