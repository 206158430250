import Axios from "axios";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars";
import { MainContext } from "../../context/MainContext";
import pathIcn from "../../static/images/clipIcons/pathData.svg";
import editApp from "../../static/images/clipIcons/appData.svg";
import deleteApp from "../../static/images/refreshIcon/delete.svg";
import yesIcn from "../../static/images/clipIcons/yes.svg";
import noIcn from "../../static/images/clipIcons/no.svg";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import back from "../../static/images/back.svg";
import close from "../../static/images/close.svg";

function FounderListSidebar({ founderSelected, onClose, inTab, setInTab }) {
  const history = useHistory();
  const { token, tostShowOn } = useContext(MainContext);
  const [step, setStep] = useState("");
  const [tab, setTab] = useState("Actions");
  const [loading, setLoading] = useState(false);
  function deleteBanker() {
    setLoading(true);
    Axios.post(
      "https://comms.globalxchange.io/gxb/app/gxlive/user/operator/delete", // additional context
      {
        email: founderSelected?.email,
        token,
        operator_id: founderSelected?.operator_id,
      }
    )
      .then(({ data }) => {
        if (data.status) {
          setStep("deleteSucces");
        } else {
          tostShowOn(data.message || "Something Went Wrong");
        }
      })
      .finally(() => setLoading(false));
  }
  function getContent() {
    switch (true) {
      case tab === "Actions" && step === "Delete":
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm">
                Are You Sure You Want To Delete {founderSelected?.displayName}?
              </div>
              <Scrollbars className="coinList">
                <div className="coinItem" onClick={() => deleteBanker()}>
                  <img src={yesIcn} alt="" className="coin" />
                  <span>Confirm</span>
                </div>
                <div className="coinItem" onClick={() => setStep("")}>
                  <img src={noIcn} alt="" className="coin" />
                  <span>No</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );
      case tab === "Actions" && step === "deleteSucces":
        setTimeout(() => {
          history.push("/society/Founders");
          window.location.reload();
        }, 2000);
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm m-auto">
                You Have Successfully Deleted Operator{" "}
                {founderSelected?.displayName}. You Will Be Redirected To The
                Operator List Right Now
              </div>
            </div>
          </>
        );
      case tab === "Actions":
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm">
                Select One Of The Following Actions
              </div>
              <Scrollbars className="coinList">
                <div className="coinItem disable" onClick={() => {}}>
                  <img src={editApp} alt="" className="coin" />
                  <span>Edit Founder Profile</span>
                </div>
                <div className="coinItem disable" onClick={() => {}}>
                  <img src={pathIcn} alt="" className="coin" />
                  <span>See Founders Apps</span>
                </div>
                <div className="coinItem" onClick={() => setStep("Delete")}>
                  <img src={deleteApp} alt="" className="coin" />
                  <span>Delete Banker</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );
      default:
    }
  }
  return (
    <div className="godsEyeUserFiter">
      <div className="headTabBox">
        <div
          className={`tab ${tab === "About"}`}
          onClick={() => {
            setTab("About");
            setStep("");
          }}
        >
          About
        </div>
        <div
          className={`tab ${tab === "Actions"}`}
          onClick={() => {
            setTab("Actions");
            setStep("");
          }}
        >
          Actions
        </div>
      </div>
      <div className="header">
        <div className="content">
          <div className="title">
            <img src={founderSelected?.brand_logo} alt="" className="icon" />
            {founderSelected.brand_name}
          </div>
          <div className="breadCrumbs">
            <span
              onClick={() => {
                setStep("");
              }}
            >
              {tab === "Actions"
                ? `${founderSelected.brand_name} Actions`
                : `About ${founderSelected.brand_name}`}
            </span>{" "}
            {step ? (
              <>
                -&gt;&nbsp;
                <span>{step}</span>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        {step ? (
          <div
            className="backBtn"
            onClick={() => {
              setStep("");
            }}
          >
            <img src={back} alt="" />
          </div>
        ) : (
          ""
        )}
        <div
          className="backBtn"
          onClick={() => {
            onClose();
          }}
        >
          <img src={close} alt="" />
        </div>
      </div>
      {getContent()}
      {loading && <LoadingAnim />}
    </div>
  );
}

export default FounderListSidebar;
