import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import JsonWebToken from "jsonwebtoken";
import Scrollbars from "react-custom-scrollbars";
import { useHistory } from "react-router";
import cloudUploadIcon from "../../static/images/postClipArt/cloudUpload.svg";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import { MainContext } from "../../context/MainContext";
import loadingGif from "../../static/animations/loading.gif";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

function EditIndustry({ industry }) {
  const history = useHistory();
  const { tostShowOn, email, token } = useContext(MainContext);
  const [step, setStep] = useState("");
  const [loading, setLoading] = useState(false);

  // Form Variables
  const [icon, setIcon] = useState("");
  const [logo, setLogo] = useState("");
  const [whiteIcon, setWhiteIcon] = useState("");
  const [whiteLogo, setWhiteLogo] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");
  const [primaryColor, setPrimaryColor] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");

  const [iconLoading, setIconLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [whiteIconLoading, setWhiteIconLoading] = useState(false);
  const [whiteLogoLoading, setWhiteLogoLoading] = useState(false);
  const [coverPhotoLoading, setCoverPhotoLoading] = useState(false);

  useEffect(() => {
    setName(industry.name);
    setIcon(industry.icon);
    setCoverPhoto(industry?.other_data?.coverphoto);
    setDesc(industry.description);
    setLogo(industry.other_data?.colourlogo);
    setWhiteIcon(industry.other_data?.whiteicon);
    setWhiteLogo(industry.other_data?.whitelogo);
    setPrimaryColor(industry?.color_code);
  }, [industry]);

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
    setLoading(false);
  };

  function getContent() {
    switch (step) {
      case "updateSucces":
        setTimeout(() => {
          history.push("/commerce/Industries");
          window.location.reload();
        }, 2000);
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm m-auto">
                You Have Successfully Updated {industry.name}. You Will Be
                Redirected To The Updated Industry List Automatically
              </div>
            </div>
          </>
        );
      default:
        return (
          <Scrollbars
            className="scrollForm"
            renderTrackHorizontal={() => <div />}
            renderThumbHorizontal={() => <div />}
            renderTrackVertical={() => <div />}
            renderThumbVertical={() => <div />}
          >
            <div className="name">Industry Name</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="name">Describe The Industry</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Description Here"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
            </div>
            <div className="name">Upload Branding Material</div>
            <div className="filesUpload">
              <label className="fileInp icon">
                <img
                  className={`${Boolean(icon)}`}
                  src={iconLoading ? loadingGif : icon || cloudUploadIcon}
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setIcon, setIconLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">Colored Icon</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
              <label className="fileInp icon">
                <img
                  className={`white ${Boolean(whiteIcon)}`}
                  src={
                    whiteIconLoading ? loadingGif : whiteIcon || cloudUploadIcon
                  }
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setWhiteIcon, setWhiteIconLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">White Icon</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
              <label className="fileInp cover">
                <img
                  className={`${Boolean(coverPhoto)}`}
                  src={
                    coverPhotoLoading
                      ? loadingGif
                      : coverPhoto || cloudUploadIcon
                  }
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setCoverPhoto, setCoverPhotoLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">Cover Photo</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
            </div>
            <div className="filesUpload last">
              <label className="fileInp fullLogo">
                <img
                  className={`${Boolean(logo)}`}
                  src={logoLoading ? loadingGif : logo || cloudUploadIcon}
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setLogo, setLogoLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">Colored Logo</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
              <label className="fileInp fullLogo">
                <img
                  className={`white ${Boolean(whiteLogo)}`}
                  src={
                    whiteLogoLoading ? loadingGif : whiteLogo || cloudUploadIcon
                  }
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setWhiteLogo, setWhiteLogoLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">White Logo</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
            </div>
            <div className="name">Enter Primary Color Code</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Code Here..."
                value={primaryColor}
                onChange={(e) => setPrimaryColor(e.target.value)}
              />
              <div
                className="color"
                style={{ width: "10%", background: `#${primaryColor}` }}
              />
            </div>
            <div className="space"></div>
          </Scrollbars>
        );
    }
  }

  function addEndorsement() {
    setLoading(true);
    Axios.put(`https://commerce.apimachine.com/industries/${industry?._id}`, {
      name: name === industry.name ? undefined : name,
      description: desc === industry.description ? undefined : desc,
      icon: icon === industry.icon ? undefined : icon,
      color_code:
        primaryColor === industry.color_code ? undefined : primaryColor,
      other_data:
        coverPhoto !== industry?.other_data?.coverphoto ||
        whiteIcon !== industry?.other_data?.whiteicon ||
        logo !== industry?.other_data?.colourlogo ||
        whiteLogo !== industry?.other_data?.whitelogo
          ? {
              coverphoto: coverPhoto,
              whiteicon: whiteIcon,
              colourlogo: logo,
              whitelogo: whiteLogo,
            }
          : undefined,
    })
      .then(({ data }) => {
        if (data.status) {
          tostShowOn("Industry Updated");
          setStep("updateSucces");
        } else {
          tostShowOn(data.message);
        }
      })
      .finally(() => setLoading(false));
  }

  function validate() {
    if (
      name &&
      icon &&
      coverPhoto &&
      logo &&
      whiteIcon &&
      whiteLogo &&
      primaryColor &&
      desc
    ) {
      addEndorsement();
    } else {
      tostShowOn("All Fields Are Mandatory");
    }
  }

  return (
    <>
      <div className="newConglomerate">{getContent()}</div>
      <div className="btnSubmit" onClick={validate}>
        Submit Industry Edit
      </div>
      {loading && <LoadingAnim />}{" "}
    </>
  );
}

export default EditIndustry;
