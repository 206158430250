import React, { useContext, useRef, createRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";

import OnOutsideClick from "../../utils/OnOutsideClick";
import { MainContext } from "../../context/MainContext";
import { useHistory } from "react-router-dom";

function AppItem({ app, appSelected, setAppSelected }) {
  const { copyToClipboard } = useContext(MainContext);
  const elRefs = useRef([]);
  const history = useHistory();
  if (elRefs.current.length !== 7) {
    // add or remove refs
    elRefs.current = Array(7)
      .fill()
      .map((_, i) => elRefs.current[i] || createRef());
  }

  const refOut = useRef();
  const ref = useRef();
  OnOutsideClick(
    ref,
    () => {
      if (app !== appSelected) setAppSelected(app);
      else setAppSelected();
    },
    refOut,
    elRefs
  );
  return (
    <div
      className={`appItem ${!appSelected || app === appSelected}`}
      ref={refOut}
      key={app._id}
    >
      <img
        src={app.app_icon}
        alt=""
        className="appIcon"
        ref={elRefs.current[6]}
        onClick={() => history.push(`/apps/${app.app_code}`)}
      />
      <div className="name">
        <div className="appName">
          {app?.app_name}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[0]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(`${app?.app_name}`, `App Name Copied`);
            }}
          />
        </div>
        <div className="appCode">
          {app?.app_code}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[1]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(`${app?.app_code}`, `App Code Copied`);
            }}
          />
        </div>
      </div>
      <div className="name">
        <div className="appName">
          {moment().diff(moment(app.timestamp), "days")} Days
          <FontAwesomeIcon
            forwardedRef={elRefs.current[2]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(
                `${moment().diff(moment(app.timestamp), "days")} Days`,
                `Days Copied`
              );
            }}
          />
        </div>
        <div className="appCode">
          {moment(app.timestamp).format("[Since] MMMM Do YYYY")}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[3]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(
                `${moment(app.timestamp).format("[Since] MMMM Do YYYY")}`,
                `Since Copied`
              );
            }}
          />
        </div>
      </div>
      <div className="status">
        {app.status}
        <FontAwesomeIcon
          forwardedRef={elRefs.current[4]}
          icon={faCopy}
          className="cpyIcn"
          onClick={() => {
            copyToClipboard(`${app.status}`, `Status Copied`);
          }}
        />
      </div>
      <div className="createdBy">
        {app.created_by}
        <FontAwesomeIcon
          forwardedRef={elRefs.current[5]}
          icon={faCopy}
          className="cpyIcn"
          onClick={() => {
            copyToClipboard(`${app.created_by}`, `Email Copied`);
          }}
        />
      </div>
    </div>
  );
}

export default AppItem;
