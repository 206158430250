import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useRef, createRef } from "react";
import { MainContext } from "../../context/MainContext";
import OnOutsideClick from "../../utils/OnOutsideClick";

function OTCDeskItem({ otcDesk, setTab, otcSelected, setOtcSelected }) {
  const { copyToClipboard } = useContext(MainContext);
  const elRefs = useRef([]);

  if (elRefs.current.length !== 7) {
    // add or remove refs
    elRefs.current = Array(7)
      .fill()
      .map((_, i) => elRefs.current[i] || createRef());
  }

  const refOut = useRef();
  OnOutsideClick(
    undefined,
    () => {
      setOtcSelected(otcDesk);
      setTab("Actions");
    },
    refOut,
    elRefs
  );
  return (
    <div
      ref={refOut}
      className={`userItem ${!otcSelected || otcSelected?._id === otcDesk._id}`}
    >
      <img alt="" src={otcDesk.color_logo} className="logoImg" />
      <div className="nameEmail">
        <span className="name">{otcDesk?.app_code}</span>
        <span className="email">
          App Code
          <FontAwesomeIcon
            forwardedRef={elRefs.current[0]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(`${otcDesk?.app_code}`, `App Code Copied`);
            }}
          />
        </span>
      </div>
      <div className="nameEmail">
        <span className="name">{otcDesk?.email}</span>
        <span className="email">
          Owner Email
          <FontAwesomeIcon
            forwardedRef={elRefs.current[1]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(`${otcDesk.email}`, `Owner Email Copied`);
            }}
          />
        </span>
      </div>
      <div className="nameEmail hideOpen">
        <span className="name">{otcDesk?.country}</span>
        <span className="email">
          Desk Country
          <FontAwesomeIcon
            forwardedRef={elRefs.current[1]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(`${otcDesk.country}`, `Desk Country Copied`);
            }}
          />
        </span>
      </div>
      <div className="btnActions hideOpen" ref={elRefs.current[3]}>
        <div
          className="btnAction"
          onClick={() => {
            window.open(otcDesk?.website, "_blank");
          }}
        >
          Website
        </div>
        <div className="btnAction" onClick={() => {}}>
          Actions
        </div>
      </div>
    </div>
  );
}

export default OTCDeskItem;
