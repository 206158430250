import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import JsonWebToken from "jsonwebtoken";
import Scrollbars from "react-custom-scrollbars";
import { useHistory } from "react-router";
import cloudUploadIcon from "../../static/images/postClipArt/cloudUpload.svg";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import { MainContext } from "../../context/MainContext";
import loadingGif from "../../static/animations/loading.gif";
import TokenAppsList from "../NewBrand/TokenAppsList";
import ConglomerateList from "../NewBrand/ConglomerateList";
import OperatorList from "../NewBrand/OperatorList";
import { ReactComponent as CreateIcon } from "../../static/images/sidebarIcons/create.svg";
import { useAppsList, useOperatorsList } from "../../queryHooks";
import WebsiteInput from "../WebsiteInput";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

function EditBrand({ brand }) {
  const history = useHistory();
  const { tostShowOn, token, email } = useContext(MainContext);
  const [step, setStep] = useState("");
  const [loading, setLoading] = useState(false);
  const { data: operators } = useOperatorsList();
  const { data: allapps } = useAppsList();

  // Form Variables
  const [icon, setIcon] = useState("");
  const [logo, setLogo] = useState("");
  const [whiteIcon, setWhiteIcon] = useState("");
  const [whiteLogo, setWhiteLogo] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [webTitle, setWebTitle] = useState("");
  const [webDesc, setWebDesc] = useState("");
  const [brandCode, setBrandCode] = useState("");
  const [website, setWebsite] = useState("");
  const [operator, setOperator] = useState("");
  const [group, setGroup] = useState("");
  const [tokenApps, setTokenApps] = useState([]);

  const [iconLoading, setIconLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [whiteIconLoading, setWhiteIconLoading] = useState(false);
  const [whiteLogoLoading, setWhiteLogoLoading] = useState(false);
  const [coverPhotoLoading, setCoverPhotoLoading] = useState(false);
  useEffect(() => {
    setName(brand.name);
    setIcon(brand.colored_icon);
    setWhiteIcon(brand.white_logo);
    setDesc(brand.description);
    setBrandCode(brand?.brand_code);
    setWebsite(brand?.website);
    setLogo(brand.other_data?.coloredfulllogo);
    setCoverPhoto(brand?.other_data?.coverphoto);
    setWhiteLogo(brand.other_data?.whitefulllogo);
    setPrimaryColor(brand?.other_data?.primarycolourcode);
    setSecondaryColor(brand?.other_data?.secondarycolourcode);
    setWebTitle(brand?.other_data?.websitename);
    setWebDesc(brand?.other_data?.websitedescription);
    setGroup(brand?.parent_groupData);
  }, [brand]);
  useEffect(() => {
    if (operators?.length) {
      const op = operators.filter(
        (operator) => operator?.operator_id === brand?.operator_id
      )[0];
      setOperator(op);
    }
  }, [operators, brand]);
  console.log(`allapps`, allapps);
  useEffect(() => {
    if (allapps?.length) {
      const apps = allapps.filter((app) =>
        brand?.associated_apps.includes(app?.app_code)
      );
      console.log(`apps`, apps);
      setTokenApps(apps);
    }
  }, [allapps, brand]);

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
    setLoading(false);
  };

  const [brandCount, setBrandCount] = useState("");
  const checkCode = async (code) => {
    Axios.get(
      `https://comms.globalxchange.io/gxb/app/gxlive/operator/brands/get?brand_code=${code}`
    ).then(({ data }) => {
      setBrandCount(data?.count);
    });
  };

  function getContent() {
    switch (step) {
      case "success":
        setTimeout(() => {
          history.push("/ventures/Brands");
          window.location.reload();
        }, 2000);
        return (
          <>
            <div className="succesView">
              <div className="labelItm">
                You Have Successfully Edited {name}. You Will Be Redirected To
                The Updated Brands List Automatically
              </div>
            </div>
          </>
        );
      case "operator":
        return (
          <OperatorList setOperator={setOperator} onClose={() => setStep("")} />
        );
      case "conglomerate":
        return (
          <ConglomerateList setGroup={setGroup} onClose={() => setStep("")} />
        );
      case "assosiatedApp":
        return (
          <TokenAppsList
            tokenApps={tokenApps}
            setTokenApps={setTokenApps}
            onClose={() => setStep("")}
          />
        );
      default:
        return (
          <>
            <Scrollbars
              className="scrollForm"
              renderTrackHorizontal={() => <div />}
              renderThumbHorizontal={() => <div />}
              renderTrackVertical={() => <div />}
              renderThumbVertical={() => <div />}
            >
              <div className="name">Select GXLive Operator</div>
              {operator ? (
                <div className="user" onClick={() => setStep("operator")}>
                  <img className="dp" src={operator?.brand_logo} alt="" />
                  <div className="userDetail">
                    <div className="name">{operator?.brand_name}</div>
                    <div className="email">{operator?.email}</div>
                  </div>
                </div>
              ) : (
                <div className="inputWrap" onClick={() => setStep("operator")}>
                  <div className="label">Click Here To Select An Operator</div>
                </div>
              )}
              <div className="name">Name The Brand</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Brand Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="name">Create Brand Code</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Brand Code"
                  readOnly
                  value={brandCode}
                  onChange={(e) => {
                    setBrandCode(e.target.value);
                    setBrandCount("");
                  }}
                />
                <div
                  className="btnCheck"
                  onClick={() => {
                    brandCode === "" ? setBrandCode("") : checkCode(brandCode);
                  }}
                >
                  {brandCount === "" ? "Check" : "Reset"}
                </div>
                {brandCount === "" ? (
                  ""
                ) : (
                  <div className={`status ${brandCount > 0}`}>
                    {brandCount > 0
                      ? "Your Brand Code Is Not Available"
                      : "Your Brand Code Is Available"}
                  </div>
                )}
              </div>
              <div className="name">Enter Brand Website</div>
              <WebsiteInput website={website} setWebsite={setWebsite} />
              <div className="name">Select Parent Conglomerate</div>
              {group ? (
                <div className="user" onClick={() => setStep("conglomerate")}>
                  <img className="dp" src={group?.icon} alt="" />
                  <div className="userDetail">
                    <div className="name">{group?.groupname}</div>
                    <div className="email">{group?.email}</div>
                  </div>
                </div>
              ) : (
                <div
                  className="inputWrap"
                  onClick={() => setStep("conglomerate")}
                >
                  <div className="label">
                    Click Here To Select An Conglomerate
                  </div>
                </div>
              )}
              <div className="name">Describe The Brand</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Brand Description"
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </div>
              <div className="name">Add Associated TokenApps</div>
              <Scrollbars className="tokenAppList">
                {tokenApps?.map((app) => (
                  <div className="cardBtn">
                    <div className="cardIn">
                      <img src={app?.app_icon} alt="" />
                    </div>
                    <span>{app?.app_name}</span>
                  </div>
                ))}
                <div
                  className="cardBtn"
                  onClick={() => setStep("assosiatedApp")}
                >
                  <div className="cardIn">
                    <CreateIcon />
                  </div>
                </div>
              </Scrollbars>
              <div className="name">Upload Branding Material</div>
              <div className="filesUpload">
                <label className="fileInp icon">
                  <img
                    className={`${Boolean(icon)}`}
                    src={iconLoading ? loadingGif : icon || cloudUploadIcon}
                    alt=""
                  />
                  <input
                    type="file"
                    onChange={(e) => {
                      uploadImage(e, setIcon, setIconLoading);
                    }}
                    accept="image/*"
                  />
                  <div className="text">Colored Icon</div>
                  <div className="hovTxt">
                    Upload
                    <br />
                    New
                  </div>
                </label>
                <label className="fileInp icon">
                  <img
                    className={`white ${Boolean(whiteIcon)}`}
                    src={
                      whiteIconLoading
                        ? loadingGif
                        : whiteIcon || cloudUploadIcon
                    }
                    alt=""
                  />
                  <input
                    type="file"
                    onChange={(e) => {
                      uploadImage(e, setWhiteIcon, setWhiteIconLoading);
                    }}
                    accept="image/*"
                  />
                  <div className="text">White Icon</div>
                  <div className="hovTxt">
                    Upload
                    <br />
                    New
                  </div>
                </label>
                <label className="fileInp cover">
                  <img
                    className={`${Boolean(coverPhoto)}`}
                    src={
                      coverPhotoLoading
                        ? loadingGif
                        : coverPhoto || cloudUploadIcon
                    }
                    alt=""
                  />
                  <input
                    type="file"
                    onChange={(e) => {
                      uploadImage(e, setCoverPhoto, setCoverPhotoLoading);
                    }}
                    accept="image/*"
                  />
                  <div className="text">Cover Photo</div>
                  <div className="hovTxt">
                    Upload
                    <br />
                    New
                  </div>
                </label>
              </div>
              <div className="filesUpload last">
                <label className="fileInp fullLogo">
                  <img
                    className={`${Boolean(logo)}`}
                    src={logoLoading ? loadingGif : logo || cloudUploadIcon}
                    alt=""
                  />
                  <input
                    type="file"
                    onChange={(e) => {
                      uploadImage(e, setLogo, setLogoLoading);
                    }}
                    accept="image/*"
                  />
                  <div className="text">Colored Logo</div>
                  <div className="hovTxt">
                    Upload
                    <br />
                    New
                  </div>
                </label>
                <label className="fileInp fullLogo">
                  <img
                    className={`white ${Boolean(whiteLogo)}`}
                    src={
                      whiteLogoLoading
                        ? loadingGif
                        : whiteLogo || cloudUploadIcon
                    }
                    alt=""
                  />
                  <input
                    type="file"
                    onChange={(e) => {
                      uploadImage(e, setWhiteLogo, setWhiteLogoLoading);
                    }}
                    accept="image/*"
                  />
                  <div className="text">White Logo</div>
                  <div className="hovTxt">
                    Upload
                    <br />
                    New
                  </div>
                </label>
              </div>
              <div className="name">Enter Primary Color Code</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Code Here..."
                  value={primaryColor}
                  onChange={(e) => setPrimaryColor(e.target.value)}
                />
                <div
                  className="color"
                  style={{ width: "10%", background: `#${primaryColor}` }}
                />
              </div>
              <div className="name">Enter Secondary Color Code</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Code Here..."
                  value={secondaryColor}
                  onChange={(e) => setSecondaryColor(e.target.value)}
                />
                <div
                  className="color"
                  style={{ width: "10%", background: `#${secondaryColor}` }}
                />
              </div>
              <div className="name">Website Title</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Brand Description"
                  value={webTitle}
                  onChange={(e) => setWebTitle(e.target.value)}
                />
              </div>
              <div className="name">Website Description</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Brand Description"
                  value={webDesc}
                  onChange={(e) => setWebDesc(e.target.value)}
                />
              </div>
              <div className="space"></div>
            </Scrollbars>
            <div className="ftBtns">
              <div className="newField" onClick={() => {}}>
                New Field
              </div>
              <div className="btnSubmit" onClick={() => validate(true)}>
                Submit
              </div>
            </div>
          </>
        );
    }
  }

  function updateBrand() {
    setLoading(true);
    Axios.post(
      `https://comms.globalxchange.io/gxb/app/gxlive/operator/brands/edit`,
      {
        email: operator?.email,
        token: token,
        brand_code: brand?.brand_code,
        update_data: {
          name: name === brand.name ? undefined : name,
          description: desc === brand.description ? undefined : desc,
          email: email === brand.email ? undefined : email,
          operator_id:
            operator.operator_id === brand.operator_id
              ? undefined
              : operator?.operator_id,
          website: website === brand.website ? undefined : website,
          colored_icon: icon === brand.colored_icon ? undefined : icon,
          white_logo: whiteLogo === brand.white_logo ? undefined : whiteLogo,
          parent_group_id:
            group?.group_id === brand?.group_id ? undefined : group?.group_id,
          associated_apps:
            tokenApps.map((app) => app?.app_code) === brand?.associated_apps
              ? undefined
              : tokenApps.map((app) => app?.app_code),
          other_data:
            primaryColor !== brand?.primarycolourcode ||
            secondaryColor !== brand?.secondarycolourcode ||
            whiteLogo !== brand?.whitefulllogo ||
            logo !== brand?.coloredfulllogo ||
            coverPhoto !== brand?.coverphoto ||
            webTitle !== brand?.websitename ||
            webDesc !== brand?.websitedescription
              ? {
                  primarycolourcode: primaryColor,
                  secondarycolourcode: secondaryColor,
                  whitefulllogo: whiteLogo,
                  coloredfulllogo: logo,
                  coverphoto: coverPhoto,
                  websitename: webTitle,
                  websitedescription: webDesc,
                }
              : undefined,
        },
      }
    )
      .then(({ data }) => {
        if (data.status) {
          setStep("updateSucces");
        } else {
          tostShowOn(data.message);
        }
      })
      .finally(() => setLoading(false));
  }

  function validate() {
    if (
      (icon,
      logo,
      whiteIcon,
      whiteLogo,
      coverPhoto,
      primaryColor,
      secondaryColor,
      name,
      desc,
      website,
      webTitle,
      webDesc,
      brandCode,
      operator,
      group,
      tokenApps)
    ) {
      updateBrand();
    } else {
      tostShowOn("All Fields Are Mandatory");
    }
  }

  return (
    <>
      <div className="newConglomerate">{getContent()}</div>
      {loading && <LoadingAnim />}{" "}
    </>
  );
}

export default EditBrand;
