import React from "react";

function AssetLiquidity({ liquidity, setLiquidity, search, setSearch }) {
  return (
    <div className="viewingAs">
      <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        type="text"
        className="searchInp"
        placeholder="Type In The Asset"
      />
      <div
        className={`btnType ${liquidity === "Liquid"}`}
        onClick={() => setLiquidity("Liquid")}
      >
        Liquid
      </div>
      <div
        className={`btnType ${liquidity === "Bonds"}`}
        onClick={() => setLiquidity("Bonds")}
      >
        Bonds
      </div>
    </div>
  );
}

export default AssetLiquidity;
