import React from "react";
import { Switch, Route } from "react-router-dom";
import CapitalLoginPage from "./pages/CapitalLoginPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import PrintReciept from "./pages/PrintReciept";
import PrintRecieptApp from "./pages/PrintRecieptApp";
import RecieptDetailPage from "./pages/RecieptDetailPage";
import SwitcherPage from "./pages/SwitcherPage";

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/sp" component={PrintReciept} />
      <Route exact path="/landing" component={PrintReciept} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/nvestlogin" component={CapitalLoginPage} />
      <Route exact path="/sp" component={PrintReciept} />
      <Route exact path="/sp/:app" component={PrintRecieptApp} />
      <Route exact path="/sp/:app/:detail" component={RecieptDetailPage} />
      <Route path="/*/:txnId" component={SwitcherPage} />
      <Route path="*" component={SwitcherPage} />
      {/* <Route exact path="/withdrawals" component={Withdrawals} />
      <Route exact path="/content" component={EngagementPage} />
      <Route exact path="/" component={GodsEyePage} />
      <Route exact path="/assetsIo" component={AssetsIo} /> */}
    </Switch>
  );
}

export default Routes;
