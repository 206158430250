import React, { useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import JsonWebToken from "jsonwebtoken";
import MailSlurp from "mailslurp-client";
import Axios from "axios";

import eventIcn from "../../static/images/clipIcons/eventIcon.svg";
import cloudUpload from "../../static/images/postClipArt/cloudUpload.svg";
import refreshIcn from "../../static/images/clipIcons/refresh.svg";
import extAsset from "../../static/images/postClipArt/extAsset.svg";
import intAsset from "../../static/images/postClipArt/intAsset.svg";
import video from "../../static/images/postClipArt/video.svg";
import article from "../../static/images/postClipArt/article.svg";
import blockIcon from "../../static/images/clipIcons/blockIcon.svg";
import tokenIcon from "../../static/images/clipIcons/xid.svg";
import terminalApps from "../../static/images/appLogos/terminalApps.svg";
import marketsIcon from "../../static/images/appLogos/markets.svg";
import otcDesks from "../../static/images/appLogos/otcDesks.svg";
import industry from "../../static/images/clipIcons/industry.svg";
import endorsement from "../../static/images/clipIcons/endorsement.svg";
import commereceIcon from "../../static/images/clipIcons/commerece.svg";
import publishers from "../../static/images/sidebarIcons/publishers.svg";
import bankerIconB from "../../static/images/clipIcons/bankerB.svg";
import viral from "../../static/images/sidebarIcons/viral.svg";
import ventures from "../../static/images/clipIcons/ventures.svg";
import marketplace from "../../static/images/clipIcons/marketplace.svg";
import shopperIcon from "../../static/images/clipIcons/shopperIcon.svg";
import serviceShopIcon from "../../static/images/clipIcons/serviceShopIcon.svg";
import retailerIndustry from "../../static/images/clipIcons/retailerIndustry.svg";
import commerceIndex from "../../static/images/clipIcons/commerceIndex.svg";
import retailerBag from "../../static/images/clipIcons/retailerBag.svg";
import conglomerate from "../../static/images/logos/conglomerate.svg";
import brands from "../../static/images/logos/brands.svg";
import back from "../../static/images/back.svg";
import close from "../../static/images/close.svg";
import tokenAppCategory from "../../static/images/clipIcons/tokenAppCategory.svg";
import entrepronours from "../../static/images/clipIcons/entrepronours.svg";
import bondIcon from "../../static/images/clipIcons/bondIcon.svg";
import bondTier from "../../static/images/clipIcons/bondTier.svg";

import { MainContext } from "../../context/MainContext";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import { useHistory } from "react-router-dom";
import NewConglomerate from "../NewConglomerate/NewConglomerate";
import NewBankerCategory from "../NewBankerCategory/NewBankerCategory";
import NewEndorsement from "../NewEndorsement";
import NewMarketplace from "../NewMarketplace";
import NewTerminalApp from "../NewTerminalApp";
import NewIndustry from "../NewIndustry";
import NewShopper from "../NewShopper";
import NewBrand from "../NewBrand";
import NewBanker from "../NewBanker/NewBanker";
import NewTokenAppLite from "../NewTokenAppLite";
import NewTokenAppCategory from "../NewTokenAppCategory";
import NewEntrepreneur from "../NewEntrepreneur";
import NewBondTier from "../NewBondTier";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const API_KEY =
  "86cee2f39d56b3b5a6b2e4c827cc1382d1be6bad16a9d35cd0e659ef9272d02c";
const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

const MAIN_MENU = [
  {
    name: "Assets",
    icon: extAsset,
  },
  {
    name: "Endorsements",
    icon: endorsement,
  },
  {
    name: "Terminals",
    icon: terminalApps,
  },
  {
    name: "Commerce",
    icon: commereceIcon,
  },
  {
    name: "Bankers",
    icon: bankerIconB,
  },
  {
    name: "Banker Category",
    icon: bankerIconB,
  },
  {
    name: "EmailChains",
    icon: blockIcon,
  },
  {
    name: "Ventures",
    icon: ventures,
  },
  {
    name: "Markets",
    icon: marketsIcon,
  },
  {
    name: "Viral",
    icon: viral,
  },
  {
    name: "Publications",
    icon: publishers,
  },
  {
    name: "TokenApp",
    icon: tokenIcon,
  },
  {
    name: "Bonds",
    icon: bondIcon,
  },
];

function GlobalSidebar({ onClose, show }) {
  const mailslurp = new MailSlurp({
    apiKey: API_KEY,
  });

  const [step, setStep] = useState("");

  const [thubnailLink, setThubnailLink] = useState("");
  const [thumbLoading, setThumbLoading] = useState(false);
  const uploadImage = async (e) => {
    setThumbLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setThubnailLink(data.payload.url);
    setThumbLoading(false);
  };

  const [coinName, setCoinName] = useState("");
  const [coinSymbol, setCoinSymbol] = useState("");
  const [type, setType] = useState("fiat");
  const [eth_token, setEth_token] = useState(true);
  const [stable_coin, setStable_coin] = useState(true);
  const [loading, setLoading] = useState(8);
  const [createPaymentsPath, setCreatePaymentsPath] = useState(true);
  const { token, email, tostShowOn } = useContext(MainContext);
  const history = useHistory();

  useEffect(() => {
    if (loading === 7) {
      history.push(`/centralbank/${type}`);
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  function submitAsset() {
    setLoading(0);
    Axios.post("https://comms.globalxchange.io/coin/vault/add", {
      token: token,
      email: email,
      coinName: coinName,
      coinSymbol: coinSymbol,
      coinImage: thubnailLink,
      type: type,
      eth_token: eth_token,
      stable_coin: stable_coin,
      create_payment_path: createPaymentsPath,
    })
      .then(({ data }) => {
        if (data.status) {
          const email = "shorupan@brokerapp.io";
          Axios.post(
            "https://comms.globalxchange.io/coin/vault/service/payment/paths/define",
            {
              token: token,
              email: email,
              to_currency: coinSymbol,
              app_code: "ice",
              banker: "Global X Change",
              country: "Worldwide",
              depositMethod: "vault",
              from_currency: "USDT",
              institution_id: "5f006e3f91af6952132f873a",
              select_type: "instant",
              instant_link: "www.globalxchange.com",
              total_steps: {
                step1: {
                  name: "Initaiate transaction",
                  status: "Initiated",
                },
                step2: {
                  name: "txn done",
                  status: "completed",
                },
                setp3: {
                  name: "txn cancelled",
                  status: "cancelled",
                },
              },
              success_step: "step2",
              failure_step: "step3",
              banker_trade_fee: 0,
              gx_trade_fee: 0,
              banker_fixed_fee: 0,
              gx_fixed_fee: 0,
              hedge: false,
            }
          ).finally(() => setLoading((loading) => loading + 1));
          Axios.post(
            "https://comms.globalxchange.io/coin/vault/service/payment/paths/define",
            {
              token: token,
              email: email,
              to_currency: "USDT",
              app_code: "ice",
              banker: "Global X Change",
              country: "Worldwide",
              depositMethod: "vault",
              from_currency: coinSymbol,
              institution_id: "5f006e3f91af6952132f873a",
              select_type: "instant",
              instant_link: "www.globalxchange.com",
              total_steps: {
                step1: {
                  name: "Initaiate transaction",
                  status: "Initiated",
                },
                step2: {
                  name: "txn done",
                  status: "completed",
                },
                setp3: {
                  name: "txn cancelled",
                  status: "cancelled",
                },
              },
              success_step: "step2",
              failure_step: "step3",
              banker_trade_fee: 0,
              gx_trade_fee: 0,
              banker_fixed_fee: 0,
              gx_fixed_fee: 0,
              hedge: false,
            }
          ).finally(() => setLoading((loading) => loading + 1));
          Axios.post(
            "https://comms.globalxchange.io/coin/vault/service/payment/paths/define",
            {
              token: token,
              email: email,
              to_currency: coinSymbol,
              app_code: "ice",
              banker: "Global X Change",
              country: "Worldwide",
              depositMethod: "vault",
              from_currency: "BTC",
              institution_id: "5f006e3f91af6952132f873a",
              select_type: "instant",
              instant_link: "www.globalxchange.com",
              total_steps: {
                step1: {
                  name: "Initaiate transaction",
                  status: "Initiated",
                },
                step2: {
                  name: "txn done",
                  status: "completed",
                },
                setp3: {
                  name: "txn cancelled",
                  status: "cancelled",
                },
              },
              success_step: "step2",
              failure_step: "step3",
              banker_trade_fee: 0,
              gx_trade_fee: 0,
              banker_fixed_fee: 0,
              gx_fixed_fee: 0,
              hedge: false,
            }
          ).finally(() => setLoading((loading) => loading + 1));
          Axios.post(
            "https://comms.globalxchange.io/coin/vault/service/payment/paths/define",
            {
              token: token,
              email: email,
              to_currency: "BTC",
              app_code: "ice",
              banker: "Global X Change",
              country: "Worldwide",
              depositMethod: "vault",
              from_currency: coinSymbol,
              institution_id: "5f006e3f91af6952132f873a",
              select_type: "instant",
              instant_link: "www.globalxchange.com",
              total_steps: {
                step1: {
                  name: "Initaiate transaction",
                  status: "Initiated",
                },
                step2: {
                  name: "txn done",
                  status: "completed",
                },
                setp3: {
                  name: "txn cancelled",
                  status: "cancelled",
                },
              },
              success_step: "step2",
              failure_step: "step3",
              banker_trade_fee: 0,
              gx_trade_fee: 0,
              banker_fixed_fee: 0,
              gx_fixed_fee: 0,
              hedge: false,
            }
          ).finally(() => setLoading((loading) => loading + 1));
          Axios.post(
            "https://comms.globalxchange.io/coin/vault/service/payment/paths/define",
            {
              token: token,
              email: email,
              to_currency: coinSymbol,
              app_code: "ice",
              banker: "Global X Change",
              country: "Worldwide",
              depositMethod: "vault",
              from_currency: "ETH",
              institution_id: "5f006e3f91af6952132f873a",
              select_type: "instant",
              instant_link: "www.globalxchange.com",
              total_steps: {
                step1: {
                  name: "Initaiate transaction",
                  status: "Initiated",
                },
                step2: {
                  name: "txn done",
                  status: "completed",
                },
                setp3: {
                  name: "txn cancelled",
                  status: "cancelled",
                },
              },
              success_step: "step2",
              failure_step: "step3",
              banker_trade_fee: 0,
              gx_trade_fee: 0,
              banker_fixed_fee: 0,
              gx_fixed_fee: 0,
              hedge: false,
            }
          ).finally(() => setLoading((loading) => loading + 1));
          Axios.post(
            "https://comms.globalxchange.io/coin/vault/service/payment/paths/define",
            {
              token: token,
              email: email,
              to_currency: "ETH",
              app_code: "ice",
              banker: "Global X Change",
              country: "Worldwide",
              depositMethod: "vault",
              from_currency: coinSymbol,
              institution_id: "5f006e3f91af6952132f873a",
              select_type: "instant",
              instant_link: "www.globalxchange.com",
              total_steps: {
                step1: {
                  name: "Initaiate transaction",
                  status: "Initiated",
                },
                step2: {
                  name: "txn done",
                  status: "completed",
                },
                setp3: {
                  name: "txn cancelled",
                  status: "cancelled",
                },
              },
              success_step: "step2",
              failure_step: "step3",
              banker_trade_fee: 0,
              gx_trade_fee: 0,
              banker_fixed_fee: 0,
              gx_fixed_fee: 0,
              hedge: false,
            }
          ).finally(() => setLoading((loading) => loading + 1));
        } else {
          tostShowOn(data.message);
          setLoading(8);
        }
      })
      .finally(() => setLoading((loading) => loading + 1));
  }

  const [mailId, setMailId] = useState("");
  async function createInbox() {
    const inboxController = mailslurp.inboxController;
    const inbox = await inboxController.createInbox(
      undefined,
      `${mailId}@blockcheck.io`,
      undefined
    );
  }

  const [mainMenu, setMainMenu] = useState("");

  function getContent(step) {
    switch (true) {
      case step === "ExternalAsset":
        return (
          <>
            <Scrollbars
              className="coinList"
              renderTrackHorizontal={() => <div />}
              renderThumbHorizontal={() => <div />}
              renderTrackVertical={() => <div />}
              renderThumbVertical={() => <div />}
            >
              <div className="name">What Is The Name Of The Asset?</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  value={coinName}
                  onChange={(e) => setCoinName(e.target.value)}
                />
                <label className="fileInp">
                  <img
                    src={
                      thumbLoading ? refreshIcn : thubnailLink || cloudUpload
                    }
                    alt=""
                  />
                  <input type="file" onChange={uploadImage} accept="image/*" />
                </label>
              </div>
              <div className="name">What Is The Asset Symbol?</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  value={coinSymbol}
                  onChange={(e) => setCoinSymbol(e.target.value)}
                />
              </div>
              <div className="name">What Type Of Asset Is It?</div>
              <div className="inputWrap options">
                <div
                  className={`option ${type === "fiat"}`}
                  onClick={() => setType("fiat")}
                >
                  Fiat
                </div>
                <div
                  className={`option ${type === "crypto"}`}
                  onClick={() => setType("crypto")}
                >
                  Crypto
                </div>
              </div>
              <div className="name">Is This An Ethereum Based Asset</div>
              <div className="inputWrap options">
                <div
                  className={`option ${eth_token}`}
                  onClick={() => setEth_token(true)}
                >
                  True
                </div>
                <div
                  className={`option ${!eth_token}`}
                  onClick={() => setEth_token(false)}
                >
                  False
                </div>
              </div>
              <div className="name">Is This A Stable Coin?</div>
              <div className="inputWrap options">
                <div
                  className={`option ${stable_coin}`}
                  onClick={() => setStable_coin(true)}
                >
                  True
                </div>
                <div
                  className={`option ${!stable_coin}`}
                  onClick={() => setStable_coin(false)}
                >
                  False
                </div>
              </div>
              <div className="name">
                Do You Want To Create A Withdrawal Pathway With Global X Change?
              </div>
              <div className="inputWrap options">
                <div
                  className={`option ${createPaymentsPath}`}
                  onClick={() => setCreatePaymentsPath(true)}
                >
                  True
                </div>
                <div
                  className={`option ${!createPaymentsPath}`}
                  onClick={() => setCreatePaymentsPath(false)}
                >
                  False
                </div>
              </div>
            </Scrollbars>
            <button
              className="btnSubmit"
              disabled={!coinName || !coinSymbol || !thubnailLink}
              onClick={submitAsset}
            >
              Submit Asset
            </button>
          </>
        );
      case step === "BlockcheckMail":
        return (
          <>
            <Scrollbars
              className="coinList"
              renderTrackHorizontal={() => <div />}
              renderThumbHorizontal={() => <div />}
              renderTrackVertical={() => <div />}
              renderThumbVertical={() => <div />}
            >
              <div className="name">What Is The Address For The Email?</div>
              <label className="inputWrap">
                <input
                  type="text"
                  className="text"
                  value={mailId}
                  onChange={(e) => setMailId(e.target.value)}
                />
                <div className="domain">
                  <span>@blockcheck.io</span>
                </div>
              </label>
            </Scrollbars>
            <button
              className="btnSubmit"
              onClick={() => {
                createInbox();
              }}
            >
              Create Address
            </button>
          </>
        );
      case step === "Conglomerate":
        return <NewConglomerate onBack={() => setStep("")} onClose={onClose} />;
      case step === "Banker":
        return <NewBanker onBack={() => setStep("")} onClose={onClose} />;
      case step === "EndorsementMarket":
        return <NewEndorsement onBack={() => setStep("")} onClose={onClose} />;
      case step === "Marketplace":
        return <NewMarketplace onBack={() => setStep("")} onClose={onClose} />;
      case step === "TerminalApp":
        return <NewTerminalApp onBack={() => setStep("")} onClose={onClose} />;
      case step === "Industry":
        return <NewIndustry onBack={() => setStep("")} onClose={onClose} />;
      case step === "Shopper":
        return <NewShopper />;
      case step === "Brands":
        return <NewBrand />;
      case mainMenu === "Bonds" && step === "Bond Tier":
        return <NewBondTier />;
      case mainMenu === "TokenApp" && step === "TokenAppLite":
        return <NewTokenAppLite />;
      case mainMenu === "TokenApp" && step === "TokenAppCategory":
        return <NewTokenAppCategory />;
      case mainMenu === "TokenApp" && step === "Entrepreneurs":
        return <NewEntrepreneur />;
      case mainMenu === "Assets":
        return (
          <>
            <Scrollbars
              className="coinList"
              renderTrackHorizontal={() => <div />}
              renderThumbHorizontal={() => <div />}
              renderTrackVertical={() => <div />}
              renderThumbVertical={() => <div />}
            >
              <div
                className="coinItem"
                onClick={() => setStep("ExternalAsset")}
              >
                <img src={extAsset} alt="" className="coin" />
                <span>External Asset</span>
              </div>
              <div className="coinItem disable">
                <img src={intAsset} alt="" className="coin" />
                <span>Internal Asset</span>
              </div>
            </Scrollbars>
          </>
        );
      case mainMenu === "Endorsements":
        return (
          <>
            <Scrollbars
              className="coinList"
              renderTrackHorizontal={() => <div />}
              renderThumbHorizontal={() => <div />}
              renderTrackVertical={() => <div />}
              renderThumbVertical={() => <div />}
            >
              <div
                className="coinItem"
                onClick={() => setStep("EndorsementMarket")}
              >
                <img src={endorsement} alt="" className="coin" />
                <span>Endorsement Market</span>
              </div>
              <div className="coinItem" onClick={() => setStep("Marketplace")}>
                <img src={endorsement} alt="" className="coin" />
                <span>Endorsement Marketplace</span>
              </div>
            </Scrollbars>
          </>
        );
      case mainMenu === "Terminals":
        return (
          <>
            <Scrollbars
              className="coinList"
              renderTrackHorizontal={() => <div />}
              renderThumbHorizontal={() => <div />}
              renderTrackVertical={() => <div />}
              renderThumbVertical={() => <div />}
            >
              <div className="coinItem" onClick={() => setStep("TerminalApp")}>
                <img src={terminalApps} alt="" className="coin" />
                <span>Terminal App</span>
              </div>
            </Scrollbars>
          </>
        );
      case mainMenu === "Commerce":
        return (
          <>
            <Scrollbars
              className="coinList"
              renderTrackHorizontal={() => <div />}
              renderThumbHorizontal={() => <div />}
              renderTrackVertical={() => <div />}
              renderThumbVertical={() => <div />}
            >
              <div className="coinItem" onClick={() => {}}>
                <img src={marketplace} alt="" className="coin" />
                <span>Marketplace</span>
              </div>
              <div className="coinItem" onClick={() => setStep("Industry")}>
                <img src={industry} alt="" className="coin" />
                <span>Industry</span>
              </div>
              <div className="coinItem" onClick={() => setStep("Shopper")}>
                <img src={shopperIcon} alt="" className="coin" />
                <span>Shopper</span>
              </div>
              <div className="coinItem" onClick={() => {}}>
                <img src={serviceShopIcon} alt="" className="coin" />
                <span>Service Shop</span>
              </div>
              <div className="coinItem" onClick={() => {}}>
                <img src={retailerBag} alt="" className="coin" />
                <span>Retailer</span>
              </div>
              <div className="coinItem" onClick={() => {}}>
                <img src={retailerIndustry} alt="" className="coin" />
                <span>Retailer</span>
              </div>
              <div className="coinItem" onClick={() => {}}>
                <img src={commerceIndex} alt="" className="coin" />
                <span>Commerce Index</span>
              </div>
            </Scrollbars>
          </>
        );
      case mainMenu === "Bankers":
        return <NewBanker onBack={() => setMainMenu("")} onClose={onClose} />;
      case mainMenu === "Publications":
        return (
          <>
            <Scrollbars
              className="coinList"
              renderTrackHorizontal={() => <div />}
              renderThumbHorizontal={() => <div />}
              renderTrackVertical={() => <div />}
              renderThumbVertical={() => <div />}
            >
              <div
                className="coinItem"
                onClick={() => {
                  history.push("/content/events");
                  onClose();
                }}
              >
                <img src={eventIcn} alt="" className="coin" />
                <span>Event</span>
              </div>
              <div
                className="coinItem"
                onClick={() => {
                  history.push("/content/article");
                  onClose();
                }}
              >
                <img src={article} alt="" className="coin" />
                <span>Article</span>
              </div>
              <div
                className="coinItem"
                onClick={() => {
                  history.push("/content/video");
                  onClose();
                }}
              >
                <img src={video} alt="" className="coin" />
                <span>Video</span>
              </div>
            </Scrollbars>
          </>
        );
      case mainMenu === "EmailChains":
        return (
          <>
            <Scrollbars
              className="coinList"
              renderTrackHorizontal={() => <div />}
              renderThumbHorizontal={() => <div />}
              renderTrackVertical={() => <div />}
              renderThumbVertical={() => <div />}
            >
              <div
                className="coinItem"
                onClick={() => setStep("BlockcheckMail")}
              >
                <img src={blockIcon} alt="" className="coin" />
                <span>BlockCheck Mail</span>
              </div>
            </Scrollbars>
          </>
        );
      case mainMenu === "Ventures":
        return (
          <>
            <Scrollbars
              className="coinList"
              renderTrackHorizontal={() => <div />}
              renderThumbHorizontal={() => <div />}
              renderTrackVertical={() => <div />}
              renderThumbVertical={() => <div />}
            >
              <div
                className="coinItem "
                onClick={() => setStep("Conglomerate")}
              >
                <img src={conglomerate} alt="" className="coin" />
                <span>Conglomerate</span>
              </div>
              <div className="coinItem " onClick={() => setStep("Brands")}>
                <img src={brands} alt="" className="coin" />
                <span>Brands</span>
              </div>
            </Scrollbars>
          </>
        );
      case mainMenu === "Bonds":
        return (
          <>
            <Scrollbars
              className="coinList"
              renderTrackHorizontal={() => <div />}
              renderThumbHorizontal={() => <div />}
              renderTrackVertical={() => <div />}
              renderThumbVertical={() => <div />}
            >
              <div className="coinItem " onClick={() => setStep("Custom Bond")}>
                <img src={bondIcon} alt="" className="coin" />
                <span>Custom Bond</span>
              </div>
              <div className="coinItem" onClick={() => setStep("Bond Tier")}>
                <img src={bondTier} alt="" className="coin" />
                <span>Bond Tier</span>
              </div>
            </Scrollbars>
          </>
        );

      case mainMenu === "TokenApp":
        return (
          <>
            <Scrollbars
              className="coinList"
              renderTrackHorizontal={() => <div />}
              renderThumbHorizontal={() => <div />}
              renderTrackVertical={() => <div />}
              renderThumbVertical={() => <div />}
            >
              <div
                className="coinItem "
                onClick={() => setStep("TokenAppLite")}
              >
                <img src={tokenIcon} alt="" className="coin" />
                <span>TokenApp Lite</span>
              </div>
              <div className="coinItem disable" onClick={() => {}}>
                <img src={tokenIcon} alt="" className="coin" />
                <span>TokenApp Full</span>
              </div>
              <div
                className="coinItem "
                onClick={() => setStep("TokenAppCategory")}
              >
                <img src={tokenAppCategory} alt="" className="coin" />
                <span>TokenApp Category</span>
              </div>
              <div
                className="coinItem "
                onClick={() => setStep("Entrepreneurs")}
              >
                <img src={entrepronours} alt="" className="coin" />
                <span>Entrepreneurs</span>
              </div>
            </Scrollbars>
          </>
        );
      case mainMenu === "Viral":
        return (
          <>
            <Scrollbars
              className="coinList"
              renderTrackHorizontal={() => <div />}
              renderThumbHorizontal={() => <div />}
              renderTrackVertical={() => <div />}
              renderThumbVertical={() => <div />}
            ></Scrollbars>
          </>
        );
      case mainMenu === "Markets":
        return (
          <>
            <Scrollbars
              className="coinList"
              renderTrackHorizontal={() => <div />}
              renderThumbHorizontal={() => <div />}
              renderTrackVertical={() => <div />}
              renderThumbVertical={() => <div />}
            >
              <div className="coinItem " onClick={() => setStep("OTCDesk")}>
                <img src={otcDesks} alt="" className="coin" />
                <span>OTCDesk</span>
              </div>
            </Scrollbars>
          </>
        );
      case mainMenu === "Banker Category":
        return (
          <NewBankerCategory onBack={() => setMainMenu("")} onClose={onClose} />
        );
      default:
        return (
          <>
            <Scrollbars className="verticalScrollBox">
              {MAIN_MENU.map((itm) => (
                <div className="itemBox" onClick={() => setMainMenu(itm.name)}>
                  <div className="iconWrap">
                    <svg viewBox="0 0 1 1" />
                    <img src={itm.icon} alt="" className="icon" />
                  </div>
                  <span className="label">{itm.name}</span>
                </div>
              ))}
              <div className="itemBox inv">
                <div className="iconWrap">
                  <svg viewBox="0 0 1 1" />
                </div>
                <span className="label"></span>
              </div>
              <div className="itemBox inv">
                <div className="iconWrap">
                  <svg viewBox="0 0 1 1" />
                </div>
                <span className="label"></span>
              </div>
            </Scrollbars>
          </>
        );
    }
  }
  return (
    <>
      {show ? (
        <div className="globalSidebar">
          <div
            className="overlay"
            onClick={() => {
              try {
                onClose();
              } catch (error) {}
            }}
          />
          <div className="sidebar">
            <div className="header">
              <div className="content">
                <div className="title">New Item</div>
                <div className="breadCrumbs">
                  <span
                    onClick={() => {
                      setStep("");
                      setMainMenu("");
                    }}
                  >
                    Add New
                  </span>
                  {mainMenu && (
                    <>
                      -&gt;&nbsp;
                      <span
                        onClick={() => {
                          setStep("");
                        }}
                      >
                        {mainMenu}
                      </span>
                    </>
                  )}
                  {step && (
                    <>
                      -&gt;&nbsp;
                      <span>{step}</span>
                    </>
                  )}
                </div>
              </div>
              <div
                className="backBtn"
                onClick={() => {
                  if (step) {
                    setStep("");
                  }
                  if (mainMenu) {
                    setMainMenu("");
                  } else {
                    try {
                      // onBack();
                    } catch (error) {}
                  }
                }}
              >
                <img src={back} alt="" />
              </div>
              <div
                className="closeBtn"
                onClick={() => {
                  try {
                    onClose();
                  } catch (error) {}
                }}
              >
                <img src={close} alt="" />
              </div>
            </div>
            {getContent(step)}
            {loading < 7 && <LoadingAnim />}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default GlobalSidebar;
