import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useRef, createRef } from "react";
import { MainContext } from "../../context/MainContext";
import OnOutsideClick from "../../utils/OnOutsideClick";

function BankerListItem({ xchangeSelected, setXchangeSelected, user, setTab }) {
  const { copyToClipboard } = useContext(MainContext);
  const elRefs = useRef([]);

  if (elRefs.current.length !== 7) {
    // add or remove refs
    elRefs.current = Array(7)
      .fill()
      .map((_, i) => elRefs.current[i] || createRef());
  }

  const refOut = useRef();
  OnOutsideClick(
    undefined,
    () => {
      setXchangeSelected(user);
      setTab("Actions");
    },
    refOut,
    elRefs
  );
  return (
    <div
      ref={refOut}
      className={`userItem ${
        !xchangeSelected || xchangeSelected?._id === user._id
      }`}
    >
      <img alt="" src={user.profilePicURL} className="profileImg" />
      <div className="nameEmail">
        <span className="name">
          {user._displayName}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[0]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(`${user._displayName}`, `Banker Name Copied`);
            }}
          />
        </span>
        <span className="email">
          {user.bankerTag}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[1]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(`${user.bankerTag}`, `Banker Tag Copied`);
            }}
          />
        </span>
      </div>
      <div className="nameEmail">
        <span className="name">
          {user.category}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[5]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(`${user.category}`, `Category Copied`);
            }}
          />
        </span>
        <span className="email">
          {user.subCategory}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[6]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(`${user.subCategory}`, `Sub Category Copied`);
            }}
          />
        </span>
      </div>
      <div className="time">
        {user.email}
        <FontAwesomeIcon
          forwardedRef={elRefs.current[2]}
          icon={faCopy}
          className="cpyIcn"
          onClick={() => {
            copyToClipboard(`${user.email}`, `Email Id Copied`);
          }}
        />
      </div>
      <div className="balance hideOpen">
        {user.country}
        <FontAwesomeIcon
          forwardedRef={elRefs.current[3]}
          icon={faCopy}
          className="cpyIcn"
          onClick={() => {
            copyToClipboard(`${user.country}`, `Country Copied`);
          }}
        />
      </div>
      <div className="btnActions hideOpen" ref={elRefs.current[3]}>
        <div className="btnAction" onClick={() => {}}>
          Profile
        </div>
        <div className="btnAction" onClick={() => {}}>
          Actions
        </div>
      </div>
    </div>
  );
}

export default BankerListItem;
