import { SearchAndFilter } from "../BrokerDashboard/index";
import React, { useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { MoreTabContext } from "../../context/MoreTabContext";
import MarketplacesItem from "./MarketplacesItem";
import MarketplacesListSidebar from "./MarketplacesListSidebar";

function MarketplacesList({ list, setList, searchOn }) {
  const [marketplaces, setMarketplaces] = useState();
  const { marketplaceslist, marketplaceslistLoading } =
    useContext(MoreTabContext);
  //
  useEffect(() => {
    if (marketplaces) setList(true);
    else setList(false);
  }, [marketplaces, setList]);

  useEffect(() => {
    if (!list) setMarketplaces();
  }, [list]);

  // Searc Vars

  const [search, setSearch] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const [filteMarketplacesName, setFilteMarketplacesName] = useState("");

  return (
    <>
      {
        <div className="godsEyeMoreList">
          {searchOn && (
            <SearchAndFilter
              search={search}
              setSearch={setSearch}
              placeholder={"Search Marketplaces"}
              filterBy={`${"Name Of Group"}`}
              mainList={[
                {
                  key: "0",
                  label: "Function Performed By Search Bar",
                  switch: isFilter,
                  switchLabel: isFilter ? "Filter" : "Search",
                  switchClick: () => setIsFilter(!isFilter),
                },
                {
                  key: "1",
                  label: "Filter Range",
                  switch: currentPage,
                  switchLabel: currentPage ? "Current Page" : "Entire List",
                  switchClick: () => setCurrentPage(!currentPage),
                },
              ]}
              filterList={[
                {
                  key: "11",
                  label: "Name Of Market",
                  switch: filteMarketplacesName,
                  switchLabel: filteMarketplacesName ? "On" : "Off",
                  switchClick: () =>
                    setFilteMarketplacesName(!filteMarketplacesName),
                },
              ]}
            />
          )}
          <Scrollbars className="moreListWrapper">
            {marketplaceslistLoading
              ? Array(6)
                  .fill("")
                  .map((_, i) => (
                    <div className="listItem">
                      <Skeleton alt="" className="icon" />
                      <Skeleton className="name" width={400} />
                      <Skeleton className="count" width={30} />
                    </div>
                  ))
              : marketplaceslist
                  .filter((data) =>
                    data.name.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((data) => (
                    <MarketplacesItem
                      key={data._id}
                      marketplaces={data}
                      onClick={() => setMarketplaces(data)}
                      selected={marketplaces}
                    />
                  ))}
          </Scrollbars>
        </div>
      }
      {marketplaces && (
        <MarketplacesListSidebar
          onClose={() => setMarketplaces()}
          marketplaces={marketplaces}
        />
      )}
    </>
  );
}

export default MarketplacesList;
