import React, { useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { ReactComponent as LockIcon } from '../../images/clipIcons/logout.svg'

import styles from './sidebarclassic.module.scss'

function SidebarClassic({
  logo,
  logoText,
  email,
  onLogout,
  profilePic,
  profileName,
  active,
  groupOneName,
  groupTwoName,
  groupThreeName,
  groupOneList = [],
  groupTwoList = [],
  groupThreeList = [],
  groupOneFunction,
  groupTwoFunction,
  groupThreeFunction,
  addBtn,
  addBtnOnClick
}) {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <div
      className={`${styles.badDashSider} ${
        collapsed && styles.true
      } ${collapsed}`}
    >
      <div className={`${styles.logo} ${collapsed && styles.true}`}>
        {collapsed ? (
          <img
            className={styles.logoImg}
            src={logo}
            alt=''
            onClick={() => setCollapsed(!collapsed)}
          />
        ) : (
          <img
            className={styles.logoImg}
            src={logoText}
            alt=''
            onClick={() => setCollapsed(!collapsed)}
          />
        )}
        {addBtn && (
          <img
            className={styles.btnImg}
            src={addBtn}
            alt=''
            onClick={() => {
              try {
                addBtnOnClick()
              } catch (error) {}
            }}
          />
        )}
      </div>
      <div className={`${styles.siderContents} ${collapsed && styles.true}`}>
        <div className={styles.group}>
          <h5>
            {groupOneName}
            {groupOneFunction && (
              <span
                onClick={() => {
                  try {
                    groupOneFunction.onClick()
                  } catch (error) {}
                }}
              >
                {groupOneFunction.name}
              </span>
            )}
          </h5>
          <Scrollbars
            autoHide
            className={styles.menuScroll}
            renderView={(props) => (
              <div {...props} className={styles.menuList} />
            )}
            renderThumbHorizontal={() => <div />}
            renderThumbVertical={() => <div />}
          >
            {groupOneList.map((item) => {
              return (
                <div
                  key={item.name}
                  onClick={() => {
                    try {
                      item.onClick()
                    } catch (error) {}
                  }}
                  className={`${styles.menuItm} ${
                    active === item.name && styles.active
                  } ${item.sticky && styles.sticky}`}
                >
                  {item.svg ? item.svg : <img src={item.icon} alt='' />}
                  <h5>{item.name}</h5>
                </div>
              )
            })}
          </Scrollbars>
        </div>
        <div className={styles.group}>
          <h5>
            {groupTwoName}
            {groupTwoFunction && (
              <span
                onClick={() => {
                  try {
                    groupTwoFunction.onClick()
                  } catch (error) {}
                }}
              >
                {groupTwoFunction.name}
              </span>
            )}
          </h5>
          <Scrollbars
            autoHide
            className={styles.menuScroll}
            renderView={(props) => (
              <div {...props} className={styles.menuList} />
            )}
            renderThumbHorizontal={() => <div />}
            renderThumbVertical={() => <div />}
          >
            {groupTwoList.map((item) => {
              return (
                <div
                  key={item.name}
                  onClick={() => {
                    try {
                      item.onClick()
                    } catch (error) {}
                  }}
                  className={`${styles.menuItm} ${
                    active === item.name && styles.active
                  } ${item.sticky && styles.sticky}`}
                >
                  {item.svg ? item.svg : <img src={item.icon} alt='' />}
                  <h5>{item.name}</h5>
                </div>
              )
            })}
          </Scrollbars>
        </div>
        <div className={styles.group}>
          <h5>
            {groupThreeName}
            {groupThreeFunction && (
              <span
                onClick={() => {
                  try {
                    groupThreeFunction.onClick()
                  } catch (error) {}
                }}
              >
                {groupThreeFunction.name}
              </span>
            )}
          </h5>
          <Scrollbars
            autoHide
            className={styles.menuScroll}
            renderView={(props) => (
              <div {...props} className={styles.menuList} />
            )}
            renderThumbHorizontal={() => <div />}
            renderThumbVertical={() => <div />}
          >
            {groupThreeList.map((item) => {
              return (
                <div
                  key={item.name}
                  onClick={() => {
                    try {
                      item.onClick()
                    } catch (error) {}
                  }}
                  className={`${styles.menuItm} ${
                    active === item.name && styles.active
                  }  ${item.sticky && styles.sticky}`}
                >
                  {item.svg ? item.svg : <img src={item.icon} alt='' />}
                  <h5>{item.name}</h5>
                </div>
              )
            })}
          </Scrollbars>
        </div>
        <div className={styles.profileLogout}>
          <img className={styles.dp} src={profilePic} alt='' />
          <div className={styles.name}>
            <div className={styles.nameIn}>
              <h4>{profileName}</h4>
              <div className={styles.logout}>{email}</div>
            </div>
            <LockIcon
              className={styles.msg}
              onClick={() => {
                try {
                  onLogout()
                } catch (e) {}
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SidebarClassic
