import Axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";

function BankerList({ setBanker, onClose }) {
  const [bankers, setBankers] = useState([]);
  const [bankerLoading, setBankerLoading] = useState(true);
  useEffect(() => {
    Axios.get("https://teller2.apimachine.com/admin/allBankers")
      .then(({ data }) => {
        if (data.status) setBankers(data.data);
      })
      .finally(() => setBankerLoading(false));
  }, []);
  const [search, setSearch] = useState("");
  return (
    <Fragment>
      <div className="titleOp">Select Banker</div>
      <div className="searchWrap">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Bankers....|"
        />
      </div>
      <Scrollbars className="searchList">
        {bankerLoading
          ? Array(6)
              .fill("")
              .map((_, i) => (
                <div className="user" key={i}>
                  <Skeleton className="dp" circle />
                  <div className="userDetail">
                    <Skeleton className="name" width={200} />
                    <Skeleton className="email" width={200} />
                  </div>
                </div>
              ))
          : bankers
              .filter(
                (banker) =>
                  banker.displayName
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  banker.email?.toLowerCase().includes(search.toLowerCase())
              )
              .map((banker) => (
                <div
                  className="user"
                  key={banker._id}
                  onClick={() => {
                    setBanker(banker);
                    onClose();
                  }}
                >
                  <img className="dp" src={banker.profilePicURL} alt="" />
                  <div className="userDetail">
                    <div className="name">{banker.displayName}</div>
                    {/* <div className="email">{banker.email}</div> */}
                  </div>
                </div>
              ))}
        <div className="space"></div>
      </Scrollbars>
    </Fragment>
  );
}

export default BankerList;
