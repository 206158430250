import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";
import { useHistory } from "react-router";
import cloudUploadIcon from "../../static/images/postClipArt/cloudUpload.svg";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import { MainContext } from "../../context/MainContext";
import loadingGif from "../../static/animations/loading.gif";
import { uploadImage } from "../../utils";

function EditBanker({ banker }) {
  const history = useHistory();
  const { tostShowOn, token, email } = useContext(MainContext);
  const [step, setStep] = useState("");
  const [loading, setLoading] = useState(false);

  // Form Variables
  const [bankerTag, setBankerTag] = useState("");
  const [emailBanker, setEmailBanker] = useState("");
  const [address, setAddress] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [phone, setPhone] = useState("");
  const [primaryColor, setPrimaryColor] = useState("");
  const [cognitoName, setCognitoName] = useState("");
  const [desc, setDesc] = useState("");
  const [country, setCountry] = useState("");
  const [lxProfileId, setLxProfileId] = useState("");
  const [gxBankerProfileId, setGxBankerProfileId] = useState("");
  const [autoDeposit, setAutoDeposit] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [followers, setFollowers] = useState("");

  //Form Images
  const [profilePic, setProfilePic] = useState("");
  const [profilePicPng, setProfilePicPng] = useState("");
  const [profilePicWhite, setProfilePicWhite] = useState("");
  const [profilePicWhitePng, setProfilePicWhitePng] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");

  //Form Image Loaders
  const [profilePicLoading, setProfilePicLoading] = useState(false);
  const [profilePicPngLoading, setProfilePicPngLoading] = useState(false);
  const [profilePicWhiteLoading, setProfilePicWhiteLoading] = useState(false);
  const [profilePicWhitePngLoading, setProfilePicWhitePngLoading] =
    useState(false);
  const [coverPhotoLoading, setCoverPhotoLoading] = useState(false);

  // Set Initial Values
  useEffect(() => {
    setBankerTag(banker?.bankerTag);
    setEmailBanker(banker?.email);
    setAddress(banker?.address);
    setDisplayName(banker?.displayName);
    setPhone(banker?.phone);
    setPrimaryColor(banker?.colorCode);
    setCognitoName(banker?.cognitoName);
    setDesc(banker?.description);
    setCountry(banker?.country);
    setLxProfileId(banker?.LXAppProfileID);
    setGxBankerProfileId(banker?.BankerGXProfile);
    setAutoDeposit(String(banker?.autoDeposit));
    setCategory(banker?.category);
    setSubCategory(banker?.subCategory);
    setFollowers(banker?.bankerFollowersCount);
    setProfilePic(banker?.profilePicURL);
    setProfilePicPng(banker?.profilePicPNG);
    setProfilePicWhite(banker?.profilePicWhite);
    setProfilePicWhitePng(banker?.profilePicWhitePNG);
    setCoverPhoto(banker?.coverPicURL);
  }, [banker]);

  function getContent() {
    switch (step) {
      case "success":
        setTimeout(() => {
          history.push("/society/Bankers");
          window.location.reload();
        }, 2000);
        return (
          <>
            <div className="succesView">
              <div className="labelItm">
                You Have Successfully Edited {bankerTag}. You Will Be Redirected
                To The Updated Bankers List Automatically
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <Scrollbars
              className="scrollForm"
              renderTrackHorizontal={() => <div />}
              renderThumbHorizontal={() => <div />}
              renderTrackVertical={() => <div />}
              renderThumbVertical={() => <div />}
            >
              <div className="name">Banker Tag</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Banker Tag"
                  value={bankerTag}
                  readOnly
                />
              </div>
              <div className="name">Email</div>
              <div className="inputWrap">
                <input
                  type="email"
                  className="text"
                  placeholder="Enter Email"
                  readOnly
                  value={emailBanker}
                  onChange={(e) => {
                    setEmailBanker(e.target.value);
                  }}
                />
              </div>
              <div className="name">Upload Branding Material</div>
              <div className="filesUpload">
                <label className="fileInp icon">
                  <img
                    className={`${Boolean(profilePic)}`}
                    src={
                      profilePicLoading
                        ? loadingGif
                        : profilePic || cloudUploadIcon
                    }
                    alt=""
                  />
                  <input
                    type="file"
                    onChange={(e) => {
                      uploadImage(e, setProfilePic, setProfilePicLoading);
                    }}
                    accept="image/*"
                  />
                  <div className="text">Profile Pic</div>
                  <div className="hovTxt">
                    Upload
                    <br />
                    New
                  </div>
                </label>
                <label className="fileInp icon">
                  <img
                    className={`white ${Boolean(profilePicPng)}`}
                    src={
                      profilePicPngLoading
                        ? loadingGif
                        : profilePicPng || cloudUploadIcon
                    }
                    alt=""
                  />
                  <input
                    type="file"
                    onChange={(e) => {
                      uploadImage(e, setProfilePicPng, setProfilePicPngLoading);
                    }}
                    accept="image/*"
                  />
                  <div className="text">Profile Pic PNG</div>
                  <div className="hovTxt">
                    Upload
                    <br />
                    New
                  </div>
                </label>
                <label className="fileInp cover">
                  <img
                    className={`${Boolean(profilePicWhite)}`}
                    src={
                      profilePicWhiteLoading
                        ? loadingGif
                        : profilePicWhite || cloudUploadIcon
                    }
                    alt=""
                  />
                  <input
                    type="file"
                    onChange={(e) => {
                      uploadImage(
                        e,
                        setProfilePicWhite,
                        setProfilePicWhiteLoading
                      );
                    }}
                    accept="image/*"
                  />
                  <div className="text">Profile Pic White</div>
                  <div className="hovTxt">
                    Upload
                    <br />
                    New
                  </div>
                </label>
              </div>
              <div className="filesUpload last">
                <label className="fileInp fullLogo">
                  <img
                    className={`${Boolean(profilePicWhitePng)}`}
                    src={
                      profilePicWhitePngLoading
                        ? loadingGif
                        : profilePicWhitePng || cloudUploadIcon
                    }
                    alt=""
                  />
                  <input
                    type="file"
                    onChange={(e) => {
                      uploadImage(
                        e,
                        setProfilePicWhitePng,
                        setProfilePicWhitePngLoading
                      );
                    }}
                    accept="image/*"
                  />
                  <div className="text">Profile Pic White PNG</div>
                  <div className="hovTxt">
                    Upload
                    <br />
                    New
                  </div>
                </label>
                <label className="fileInp fullLogo">
                  <img
                    className={`white ${Boolean(coverPhoto)}`}
                    src={
                      coverPhotoLoading
                        ? loadingGif
                        : coverPhoto || cloudUploadIcon
                    }
                    alt=""
                  />
                  <input
                    type="file"
                    onChange={(e) => {
                      uploadImage(e, setCoverPhoto, setCoverPhotoLoading);
                    }}
                    accept="image/*"
                  />
                  <div className="text">Cover Photo</div>
                  <div className="hovTxt">
                    Upload
                    <br />
                    New
                  </div>
                </label>
              </div>
              <div className="name">Address</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="name">Display Name</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Display Name"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                />
              </div>
              <div className="name">Phone Number</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="name">Enter Colour Code</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Colour Code"
                  value={primaryColor}
                  onChange={(e) => setPrimaryColor(e.target.value)}
                />
                <div
                  className="color"
                  style={{ width: "10%", background: `#${primaryColor}` }}
                />
              </div>
              <div className="name">Cognito Name</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Cognito Name"
                  value={cognitoName}
                  onChange={(e) => setCognitoName(e.target.value)}
                />
              </div>
              <div className="name">Description</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Phone Number"
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                />
              </div>
              <div className="name">Country</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
              <div className="name">LX Profile ID</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter LX Profile ID"
                  value={lxProfileId}
                  onChange={(e) => setLxProfileId(e.target.value)}
                />
              </div>
              <div className="name">GX Banker Profile ID</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter GX Banker Profile ID"
                  value={gxBankerProfileId}
                  onChange={(e) => setGxBankerProfileId(e.target.value)}
                />
              </div>
              <div className="name">Auto Deposit</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Auto Deposit"
                  value={autoDeposit}
                  onChange={(e) => setAutoDeposit(e.target.value)}
                />
              </div>
              <div className="name">Category</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />
              </div>
              <div className="name">Sub Category</div>
              <div className="inputWrap">
                <input
                  type="text"
                  className="text"
                  placeholder="Enter Sub Category"
                  value={subCategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                />
              </div>
              <div className="name">Followers</div>
              <div className="inputWrap">
                <input
                  type="number"
                  className="text"
                  placeholder="Enter Followers"
                  value={followers}
                  onChange={(e) => setFollowers(e.target.value)}
                />
              </div>
              <div className="space"></div>
            </Scrollbars>
            <div className="ftBtns" style={{ margin: "0 -40px" }}>
              <div
                className="btnSubmit"
                style={{
                  flex: 1,
                  borderStyle: "solid none none none",
                  borderWidth: 1,
                  borderColor: "#e7e7e780",
                }}
                onClick={() => validate(true)}
              >
                Submit Edits
              </div>
            </div>
          </>
        );
    }
  }

  function updatebanker() {
    setLoading(true);
    Axios.put(
      `https://teller2.apimachine.com/lxUser/update/banker`,
      {
        bankerTag: bankerTag === banker?.bankerTag ? undefined : bankerTag,
        address: address === banker?.address ? undefined : address,
        displayName:
          displayName === banker?.displayName ? undefined : displayName,
        phone: phone === banker?.phone ? undefined : phone,
        colorCode:
          primaryColor === banker?.colorCode ? undefined : primaryColor,
        cognitoName:
          cognitoName === banker?.cognitoName ? undefined : cognitoName,
        description: desc === banker?.description ? undefined : desc,
        country: country === banker?.country ? undefined : country,
        LXAppProfileID:
          lxProfileId === banker?.LXAppProfileID ? undefined : lxProfileId,
        BankerGXProfile:
          gxBankerProfileId === banker?.BankerGXProfile
            ? undefined
            : gxBankerProfileId,
        autoDeposit:
          (autoDeposit === "true") === banker?.autoDeposit
            ? undefined
            : autoDeposit === "true", //To Be Fixed Later
        category: category === banker?.category ? undefined : category,
        subCategory:
          subCategory === banker?.subCategory ? undefined : subCategory,
        bankerFollowersCount:
          followers === banker?.bankerFollowersCount ? undefined : followers,
        profilePicURL:
          profilePic === banker?.profilePicURL ? undefined : profilePic,
        profilePicPNG:
          profilePicPng === banker?.profilePicPNG ? undefined : profilePicPng,
        profilePicWhite:
          profilePicWhite === banker?.profilePicWhite
            ? undefined
            : profilePicWhite,
        profilePicWhitePNG:
          profilePicWhitePng === banker?.profilePicWhitePNG
            ? undefined
            : profilePicWhitePng,
        coverPicURL:
          coverPhoto === banker?.coverPicURL ? undefined : coverPhoto,
      },
      {
        headers: {
          email,
          token,
        },
      }
    )
      .then(({ data }) => {
        if (data.status) {
          tostShowOn("banker Updated");
          setStep("updateSucces");
        } else {
          tostShowOn(data.message);
        }
      })
      .finally(() => setLoading(false));
  }

  function validate() {
    if (
      (bankerTag,
      emailBanker,
      address,
      displayName,
      phone,
      primaryColor,
      cognitoName,
      desc,
      country,
      lxProfileId,
      gxBankerProfileId,
      autoDeposit,
      category,
      subCategory,
      followers,
      profilePic,
      profilePicPng,
      profilePicWhite,
      profilePicWhitePng,
      coverPhoto)
    ) {
      updatebanker();
    } else {
      tostShowOn("All Fields Are Mandatory");
    }
  }

  return (
    <>
      <div className="newConglomerate">{getContent()}</div>
      {loading && <LoadingAnim />}{" "}
    </>
  );
}

export default EditBanker;
