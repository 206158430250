import React, { useContext, useState } from "react";
import Axios from "axios";
import JsonWebToken from "jsonwebtoken";
import Scrollbars from "react-custom-scrollbars";
import { useHistory } from "react-router";
import cloudUploadIcon from "../../static/images/postClipArt/cloudUpload.svg";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import { MainContext } from "../../context/MainContext";
import loadingGif from "../../static/animations/loading.gif";
import OperatorList from "./OperatorList";
import ConglomerateList from "./ConglomerateList";
import WebsiteInput from "../WebsiteInput";
import { useMutation } from "react-query";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

async function chckAppCode(code) {
  const { data } = await axios.get(
    "https://comms.globalxchange.io/gxb/apps/get",
    {
      params: {
        app_code: code,
      },
    }
  );
  return data;
}

function NewTokenAppLite() {
  const history = useHistory();
  const { tostShowOn, email, token } = useContext(MainContext);
  const [step, setStep] = useState("");
  const [loading, setLoading] = useState(false);

  // Form Variables
  const [icon, setIcon] = useState("");
  const [whiteLogo, setWhiteLogo] = useState("");
  const [name, setName] = useState("");
  const [appCode, setAppCode] = useState("");
  const [appStartCode, setAppStartCode] = useState("");
  const [website, setWebsite] = useState("");
  const [operator, setOperator] = useState("");
  const [group, setGroup] = useState("");

  const [iconLoading, setIconLoading] = useState(false);
  const [whiteLogoLoading, setWhiteLogoLoading] = useState(false);

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
    setLoading(false);
  };

  const {
    mutate: checkAppCode,
    data: appCodeData,
    reset: resetAppCode,
  } = useMutation(chckAppCode, {});

  function getContent() {
    switch (step) {
      case "success":
        setTimeout(() => {
          history.push("/xid");
          window.location.reload();
        }, 2000);
        return (
          <div className="newConglomerate">
            <div className="succesView">
              <div className="labelItm">
                You Have Successfully Created {name}. You Will Be Redirected To
                The Updated TokenApp App List Automatically
              </div>
            </div>
          </div>
        );
      case "operator":
        return (
          <div className="newConglomerate">
            <OperatorList
              setOperator={setOperator}
              onClose={() => setStep("")}
            />
          </div>
        );
      case "conglomerate":
        return (
          <div className="newConglomerate">
            <ConglomerateList setGroup={setGroup} onClose={() => setStep("")} />
          </div>
        );
      default:
        return (
          <>
            <div className="newConglomerate">
              <Scrollbars
                className="scrollForm"
                renderTrackHorizontal={() => <div />}
                renderThumbHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
                renderThumbVertical={() => <div />}
              >
                <div className="name">Select GXLive Operator</div>
                {operator ? (
                  <div className="user" onClick={() => setStep("operator")}>
                    <img className="dp" src={operator?.brand_logo} alt="" />
                    <div className="userDetail">
                      <div className="name">{operator?.brand_name}</div>
                      <div className="email">{operator?.email}</div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="inputWrap"
                    onClick={() => setStep("operator")}
                  >
                    <div className="label">
                      Click Here To Select An Operator
                    </div>
                  </div>
                )}
                <div className="name">Select Conglomerate</div>
                {group ? (
                  <div className="user" onClick={() => setStep("conglomerate")}>
                    <img className="dp" src={group?.icon} alt="" />
                    <div className="userDetail">
                      <div className="name">{group?.groupname}</div>
                      <div className="email">{group?.email}</div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="inputWrap"
                    onClick={() => setStep("conglomerate")}
                  >
                    <div className="label">
                      Click Here To Select An Conglomerate
                    </div>
                  </div>
                )}
                <div className="name">Enter App Code</div>
                <div className="inputWrap">
                  <input
                    type="text"
                    className="text"
                    placeholder="Enter Your App Code"
                    value={appCode}
                    onChange={(e) => {
                      setAppCode(e.target.value);
                      resetAppCode();
                    }}
                  />
                  <div
                    className="btnCheck"
                    onClick={() => {
                      appCodeData?.status
                        ? resetAppCode()
                        : checkAppCode(appCode);
                    }}
                  >
                    {appCodeData?.status ? (
                      <FontAwesomeIcon icon={faSpinner} />
                    ) : (
                      "Check"
                    )}
                  </div>
                  {appCodeData?.status ? (
                    <div
                      className={`statusBar ${Boolean(appCodeData?.count)}`}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="name">Upload Branding Material</div>
                <div className="filesUpload">
                  <label className="fileInp icon">
                    <img
                      className={`${Boolean(icon)}`}
                      src={iconLoading ? loadingGif : icon || cloudUploadIcon}
                      alt=""
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setIcon, setIconLoading);
                      }}
                      accept="image/*"
                    />
                    <div className="text">Coloured Icon</div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                  <label className="fileInp cover">
                    <img
                      className={`${Boolean(whiteLogo)}`}
                      src={
                        whiteLogoLoading
                          ? loadingGif
                          : whiteLogo || cloudUploadIcon
                      }
                      alt=""
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setWhiteLogo, setWhiteLogoLoading);
                      }}
                      accept="image/*"
                    />
                    <div className="text">White Logo</div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                  <label
                    className="fileInp icon"
                    style={{
                      visibility: "hidden",
                    }}
                  />
                </div>
                <div className="name">Enter App Name</div>
                <div className="inputWrap">
                  <input
                    type="text"
                    className="text"
                    placeholder="Enter An App Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="name">Enter App Start Code</div>
                <div className="inputWrap">
                  <input
                    type="text"
                    className="text"
                    placeholder="Enter An App Name"
                    value={appStartCode}
                    onChange={(e) => setAppStartCode(e.target.value)}
                  />
                </div>
                <div className="name">Enter App Website</div>
                <WebsiteInput website={website} setWebsite={setWebsite} />
                <div className="space"></div>
              </Scrollbars>
            </div>
            <div className="ftBtns">
              <div className="newField" onClick={() => {}}>
                New Field
              </div>
              <div className="btnSubmit" onClick={() => validate(true)}>
                Submit
              </div>
            </div>
          </>
        );
    }
  }

  function addTokenApp() {
    console.log(operator, "hjjdhwbed");
    setLoading(true);
    Axios.post("https://comms.globalxchange.io/gxb/apps/create", {
      email: operator?.email,
      token,
      app_code: appCode,
      app_name: name,
      profile_start_code: appStartCode,
      app_icon: icon,
      operatorid: operator?.operator_id,
      parent_group_id: group?.group_id,
      white_logo: whiteLogo,
      website: website,
    })
      .then(({ data }) => {
        if (data.status === false) {
          tostShowOn(data.message || "API Error");
        } else setStep("success");
      })
      .catch((error) => {
        tostShowOn(error?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  }

  function validate() {
    if (
      (icon, whiteLogo, name, website, operator, group, appCode, appStartCode)
    ) {
      addTokenApp();
    } else {
      tostShowOn("All Fields Are Mandatory");
    }
  }

  return (
    <>
      {getContent()}
      {loading && <LoadingAnim />}{" "}
    </>
  );
}

export default NewTokenAppLite;
