import Axios from "axios";
import React, { useContext, useState } from "react";
import { MainContext } from "../../context/MainContext";
import { FormatCurrency } from "../../utils/FunctionTools";

function CreateBond({ coinToChange, getRates, getBondData }) {
  const { email, token, tostShowOn } = useContext(MainContext);
  const [step, setStep] = useState(0);
  const [bondValue, setBondValue] = useState("");
  const [coinData, setCoinData] = useState({});
  const [fee, setFee] = useState("");
  function setValue() {
    Axios.post(
      "https://comms.globalxchange.io/coin/iced/admin/contract/value/set",
      {
        email: email,
        token: token,
        coin: coinToChange.coinSymbol,
        amount: parseFloat(bondValue),
      }
    ).then(({ data }) => {
      tostShowOn(data.message);
    });
  }
  function configureRate() {
    Axios.post(
      "https://comms.globalxchange.io/coin/iced/admin/contract/interest/rate/compressor",
      {
        email: email,
        token: token,
        coin: coinToChange.coinSymbol,
        base_compression_rate: parseFloat(coinData.base_compression_rate),
        base_velocity: parseFloat(coinData.base_velocity),
        acceleration: parseFloat(coinData.acceleration),
        base_interest_rate: parseFloat(coinData.base_interest_rate),
        longevity_bonus_rate: parseFloat(coinData.longevity_bonus_rate),
      }
    ).then(({ data }) => {
      tostShowOn(data.message);
    });
  }
  function configureFee() {
    Axios.post(
      "https://comms.globalxchange.io/coin/iced/admin/contract/daily/fee/set",
      {
        email: email,
        token: token,
        coin: coinToChange.coinSymbol,
        fee: parseFloat(fee),
      }
    ).then(({ data }) => {
      tostShowOn(data.message);
      if (data.status) {
        try {
          getRates();
          getBondData();
        } catch (error) {}
      }
    });
  }
  function getStep(step) {
    switch (step) {
      case 0:
        return (
          <div className="sidebarContent createBond" key="1">
            <div className="colTitle">
              <div className="title">Bond Registration</div>
              <div className="subTitle">What Is The Cost Of One Bond?</div>
            </div>
            <div className="fullBox">
              <input
                value={bondValue}
                onChange={(e) => setBondValue(e.target.value)}
                type="text"
                placeholder={FormatCurrency(0, coinToChange?.coinSymbol)}
              />
              <div className="label">
                <img src={coinToChange.coinImage} alt="" />
                <span>{coinToChange.coinName}</span>
              </div>
            </div>
            <div className="cntRow">
              <div className="boxWrap simulate">
                <div
                  className="value"
                  onClick={() => {
                    setStep(1);
                    setValue();
                  }}
                >
                  Next
                </div>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="sidebarContent" key="2">
            <div className="colTitle">
              <div className="title">Bond Registration</div>
              <div className="subTitle">
                Configure Interest Rate Mechanics For Standard Bonds
              </div>
            </div>
            <div className="colGroup">
              <div className="rowLabel">
                <span className="label">Compression Rate</span>
                <span className="label">Base Velocity</span>
              </div>
              <div className="cntRow">
                <div className="boxWrap">
                  <input
                    type="text"
                    className="value"
                    placeholder="0.00"
                    value={coinData.base_compression_rate}
                    onChange={(e) => {
                      e.persist();
                      setCoinData((data) => ({
                        ...data,
                        base_compression_rate: e.target.value,
                      }));
                    }}
                  />
                  <div className="percentLabel">%</div>
                </div>
                <div className="boxWrap">
                  <input
                    type="text"
                    className="value"
                    placeholder="0.00"
                    value={coinData.base_velocity}
                    onChange={(e) => {
                      e.persist();
                      setCoinData((data) => ({
                        ...data,
                        base_velocity: e.target.value,
                      }));
                    }}
                  />
                  <div className="percentLabel">%</div>
                </div>
              </div>
            </div>
            <div className="colGroup">
              <div className="rowLabel">
                <span className="label">Acceleration</span>
              </div>
              <div className="cntRow">
                <div className="boxWrap">
                  <input
                    type="text"
                    className="value"
                    placeholder="0.00"
                    value={coinData.acceleration}
                    onChange={(e) => {
                      e.persist();
                      setCoinData((data) => ({
                        ...data,
                        acceleration: e.target.value,
                      }));
                    }}
                  />
                  <div className="percentLabel">%</div>
                </div>
              </div>
            </div>
            <div className="cntRow">
              <div className="boxWrap simulate">
                <div className="value" onClick={() => setStep(2)}>
                  Next
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="sidebarContent" key="3">
            <div className="colTitle">
              <div className="title">Bond Registration</div>
              <div className="subTitle">
                Configure Interest Rate Mechanics For Secondary Bonds
              </div>
            </div>
            <div className="colGroup">
              <div className="rowLabel">
                <span className="label">Base Interest Rate</span>
              </div>
              <div className="cntRow">
                <div className="boxWrap">
                  <input
                    type="text"
                    className="value"
                    placeholder="0.00"
                    value={coinData.base_interest_rate}
                    onChange={(e) => {
                      e.persist();
                      setCoinData((data) => ({
                        ...data,
                        base_interest_rate: e.target.value,
                      }));
                    }}
                  />
                  <div className="percentLabel">%</div>
                </div>
              </div>
            </div>
            <div className="colGroup">
              <div className="rowLabel">
                <span className="label">Longevity Bonus Rate</span>
              </div>
              <div className="cntRow">
                <div className="boxWrap">
                  <input
                    type="text"
                    className="value"
                    placeholder="0.00"
                    value={coinData.longevity_bonus_rate}
                    onChange={(e) => {
                      e.persist();
                      setCoinData((data) => ({
                        ...data,
                        longevity_bonus_rate: e.target.value,
                      }));
                    }}
                  />
                  <div className="percentLabel">%</div>
                </div>
              </div>
            </div>
            <div className="cntRow">
              <div className="boxWrap simulate">
                <div
                  className="value"
                  onClick={() => {
                    setStep(3);
                    configureRate();
                  }}
                >
                  Next
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="sidebarContent" key="4">
            <div className="colTitle">
              <div className="title">Bond Registration</div>
              <div className="subTitle">Establish Broker Fees</div>
            </div>
            <div className="colGroup">
              <div className="rowLabel single">
                <span className="label">
                  What Are The Fees Deducted For BrokerApp?
                </span>
              </div>
              <div className="cntRow single">
                <div className="boxWrap">
                  <input
                    type="text"
                    className="value"
                    placeholder="0.00"
                    value={fee}
                    onChange={(e) => setFee(e.target.value)}
                  />
                  <div className="percentLabel">%</div>
                </div>
              </div>
            </div>
            <div className="cntRow">
              <div className="boxWrap simulate">
                <div
                  className="value"
                  onClick={() => {
                    setStep(4);
                    configureFee();
                  }}
                >
                  Next
                </div>
              </div>
            </div>
          </div>
        );

      default:
        break;
    }
  }
  return <>{getStep(step)}</>;
}

export default CreateBond;
