import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useEffect } from "react";
// import NavTabs from "../NavTabs/NavTabs";
import { TabsContext } from "../../context/TabsContext";

function Navbars({
  contents,
  logoNtext,
  onChangeDropdown,
  onChangeTab,
  newTag,
  onNew,
  changeTab,
}) {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [dropList, setDropList] = useState([]);
  const [dropSelected, setDropSelected] = useState("");
  const [tabList, setTabList] = useState([]);
  const [tabSelected, setTabSelected] = useState("");
  const { navTabs } = useContext(TabsContext);

  useEffect(() => {
    setDropList(Object.keys(contents));
    setDropSelected(Object.keys(contents) && Object.keys(contents)[0]);
  }, [contents]);

  useEffect(() => {
    if (dropSelected) {
      setTabList(contents && contents[dropSelected]);
      setTabSelected(
        contents && contents[dropSelected] && contents[dropSelected][0]
      );
      onChangeDropdown(dropSelected);
    }
  }, [dropSelected, contents]);

  useEffect(() => {
    if (tabSelected) {
      onChangeTab(tabSelected);
    }
  }, [tabSelected]);

  useEffect(() => {
    if (changeTab) {
      setTabSelected(changeTab);
    }
  }, [changeTab]);

  return (
    <nav>
      <div className="bets-dash-navbar">
        {/* <NavTabs /> */}
        {navTabs}
        {/* <div
          className="new-project"
          onClick={() => {
            try {
              onNew();
            } catch (error) {}
          }}
        >
          <h5>
            <FontAwesomeIcon icon={faPlus} />
            <div className=" my-auto ml-2">New {newTag}</div>
          </h5>
        </div> */}
      </div>
      <div className="nav-bottom">
        <div className="tab">
          {tabList.map((tabItm) => (
            <div
              className={"tab-itm " + (tabSelected === tabItm)}
              onClick={() => setTabSelected(tabItm)}
            >
              <h6>{tabItm}</h6>
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
}

export default Navbars;
