import React, { useState, useEffect, useContext } from "react";
import { TabsContext } from "../../context/TabsContext";
// import NavTabs from "../NavTabs/NavTabs";

function NavbarWithdrawals({
  tabs,
  changeTab,
  logoNtext,
  onChangeTab,
  dropDownOpen,
  search,
  setSearch,
  button,
  viewSwitcher,
  tabClick,
}) {
  const [tabList, setTabList] = useState([]);
  const [tabSelected, setTabSelected] = useState("");
  const { navTabs } = useContext(TabsContext);

  useEffect(() => {
    setTabList(tabs);
    setTabSelected(tabs[0]);
  }, [tabs]);

  useEffect(() => {
    if (tabSelected) {
      onChangeTab(tabSelected);
    }
  }, [tabSelected]);

  useEffect(() => {
    if (changeTab) {
      setTabSelected(changeTab);
    }
  }, [changeTab]);

  return (
    <nav>
      <div className="bets-dash-navbar">
        {/* <NavTabs /> */}
        {navTabs}
        {button}
      </div>
      <div className="nav-bottom">
        <div className="tab">
          {tabList.map((tabItm) => (
            <div
              className={"tab-itm " + (tabSelected === tabItm)}
              onClick={() => {
                setTabSelected(tabItm);
                tabClick();
              }}
            >
              <h6>{tabItm}</h6>
            </div>
          ))}
        </div>
        {viewSwitcher}
        {dropDownOpen && (
          <input
            type="text"
            className="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Publications.."
          />
        )}
      </div>
    </nav>
  );
}

export default NavbarWithdrawals;
