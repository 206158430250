import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

function CommerceMarketplaceFooter({
  list,
  setFilterBy,
  filterBy,
  filterTypes,
  filter,
  setFilter,
  count,
}) {
  console.log(`filter`, {}, filter, filter === {}, list);

  return (
    <div className="listsFooter">
      <div className="filterBox">
        {filterBy} <FontAwesomeIcon icon={faCaretDown} />
      </div>
      <div className="vertScrolllList">
        <div
          className={`listItem ${!filter.name}`}
          onClick={() => setFilter({})}
        >
          <img
            src="https://avatars.dicebear.com/api/initials/A.svg?b=%23002a51"
            alt=""
            className="icon"
          />
          <span>All {filterBy}</span>
        </div>
        {list.map((item) => (
          <div
            className={`listItem ${filter._id === item._id}`}
            onClick={() => setFilter(item)}
          >
            <img
              src={
                item.icon ||
                `https://avatars.dicebear.com/api/initials/${item.name}.svg?b=%23002a51`
              }
              alt=""
              className="icon"
            />
            <span>{item.name}</span>
          </div>
        ))}
      </div>
      <div className="countBox">{count}</div>
    </div>
  );
}

export default CommerceMarketplaceFooter;
