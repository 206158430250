import { IconsViewSwitcher, Navbar } from "../components/BrokerDashboard/index";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import GodsIoApps from "../components/GodsIoApps/GodsIoApps";
import { TabsContext } from "../context/TabsContext";

import MainLayout from "../layouts/MainLayout";

import { ReactComponent as IconClose } from "../static/images/clipIcons/close.svg";
import { ReactComponent as RefreshIcn } from "../static/images/clipIcons/refresh.svg";

const tabs = ["Apps"];

function AppsPage({ hide }) {
  const history = useHistory();
  const { navTabs } = useContext(TabsContext);

  const [list, setList] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [search, setSearch] = useState("");

  const [searchOn, setSearchOn] = useState(false);

  const [tabClick, setTabClick] = useState(false);
  const [tabSelected, setTabSelected] = useState("Apps");

  return (
    <MainLayout active={"Apps"} hide={hide}>
      <Navbar
        navTabs={navTabs}
        tabs={tabs}
        tabSelected={tabSelected}
        setTabSelected={(tab) => setTabSelected(tab)}
        search={search}
        setSearch={setSearch}
        viewSwitcher={
          <IconsViewSwitcher
            listIcn={list && <IconClose />}
            boardIcn={<RefreshIcn />}
            board={refresh}
            onBoardClick={() => {
              if (refresh) setRefresh(false);
              else {
                setRefresh(true);
                setList(false);
              }
            }}
            list={list}
            onListClick={() => {
              if (list) setList(false);
              else {
                setRefresh(false);
              }
            }}
            search={searchOn}
            onSearchClick={() => setSearchOn(!searchOn)}
          />
        }
        tabClick={() => {
          setTabClick(!tabClick);
        }}
        searchPlaceHolder=""
      />
      {/* <GodsIoApps searchOn={searchOn} list={list} setList={setList} /> */}
    </MainLayout>
  );
}

export default AppsPage;
