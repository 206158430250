import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom";

import society from "../static/images/logos/society.svg";
import mainLogo from "../static/images/logos/civilisationFullLogo.svg";
import vaultFullLogo from "../static/images/logos/vaultFullLogo.svg";
import connectLogo from "../static/images/logos/connectLogo.svg";
import moneyMarkets from "../static/images/logos/moneyMarkets.svg";
import terminalsLogo from "../static/images/logos/terminalsLogo.svg";
import blockcheckLogo from "../static/images/logos/blockcheckLogo.svg";
import brokerLogo from "../static/images/logos/brokerLogo.svg";
import mallsLogo from "../static/images/logos/mallsLogo.svg";
import { ReactComponent as PasteIcon } from "../static/images/clipIcons/paste.svg";

const apps = {
  connect: {
    logo: connectLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  blockcheck: {
    logo: blockcheckLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  moneymarkets: {
    logo: moneyMarkets,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  vault: {
    logo: vaultFullLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  terminals: {
    logo: terminalsLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  brokerapp: {
    logo: brokerLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  malls: {
    logo: mallsLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
};

function PrintRecieptApp() {
  const history = useHistory();
  const { app } = useParams();
  const [search, setSearch] = useState("");
  return (
    <>
      <nav className="navHeader">
        <img
          src={society}
          alt="vaultLogo"
          className="vaultLogo"
          onClick={() => history.push("/sp")}
        />
        <Scrollbars
          className="appListWrapper"
          renderThumbHorizontal={() => <div />}
          renderThumbVertical={() => <div />}
          renderTrackHorizontal={() => <div />}
          renderTrackVertical={() => <div />}
        >
          {Object.keys(apps)
            .filter((key) => key !== app)
            .map((key) => (
              <div
                key={key}
                className="app"
                onClick={() => history.push(`/sp/${key}`)}
              >
                <img src={apps[key].logo} alt="" />
              </div>
            ))}
        </Scrollbars>
      </nav>
      <div className="recieptAppContent">
        <div className="contentArea">
          <img src={apps[app].logo} alt="" className="logo" />
          <div className="desc">{apps[app].text}</div>
          <label className="search">
            <input
              type="text"
              className="textInput"
              placeholder="Enter The Vault Identification Number"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") history.push(`/sp/${app}/${search}`);
              }}
            />
            <PasteIcon
              onClick={() => {
                navigator.clipboard
                  .readText()
                  .then((clipText) => setSearch(clipText));
              }}
            />
            <FontAwesomeIcon
              icon={faSearch}
              onClick={() => history.push(`/sp/${app}/${search}`)}
            />
          </label>
        </div>
        <img
          src={require("../static/images/bgs/bg.jpg")}
          alt=""
          className="bgImg"
        />
      </div>
    </>
  );
}

export default PrintRecieptApp;
