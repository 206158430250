import React from "react";
import video from "../../static/images/postClipArt/video.svg";
import article from "../../static/images/postClipArt/article.svg";

function NewPostLander({ setPostType }) {
  return (
    <div className="newPostLander">
      <div className="post" onClick={() => setPostType("video")}>
        <img src={video} alt="" />
        <div className="name">Video</div>
      </div>
      <div className="post" onClick={() => setPostType("article")}>
        <img src={article} alt="" />
        <div className="name">Article</div>
      </div>
    </div>
  );
}

export default NewPostLander;
