import React, { useContext, useState, useEffect } from "react";
import Axios from "axios";
import JsonWebToken from "jsonwebtoken";
import Scrollbars from "react-custom-scrollbars";
import { useHistory } from "react-router";
import cloudUploadIcon from "../../static/images/postClipArt/cloudUpload.svg";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import { MainContext } from "../../context/MainContext";
import loadingGif from "../../static/animations/loading.gif";
import back from "../../static/images/back.svg";
import close from "../../static/images/close.svg";
import Skeleton from "react-loading-skeleton";
import BankerList from "./BankerList";
import WebsiteInput from "../WebsiteInput";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

function NewTerminalApp({ onClose, onBack }) {
  const history = useHistory();
  const { tostShowOn, email, token } = useContext(MainContext);
  const [step, setStep] = useState("");
  const [loading, setLoading] = useState(false);

  // Form Variables
  const [icon, setIcon] = useState("");
  const [logo, setLogo] = useState("");
  const [whiteIcon, setWhiteIcon] = useState("");
  const [whiteLogo, setWhiteLogo] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");
  const [primaryColor, setPrimaryColor] = useState("");
  const [website, setWebsite] = useState("");
  const [terminalName, setTerminalName] = useState("");
  const [desc, setDesc] = useState("");
  const [country, setCountry] = useState("");
  const [pairs, setPairs] = useState([]);
  const [banker, setBanker] = useState();

  const [iconLoading, setIconLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [whiteIconLoading, setWhiteIconLoading] = useState(false);
  const [whiteLogoLoading, setWhiteLogoLoading] = useState(false);
  const [coverPhotoLoading, setCoverPhotoLoading] = useState(false);

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
    setLoading(false);
  };
  const [pairsLoading, setPairsLoading] = useState(true);
  const [pairsList, setPairsList] = useState([]);
  useEffect(() => {
    setPairsLoading(true);
    Axios.get("https://api.terminals.dev/coin-pair")
      .then(({ data }) => {
        setPairsList(data);
      })
      .finally(() => setPairsLoading(false));
  }, []);

  const [search, setSearch] = useState("");
  function getContent() {
    switch (step) {
      case "success":
        setTimeout(() => {
          history.push("/society/Terminal Apps");
          window.location.reload();
        }, 2000);
        return (
          <div className="newConglomerate">
            <div className="succesView">
              <div className="labelItm">
                You Have Successfully Created {terminalName}. You Will Be
                Redirected To The Updated Terminals App List Automatically
              </div>
            </div>
          </div>
        );
      case "banker":
        return (
          <div className="newConglomerate">
            <BankerList setBanker={setBanker} onClose={() => setStep("")} />
          </div>
        );
      case "pairs":
        return (
          <>
            <div className="newConglomerate">
              <div className="title">Add Local Pairs To Terminal</div>
              <input
                type="text"
                className="searchBox"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Pairs....|"
              />
              <Scrollbars className="searchList">
                {pairsLoading
                  ? Array(6)
                      .fill("")
                      .map((_, i) => (
                        <div className="user" key={i}>
                          <Skeleton className="dp" circle />
                          <div className="userDetail">
                            <Skeleton className="name" width={200} />
                            <Skeleton className="email" width={200} />
                          </div>
                        </div>
                      ))
                  : pairsList
                      .filter(
                        (coinPair) =>
                          coinPair.base
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          coinPair.quote
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                          coinPair.pair
                            .toLowerCase()
                            .includes(search.toLowerCase())
                      )
                      .map((coinPair) => (
                        <div
                          className={`user ${pairs.includes(coinPair.pair)}`}
                          key={coinPair.id}
                          onClick={() => {
                            if (pairs.includes(coinPair.pair)) {
                              setPairs((prev) =>
                                prev.filter((pair) => pair !== coinPair.pair)
                              );
                            } else {
                              setPairs((prev) => [...prev, coinPair.pair]);
                            }
                          }}
                        >
                          <img className="dp" src={coinPair.image} alt="" />
                          <div className="userDetail">
                            <div className="name">{coinPair.pair}</div>
                          </div>
                        </div>
                      ))}
                <div className="space"></div>
              </Scrollbars>
            </div>
            <div className="btnSubmit" onClick={() => validate()}>
              Submit Terminal
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="newConglomerate">
              <Scrollbars
                className="scrollForm"
                renderTrackHorizontal={() => <div />}
                renderThumbHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
                renderThumbVertical={() => <div />}
              >
                <div className="name">Terminal Name</div>
                <div className="inputWrap">
                  <input
                    type="text"
                    className="text"
                    placeholder="Enter Name"
                    value={terminalName}
                    onChange={(e) => setTerminalName(e.target.value)}
                  />
                </div>
                <div className="name">Describe The Terminal</div>
                <div className="inputWrap">
                  <input
                    type="text"
                    className="text"
                    placeholder="Enter Description Here"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </div>
                <div className="name">Upload Branding Material</div>
                <div className="filesUpload">
                  <label className="fileInp icon">
                    <img
                      className={`${Boolean(icon)}`}
                      src={iconLoading ? loadingGif : icon || cloudUploadIcon}
                      alt=""
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setIcon, setIconLoading);
                      }}
                      accept="image/*"
                    />
                    <div className="text">Colored Icon</div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                  <label className="fileInp icon">
                    <img
                      className={`white ${Boolean(whiteIcon)}`}
                      src={
                        whiteIconLoading
                          ? loadingGif
                          : whiteIcon || cloudUploadIcon
                      }
                      alt=""
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setWhiteIcon, setWhiteIconLoading);
                      }}
                      accept="image/*"
                    />
                    <div className="text">White Icon</div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                  <label className="fileInp cover">
                    <img
                      className={`${Boolean(coverPhoto)}`}
                      src={
                        coverPhotoLoading
                          ? loadingGif
                          : coverPhoto || cloudUploadIcon
                      }
                      alt=""
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setCoverPhoto, setCoverPhotoLoading);
                      }}
                      accept="image/*"
                    />
                    <div className="text">Cover Photo</div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                </div>
                <div className="filesUpload last">
                  <label className="fileInp fullLogo">
                    <img
                      className={`${Boolean(logo)}`}
                      src={logoLoading ? loadingGif : logo || cloudUploadIcon}
                      alt=""
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setLogo, setLogoLoading);
                      }}
                      accept="image/*"
                    />
                    <div className="text">Colored Logo</div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                  <label className="fileInp fullLogo">
                    <img
                      className={`white ${Boolean(whiteLogo)}`}
                      src={
                        whiteLogoLoading
                          ? loadingGif
                          : whiteLogo || cloudUploadIcon
                      }
                      alt=""
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setWhiteLogo, setWhiteLogoLoading);
                      }}
                      accept="image/*"
                    />
                    <div className="text">White Logo</div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                </div>
                <div className="name">Enter The Website</div>
                <WebsiteInput website={website} setWebsite={setWebsite} />
                <div className="name">Enter The Country</div>
                <div className="inputWrap">
                  <input
                    type="text"
                    className="text"
                    placeholder="Enter Country Here"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>
                <div className="name">Enter Primary Color Code</div>
                <div className="inputWrap">
                  <input
                    type="text"
                    className="text"
                    placeholder="Enter Code Here..."
                    value={primaryColor}
                    onChange={(e) => setPrimaryColor(e.target.value)}
                  />
                  <div
                    className="color"
                    style={{ width: "10%", background: `#${primaryColor}` }}
                  />
                </div>
                <div className="name">Select The Founding Banker</div>
                {banker ? (
                  <div className="user" onClick={() => setStep("banker")}>
                    <img className="dp" src={banker?.profilePicURL} alt="" />
                    <div className="userDetail">
                      <div className="name">{banker?.bankerTag}</div>
                      <div className="email">{banker?.email}</div>
                    </div>
                  </div>
                ) : (
                  <div className="inputWrap" onClick={() => setStep("banker")}>
                    <div className="label">
                      Click Here To Select The Founding Banker
                    </div>
                  </div>
                )}
                <div className="space"></div>
              </Scrollbars>
            </div>
            <div className="btnSubmit" onClick={() => validate(true)}>
              Next Step
            </div>
          </>
        );
    }
  }

  function addTerminalApp() {
    setLoading(true);
    Axios.post(
      "https://api.terminals.dev/terminal",
      {
        name: terminalName,
        acceptedBasePairs: pairs,
        coverPic: coverPhoto,
        description: desc,
        country: country,
        website: website,
        colorCode: primaryColor,
        fullLogoColoured: logo,
        iconColoured: icon,
        fullLogoWhite: whiteLogo,
        iconWhite: whiteIcon,
      },
      {
        headers: {
          email: email,
          token: token,
        },
      }
    )
      .then(({ data }) => {
        tostShowOn("New Terminal App Added");
        setStep("success");
      })
      .catch((error) => {
        tostShowOn(error?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  }

  function validate(noPair) {
    if (
      terminalName &&
      icon &&
      coverPhoto &&
      primaryColor &&
      desc &&
      country &&
      website &&
      banker?.email &&
      (pairs.length || noPair)
    ) {
      if (noPair) {
        setStep("pairs");
      } else addTerminalApp();
    } else {
      tostShowOn("All Fields Are Mandatory");
    }
  }

  return (
    <>
      {getContent()}
      {loading && <LoadingAnim />}{" "}
    </>
  );
}

export default NewTerminalApp;
