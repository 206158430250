import React, { Fragment, useContext } from "react";
import { TabsContext } from "../context/TabsContext";
import AssetsIo from "./AssetsIo";
import EngagementPage from "./EngagementPage";
import GodsEyePage from "./GodsEyePage";
import CentralBank from "./CentralBank";
import CounselPage from "./CounselPage";
import LiquidityPage from "./LiquidityPage";
import { MainContext } from "../context/MainContext";
import BlockCheckPage from "./BlockCheckPage";
import XIDPage from "./XIDPage";
import AppsPage from "./AppsPage";
import AppsDetailPage from "./AppsDetailPage";
import MarketsPage from "./MarketsPage";
import CommercePage from "./CommercePage";
import ViralPage from "./ViralPage";
import VenturesPage from "./VenturesPage";

function SwitcherPage() {
  const { tabIndex, tabs } = useContext(TabsContext);
  const { refreshPage } = useContext(MainContext);

  function getNavHead(tab, i) {
    switch (true) {
      case /\/businesses/.test(tab.location):
        return (
          <Fragment key={`${tab.key} ${refreshPage !== "app"}`}>
            <AppsPage hide={i !== tabIndex} />
          </Fragment>
        );
      case /\/apps\/ice/.test(tab.location):
        return (
          <Fragment key={`${tab.key} ${refreshPage !== "app"}`}>
            <AssetsIo hide={i !== tabIndex} />
          </Fragment>
        );
      case /\/markets/.test(tab.location):
        return (
          <Fragment key={`${tab.key}`}>
            <MarketsPage hide={i !== tabIndex} />
          </Fragment>
        );
      case /\/apps\/counsel/.test(tab.location):
        return (
          <Fragment key={`${tab.key}`}>
            <CounselPage hide={i !== tabIndex} />
          </Fragment>
        );
      case /\/apps\/*/.test(tab.location):
        return (
          <Fragment key={`${tab.key}`}>
            <AppsDetailPage hide={i !== tabIndex} />
          </Fragment>
        );
      case /\/commerce/.test(tab.location):
        return (
          <Fragment key={`${tab.key}`}>
            <CommercePage hide={i !== tabIndex} />
          </Fragment>
        );
      case /\/ventures/.test(tab.location):
        return (
          <Fragment key={`${tab.key}`}>
            <VenturesPage hide={i !== tabIndex} />
          </Fragment>
        );
      case /\/viral/.test(tab.location):
        return (
          <Fragment key={`${tab.key}`}>
            <ViralPage hide={i !== tabIndex} />
          </Fragment>
        );
      case /\/Teamforce/.test(tab.location):
        return (
          <Fragment key={`${tab.key}`}>
            <AssetsIo hide={i !== tabIndex} />
          </Fragment>
        );
      case /\/xid/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <XIDPage hide={i !== tabIndex} />
          </Fragment>
        );
      case /\/liquidity/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <LiquidityPage hide={i !== tabIndex} />
          </Fragment>
        );
      case /\/content/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <EngagementPage hide={i !== tabIndex} />
          </Fragment>
        );
      case /\/centralbank/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <CentralBank hide={i !== tabIndex} />
          </Fragment>
        );
      case /\/blockcheck/.test(tab.location):
        return (
          <Fragment key={tab.key}>
            <BlockCheckPage hide={i !== tabIndex} />
          </Fragment>
        );
      case /\/society/.test(tab.location):
        return (
          <Fragment key={`${tab.key} ${refreshPage !== "society"}`}>
            <GodsEyePage hide={i !== tabIndex} />
          </Fragment>
        );
      default:
        break;
    }
  }
  return <>{tabs.map((tab, i) => getNavHead(tab, i))}</>;
}

export default SwitcherPage;
