import React from 'react'
import styles from './navbar.module.scss'

function Navbar({
  navTabs,
  tabs = [],
  tabSelected,
  setTabSelected,
  search,
  setSearch,
  button,
  viewSwitcher,
  tabClick,
  searchPlaceHolder,
  searchOpen
}) {
  return (
    <nav>
      <div className={`${styles.betsDashNavbar} betsDashNavbar`}>
        {navTabs}
        {button}
      </div>
      <div className={`${styles.navBottom} navBottom`}>
        <div className={styles.tab}>
          {tabs.map((tabItm) => (
            <div
              key={tabItm}
              className={
                styles.tabitm +
                ' ' +
                (tabSelected === tabItm ? styles.true : '')
              }
              onClick={() => {
                setTabSelected(tabItm)
                tabClick()
              }}
            >
              <h6>{tabItm}</h6>
            </div>
          ))}
        </div>
        {viewSwitcher}
        {searchOpen && (
          <input
            type='text'
            className={styles.search}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={searchPlaceHolder || 'Search'}
          />
        )}
      </div>
    </nav>
  )
}

export default Navbar
