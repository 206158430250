import { SearchAndFilter } from "../BrokerDashboard/index";
import React, { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { useOTCDesks } from "../../queryHooks";
import OTCDeskItem from "./OTCDeskItem";

function OTCDesksList({ list, setList, searchOn }) {
  const { data: otcDesksList, isLoading: loading } = useOTCDesks();
  const [count, setCount] = useState(10);
  const [otcSelected, setOtcSelected] = useState();
  const [search, setSearch] = useState("");
  const ref = useRef();
  useEffect(() => {
    if (searchOn) {
      setTimeout(() => {
        ref?.current && ref.current.focus();
      }, 200);
    }
  }, [searchOn]);

  const [tab, setTab] = useState("");

  useEffect(() => {
    if (otcSelected) setList(true);
    else setList(false);
  }, [otcSelected, setList]);

  useEffect(() => {
    if (!list) setOtcSelected();
  }, [list]);

  // Filter Variables
  const [currentPage, setCurrentPage] = useState(true);
  const [isFilter, setIsFilter] = useState(false);
  const [filterBankerTag, setFilterBankerTag] = useState(true);
  const [filterEmail, setFilterEmail] = useState(false);
  const [filterCountry, setFilterCountry] = useState(false);

  return (
    <>
      <div className="bankerList">
        {searchOn && (
          <SearchAndFilter
            search={search}
            setSearch={setSearch}
            placeholder="Type In Any Bankers....."
            filterBy={`${filterEmail ? "Email ," : ""}${
              filterBankerTag ? "Banker Tag ," : ""
            }${filterCountry ? "Country" : ""}`}
            mainList={[
              {
                key: "0",
                label: "Function Performed By Search Bar",
                switch: isFilter,
                switchLabel: isFilter ? "Filter" : "Search",
                switchClick: () => setIsFilter(!isFilter),
              },
              {
                key: "1",
                label: "Filter Range",
                switch: false,
                switchLabel: currentPage ? "Current Page" : "Entire List",
                switchClick: () => setCurrentPage(!currentPage),
              },
            ]}
            filterList={[
              {
                key: "10",
                label: "Banker Tag",
                switch: filterBankerTag,
                switchLabel: filterBankerTag ? "On" : "Off",
                switchClick: () => setFilterBankerTag(!filterBankerTag),
              },
              {
                key: "11",
                label: "Email",
                switch: filterEmail,
                switchLabel: filterEmail ? "On" : "Off",
                switchClick: () => setFilterEmail(!filterEmail),
              },
              {
                key: "12",
                label: "Country",
                switch: filterCountry,
                switchLabel: filterCountry ? "On" : "Off",
                switchClick: () => setFilterCountry(!filterCountry),
              },
            ]}
          />
        )}
        <Scrollbars
          autoHide
          className="bankerListWrapper"
          renderView={(props) => (
            <div {...props} className={`xchangeList ${Boolean(otcSelected)}`} />
          )}
          onScrollFrame={(data) => {
            data.top === 1 && setCount((count) => count + 10);
          }}
        >
          {loading
            ? Array.from(Array(10).keys()).map((key) => (
                <div className="userItem" key={key}>
                  <Skeleton circle width={50} height={50} />
                  <div className="nameEmail">
                    <span className="name">
                      <Skeleton />
                    </span>
                    <span className="email">
                      <Skeleton />
                    </span>
                  </div>
                  <div className="time">
                    <Skeleton />
                  </div>
                  <div className="balance">
                    <Skeleton />
                  </div>
                  <div className="balance">
                    <Skeleton />
                  </div>
                  <div className="btnActions">
                    <Skeleton className="btnAction" />
                  </div>
                </div>
              ))
            : otcDesksList
                .filter(
                  (otcDesk) =>
                    (filterBankerTag &&
                      otcDesk?.app_code
                        ?.toLowerCase()
                        .includes(search.toLowerCase())) ||
                    (filterEmail &&
                      otcDesk?.email
                        ?.toLowerCase()
                        .includes(search.toLowerCase())) ||
                    (filterCountry &&
                      otcDesk?.country
                        ?.toLowerCase()
                        .includes(search.toLowerCase()))
                )
                .slice(0, count)
                .map((otcDesk) => (
                  <OTCDeskItem
                    key={otcDesk._id}
                    otcDesk={otcDesk}
                    setTab={setTab}
                    otcSelected={otcSelected}
                    setOtcSelected={setOtcSelected}
                  />
                ))}
          <div
            className="fxClose"
            onClick={() => {
              setList(false);
              setOtcSelected();
            }}
          />
        </Scrollbars>
      </div>
      {/* {otcSelected && (
        <BankerListSidebar
          otcSelected={otcSelected}
          onClose={() => setOtcSelected()}
          setInTab={setTab}
          inTab={tab}
        />
      )} */}
    </>
  );
}
export default OTCDesksList;
