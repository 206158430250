import React, { useEffect, useRef, useState } from 'react'
import styles from './searchAndFilter.module.scss'
import { ReactComponent as SettingsIcon } from '../../images/clipIcons/settings.svg'
import { ReactComponent as CloseIcon } from '../../images/clipIcons/close.svg'
import { ReactComponent as BackIcon } from '../../images/clipIcons/back.svg'

function SearchAndFilter({
  search,
  setSearch,
  placeholder,
  filterBy,
  mainList,
  filterList
}) {
  const ref = useRef()
  useEffect(() => {
    setTimeout(() => {
      ref?.current && ref.current.focus()
    }, 200)
  }, [])
  const [settings, setSettings] = useState(false)
  const [step, setStep] = useState(0)
  function getContent() {
    switch (step) {
      case 1:
        return (
          <div className={styles.filterView}>
            {filterList?.map((item) => (
              <div key={item.key} className={styles.listItem}>
                <span className={styles.label}>{item.label}</span>
                <div className={styles.switchGroup}>
                  <div
                    className={`${styles.switch} ${item.switch && styles.on}`}
                    onClick={() => {
                      try {
                        item.switchClick()
                      } catch (error) {}
                    }}
                  >
                    <div className={styles.switchBall} />
                  </div>
                  <div className={styles.switchLabel}>{item.switchLabel}</div>
                </div>
              </div>
            ))}
          </div>
        )

      default:
        return (
          <div className={styles.filterView}>
            {mainList?.map((item) => (
              <div key={item.key} className={styles.listItem}>
                <span className={styles.label}>{item.label}</span>
                <div className={styles.switchGroup}>
                  <div
                    className={`${styles.switch} ${item.switch && styles.on}`}
                    onClick={() => {
                      try {
                        item.switchClick()
                      } catch (error) {}
                    }}
                  >
                    <div className={styles.switchBall} />
                  </div>
                  <div className={styles.switchLabel}>{item.switchLabel}</div>
                </div>
              </div>
            ))}
            <div className={styles.listItem} onClick={() => setStep(1)}>
              <span className={styles.label}>Filter By</span>
              <span className={styles.value}>{filterBy}</span>
            </div>
          </div>
        )
    }
  }

  return (
    <div className={styles.searchAndFilter}>
      <input
        className={styles.serchInp}
        ref={ref}
        type='text'
        placeholder={placeholder}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {step === 1 && (
        <div className={styles.moreFilter} onClick={() => setStep(0)}>
          <BackIcon />
        </div>
      )}
      <div className={styles.moreFilter} onClick={() => setSettings(!settings)}>
        {settings ? <CloseIcon /> : <SettingsIcon />}
      </div>
      {settings && getContent()}
    </div>
  )
}

export default SearchAndFilter
