import axios from "axios";
import JsonWebToken from "jsonwebtoken";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

export const uploadImage = async (e, setImage, setLoading) => {
  setLoading(true);
  const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
    e.target.files[0].name.lastIndexOf(".")
  )}`;
  const formData = new FormData();
  const file = renameFile(e.target.files[0], fileName);
  formData.append("files", file);
  const path_inside_brain = "root/";
  const token = JsonWebToken.sign({ name: fileName, email: emailDev }, secret, {
    algorithm: "HS512",
    expiresIn: 240,
    issuer: "gxjwtenchs512",
  });
  let { data } = await axios.post(
    `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
    formData,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
  setImage(data.payload.url);
  setLoading(false);
};
