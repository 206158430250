import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { createRef, useContext } from "react";
import { useRef } from "react";
import { MainContext } from "../../context/MainContext";
import OnOutsideClick from "../../utils/OnOutsideClick";

function FounderListItem({ founderSelected, setFounderSelected, user }) {
  const { copyToClipboard } = useContext(MainContext);
  const elRefs = useRef([]);
  if (elRefs.current.length !== 4) {
    // add or remove refs
    elRefs.current = Array(4)
      .fill()
      .map((_, i) => elRefs.current[i] || createRef());
  }

  const refOut = useRef();
  OnOutsideClick(
    undefined,
    () => {
      setFounderSelected(user);
    },
    refOut,
    elRefs
  );
  return (
    <div
      ref={refOut}
      className={`userItem ${
        !founderSelected || founderSelected?._id === user._id
      }`}
    >
      <img alt="" src={user.brand_logo} className="profileImg" />
      <div className="nameEmail">
        <span className="name">
          {user.brand_name}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[0]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(`${user.brand_name}`, `Brand Name Copied`);
            }}
          />
        </span>
        <span className="email">
          {user.operator_id}
          <FontAwesomeIcon
            forwardedRef={elRefs.current[1]}
            icon={faCopy}
            className="cpyIcn"
            onClick={() => {
              copyToClipboard(`${user.operator_id}`, `OperatorId Copied`);
            }}
          />
        </span>
      </div>
      <div className="col-md-1" />
      <div className="time">
        {user.email}
        <FontAwesomeIcon
          forwardedRef={elRefs.current[2]}
          icon={faCopy}
          className="cpyIcn"
          onClick={() => {
            copyToClipboard(`${user.email}`, `Email Copied`);
          }}
        />
      </div>
      <div className="col-md-1" />
      <div className="balance hideOpen">
        {user.brand_country}
        <FontAwesomeIcon
          forwardedRef={elRefs.current[3]}
          icon={faCopy}
          className="cpyIcn"
          onClick={() => {
            copyToClipboard(`${user.brand_country}`, `Country Copied`);
          }}
        />
      </div>
    </div>
  );
}

export default FounderListItem;
