import React, { useContext } from "react";
import { MainContext } from "../../context/MainContext";
import { useUserDetail } from "../../queryHooks";
import { FormatCurrency } from "../../utils/FunctionTools";
import classNames from "./blockChainTabContent.module.scss";
import { ReactComponent as CopyIcon } from "../../static/images/clipIcons/copy.svg";
import { ReactComponent as EtherScan } from "../../static/images/clipIcons/etherScan.svg";

function BlockUserItem({ user, coin }) {
  const { data: userData } = useUserDetail(user?.email);
  const { allApps, copyToClipboard } = useContext(MainContext);
  return (
    <div className={classNames.userItem}>
      <div className={classNames.user}>
        <img
          src={
            userData?.profile_img ||
            `https://avatars.dicebear.com/api/initials/${
              userData?.name || user.email
            }.svg`
          }
          alt=""
          className={classNames.profileImg}
        />
        <div className={classNames.nameEmail}>
          <div className={classNames.name}>{userData?.name}</div>
          <div className={classNames.email}>{user.email}</div>
        </div>
      </div>
      <div className={classNames.app}>
        <img
          src={allApps && allApps[user?.app_code]?.app_icon}
          alt=""
          className={classNames.appIcon}
        />
        <span className={classNames.appName}>
          {allApps && allApps[user?.app_code]?.app_name}
        </span>
      </div>
      <div className={classNames.addressWrap}>
        <div className={classNames.address}>
          <div className={classNames.value}>{user?.coinAddress}</div>
          <div
            className={classNames.box}
            onClick={() =>
              copyToClipboard(user?.coinAddress, "Coin Address Copied")
            }
          >
            <CopyIcon />
          </div>
          <div className={classNames.box}>
            <EtherScan />
          </div>
        </div>
      </div>
      <div className={classNames.assetsAmounts}>
        <div className={classNames.asset}>
          {FormatCurrency(user?.eth_network_balance, coin?.coinSymbol)}
          <div className={classNames.coin}>
            <img src={coin?.coinImage} alt="" />
            <span>{coin?.coinSymbol}</span>
          </div>
        </div>
        <div className={classNames.asset}>
          ${FormatCurrency(user?.eth_network_balance * coin?.usd_price, "USD")}
          <div className={classNames.coin}>
            <img
              src="https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/dollar.png"
              alt=""
            />
            <span>USD</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlockUserItem;
