import React, { useState } from "react";
import BankerTransactionFilterDisplay from "./BankerTransactionFilterDisplay";

function BankerTransactionFilter({ isList, setIsList }) {
  const [tab, setTab] = useState("Display");
  function getTabContent() {
    switch (tab) {
      case "Display":
        return (
          <BankerTransactionFilterDisplay
            isList={isList}
            setIsList={setIsList}
          />
        );
      default:
        break;
    }
  }
  return (
    <div className="godsEyeUserFiter">
      <div className="headTabs">
        <div
          className={`tab ${tab === "Display"}`}
          onClick={() => setTab("Display")}
        >
          Display
        </div>
        <div
          className={`tab ${tab === "Currency"}`}
          onClick={() => setTab("Currency")}
        >
          Currency
        </div>
        <div
          className={`tab ${tab === "Stats"}`}
          onClick={() => setTab("Stats")}
        >
          List Stats
        </div>
        <div className="tab disable">Filter 3</div>
      </div>
      {getTabContent()}
    </div>
  );
}

export default BankerTransactionFilter;
