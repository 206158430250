import React, { useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { MoreTabContext } from "../../context/MoreTabContext";
import PublicationItem from "./PublicationItem";

function PublicationList({ list, setList }) {
  const [publication, setPublication] = useState();
  const { publicationlist, publicationlistLoading } =
    useContext(MoreTabContext);
  //
  useEffect(() => {
    if (publication) setList(true);
    else setList(false);
  }, [publication, setList]);

  useEffect(() => {
    if (!list) setPublication();
  }, [list]);

  return (
    <>
      {
        <div className="godsEyeMoreList">
          <Scrollbars className="moreListWrapper">
            {publicationlistLoading
              ? Array(6)
                  .fill("")
                  .map((_, i) => (
                    <div className="listItem">
                      <Skeleton alt="" className="icon" />
                      <Skeleton className="name" width={400} />
                      <Skeleton className="count" width={30} />
                    </div>
                  ))
              : publicationlist.map((publication) => (
                  <PublicationItem
                    key={publication._id}
                    publication={publication}
                    onClick={() => setPublication(publication)}
                  />
                ))}
          </Scrollbars>
        </div>
      }
    </>
  );
}

export default PublicationList;
