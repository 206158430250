import Axios from "axios";
import React, { createContext, useEffect, useState } from "react";

export const MoreTabContext = createContext();

function MoreTabContextProvider({ children }) {
  const [terminalapplist, setTerminalAppList] = useState([]);
  const [terminalapplistLoading, setTerminalAppListLoading] = useState(true);
  useEffect(() => {
    Axios.get("https://api.terminals.dev/terminal/all")
      .then(({ data }) => {
        setTerminalAppList(data);
      })
      .finally(() => setTerminalAppListLoading(false));
  }, []);

  const [publicationlist, setPublicationList] = useState([]);
  const [publicationlistLoading, setPublicationListLoading] = useState(true);
  useEffect(() => {
    Axios.get("https://publications.apimachine.com/publication")
      .then(({ data }) => {
        setPublicationList(data.data);
      })
      .finally(() => setPublicationListLoading(false));
  }, []);

  const [publisherlist, setPublisherList] = useState([]);
  const [publisherlistLoading, setPublisherListLoading] = useState(true);
  useEffect(() => {
    Axios.get("https://publications.apimachine.com/publisher")
      .then(({ data }) => {
        setPublisherList(data.data);
      })
      .finally(() => setPublisherListLoading(false));
  }, []);

  // Malls List
  const [malllist, setMallList] = useState([]);
  const [malllistLoading, setMallListLoading] = useState(true);
  useEffect(() => {
    Axios.get(
      "https://comms.globalxchange.io/gxb/product/endorsement/market/get"
    )
      .then(({ data }) => {
        if (data.status) setMallList(data.markets);
      })
      .finally(() => setMallListLoading(false));
  }, []);

  // Market Places List
  const [marketplaceslist, setMarketplacesList] = useState([]);
  const [marketplaceslistLoading, setMarketplacesListLoading] = useState(true);
  useEffect(() => {
    Axios.get("https://comms.globalxchange.io/gxb/product/marketplace/get")
      .then(({ data }) => {
        if (data.status) setMarketplacesList(data.markets);
      })
      .finally(() => setMarketplacesListLoading(false));
  }, []);

  // Industry List
  const [industryList, setIndustryList] = useState([]);
  const [industryListLoading, setIndustryListLoading] = useState(false);
  useEffect(() => {
    Axios.get("https://commerce.apimachine.com/industries")
      .then(({ data }) => {
        if (data.status) setIndustryList(data.data);
      })
      .finally(() => setIndustryListLoading(false));
  }, []);
  return (
    <MoreTabContext.Provider
      value={{
        terminalapplist,
        terminalapplistLoading,
        terminalCount: terminalapplist?.length,
        publicationlist,
        publicationlistLoading,
        publicationCount: publicationlist?.length,
        publisherlist,
        publisherlistLoading,
        publisherCount: publisherlist?.length,
        malllist,
        malllistLoading,
        malllistCount: malllist?.length,
        marketplaceslist,
        marketplaceslistLoading,
        marketplaceslistCount: marketplaceslist?.length,
        industryList,
        industryListLoading,
      }}
    >
      {children}
    </MoreTabContext.Provider>
  );
}

export default MoreTabContextProvider;
