import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React, { useEffect, useState } from "react";

function NewNavbar({ publication, onSuccess, onClose, navData }) {
  const [keys, setKeys] = useState([
    "application_id",
    "email",
    "navTitle",
    "desc",
    "icon",
  ]);
  const [dropOpen, setDropOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState("application_id");
  const [data, setData] = useState({
    application_id: publication.fxa_app_id,
    email: publication.email,
    navTitle: "",
    desc: "",
    icon: "",
  });
  const [updateId, setUpdateId] = useState(false);
  useEffect(() => {
    if (navData) {
      setUpdateId(navData._id);
      const newData = {
        ...navData,
        email: publication.email,
      };
      delete newData.createdAt;
      delete newData.updatedAt;
      delete newData._id;
      delete newData.__v;
      setData(newData);
      setKeys(Object.keys(newData));
      setSelectedKey(Object.keys(newData)[0]);
    }
  }, [navData, publication.email]);
  const onUpdateClick = () => {
    if (updateId) {
      Axios.put(
        `https://fxagency.apimachine.com/navbar/${updateId}`,
        data
      ).then(({ data }) => {
        if (data.status) {
          onSuccess();
        }
      });
    } else {
      Axios.post("https://fxagency.apimachine.com/navbar", data).then(
        ({ data }) => {
          if (data.status) {
            onSuccess();
          }
        }
      );
    }
  };
  return (
    <div className="deleteWithdraw">
      <div
        className="overlay"
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <div className="modalCopy">
        <div className="head">
          <span>
            {navData ? "Update" : "New"} Navbar For {publication.name}
          </span>
        </div>
        <div className="deleteContent">
          <div className="labelDel">
            Select The New Step For This Transaction?
          </div>
          <div className="btnDropdown" onClick={() => setDropOpen(!dropOpen)}>
            <span>{selectedKey}</span>
            <FontAwesomeIcon icon={dropOpen ? faCaretUp : faCaretDown} />
            {dropOpen && (
              <div className="dropList">
                {keys.map((key) => (
                  <div
                    className="dropItem"
                    onClick={() => {
                      setSelectedKey(key);
                    }}
                  >
                    {key}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="notesWrapper">
            <textarea
              className={`ipNotes ${Boolean(data[selectedKey])}`}
              value={data[selectedKey]}
              onChange={(e) =>
                setData({ ...data, [selectedKey]: e.target.value })
              }
              id="ipNotes"
            ></textarea>
            <label htmlFor="ipNotes">Enter Notes</label>
          </div>
          <div className="btnUpdate" onClick={onUpdateClick}>
            Update
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewNavbar;
