import React, { useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'

import { ReactComponent as LockIcon } from '../../images/clipIcons/logout.svg'
import { ReactComponent as CaretIcon } from '../../images/clipIcons/caretUp.svg'
import styles from './sidebar.module.scss'

function Sidebar({
  logo,
  logoText,
  email,
  onLogout,
  profilePic,
  profileName,
  active,
  menuList,
  groupOpen
}) {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <div
      className={`${styles.badDashSider} ${
        collapsed && styles.true
      } ${collapsed}`}
    >
      <div
        className={`${styles.logo} ${collapsed && styles.true}`}
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? <img src={logo} alt='' /> : <img src={logoText} alt='' />}
      </div>
      <div className={`${styles.siderContents} ${collapsed && styles.true}`}>
        <Scrollbars
          className={styles.menuGroupScroll}
          renderView={(props) => (
            <div {...props} className={styles.menuGroupList} />
          )}
          renderThumbHorizontal={() => <div />}
          renderThumbVertical={() => <div />}
        >
          {menuList.map((group) => {
            return (
              <div
                key={group.title}
                className={`${styles.group} ${
                  group.title !== groupOpen && !collapsed && styles.close
                }`}
              >
                <h5 onClick={group.onClick}>
                  <span>{group.title}</span>
                  <CaretIcon className={`${styles.caret} ${styles.down}`} />
                </h5>
                <Scrollbars
                  autoHide
                  className={styles.menuScroll}
                  renderView={(props) => (
                    <div {...props} className={styles.menuList} />
                  )}
                  renderThumbHorizontal={() => <div />}
                  renderThumbVertical={() => <div />}
                >
                  {group.menu?.map((item) => {
                    return (
                      <div
                        key={item.name}
                        onClick={() => {
                          try {
                            item.onClick()
                          } catch (error) {}
                        }}
                        className={`${styles.menuItm} ${
                          active === item.name && styles.active
                        }`}
                      >
                        {item.svg ? item.svg : <img src={item.icon} alt='' />}
                        <h5>{item.name}</h5>
                      </div>
                    )
                  })}
                </Scrollbars>
              </div>
            )
          })}
        </Scrollbars>
        <div className={styles.profileLogout}>
          <img className={styles.dp} src={profilePic} alt='' />
          <div className={styles.name}>
            <div className=''>
              <h4>{profileName}</h4>
              <div className={styles.logout}>{email}</div>
            </div>
          </div>
          <LockIcon
            className={styles.logoutIcon}
            onClick={() => {
              try {
                onLogout()
              } catch (e) {}
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Sidebar
