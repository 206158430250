import React from "react";
import EngagementNewEvent from "./EngagementNewEvent";

function EngagementEvents({ newEvent, setNewEvent }) {
  return (
    <div>
      {newEvent && (
        <EngagementNewEvent
          onClose={() => setNewEvent(false)}
          onSuccess={() => setNewEvent(false)}
        />
      )}
    </div>
  );
}

export default EngagementEvents;
