import React, { useState, useContext, useEffect } from "react";
import OtpInput from "react-otp-input";
import { MainContext } from "../../context/MainContext";
import logo from "../../static/images/logos/civilisationIcon.svg";

const otpRegex = new RegExp(/^\d*$/);
function EnterPinUnlock({ onSucces, onClose, local, label, logoParam }) {
  const { tostShowOn } = useContext(MainContext);
  const [pin, setPin] = useState("");
  const pinValidator = (pinStr) => {
    if (otpRegex.test(pinStr)) setPin(pinStr);
  };
  useEffect(() => {
    if (pin.length === 4) {
      if (local) {
        if (pin === local) {
          try {
            onSucces();
          } catch (error) {}
        } else {
          tostShowOn("Incorrect Pin", "Error!");
          setPin("");
        }
      }
    }
  }, [pin, tostShowOn]);
  return (
    <div className="enterPinUnlock">
      <div
        className="overlayClose"
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <div className="pinCard">
        <div className="inCard">
          <img src={logoParam || logo} alt="" className="logo" />
          <div className="title">{label}</div>
          <OtpInput
            containerStyle="otp-input-wrapper"
            value={pin}
            onChange={(otp) => pinValidator(otp)}
            numInputs={4}
            separator={<span> </span>}
            inputStyle="otp-input"
          />
        </div>
        {/* <div
          className="btnCloseFooter"
          onClick={() => {
            try {
              onClose();
            } catch (error) {}
          }}
        >
          Close
        </div> */}
      </div>
    </div>
  );
}

export default EnterPinUnlock;
