import React, { useState } from "react";
import GodsEyeFilterCurrency from "./GodsEyeFilterCurrency";
import GodsEyeFilterDisplay from "./GodsEyeFilterDisplay";
import GodsEyeListStats from "./GodsEyeListStats";

function GodsEyeUserFilter({
  setCoinSelected,
  isUname,
  setIsUname,
  itemPerPage,
  setItemPerPage,
  totalUsers,
  appSelected,
}) {
  const [tab, setTab] = useState("Stats");
  function getTabContent() {
    switch (tab) {
      case "Display":
        return (
          <GodsEyeFilterDisplay
            isUname={isUname}
            setIsUname={setIsUname}
            itemPerPage={itemPerPage}
            setItemPerPage={setItemPerPage}
          />
        );
      case "Currency":
        return <GodsEyeFilterCurrency setCoinSelected={setCoinSelected} />;
      case "Stats":
        return (
          <GodsEyeListStats totalUsers={totalUsers} appSelected={appSelected} />
        );
      default:
        break;
    }
  }
  return (
    <div className="godsEyeUserFiter">
      <div className="headTabs">
        <div
          className={`tab ${tab === "Display"}`}
          onClick={() => setTab("Display")}
        >
          Display
        </div>
        <div
          className={`tab ${tab === "Currency"}`}
          onClick={() => setTab("Currency")}
        >
          Currency
        </div>
        <div
          className={`tab ${tab === "Stats"}`}
          onClick={() => setTab("Stats")}
        >
          List Stats
        </div>
        <div className="tab disable">Filter 3</div>
      </div>
      {getTabContent()}
    </div>
  );
}

export default GodsEyeUserFilter;
