import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

import { MainContext } from "../../context/MainContext";
import back from "../../static/images/back.svg";
import saveIcon from "../../static/images/clipIcons/save.svg";
import apiIcon from "../../static/images/clipIcons/apis.svg";

function XIDSidebar({ groupSelected, onClose }) {
  const { copyToClipboard } = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [fieldList, setFieldList] = useState([]);
  useEffect(() => {
    setLoading(true);
    Axios.get(
      `https://comms.globalxchange.io/gxb/apps/fields/profile/get?group_id=${groupSelected?.group_id}`
    )
      .then(({ data }) => {
        if (data.status) {
          setFieldList(data.appFields);
        }
      })
      .finally(() => setLoading(false));
    return () => {};
  }, [groupSelected]);
  const [step, setStep] = useState("");
  const [tab, setTab] = useState("About");
  function getContent() {
    switch (true) {
      case tab === "About" && step === "API":
        return (
          <>
            <div className="header">
              <div className="content">
                <div className="title">About {groupSelected?.name}</div>
                <div className="breadCrumbs">
                  <span
                    onClick={() => {
                      setStep("");
                    }}
                  >
                    About
                  </span>{" "}
                  -&gt;&nbsp;
                  <span>API</span>
                </div>
              </div>
              <div
                className="backBtn"
                onClick={() => {
                  setStep("");
                }}
              >
                <img src={back} alt="" />
              </div>
            </div>
            <div className="godsEyeFilterCurrency">
              <Scrollbars className="itemListScroll">
                <div className="endPointLabel">
                  Get API For Selected Group ID
                </div>
                <div className="valueBox">
                  <span>{`https://comms.globalxchange.io/gxb/apps/field/groups/get?group_id=${groupSelected.group_id}`}</span>
                  <FontAwesomeIcon
                    icon={faCopy}
                    className="copyBtn"
                    onClick={() =>
                      copyToClipboard(
                        `https://comms.globalxchange.io/gxb/apps/field/groups/get?group_id=${groupSelected.group_id}`,
                        "Endpoint For Field"
                      )
                    }
                  />
                </div>
              </Scrollbars>
            </div>
          </>
        );
      case tab === "About" && step === "viewFields":
        return (
          <>
            <div className="header">
              <div className="content">
                <div className="title">About {groupSelected?.name}</div>
                <div className="breadCrumbs">
                  <span
                    onClick={() => {
                      setStep("");
                    }}
                  >
                    About
                  </span>{" "}
                  -&gt;&nbsp;
                  <span>View Fields</span>
                </div>
              </div>
              <div
                className="backBtn"
                onClick={() => {
                  setStep("");
                }}
              >
                <img src={back} alt="" />
              </div>
            </div>
            <div className="godsEyeFilterCurrency">
              <Scrollbars className="itemListScroll">
                {fieldList.map((field) => (
                  <div className="boxItem">
                    <div className="fieldDetail">
                      <img
                        src={
                          field?.field_icon ||
                          `https://avatars.dicebear.com/api/initials/${field?.name}.svg?b=%23000000&chars=1&bold=1`
                        }
                        alt=""
                        className="icon"
                      />
                      <div className="field">
                        <div className="fieldName">
                          <span>{field.name}</span>
                          <FontAwesomeIcon
                            icon={faCopy}
                            className="copyBtn"
                            onClick={() => copyToClipboard(field.name, "Name")}
                          />
                        </div>
                        <div className="fieldType">
                          <span>{field.field_type}</span>
                          <FontAwesomeIcon
                            icon={faCopy}
                            className="copyBtn"
                            onClick={() =>
                              copyToClipboard(field.field_type, "field_type")
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="desc">{field?.description}</div>
                    <div className="fieldKey">
                      <span>{field?.field_key}</span>
                      <FontAwesomeIcon
                        icon={faCopy}
                        className="copyBtn"
                        onClick={() =>
                          copyToClipboard(field.field_key, "field_key")
                        }
                      />
                    </div>
                    <div className="endPointLabel">Endpoint For Field</div>
                    <div className="valueBox">
                      <span>{`https://comms.globalxchange.io/gxb/apps/fields/profile/get?field_key=${field.field_key}`}</span>
                      <FontAwesomeIcon
                        icon={faCopy}
                        className="copyBtn"
                        onClick={() =>
                          copyToClipboard(
                            `https://comms.globalxchange.io/gxb/apps/fields/profile/get?field_key=${field.field_key}`,
                            "Endpoint For Field"
                          )
                        }
                      />
                    </div>
                  </div>
                ))}
              </Scrollbars>
            </div>
          </>
        );
      case tab === "About":
        return (
          <>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm">
                Select One Of The Following Options
              </div>
              <Scrollbars className="coinList">
                <div className="coinItem" onClick={() => setStep("viewFields")}>
                  <img
                    src={saveIcon}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>See Fields</span>
                  <span style={{ marginLeft: "auto", flex: "unset" }}>
                    {groupSelected?.fields_count}
                  </span>
                </div>
                <div className="coinItem" onClick={() => setStep("API")}>
                  <img
                    src={apiIcon}
                    alt=""
                    className="coin"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <span>API</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );
      default:
        return;
    }
  }
  return (
    <div className="godsEyeUserFiter">
      <div className="headTabBox">
        <div
          className={`tab ${tab === "About"}`}
          onClick={() => {
            setTab("About");
            setStep("");
          }}
        >
          About
        </div>
        <div
          className={`tab ${tab === "Actions"}`}
          onClick={() => {
            setTab("Actions");
            setStep("");
          }}
        >
          Actions
        </div>
      </div>
      {getContent()}
    </div>
  );
}

export default XIDSidebar;
