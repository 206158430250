import React from "react";
import { FormatCurrency } from "../../utils/Functions";

function CentralBankCoinItem({ coin, actionClick, onClick, active }) {
  return (
    <div
      className={`centralBankCoinItem ${active}`}
      onClick={() => {
        try {
          onClick();
        } catch (error) {}
      }}
    >
      <img src={coin.coinImage} alt="" className="coinImg" />
      <div className="coinName">{coin.coinName}</div>
      <div className="coinVal">${FormatCurrency(coin.usd_price)}</div>
      <div className={`coinVal ${coin._24hrchange > 0 ? "green" : "red"}`}>
        ${FormatCurrency(coin._24hrchange)}
      </div>
      <div className="coinVal red">$0.00</div>
      <div
        className="btnAction"
        onClick={() => {
          try {
            actionClick();
          } catch (error) {}
        }}
      >
        Actions
      </div>
    </div>
  );
}

export default CentralBankCoinItem;
