import React, { useContext, useState } from "react";
import Axios from "axios";
import JsonWebToken from "jsonwebtoken";
import Scrollbars from "react-custom-scrollbars";
import { useHistory } from "react-router";
import cloudUploadIcon from "../../static/images/postClipArt/cloudUpload.svg";
import CoinList from "./CoinList";
import OperatorList from "./OperatorList";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import { MainContext } from "../../context/MainContext";
import loadingGif from "../../static/animations/loading.gif";
import WebsiteInput from "../WebsiteInput";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

function NewConglomerate({ onClose, onBack }) {
  const history = useHistory();
  const { tostShowOn, email, token } = useContext(MainContext);
  const [step, setStep] = useState("");
  const [loading, setLoading] = useState(false);

  const [operator, setOperator] = useState("");
  const [groupName, setGroupName] = useState("");
  const [icon, setIcon] = useState("");
  const [logo, setLogo] = useState("");
  const [whiteIcon, setWhiteIcon] = useState("");
  const [whiteLogo, setWhiteLogo] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");
  const [description, setDescription] = useState("");
  const [website, setWebsite] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [tokenDetail, setTokenDetail] = useState();
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [websiteTitle, setWebsiteTitle] = useState("");
  const [websiteDescription, setWebsiteDescription] = useState("");

  const [iconLoading, setIconLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [whiteIconLoading, setWhiteIconLoading] = useState(false);
  const [whiteLogoLoading, setWhiteLogoLoading] = useState(false);
  const [coverPhotoLoading, setCoverPhotoLoading] = useState(false);

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
    setLoading(false);
  };

  function getContent() {
    switch (step) {
      case "operator":
        return (
          <OperatorList setOperator={setOperator} onClose={() => setStep("")} />
        );
      case "coin":
        return (
          <CoinList
            setCoinSymbol={setTokenSymbol}
            setTokenDetail={setTokenDetail}
            onClose={() => setStep("")}
          />
        );

      default:
        return (
          <Scrollbars
            className="scrollForm"
            renderTrackHorizontal={() => <div />}
            renderThumbHorizontal={() => <div />}
            renderTrackVertical={() => <div />}
            renderThumbVertical={() => <div />}
          >
            <div className="name">Select GXLive Operator</div>
            {operator ? (
              <div className="user" onClick={() => setStep("operator")}>
                <img className="dp" src={operator?.brand_logo} alt="" />
                <div className="userDetail">
                  <div className="name">{operator?.brand_name}</div>
                  <div className="email">{operator?.email}</div>
                </div>
              </div>
            ) : (
              <div className="inputWrap" onClick={() => setStep("operator")}>
                <div className="label">Click Here To Select An Operator</div>
              </div>
            )}
            <div className="name">Name The Conglomerate</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Name Here"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
            </div>
            <div className="name">Upload Branding Material</div>
            <div className="filesUpload">
              <label className="fileInp icon">
                <img
                  className={`${Boolean(icon)}`}
                  src={iconLoading ? loadingGif : icon || cloudUploadIcon}
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setIcon, setIconLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">Colored Icon</div>
              </label>
              <label className="fileInp icon">
                <img
                  className={`${Boolean(whiteIcon)}`}
                  src={
                    whiteIconLoading ? loadingGif : whiteIcon || cloudUploadIcon
                  }
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setWhiteIcon, setWhiteIconLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">White Icon</div>
              </label>
              <label className="fileInp cover">
                <img
                  className={`${Boolean(coverPhoto)}`}
                  src={
                    coverPhotoLoading
                      ? loadingGif
                      : coverPhoto || cloudUploadIcon
                  }
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setCoverPhoto, setCoverPhotoLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">Cover Photo</div>
              </label>
            </div>
            <div className="filesUpload last">
              <label className="fileInp fullLogo">
                <img
                  className={`${Boolean(logo)}`}
                  src={logoLoading ? loadingGif : logo || cloudUploadIcon}
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setLogo, setLogoLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">Colored Logo</div>
              </label>
              <label className="fileInp fullLogo">
                <img
                  className={`${Boolean(whiteLogo)}`}
                  src={
                    whiteLogoLoading ? loadingGif : whiteLogo || cloudUploadIcon
                  }
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setWhiteLogo, setWhiteLogoLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">White Logo</div>
              </label>
            </div>
            <div className="name">Enter The Conglomerate’s Website</div>
            <WebsiteInput website={website} setWebsite={setWebsite} />
            <div className="name">Describe The Conglomerate</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Description Here"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="name">Attach A Token</div>
            {tokenSymbol ? (
              <div className="user" onClick={() => setStep("coin")}>
                <img className="dp" src={tokenDetail?.coinImage} alt="" />
                <div className="userDetail">
                  <div className="name">{tokenDetail?.coinName}</div>
                  <div className="email">{tokenSymbol}</div>
                </div>
              </div>
            ) : (
              <div className="inputWrap" onClick={() => setStep("coin")}>
                <div className="label">Click Here To Select A Token</div>
              </div>
            )}
            <div className="name">Enter Primary Color Code</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Code Here..."
                value={primaryColor}
                onChange={(e) => setPrimaryColor(e.target.value)}
              />
              <div
                className="color"
                style={{ width: "10%", background: `#${primaryColor}` }}
              />
            </div>
            <div className="name">Enter Secondary Color Code</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Code Here..."
                value={secondaryColor}
                onChange={(e) => setSecondaryColor(e.target.value)}
              />
              <div
                className="color"
                style={{ width: "10%", background: `#${secondaryColor}` }}
              />
            </div>
            <div className="name">Website Title</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Title Here"
                value={websiteTitle}
                onChange={(e) => setWebsiteTitle(e.target.value)}
              />
            </div>
            <div className="name">Website Description</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Description Here"
                value={websiteDescription}
                onChange={(e) => setWebsiteDescription(e.target.value)}
              />
            </div>
            <div className="space"></div>
          </Scrollbars>
        );
    }
  }

  function addConglomerate() {
    setLoading(true);
    Axios.post(
      "https://comms.globalxchange.io/gxb/app/gxlive/operator/create/app/group",
      {
        email,
        token,
        operator_id: operator.operator_id,
        groupname: groupName,
        icon: icon,
        logo: logo,
        white_icon: whiteIcon,
        white_logo: whiteLogo,
        cover_photo: coverPhoto,
        description: description,
        website: website,
        tokenSymbol: tokenSymbol,
        other_data: {
          colorcode: primaryColor,
          colourcode2: secondaryColor,
          websitetitle: websiteTitle,
          websitedescription: websiteDescription,
        },
      }
    )
      .then(({ data }) => {
        if (data.status) {
          tostShowOn("New Conglomerate Added");
          history.push("/ventures/Conglomerates");
          window.location.reload();
        } else {
          tostShowOn(data.message);
        }
      })
      .finally(() => setLoading(false));
  }

  function validate() {
    if (
      operator?.operator_id &&
      groupName &&
      icon &&
      logo &&
      whiteIcon &&
      whiteLogo &&
      coverPhoto &&
      description &&
      website &&
      tokenSymbol &&
      primaryColor &&
      secondaryColor
    ) {
      addConglomerate();
    } else {
      tostShowOn("All Fields Are Mandatory");
    }
  }

  return (
    <>
      <div className="newConglomerate">{getContent()}</div>
      <div className="btnSubmit" onClick={validate}>
        Submit Conglomerate
      </div>
      {loading && <LoadingAnim />}{" "}
    </>
  );
}

export default NewConglomerate;
