import React, { useContext, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Axios from "axios";
import { useHistory } from "react-router-dom";

import capitalIcn from "../../static/images/clipIcons/capital.svg";
import appdataIcn from "../../static/images/clipIcons/appData.svg";
import deleteIcn from "../../static/images/refreshIcon/delete.svg";
import yesIcn from "../../static/images/clipIcons/yes.svg";
import noIcn from "../../static/images/clipIcons/no.svg";
import appLink from "../../static/images/clipIcons/appLink.svg";
import manipulate from "../../static/images/clipIcons/manipulate.svg";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import { MainContext } from "../../context/MainContext";
import SetInvestmentPath from "./SetInvestmentPath";

function GodsEyeAppsSidebar({ appSelected, onClose }) {
  const history = useHistory();
  const [step, setStep] = useState("");
  const [loading, setLoading] = useState(false);
  const { token, tostShowOn, getApps } = useContext(MainContext);
  function deleteApp() {
    setLoading(true);
    Axios.post("https://comms.globalxchange.io/gxb/apps/delete", {
      email: appSelected?.created_by,
      token: token,
      app_code: appSelected?.app_code,
    })
      .then(({ data }) => {
        if (data.status) {
          getApps();
          try {
            onClose();
          } catch (error) {}
          setStep("deleteSucces");
        } else {
          tostShowOn(data.message);
        }
      })
      .finally(() => setLoading(false));
  }

  function getContent() {
    switch (step) {
      case "delete":
        return (
          <>
            <div className="userHeads">
              <img src={appSelected?.app_icon} alt="" />
              <div className="detail">
                <div className="name">{appSelected?.app_name}</div>
                <div className="email">
                  <span
                    onClick={() => {
                      try {
                        onClose();
                      } catch (error) {}
                    }}
                  >
                    Apps
                  </span>{" "}
                  -&gt;{" "}
                  <span
                    onClick={() => {
                      setStep("");
                    }}
                  >
                    Actions
                  </span>{" "}
                  -&gt; <span>Delete</span>
                </div>
              </div>
            </div>
            <div className="godsEyeFilterCurrency ">
              <div className="labelItm">
                Are You Sure You Want To Delete {appSelected.app_name}
              </div>
              <Scrollbars
                className="coinList"
                renderThumbHorizontal={() => <div />}
                renderThumbVertical={() => <div />}
                renderTrackHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
              >
                <div className="coinItem" onClick={() => deleteApp()}>
                  <img src={yesIcn} alt="" className="coin" />
                  <span>Confirm</span>
                </div>
                <div className="coinItem" onClick={() => setStep()}>
                  <img src={noIcn} alt="" className="coin" />
                  <span>Never Mind</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );
      case "deleteSucces":
        setTimeout(() => {
          history.push("/society/Apps");
          window.location.reload();
        }, 2000);
        return (
          <>
            <div className="userHeads">
              <img src={appSelected?.app_icon} alt="" />
              <div className="detail">
                <div className="name">{appSelected?.app_name}</div>
                <div className="email">
                  <span
                    onClick={() => {
                      try {
                        onClose();
                      } catch (error) {}
                    }}
                  >
                    Apps
                  </span>{" "}
                  -&gt;{" "}
                  <span
                    onClick={() => {
                      setStep("");
                    }}
                  >
                    Actions
                  </span>{" "}
                  -&gt; <span>Delete</span>
                </div>
              </div>
            </div>
            <div className="godsEyeFilterCurrency">
              <div className="labelItm m-auto">
                You Have Successfully Deleted App {appSelected.app_name}. You
                Will Be Redirected To The App List Right Now
              </div>
            </div>
          </>
        );

      case "editCap":
        return (
          <SetInvestmentPath onClose={onClose} onBack={() => setStep("")} />
        );
      default:
        return (
          <>
            <div className="headTabBox">
              <div className="tab false">About</div>
              <div className="tab true">Actions</div>
            </div>
            <div className="godsEyeFilterCurrency ">
              <div className="labelItm">
                Select One Of The Following Actions
              </div>
              <Scrollbars
                className="coinList"
                renderThumbHorizontal={() => <div />}
                renderThumbVertical={() => <div />}
                renderTrackHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
              >
                <div className="coinItem" onClick={() => setStep("editCap")}>
                  <img src={capitalIcn} alt="" className="coin" />
                  <span>Edit Cap Table</span>
                </div>
                <div className="coinItem disable">
                  <img src={appdataIcn} alt="" className="coin" />
                  <span>Edit App Data</span>
                </div>
                <div className="coinItem disable">
                  <img src={manipulate} alt="" className="coin" />
                  <span>Manipulate Interest Rates</span>
                </div>
                <div className="coinItem disable">
                  <img src={appLink} alt="" className="coin" />
                  <span>Update Mobile Links</span>
                </div>
                <div className="coinItem" onClick={() => setStep("delete")}>
                  <img src={deleteIcn} alt="" className="coin" />
                  <span>Delete App</span>
                </div>
              </Scrollbars>
            </div>
          </>
        );
    }
  }
  return (
    <div className="godsEyeUserFiter cenralBankSidebar">
      {getContent()}
      <div
        className="footerPage"
        onClick={() => {
          history.push(`/apps/${appSelected?.app_code}`);
        }}
      >
        <img src={appSelected?.app_icon} alt="" />
        <span>{appSelected?.app_name}</span>
      </div>
      {loading && <LoadingAnim />}
    </div>
  );
}

export default GodsEyeAppsSidebar;
