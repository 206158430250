import React from "react";

function GodsEyeEye() {
  return (
    <div className="godsEyeEye">
      <span>Coming Soon</span>
    </div>
  );
}

export default GodsEyeEye;
