import React, { Fragment, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { useAppsList } from "../../queryHooks";

function TokenAppsList({ tokenApps, setTokenApps, onClose }) {
  const { data: allapps, isLoading: allappsLoading } = useAppsList();
  const [search, setSearch] = useState("");
  return (
    <Fragment>
      <div className="titleOp">Select Parent Conglomerate</div>
      <div className="searchWrap">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search TokenApps....|"
        />
      </div>
      <Scrollbars className="searchList">
        {allappsLoading
          ? Array(6)
              .fill("")
              .map((_, i) => (
                <div className="user" key={i}>
                  <Skeleton className="dp" circle />
                  <div className="userDetail">
                    <Skeleton className="name" width={200} />
                    <Skeleton className="email" width={200} />
                  </div>
                </div>
              ))
          : allapps
              .filter(
                (allapps) =>
                  allapps.app_name
                    ?.toLowerCase()
                    .includes(search.toLowerCase()) ||
                  allapps.created_by
                    ?.toLowerCase()
                    .includes(search.toLowerCase())
              )
              .map((app) => (
                <div
                  className="user"
                  key={app._id}
                  onClick={() => {
                    setTokenApps((tokenApps) =>
                      tokenApps
                        ?.map((app) => app.app_code)
                        .includes(app?.app_code)
                        ? tokenApps.filter(
                            (appTemp) => appTemp?.app_code !== app?.app_code
                          )
                        : [...tokenApps, app]
                    );
                  }}
                >
                  <img className="dp" src={app.app_icon} alt="" />
                  <div className="userDetail">
                    <div className="name">{app.app_name}</div>
                    <div className="email">{app.created_by}</div>
                  </div>
                  <input
                    readOnly
                    style={{ marginLeft: "auto" }}
                    type="checkbox"
                    checked={tokenApps
                      ?.map((app) => app.app_code)
                      .includes(app?.app_code)}
                  ></input>
                </div>
              ))}
        <div className="space"></div>
      </Scrollbars>
      <div className="ftBtns">
        <div className="newField" onClick={() => onClose()}>
          Go Back
        </div>
        <div className="btnSubmit" onClick={() => onClose()}>
          Submit
        </div>
      </div>
    </Fragment>
  );
}

export default TokenAppsList;
