import React, { useRef } from "react";
import OnOutsideClick from "../../utils/OnOutsideClick";

function MallsItem({ endorsement, onClick, selected }) {
  const btnRef = useRef();
  const outRef = useRef();

  OnOutsideClick(
    btnRef,
    () => {
      try {
        onClick();
      } catch (error) {}
    },
    outRef
  );

  return (
    <div className={`listItem ${endorsement === selected}`} ref={outRef}>
      <div className="profile">
        <img src={endorsement.icon} alt="" className="icon" />
        <span className="name">{endorsement.name}</span>
      </div>
      <span className="count">{endorsement?.usersCount} Endorsers</span>
      <div
        className="btnWebsite"
        ref={btnRef}
        onClick={() =>
          window
            .open(
              endorsement?.additional_data?.website.includes("http")
                ? endorsement?.additional_data?.website
                : `https://${endorsement?.additional_data?.website}`,
              "_blank"
            )
            .focus()
        }
      >
        Website
      </div>
    </div>
  );
}

export default MallsItem;
