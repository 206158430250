import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../../static/animations/lock-eye-open.json";

function LoadingPage() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div
      className="loadingPage"
      style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
    >
      <div className="landingLottie">
        <div className="m-auto">
          <Lottie options={defaultOptions} width={300} height={300} />
        </div>
      </div>
    </div>
  );
}

export default LoadingPage;
