import axios from "axios";
import { useQuery } from "react-query";

const getAllShoppers = async ({ queryKey }) => {
  const { data } = await axios
    .get("https://commerce.apimachine.com/marketplaces/platform/users")
    .catch((error) => {
      throw new Error(
        error?.response?.data?.message || error.message || "API Error"
      );
    });
  if (!data?.status) {
    throw new Error(data?.message);
  }
  return data.data;
};

export const useShoppersList = () => {
  const query = useQuery(["shoppersList"], getAllShoppers);
  return query;
};

const getCommerceMarketplacesList = async ({ queryKey }) => {
  const [_key, industryId] = queryKey;
  const { data } = await axios
    .get(
      `https://commerce.apimachine.com/marketplaces?business_data=get&industry_data=get${
        industryId ? "&industry_id=" + industryId : ""
      }`
    )
    .catch((error) => {
      throw new Error(
        error?.response?.data?.message || error.message || "API Error"
      );
    });
  if (data?.status) {
    return data.data;
  } else {
    throw new Error(data?.message);
  }
};

export const useCommerceMarketplacesList = (industryId) => {
  const query = useQuery(
    ["commerceMarketplacesList", industryId],
    getCommerceMarketplacesList
  );
  return query;
};

const getAllBrands = async ({ queryKey }) => {
  const { data } = await axios
    .get("https://comms.globalxchange.io/gxb/app/gxlive/operator/brands/get")
    .catch((error) => {
      throw new Error(
        error?.response?.data?.message || error.message || "API Error"
      );
    });
  if (!data?.status) {
    throw new Error(data?.message);
  }
  return data.brands;
};

export const useBrandsList = () => {
  const query = useQuery(["brandsList"], getAllBrands);
  return query;
};

const getAllConglomerates = async () => {
  const { data } = await axios
    .get(
      "https://comms.globalxchange.io/gxb/app/gxlive/operator/app/groups/get"
    )
    .catch((error) => {
      throw new Error(
        error?.response?.data?.message || error.message || "API Error"
      );
    });
  if (!data?.status) {
    throw new Error(data?.message);
  }
  return data.groups;
};

export const useConglomeratesList = () => {
  const query = useQuery(["conglomeratesList"], getAllConglomerates);
  return query;
};

const getAllOperators = async () => {
  const { data } = await axios
    .get("https://comms.globalxchange.io/gxb/app/gxlive/user/operator/get")
    .catch((error) => {
      throw new Error(
        error?.response?.data?.message || error.message || "API Error"
      );
    });
  if (!data?.status) {
    throw new Error(data?.message);
  }
  return data.operators;
};

export const useOperatorsList = () => {
  const query = useQuery(["operatorsList"], getAllOperators);
  return query;
};

const getAllApps = async () => {
  const { data } = await axios
    .get("https://comms.globalxchange.io/gxb/apps/get")
    .catch((error) => {
      throw new Error(
        error?.response?.data?.message || error.message || "API Error"
      );
    });
  if (!data?.status) {
    throw new Error(data?.message);
  }
  return data.apps;
};

export const useAppsList = () => {
  const query = useQuery(["appsList"], getAllApps);
  return query;
};

const getAllErcCoins = async ({ queryKey }) => {
  const [_key, allCrypto = false] = queryKey;
  const { data } = await axios
    .get(
      allCrypto
        ? "https://comms.globalxchange.io/coin/vault/get/all/coins?crypto=true"
        : "https://comms.globalxchange.io/coin/vault/get/all/coins?erc20=true&crypto=true"
    )
    .catch((error) => {
      throw new Error(
        error?.response?.data?.message || error.message || "API Error"
      );
    });
  if (!data?.status) {
    throw new Error(data?.message);
  }
  return data.coins;
};

export const useErcCoinsList = (allCrypto) => {
  const query = useQuery(["ercCoinsList", allCrypto], getAllErcCoins);
  return query;
};

const getBlockChainData = async ({ queryKey }) => {
  const [_key, coin] = queryKey;
  const { data } = await axios
    .get(
      "https://comms.globalxchange.io/coin/vault/service/user/eth/coins/network/data/get",
      {
        params: {
          coin,
        },
      }
    )
    .catch((error) => {
      throw new Error(
        error?.response?.data?.message || error.message || "API Error"
      );
    });
  if (!data?.status) {
    throw new Error(data?.message);
  }
  return data;
};

export const useBlockChainData = (coin) => {
  const query = useQuery(["blockChainData", coin], getBlockChainData);
  return query;
};

const getUserDetail = async ({ queryKey }) => {
  const [_key, email] = queryKey;
  const { data } = await axios
    .get(`https://comms.globalxchange.io/user/details/get?email=${email}`)
    .catch((error) => {
      throw new Error(
        error?.response?.data?.message || error.message || "API Error"
      );
    });
  if (!data?.status) {
    throw new Error(data?.message);
  }
  return data.user;
};

export const useUserDetail = (email) => {
  const query = useQuery(["userDetails", email], getUserDetail);
  return query;
};

const getOTCDesks = async () => {
  const { data } = await axios
    .get(`https://comms.globalxchange.io/gxb/apps/otcdesk/get`)
    .catch((error) => {
      throw new Error(
        error?.response?.data?.message || error.message || "API Error"
      );
    });
  if (!data?.status) {
    throw new Error(data?.message);
  }
  return data.otcDesks;
};

export const useOTCDesks = () => {
  const query = useQuery(["OTCDesks"], getOTCDesks);
  return query;
};

const getUserList = async ({ queryKey }) => {
  const [_, { appCode }] = queryKey;
  const { data } = await axios
    .get("https://comms.globalxchange.io/gxb/apps/users/get", {
      params: {
        app_code: appCode,
      },
    })
    .catch((error) => {
      throw new Error(
        error?.response?.data?.message || error.message || "API Error"
      );
    });
  if (!data?.status) {
    throw new Error(data?.message);
  }
  return data.users;
};

export const useUserList = (appCode) => {
  const query = useQuery(["getUserList", { appCode }], getUserList);
  return query;
};

const getBondsList = async ({ queryKey }) => {
  const [_, { email }] = queryKey;
  const { data } = await axios
    .get("https://comms.globalxchange.io/coin/iced/banker/custom/bond/get", {
      params: { email },
    })
    .catch((error) => {
      throw new Error(
        error?.response?.data?.message || error.message || "API Error"
      );
    });
  if (!data?.status) {
    throw new Error(data?.message);
  }
  return data;
};

export const useBondsList = (email) => {
  const query = useQuery(["getBondsList", { email }], getBondsList);
  return query;
};
