import React, { useEffect, useState, useContext } from "react";
import ReactJson from "react-json-view";
import Axios from "axios";

import { MainContext } from "../../context/MainContext";
import circle from "../../static/images/clipIcons/circle.svg";
import react from "../../static/images/clipIcons/react.svg";
import expanded from "../../static/images/clipIcons/expanded.svg";
import UserDetaiVaults from "./UserDetaiVaults";

function GodsEyeUserDetail({ userDataToExpand }) {
  const { tostShowOn } = useContext(MainContext);
  const [jsonToShow, setJsonToShow] = useState(userDataToExpand);
  const [linageData, setLinageData] = useState({ loading: true });
  const [coinAddressData, setCoinAddressData] = useState({ loading: true });
  const [isVault, setIsVault] = useState(false);
  const [userAppList, setUserAppList] = useState([]);
  const [selectedApp, setSelectedApp] = useState({});
  const [selectedCoin, setSelectedCoin] = useState("BTC");
  const [isTxn, setIsTxn] = useState(false);
  const [coinList, setCoinList] = useState([]);
  const [coinListObject, setCoinListObject] = useState({});
  const [transactions, setTransactions] = useState([]);
  useEffect(() => {
    if (userDataToExpand?.email) {
      Axios.get(
        `https://comms.globalxchange.io/brokerage/stats/get/uplines?email=${userDataToExpand.email}`
      ).then(({ data }) => {
        setLinageData(data);
      });
      Axios.get(
        `https://comms.globalxchange.io/coin/vault/service/get/user/crypto/address/data?email=${userDataToExpand.email}`
      ).then(({ data }) => {
        setCoinAddressData(data);
      });
      Axios.get(
        `https://comms.globalxchange.io/gxb/apps/registered/user?email=${userDataToExpand.email}`
      ).then(({ data }) => {
        setUserAppList(data.userApps);
        setSelectedApp(data.userApps[0]);
      });
    }
  }, [userDataToExpand]);
  function getData(app_code, profile_id) {
    setJsonToShow({ loading: true });
    Axios.post(
      "https://comms.globalxchange.io/coin/vault/service/txns/get",
      selectedCoin
        ? {
            app_code,
            profile_id,
            coin: selectedCoin,
          }
        : {
            app_code,
            profile_id,
          }
    ).then(({ data }) => {
      if (data.status) setTransactions(data.txns);
    });
    Axios.post("https://comms.globalxchange.io/coin/vault/service/coins/get", {
      app_code,
      profile_id,
    }).then(({ data }) => {
      if (data.status) {
        setCoinList(data.coins_data);
        let coins = {};
        data.coins_data.forEach((coin) => {
          coins[coin.coinSymbol] = coin;
        });
        setCoinListObject(coins);
      }
    });
  }
  useEffect(() => {
    if (selectedApp.app_code) {
      getData(selectedApp.app_code, selectedApp.profile_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedApp, selectedCoin]);
  return (
    <div className="godsEyeUserDetai">
      <div className="jsonWrapper">
        <div className="viewOptions">
          <div
            className={`viewOptionType ${jsonToShow === userDataToExpand}`}
            onClick={() => {
              setJsonToShow(userDataToExpand);
              setIsVault(false);
            }}
          >
            <img src={react} alt="" />
            <span>GX Profile Data</span>
          </div>
          <div
            className={`viewOptionType ${jsonToShow === linageData}`}
            onClick={() => {
              setJsonToShow(linageData);
              setIsVault(false);
            }}
          >
            <img src={expanded} alt="" />
            <span>Linage</span>
          </div>
          <div
            className={`viewOptionType ${jsonToShow === coinAddressData}`}
            onClick={() => {
              setJsonToShow(coinAddressData);
              setIsVault(false);
            }}
          >
            <img src={circle} alt="" />
            <span>Addresses</span>
          </div>
          <div
            className={`viewOptionType ${isVault}`}
            onClick={() => {
              setJsonToShow({});
              setIsVault(true);
            }}
          >
            <img src={circle} alt="" />
            <span>Vaults</span>
            <div className="balanceTransaction">
              <div
                className={`balance ${!isTxn}`}
                onClick={() => setIsTxn(false)}
              >
                Balances
              </div>
              <div
                className={`transaction ${isTxn}`}
                onClick={() => setIsTxn(true)}
              >
                Transactions
              </div>
            </div>
          </div>
        </div>
        <div className="jsonView">
          <div className="jsonWrap">
            {isVault ? (
              <UserDetaiVaults
                userAppList={userAppList}
                selectedApp={selectedApp}
                setSelectedApp={setSelectedApp}
                isTxn={isTxn}
                coinList={coinList}
                selectedCoin={selectedCoin}
                setSelectedCoin={setSelectedCoin}
                transactions={transactions}
                coinListObject={coinListObject}
              />
            ) : (
              <ReactJson
                src={jsonToShow}
                collapseStringsAfterLength={50}
                displayDataTypes={false}
                enableClipboard={(copy) => {
                  navigator.clipboard
                    .writeText(
                      typeof copy?.src === "object"
                        ? JSON.stringify(copy?.src)
                        : copy?.src
                    )
                    .then(() => {
                      tostShowOn(`Value Copied to clipboard`);
                    });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GodsEyeUserDetail;
