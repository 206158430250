import { SearchAndFilter } from "../BrokerDashboard/index";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import MarketsItem from "./MarketsItem";
import MarketsListSidebar from "./MarketsListSidebar";

function MarketsList({ list, setList, searchOn }) {
  const [marketslist, setMarketsList] = useState([]);
  const [marketslistLoading, setMarketsListLoading] = useState(true);
  const [path, setPath] = useState();
  useEffect(() => {
    Axios.get("https://comms.globalxchange.io/coin/investment/path/get")
      .then(({ data }) => {
        if (data.status) setMarketsList(data.paths);
      })
      .finally(() => setMarketsListLoading(false));
  }, []);

  //
  useEffect(() => {
    if (path) setList(true);
    else setList(false);
  }, [path, setList]);

  useEffect(() => {
    if (!list) setPath();
  }, [list]);

  // Searc Vars

  const [search, setSearch] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const [filtePathName, setFiltePathName] = useState("");

  return (
    <>
      {
        <div className="godsEyeMoreList">
          {searchOn && (
            <SearchAndFilter
              search={search}
              setSearch={setSearch}
              placeholder={"Search Markets"}
              filterBy={`${"Name Of Group"}`}
              mainList={[
                {
                  key: "0",
                  label: "Function Performed By Search Bar",
                  switch: isFilter,
                  switchLabel: isFilter ? "Filter" : "Search",
                  switchClick: () => setIsFilter(!isFilter),
                },
                {
                  key: "1",
                  label: "Filter Range",
                  switch: currentPage,
                  switchLabel: currentPage ? "Current Page" : "Entire List",
                  switchClick: () => setCurrentPage(!currentPage),
                },
              ]}
              filterList={[
                {
                  key: "11",
                  label: "Name Of Group",
                  switch: filtePathName,
                  switchLabel: filtePathName ? "On" : "Off",
                  switchClick: () => setFiltePathName(!filtePathName),
                },
              ]}
            />
          )}
          <Scrollbars className="moreListWrapper">
            {marketslistLoading
              ? Array(6)
                  .fill("")
                  .map((_, i) => (
                    <div className="listItem">
                      <Skeleton alt="" className="icon" />
                      <Skeleton className="name" width={400} />
                      <Skeleton className="count" width={30} />
                    </div>
                  ))
              : marketslist
                  .filter((data) =>
                    data?.token_profile_data?.coinName
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  )
                  .map((data) => (
                    <MarketsItem
                      key={data._id}
                      path={data}
                      onClick={() => setPath(data)}
                      selected={path}
                    />
                  ))}
          </Scrollbars>
        </div>
      }
      {path && <MarketsListSidebar onClose={() => setPath()} path={path} />}
    </>
  );
}

export default MarketsList;
