import React from "react";
import Scrollbars from "react-custom-scrollbars";
import counselIcon from "../../static/images/appLogos/counsel.svg";
import cryptoLaw from "../../static/images/appLogos/cryptoLaw.svg";
import government from "../../static/images/appLogos/government.svg";
import instalegal from "../../static/images/appLogos/instalegal.svg";
import justice from "../../static/images/appLogos/justice.svg";

const typeLists = {
  content: ["Legal Tags Types", "Legal Tags", "Articles", "Services"],
  user: ["Users", "Legal Professional", "Regulator", "Super Admin"],
  userProfile: ["Admin", "Legal Firm", "Company"],
  apps: [
    { name: "Instalegal.com", icon: instalegal },
    { name: "GovernmentPrograms.ca", icon: government },
    { name: "CryptoLaw.com", icon: cryptoLaw },
    { name: "Justice.Group", icon: justice },
    { name: "Counsel.App", icon: counselIcon },
  ],
};

function JusticeContent({ filter, type, setType, setFilter }) {
  return (
    <div className="justiceContent">
      <Scrollbars className="justiceListWrap">
        {type === "apps"
          ? typeLists[type].map((item) => (
              <div className="listItem" key={item.name}>
                <img src={item.icon} alt="" />
                <span>{item.name}</span>
              </div>
            ))
          : typeLists[type].map((item) => (
              <div className="listItem" key={item}>
                {item}
              </div>
            ))}
      </Scrollbars>
      {filter === "Filter Config" ? (
        <div className="filterConfig">
          <div className="title">Filter Configuration For Collections</div>
          <Scrollbars
            className="filterScroll"
            renderThumbHorizontal={() => <div />}
            renderThumbVertical={() => <div />}
          >
            <div className="filtrationDegree">
              <div className="titlefilter">First Degree Filtration</div>
              <div className="subtitle">
                <span>
                  Filter Type: <span>Selection</span>
                </span>
                <span>
                  Query List: <span>TBD</span>
                </span>
              </div>
              <Scrollbars
                className="typleListWrapper"
                renderThumbHorizontal={() => <div />}
                renderThumbVertical={() => <div />}
              >
                <div className="type" onClick={() => setType("content")}>
                  Content Type
                </div>
                <div className="type" onClick={() => setType("user")}>
                  User Types
                </div>
                <div className="type" onClick={() => setType("userProfile")}>
                  User Profiles
                </div>
                <div className="type" onClick={() => setType("apps")}>
                  Apps
                </div>
              </Scrollbars>
            </div>
            <div className="filtrationDegree disable">
              <div className="titlefilter">Second Degree Filtration </div>
              <div className="subtitle">
                <span>
                  Filter Type: <span>Selection</span>
                </span>
                <span>
                  Query List: <span>TBD</span>
                </span>
              </div>
              <Scrollbars
                className="typleListWrapper"
                renderThumbHorizontal={() => <div />}
                renderThumbVertical={() => <div />}
              >
                <div className="type">Content Type</div>
                <div className="type">User Types</div>
                <div className="type">User Profiles</div>
                <div className="type">Apps</div>
              </Scrollbars>
            </div>
            <div className="filtrationDegree disable">
              <div className="titlefilter">Third Degree Filtration </div>
              <div className="subtitle">
                <span>
                  Filter Type: <span>Selection</span>
                </span>
                <span>
                  Query List: <span>TBD</span>
                </span>
              </div>
              <Scrollbars
                className="typleListWrapper"
                renderThumbHorizontal={() => <div />}
                renderThumbVertical={() => <div />}
              >
                <div className="type">Content Type</div>
                <div className="type">User Types</div>
                <div className="type">User Profiles</div>
                <div className="type">Apps</div>
              </Scrollbars>
            </div>
            <div className="footerButtons">
              <div className="btnReset">Reset</div>
              <div className="btnApply" onClick={() => setFilter("Type")}>
                Apply Changes
              </div>
            </div>
          </Scrollbars>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default JusticeContent;
