import React, { useContext, useState } from "react";
import Axios from "axios";
import JsonWebToken from "jsonwebtoken";
import Scrollbars from "react-custom-scrollbars";
import { useHistory } from "react-router";
import cloudUploadIcon from "../../static/images/postClipArt/cloudUpload.svg";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import { MainContext } from "../../context/MainContext";
import loadingGif from "../../static/animations/loading.gif";
import back from "../../static/images/back.svg";
import close from "../../static/images/close.svg";
import WebsiteInput from "../WebsiteInput";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

function NewEndorsement({ onClose, onBack }) {
  const history = useHistory();
  const { tostShowOn, email, token } = useContext(MainContext);
  const [step, setStep] = useState("");
  const [loading, setLoading] = useState(false);

  // Form Variables
  const [emName, setEmName] = useState("");
  const [icon, setIcon] = useState("");
  const [logo, setLogo] = useState("");
  const [whiteIcon, setWhiteIcon] = useState("");
  const [whiteLogo, setWhiteLogo] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");
  const [primaryColor, setPrimaryColor] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");
  const [websiteTitle, setWebsiteTitle] = useState("");
  const [websiteDescription, setWebsiteDescription] = useState("");
  const [desc, setDesc] = useState("");
  const [website, setWebsite] = useState("");

  const [iconLoading, setIconLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [whiteIconLoading, setWhiteIconLoading] = useState(false);
  const [whiteLogoLoading, setWhiteLogoLoading] = useState(false);
  const [coverPhotoLoading, setCoverPhotoLoading] = useState(false);

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
    setLoading(false);
  };

  function getContent() {
    switch (step) {
      default:
        return (
          <Scrollbars
            className="scrollForm"
            renderTrackHorizontal={() => <div />}
            renderThumbHorizontal={() => <div />}
            renderTrackVertical={() => <div />}
            renderThumbVertical={() => <div />}
          >
            <div className="name">Endorsement Market Name</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Name"
                value={emName}
                onChange={(e) => setEmName(e.target.value)}
              />
            </div>
            <div className="name">Describe The Endorsement Market</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Description Here"
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
            </div>
            <div className="name">Upload Branding Material</div>
            <div className="filesUpload">
              <label className="fileInp icon">
                <img
                  className={`${Boolean(icon)}`}
                  src={iconLoading ? loadingGif : icon || cloudUploadIcon}
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setIcon, setIconLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">Colored Icon</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
              <label className="fileInp icon">
                <img
                  className={`white ${Boolean(whiteIcon)}`}
                  src={
                    whiteIconLoading ? loadingGif : whiteIcon || cloudUploadIcon
                  }
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setWhiteIcon, setWhiteIconLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">White Icon</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
              <label className="fileInp cover">
                <img
                  className={`${Boolean(coverPhoto)}`}
                  src={
                    coverPhotoLoading
                      ? loadingGif
                      : coverPhoto || cloudUploadIcon
                  }
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setCoverPhoto, setCoverPhotoLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">Cover Photo</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
            </div>
            <div className="filesUpload last">
              <label className="fileInp fullLogo">
                <img
                  className={`${Boolean(logo)}`}
                  src={logoLoading ? loadingGif : logo || cloudUploadIcon}
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setLogo, setLogoLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">Colored Logo</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
              <label className="fileInp fullLogo">
                <img
                  className={`white ${Boolean(whiteLogo)}`}
                  src={
                    whiteLogoLoading ? loadingGif : whiteLogo || cloudUploadIcon
                  }
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setWhiteLogo, setWhiteLogoLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">White Logo</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
            </div>
            <div className="name">Enter The Website</div>
            <WebsiteInput website={website} setWebsite={setWebsite} />
            <div className="name">Website Title</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Title Here"
                value={websiteTitle}
                onChange={(e) => setWebsiteTitle(e.target.value)}
              />
            </div>
            <div className="name">Website Description</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Description Here"
                value={websiteDescription}
                onChange={(e) => setWebsiteDescription(e.target.value)}
              />
            </div>
            <div className="name">Enter Primary Color Code</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Code Here..."
                value={primaryColor}
                onChange={(e) => setPrimaryColor(e.target.value)}
              />
              <div
                className="color"
                style={{ width: "10%", background: `#${primaryColor}` }}
              />
            </div>
            <div className="name">Enter Secondary Color Code</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Code Here..."
                value={secondaryColor}
                onChange={(e) => setSecondaryColor(e.target.value)}
              />
              <div
                className="color"
                style={{ width: "10%", background: `#${secondaryColor}` }}
              />
            </div>
            <div className="space"></div>
          </Scrollbars>
        );
    }
  }

  function addEndorsement() {
    setLoading(true);
    Axios.post(
      "https://comms.globalxchange.io/gxb/product/endorsement/market/create",
      {
        email: email,
        token: token,
        name: emName,
        description: desc,
        icon: icon,
        cover_photo: coverPhoto,
        additional_data: {
          website: website,
          websitetitle: websiteTitle,
          websitedescription: websiteDescription,
          colourcode: primaryColor,
          colourcode2: secondaryColor,
          colouredlogo: logo,
          whitelogo: whiteLogo,
          whiteicon: whiteIcon,
        },
      }
    )
      .then(({ data }) => {
        if (data.status) {
          tostShowOn("New Endorsement Added");
          history.push("/viral/Endorsement Markets");
          window.location.reload();
        } else {
          tostShowOn(data.message);
        }
      })
      .finally(() => setLoading(false));
  }

  function validate() {
    if (
      emName &&
      icon &&
      coverPhoto &&
      logo &&
      whiteIcon &&
      whiteLogo &&
      primaryColor &&
      secondaryColor &&
      websiteTitle &&
      websiteDescription &&
      desc &&
      website
    ) {
      addEndorsement();
    } else {
      tostShowOn("All Fields Are Mandatory");
    }
  }

  return (
    <>
      <div className="newConglomerate">{getContent()}</div>
      <div className="btnSubmit" onClick={validate}>
        Submit Mall
      </div>
      {loading && <LoadingAnim />}{" "}
    </>
  );
}

export default NewEndorsement;
