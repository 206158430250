import React, { useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { MoreTabContext } from "../../context/MoreTabContext";
import PublisherItem from "./PublisherItem";

function PublisherList({ list, setList }) {
  const [publisher, setPublisher] = useState();
  const { publisherlist, publisherlistLoading } = useContext(MoreTabContext);
  //
  useEffect(() => {
    if (publisher) setList(true);
    else setList(false);
  }, [publisher, setList]);

  useEffect(() => {
    if (!list) setPublisher();
  }, [list]);
  console.log(`publisherlist`, publisherlist);
  return (
    <>
      {
        <div className="godsEyeMoreList">
          <Scrollbars className="moreListWrapper">
            {publisherlistLoading
              ? Array(6)
                  .fill("")
                  .map((_, i) => (
                    <div className="listItem">
                      <Skeleton alt="" className="icon" />
                      <Skeleton className="name" width={400} />
                      <Skeleton className="count" width={30} />
                    </div>
                  ))
              : publisherlist.map((publisher) => (
                  <PublisherItem
                    key={publisher._id}
                    publisher={publisher}
                    onClick={() => setPublisher(publisher)}
                  />
                ))}
          </Scrollbars>
        </div>
      }
    </>
  );
}

export default PublisherList;
