import React, { useState } from "react";

import Scrollbars from "react-custom-scrollbars";
import { Link, useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import mainLogo from "../static/images/logos/civilisationIcon.svg";
import vaultLogo from "../static/images/logos/vaultFullLogo.svg";
import connectLogo from "../static/images/logos/connectLogo.svg";
import moneyMarkets from "../static/images/logos/moneyMarkets.svg";
import terminalsLogo from "../static/images/logos/terminalsLogo.svg";
import blockcheckLogo from "../static/images/logos/blockcheckLogo.svg";
import brokerLogo from "../static/images/logos/brokerLogo.svg";
import mallsLogo from "../static/images/logos/mallsLogo.svg";
import society from "../static/images/logos/society.svg";
import pIcon from "../static/images/logos/pIcon.svg";
import bg1 from "../static/images/bgs/bgHomeSlider1.jpg";
import bg2 from "../static/images/bgs/bgHomeSlider2.jpg";
import bg3 from "../static/images/bgs/bgHomeSlider3.jpg";
import bg4 from "../static/images/bgs/bgHomeSlider4.jpg";
import bg5 from "../static/images/bgs/bgHomeSlider5.jpg";
import bg6 from "../static/images/bgs/bgHomeSlider6.jpg";
import bg7 from "../static/images/bgs/bgHomeSlider7.jpg";
import bg8 from "../static/images/bgs/bgHomeSlider8.jpg";
import bg9 from "../static/images/bgs/bgHomeSlider9.jpg";
import bg10 from "../static/images/bgs/bgHomeSlider10.jpg";
import bg11 from "../static/images/bgs/bgHomeSlider11.jpg";
import bg12 from "../static/images/bgs/bgHomeSlider12.jpg";
import bg13 from "../static/images/bgs/bgHomeSlider13.jpg";
import bg14 from "../static/images/bgs/bgHomeSlider14.jpg";
import bg15 from "../static/images/bgs/bgHomeSlider15.jpg";
import bg16 from "../static/images/bgs/bgHomeSlider16.jpg";
import bg17 from "../static/images/bgs/bgHomeSlider17.jpg";
import bg18 from "../static/images/bgs/bgHomeSlider18.jpg";
import bg19 from "../static/images/bgs/bgHomeSlider19.jpg";
import bg20 from "../static/images/bgs/bgHomeSlider20.jpg";

const apps = {
  connect: {
    logo: connectLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  blockcheck: {
    logo: blockcheckLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  moneymarkets: {
    logo: moneyMarkets,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  vault: {
    logo: vaultLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  terminals: {
    logo: terminalsLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  brokerapp: {
    logo: brokerLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
  malls: {
    logo: mallsLogo,
    text:
      "Transfering Money From One User To Another Across Any “Vault” Powered Application",
  },
};
let bgArr = [
  {
    name: "Economy",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg1,
  },
  {
    name: "Law & Order",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg2,
  },
  {
    name: "Taxes",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg3,
  },
  {
    name: "Regilion",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg4,
  },
  {
    name: "Military",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg5,
  },
  {
    name: "Entertainment",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",

    icon: bg6,
  },
  {
    name: "Communication",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg7,
  },
  {
    name: "Vice",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg8,
  },
  {
    name: "Healthcare",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg9,
  },
  {
    name: "Education",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg10,
  },
  {
    name: "Funeral",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg11,
  },
  {
    name: "Transportation",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg12,
  },
  {
    name: "Energy",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg13,
  },
  {
    name: "Land",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg14,
  },
  {
    name: "Agriculture",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg15,
  },
  {
    name: "Exploration",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg16,
  },
  {
    name: "Art",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg17,
  },
  {
    name: "Reproduction",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg18,
  },
  {
    name: "Government",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg19,
  },
  {
    name: "News",
    desc:
      "Discover The Applications That Underpin The Economics Of A Modern Society",
    icon: bg20,
  },
];

function PrintReciept() {
  const [index, setIndex] = useState(0);
  const history = useHistory();
  return (
    <>
      <nav className="navCompact">
        <div className="navHome">
          <Link to="/" className="logo">
            <img src={mainLogo} alt="" />
          </Link>
        </div>
        <div className="area">
          <img src={society} alt="" className="society" />
        </div>
        <div className="navHome">
          <Link to="/login" className="logo sm">
            <img src={pIcon} alt="" />
          </Link>
        </div>
      </nav>
      <div className="vaultRecieptContent">
        <div className="carouselWraper">
          <div className="heads">
            <div className="title">{bgArr[index]?.name}</div>
            <div className="subTitle">{bgArr[index]?.desc}</div>
          </div>
        </div>
        <div className="scrollList">
          {bgArr
            .slice()
            .concat(bgArr)
            .map((bg, i) => (
              <CSSTransition
                in={i >= index && i < index + 4}
                timeout={300}
                classNames="scrlItm"
                unmountOnExit
                onEnter={() => {}}
                onExited={() => {}}
              >
                <div className={`scrlItm ${i === index}`}>
                  <img
                    src={bg.icon}
                    alt=""
                    onClick={() =>
                      setIndex(i >= bgArr.length ? i % bgArr.length : i)
                    }
                  />
                </div>
              </CSSTransition>
            ))}
        </div>
        <Scrollbars className="scrollView">
          {Object.keys(apps).map((key) => (
            <div
              key={key}
              className="app"
              onClick={() => history.push(`/sp/${key}`)}
            >
              <img src={apps[key].logo} alt="" />
            </div>
          ))}
          {Object.keys(apps).map((key) => (
            <div
              key={key}
              className="app"
              onClick={() => history.push(`/sp/${key}`)}
            >
              <img src={apps[key].logo} alt="" />
            </div>
          ))}
        </Scrollbars>
      </div>
    </>
  );
}

export default PrintReciept;
