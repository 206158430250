import React, { useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";

function ActiveApps({
  setAppSelected,
  setDropDownOpen,
  appList,
  searchOn,
  loadingAppList,
}) {
  const [search, setSearch] = useState("");
  const ref = useRef();
  useEffect(() => {
    if (searchOn) {
      setTimeout(() => {
        ref?.current && ref.current.focus();
      }, 200);
    }
  }, [searchOn]);
  return (
    <div className="activeAppsList">
      {searchOn && (
        <div className="userCtrls">
          <input
            className="serchInp"
            ref={ref}
            type="text"
            placeholder="Select The App To Search For Users"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      )}
      <div className="activeHead">
        <div className="texts">
          <div className="actTitle">Active Apps</div>
          <div className="actSubTitle">
            Which App Do You Want To See Users For?
          </div>
        </div>
        <div
          className="btAllApps"
          onClick={() => {
            setAppSelected({ app_name: "All Apps", app_code: undefined });
            setDropDownOpen(false);
          }}
        >
          All Apps
        </div>
      </div>
      <Scrollbars className="scrollGrid">
        {loadingAppList
          ? Array(20)
              .fill("")
              .map(() => (
                <div className="appPublication">
                  <Skeleton className="imgWrap" />
                </div>
              ))
          : appList &&
            appList
              .filter(
                (app) =>
                  app.app_name.toLowerCase().includes(search.toLowerCase()) ||
                  app.app_code.toLowerCase().includes(search.toLowerCase())
              )
              .map((app) => (
                <div
                  className="appPublication"
                  key={app._id}
                  onClick={() => {
                    setAppSelected(app);
                    setDropDownOpen(false);
                  }}
                >
                  <div className="imgWrap">
                    <img src={app.app_icon} alt="" className="pubLogo" />
                  </div>
                  <div className="appTooltip">{app.app_name}</div>
                </div>
              ))}
      </Scrollbars>
    </div>
  );
}

export default ActiveApps;
