import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { FormatCurrency, FormatNumber } from "../../utils/FunctionTools";
import classNames from "./tokenApps.module.scss";
import TokenAppSidebar from "./TokenAppSidebar";

function TokenApps({ search, list, setList }) {
  const [loading, setLoading] = useState(true);
  const [appsList, setAppsList] = useState([]);
  const [appHoldings, setAppHoldings] = useState({});
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        "https://comms.globalxchange.io/coin/vault/service/holdings/per/app/get"
      )
      .then(({ data }) => {
        if (data.status) {
          const obj = {};
          data.apps.forEach((app) => {
            obj[app.app_code] = app;
          });
          setAppHoldings(obj);
        } else {
          setAppHoldings({});
        }
      });
    axios
      .get("https://comms.globalxchange.io/gxb/apps/get")
      .then(({ data }) => {
        if (data.status) {
          setAppsList(data.apps);
        } else {
          setAppsList([]);
        }
      })
      .finally(() => setLoading(false));
  }, []);
  const [tokenAppSelected, setTokenAppSelected] = useState();
  return (
    <div className={classNames.tokenApps}>
      <Scrollbars
        className={classNames.appsScrollbar}
        renderThumbHorizontal={() => <div />}
        renderThumbVertical={() => <div />}
        renderTrackHorizontal={() => <div />}
        renderTrackVertical={() => <div />}
      >
        <div
          className={`${classNames.header} ${
            classNames[Boolean(tokenAppSelected)]
          }`}
        >
          <span className={classNames.name}>Name</span>
          <span className={classNames.brand}>Brand</span>
          <span className={classNames.founding}>Founding</span>
          <span className={classNames.users}>Users</span>
          <span className={classNames.totalAssets}>Total Assets</span>
        </div>
        {loading
          ? Array(10)
              .fill("")
              .map((_, i) => (
                <div className={classNames.appItem}>
                  <div className={classNames.name}>
                    <Skeleton className={classNames.icon} circle />
                    <div className={classNames.texts}>
                      <Skeleton className={classNames.title} width={160} />
                      <Skeleton className={classNames.subTitle} width={130} />
                    </div>
                  </div>
                  <div className={classNames.brand}>
                    <Skeleton className={classNames.icon} circle />
                    <div className={classNames.texts}>
                      <Skeleton className={classNames.title} width={160} />
                      <Skeleton className={classNames.subTitle} width={130} />
                    </div>
                  </div>
                  <div className={classNames.founding}>
                    <div className={classNames.texts}>
                      <Skeleton className={classNames.title} width={130} />
                      <Skeleton className={classNames.subTitle} width={100} />
                    </div>
                  </div>
                  <div className={classNames.users}>
                    <div className={classNames.texts}>
                      <Skeleton className={classNames.title} width={130} />
                    </div>
                  </div>
                  <div className={classNames.totalAssets}>
                    <div className={classNames.texts}>
                      <Skeleton className={classNames.title} width={130} />
                    </div>
                  </div>
                </div>
              ))
          : appsList
              ?.filter((app) =>
                app.app_name.toLowerCase().includes(search.toLowerCase())
              )
              ?.map((app) => (
                <AppItem
                  app={app}
                  holdings={appHoldings[app.app_code]}
                  selected={app === tokenAppSelected}
                  onClick={() => setTokenAppSelected(app)}
                  sidebarOpened={Boolean(tokenAppSelected)}
                />
              ))}
      </Scrollbars>
      {tokenAppSelected && (
        <TokenAppSidebar
          tokenAppSelected={tokenAppSelected}
          onClose={() => setTokenAppSelected()}
        />
      )}
    </div>
  );
}

function AppItem({ app, holdings, onClick, selected, sidebarOpened }) {
  return (
    <div
      className={`${classNames[selected]} ${classNames.appItem} ${
        classNames[sidebarOpened ? "open" : ""]
      }`}
      onClick={onClick}
    >
      <div className={classNames.name}>
        <img src={app.app_icon} alt="" className={classNames.icon} />
        <div className={classNames.texts}>
          <div className={classNames.title}>{app.app_name}</div>
          <div className={classNames.subTitle}>{app.app_code}</div>
        </div>
      </div>
      <div className={classNames.brand}>
        <img
          src={app?.operatorData?.brand_logo}
          alt=""
          className={classNames.icon}
        />
        <div className={classNames.texts}>
          <div className={classNames.title}>
            {app?.operatorData?.brand_name}
          </div>
          <div className={classNames.subTitle}>{app?.operatorData?.email}</div>
        </div>
      </div>
      <div className={classNames.founding}>
        <div className={classNames.texts}>
          <div className={classNames.title}>
            {moment().diff(moment(app.timestamp), "days")} Days
          </div>
          <div className={classNames.subTitle}>
            {moment(app.timestamp).format("MMMM Do YYYY")}
          </div>
        </div>
      </div>
      <div className={classNames.users}>
        <div className={classNames.texts}>
          <div className={classNames.title}>
            {FormatNumber(holdings?.users_count)}
          </div>
        </div>
      </div>
      <div className={classNames.totalAssets}>
        <div className={classNames.texts}>
          <div className={classNames.title}>
            ${FormatCurrency(holdings?.assets, "USD")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TokenApps;
