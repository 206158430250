import MailSlurp from "mailslurp-client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import OpenMailView from "./OpenMailView";

const API_KEY =
  "86cee2f39d56b3b5a6b2e4c827cc1382d1be6bad16a9d35cd0e659ef9272d02c";

function InboxSidebar({ mailSelected }) {
  const mailslurp = new MailSlurp({
    apiKey: API_KEY,
  });
  const [mailList, setMailList] = useState([]);
  const [openMailId, setopenMailId] = useState("");
  const [mailDetail, setMailDetail] = useState("");

  const updateMailList = async (mailBoxId) => {
    const inboxController = mailslurp.inboxController;
    const mails = await inboxController.getEmails(
      mailBoxId,
      10,
      0,
      undefined,
      undefined,
      undefined,
      "DESC"
    );
    setMailList(mails);
  };
  useEffect(() => {
    updateMailList(mailSelected.id);
  }, [mailSelected]);
  const openMail = async (openMailId) => {
    const mailDetail = await mailslurp.getEmail(openMailId);
    setMailDetail(mailDetail);
  };
  useEffect(() => {
    if (openMailId) openMail(openMailId);
  }, [openMailId]);

  return (
    <div className="godsEyeUserFiter blockCheckSidebar">
      <div className="headTabBox">
        <div className="tab false">Details</div>
        <div className="tab true">Inbox</div>
      </div>
      {openMailId ? (
        <OpenMailView
          mailDetail={mailDetail}
          onClose={() => setopenMailId("")}
        />
      ) : (
        <Scrollbars className="mailList">
          {mailList.map((mail) => (
            <div
              key={mail.id}
              className={`mailItem ${mail.read}`}
              onClick={() => setopenMailId(mail.id)}
            >
              <img
                src={`https://ui-avatars.com/api/?name=${mail.from}`}
                alt=""
                className="profileImg"
              />
              <div className="texts">
                <div className="from">{mail.from}</div>
                <div className="subject">{mail.subject}</div>
                <div className="time">
                  {moment(mail.created).format(
                    "MMMM Do YYYY [at] h:mm:ss A zz"
                  )}
                </div>
              </div>
            </div>
          ))}
        </Scrollbars>
      )}
    </div>
  );
}

export default InboxSidebar;
