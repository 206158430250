import React, { useState } from "react";
import { useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { FormatCurrency } from "../../utils/Functions";

function UserDetaiVaults({
  userAppList,
  selectedApp,
  setSelectedApp,
  isTxn,
  coinList,
  selectedCoin,
  setSelectedCoin,
  transactions,
  coinListObject,
}) {
  const [statsSwitch, setStatsSwitch] = useState(false);
  const [totalUsd, setTotalUsd] = useState(0);
  useEffect(() => {
    let total = 0;
    coinList.forEach((coin) => {
      total += coin.coinValueUSD;
    });
    setTotalUsd(total);
  }, [coinList]);
  return (
    <>
      <>
        <Scrollbars
          className="vaultsList"
          renderThumbHorizontal={() => <div />}
          renderThumbVertical={() => <div />}
          autoHide
        >
          {userAppList.map((app) => (
            <div
              className={`appItem ${selectedApp === app}`}
              onClick={() => {
                setSelectedApp(app);
              }}
            >
              <img src={app.app_icon} alt="" />
              <div className="name">{app.app_name}</div>
            </div>
          ))}
        </Scrollbars>
        {isTxn &&
          selectedApp &&
          selectedApp.app_code &&
          coinList &&
          coinList.length && (
            <Scrollbars
              className="vaultsList"
              renderThumbHorizontal={() => <div />}
              renderThumbVertical={() => <div />}
              autoHide
            >
              {coinList.map((app) => (
                <div
                  className={`appItem ${selectedCoin === app.coinSymbol}`}
                  onClick={() => {
                    setSelectedCoin(app.coinSymbol);
                  }}
                >
                  <img src={app.coinImage} alt="" />
                  <div className="name">{app.coinName}</div>
                </div>
              ))}
            </Scrollbars>
          )}
      </>
      {isTxn ? (
        <>
          <Scrollbars
            className="txnWrapper"
            renderView={(props) => <div {...props} className="txnList" />}
            renderThumbHorizontal={() => <div />}
            renderThumbVertical={() => <div />}
          >
            <div className="header">
              <div className="asset">Asset</div>
              <div className="balance">Amount</div>
              <div className="usd">Balance</div>
            </div>
            {transactions.map((txn) => (
              <div className="txnItm">
                <div className="asset">
                  <img
                    src={
                      txn.coin &&
                      coinListObject &&
                      coinListObject[txn.coin].coinImage
                    }
                    alt=""
                  />
                  <span>
                    {txn.coin &&
                      coinListObject &&
                      coinListObject[txn.coin].coinName}
                  </span>
                </div>
                <div
                  className={`balance ${txn.deposit && "deposit"} ${
                    txn.withdraw && "withdraw"
                  }`}
                >
                  {FormatCurrency(txn.amount, txn.coin)}
                </div>
                <div className="usd">
                  {FormatCurrency(txn.updated_balance, txn.coin)}
                </div>
              </div>
            ))}
          </Scrollbars>
          <div className="footer">
            <div className="btnBox">
              Stats
              <div
                className={`box ${statsSwitch}`}
                onClick={() => setStatsSwitch(!statsSwitch)}
              >
                <div className="dot" />
              </div>
              HIghlights
            </div>
            <div className="netWorth">
              <span>NetWorth</span>
              <span>
                {FormatCurrency(
                  transactions[0] && transactions[0]?.updated_balance,
                  transactions[0] && transactions[0]?.coin
                )}{" "}
                {transactions[0] && transactions[0]?.coin}
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <Scrollbars
            className="txnWrapper"
            renderView={(props) => <div {...props} className="txnList" />}
            renderThumbHorizontal={() => <div />}
            renderThumbVertical={() => <div />}
          >
            <div className="header">
              <div className="asset">Asset</div>
              <div className="balance">Balance</div>
              <div className="usd">USD</div>
            </div>
            {coinList.map((app) => (
              <div className="txnItm">
                <div className="asset">
                  <img src={app.coinImage} alt="" />
                  <span>{app.coinName}</span>
                </div>
                <div className="balance">
                  {FormatCurrency(app.coinValue, app.coinSymbol)}
                </div>
                <div className="usd">${FormatCurrency(app.coinValueUSD)}</div>
              </div>
            ))}
          </Scrollbars>
          <div className="footer">
            <div className="btnBox">
              Stats
              <div
                className={`box ${statsSwitch}`}
                onClick={() => setStatsSwitch(!statsSwitch)}
              >
                <div className="dot" />
              </div>
              HIghlights
            </div>
            <div className="netWorth">
              <span>NetWorth</span>
              <span>${FormatCurrency(totalUsd)}</span>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default UserDetaiVaults;
