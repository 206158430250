import React, { useContext } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";

import stores from "../../static/images/clipIcons/stores.svg";
import industries from "../../static/images/clipIcons/industries.svg";
import manufactures from "../../static/images/clipIcons/manufactures.svg";
import specialists from "../../static/images/clipIcons/specialists.svg";
import commerceIndice from "../../static/images/clipIcons/commerceIndice.svg";
import { MoreTabContext } from "../../context/MoreTabContext";

function CommerceMoreList({ setTabMore }) {
  const history = useHistory();
  const { industryList } = useContext(MoreTabContext);
  return (
    <div className="godsEyeMoreList">
      <Scrollbars className="moreListWrapper">
        <div
          className="listItem"
          onClick={() => {
            history.push(`/commerce/Stores`);
          }}
        >
          <img src={stores} alt="" className="icon" />
          <span className="name">Stores</span>
          <span className="count">0</span>
        </div>
        <div
          className="listItem"
          onClick={() => {
            history.push(`/commerce/Specialists`);
          }}
        >
          <img src={specialists} alt="" className="icon" />
          <span className="name">Specialists</span>
          <span className="count">0</span>
        </div>
        <div
          className="listItem"
          onClick={() => {
            history.push(`/commerce/Industries`);
          }}
        >
          <img src={industries} alt="" className="icon" />
          <span className="name">Industries</span>
          <span className="count">{industryList.length}</span>
        </div>
        <div
          className="listItem"
          onClick={() => {
            history.push(`/commerce/Manufacturers`);
          }}
        >
          <img src={manufactures} alt="" className="icon" />
          <span className="name">Manufacturers</span>
          <span className="count">0</span>
        </div>
        <div
          className="listItem"
          onClick={() => {
            history.push(`/commerce/Commerce Indices`);
          }}
        >
          <img src={commerceIndice} alt="" className="icon" />
          <span className="name">Commerce Indices</span>
          <span className="count">0</span>
        </div>
      </Scrollbars>
    </div>
  );
}

export default CommerceMoreList;
