import React, { useContext, useState } from "react";
import JsonWebToken from "jsonwebtoken";
import Scrollbars from "react-custom-scrollbars";
import cloudUploadIcon from "../../static/images/postClipArt/cloudUpload.svg";
import Axios from "axios";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
import { MainContext } from "../../context/MainContext";
import { useHistory } from "react-router";
import loadingGif from "../../static/animations/loading.gif";
import axios from "axios";
import { useMutation } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faPlus,
  faSpinner,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import CountryList from "./CountryList";
import PartnerList from "./PartnerList";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

async function checkDisplayName(displayName) {
  const { data } = await axios.get(
    "https://teller2.apimachine.com/lxUser/checkBankerDisplayName",
    {
      params: {
        displayName,
      },
    }
  );
  return data;
}
async function chckBankerTag(bankerTag) {
  const { data } = await axios.get(
    "https://teller2.apimachine.com/lxUser/checkBankerTag",
    {
      params: {
        bankerTag,
      },
    }
  );
  return data;
}

function NewBanker({ onClose, onBack }) {
  const history = useHistory();
  const { tostShowOn, token } = useContext(MainContext);
  const [step, setStep] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    mutate: checkBankerTag,
    data: bankerTagData,
    reset: resetBankerTag,
  } = useMutation(chckBankerTag, {});

  const {
    mutate: checkdispName,
    data: dispNameData,
    reset: resetDispName,
  } = useMutation(checkDisplayName, {});

  // Form Values
  const [email, setEmail] = useState("");
  const [bankerTag, setBankerTag] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [icon, setIcon] = useState("");
  const [whitelogopng, setLogo] = useState("");
  const [iconPng, setIconPng] = useState("");
  const [whiteLogo, setWhiteLogo] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");
  const [adress, setAdress] = useState("");
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [colorCode, setColorCode] = useState("");
  const [partnerApps, setPartnerApps] = useState([]);
  const [autoDeposit, setAutoDeposit] = useState(false);

  // Loaders For Images
  const [iconLoading, setIconLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [iconPngLoading, setIconPngLoading] = useState(false);
  const [whiteLogoLoading, setWhiteLogoLoading] = useState(false);
  const [coverPhotoLoading, setCoverPhotoLoading] = useState(false);

  const uploadImage = async (e, setImage) => {
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";
    const token = JsonWebToken.sign(
      { name: fileName, email: emailDev },
      secret,
      {
        algorithm: "HS512",
        expiresIn: 240,
        issuer: "gxjwtenchs512",
      }
    );
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${token}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
  };

  function getContent() {
    switch (step) {
      case "addSuccess":
        setTimeout(() => {
          history.push("/society/Bankers");
          window.location.reload();
        }, 2000);
        return (
          <div className="succesView">
            <div className="labelItm">
              You Have Successfully Add {displayName}. You Will Be Redirected To
              The Updated Bankers List Automatically
            </div>
          </div>
        );
      case "countryList":
        return (
          <CountryList onClose={() => setStep("")} setCountry={setCountry} />
        );
      case "partner":
        return (
          <PartnerList
            onClose={() => setStep("")}
            setPartner={(partner) =>
              setPartnerApps((prev) => [...prev, partner])
            }
          />
        );
      default:
        return (
          <Scrollbars
            className="scrollForm"
            renderTrackHorizontal={() => <div />}
            renderThumbHorizontal={() => <div />}
            renderTrackVertical={() => <div />}
            renderThumbVertical={() => <div />}
          >
            <div className="name">Enter Banker Email</div>
            <div className="inputWrap">
              <input
                type="email"
                className="text"
                placeholder="Enter Banker Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="name">Create Banker Tag</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Your Banker Tag"
                value={bankerTag}
                onChange={(e) => {
                  setBankerTag(e.target.value);
                }}
              />
              <div
                className="btnCheck"
                onClick={() => {
                  if (bankerTagData?.status) {
                    resetBankerTag();
                    setBankerTag("");
                  } else checkBankerTag(bankerTag);
                }}
              >
                {bankerTagData?.status ? (
                  <FontAwesomeIcon icon={faSpinner} />
                ) : (
                  "Check"
                )}
              </div>
              {bankerTagData?.status ? (
                <div className={`statusBar ${bankerTagData?.data}`} />
              ) : (
                ""
              )}
            </div>
            <div className="name">Create Display Name</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Your Display Name"
                value={displayName}
                onChange={(e) => {
                  setDisplayName(e.target.value);
                }}
              />
              <div
                className="btnCheck"
                onClick={() => {
                  dispNameData?.status
                    ? resetDispName()
                    : checkdispName(displayName);
                }}
              >
                {dispNameData?.status ? (
                  <FontAwesomeIcon icon={faSpinner} />
                ) : (
                  "Check"
                )}
              </div>
              {dispNameData?.status ? (
                <div className={`statusBar ${dispNameData?.data}`} />
              ) : (
                ""
              )}
            </div>
            <div className="name">Upload Branding Material</div>
            <div className="filesUpload">
              <label className="fileInp icon">
                <img
                  className={`${Boolean(icon)}`}
                  src={iconLoading ? loadingGif : icon || cloudUploadIcon}
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setIcon, setIconLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">Colored Icon</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
              <label className="fileInp icon">
                <img
                  className={`white ${Boolean(iconPng)}`}
                  src={iconPngLoading ? loadingGif : iconPng || cloudUploadIcon}
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setIconPng, setIconPngLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">Colored Icon(PNG)</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
              <label className="fileInp cover">
                <img
                  className={`${Boolean(coverPhoto)}`}
                  src={
                    coverPhotoLoading
                      ? loadingGif
                      : coverPhoto || cloudUploadIcon
                  }
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setCoverPhoto, setCoverPhotoLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">Cover Photo</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
            </div>
            <div className="filesUpload last">
              <label className="fileInp icon">
                <img
                  className={`white ${Boolean(whiteLogo)}`}
                  src={
                    whiteLogoLoading ? loadingGif : whiteLogo || cloudUploadIcon
                  }
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setWhiteLogo, setWhiteLogoLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">White Logo</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
              <label className="fileInp icon">
                <img
                  className={`${Boolean(whitelogopng)}`}
                  src={
                    logoLoading ? loadingGif : whitelogopng || cloudUploadIcon
                  }
                  alt=""
                />
                <input
                  type="file"
                  onChange={(e) => {
                    uploadImage(e, setLogo, setLogoLoading);
                  }}
                  accept="image/*"
                />
                <div className="text">Colored Logo</div>
                <div className="hovTxt">
                  Upload
                  <br />
                  New
                </div>
              </label>
              <label
                className="fileInp cover"
                style={{
                  visibility: "hidden",
                }}
              />
            </div>
            <div className="name">Banker Address</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Your Address Here"
                value={adress}
                onChange={(e) => setAdress(e.target.value)}
              />
            </div>
            <div className="name">Banker Description</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Banker Description Here"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="name">Banker Country</div>
            {country?.Name ? (
              <div className="user" onClick={() => setStep("countryList")}>
                <img className="dp" src={country?.Flag} alt="" />
                <div className="userDetail">
                  <div className="name">{country?.Name}</div>
                  <div className="email">+{country?.CountryCode}</div>
                </div>
                <div
                  className="btnCheck"
                  style={{
                    border: "none",
                  }}
                >
                  <FontAwesomeIcon icon={faCaretDown} />
                </div>
              </div>
            ) : (
              <div className="inputWrap" onClick={() => setStep("countryList")}>
                <input
                  type="text"
                  className="text"
                  placeholder="Select The Country For The Banker"
                  readOnly
                />
                <div
                  className="btnCheck"
                  style={{
                    border: "none",
                  }}
                >
                  <FontAwesomeIcon icon={faCaretDown} />
                </div>
              </div>
            )}
            <div className="name">Banker Phone Number</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Banker Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="name">Banker Colour Code</div>
            <div className="inputWrap">
              <input
                type="text"
                className="text"
                placeholder="Enter Banker Colour Code"
                value={colorCode}
                onChange={(e) => setColorCode(e.target.value)}
              />
              <div
                className="color"
                style={{ width: "10%", background: `#${colorCode}` }}
              />
            </div>
            <div className="name">Banker Partnering Institutions</div>
            <div className="inputWrap" onClick={() => setStep("partner")}>
              <input
                type="text"
                className="text"
                placeholder="Select The Partnering Institutions For The Banker"
                readOnly
              />
              <div className="btnCheck">
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </div>
            {partnerApps.length ? (
              <Scrollbars
                className="tokenAppList"
                style={{
                  marginTop: "-10px",
                }}
              >
                {partnerApps.map((partner) => (
                  <div className="cardBtnRow">
                    <div
                      className="btnClose"
                      onClick={() =>
                        setPartnerApps((prev) =>
                          prev.filter(
                            (p) => p?.short_name !== partner?.short_name
                          )
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                    <div className="cardIn">
                      <img src={partner?.profile_image} alt="" />
                      <div className="name">{partner?.short_name}</div>
                    </div>
                  </div>
                ))}
              </Scrollbars>
            ) : (
              ""
            )}
            <div className="name">Auto-Deposit Status</div>
            <div className="btnsWrap">
              <div
                className={`btnGreen ${autoDeposit}`}
                onClick={() => setAutoDeposit(true)}
              >
                <span>True</span>
              </div>
              <div
                className={`btnRed ${!autoDeposit}`}
                onClick={() => setAutoDeposit(false)}
              >
                <span>False</span>
              </div>
            </div>
            <div className="space"></div>
          </Scrollbars>
        );
    }
  }

  function addBanker() {
    setLoading(true);
    Axios.post(
      "https://teller2.apimachine.com/lxUser/register/banker",
      {
        bankerTag: bankerTag, // is unique
        colorCode: colorCode,
        address: adress,
        coverPicURL: coverPhoto,
        displayName: displayName, // is unique
        phone: phone, // optional but dont send it empty
        description: description,
        partneringInstitutions: partnerApps, // must be an arraay
        country: country?.Name,
        profilePicURL: icon, //url,
        profilePicPNG: iconPng,
        profilePicWhite: whiteLogo,
        profilePicWhitePNG: whitelogopng,
        autoDeposit: autoDeposit,
        other_data: {},
      },
      {
        headers: {
          email,
          token,
        },
      }
    )
      .then(({ data }) => {
        if (data.status) {
          setStep("addSuccess");
        } else {
          tostShowOn(data.message);
        }
      })
      .finally(() => setLoading(false));
  }

  function validate() {
    if (
      bankerTag &&
      displayName &&
      icon &&
      whitelogopng &&
      iconPng &&
      whiteLogo &&
      coverPhoto &&
      adress &&
      description &&
      country &&
      phone &&
      colorCode &&
      partnerApps.length
    ) {
      addBanker();
    } else {
      tostShowOn("All Fields Are Mandatory");
    }
  }

  return (
    <>
      <div className="newConglomerate">{getContent()}</div>
      <div className="btnSubmit" onClick={validate}>
        Submit Banker
      </div>
      {loading && <LoadingAnim />}{" "}
    </>
  );
}

export default NewBanker;
