import React from "react";
import Scrollbars from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";

import endorsement from "../../static/images/clipIcons/endorsement.svg";
import mlms from "../../static/images/clipIcons/mlms.svg";
import compChains from "../../static/images/clipIcons/compChains.svg";
import compBlocks from "../../static/images/clipIcons/compBlocks.svg";
import compItems from "../../static/images/clipIcons/compItems.svg";
import compLaws from "../../static/images/clipIcons/compLaws.svg";
import compPlans from "../../static/images/clipIcons/compPlans.svg";

function ViralMoreList({ setTabMore }) {
  const history = useHistory();
  return (
    <div className="godsEyeMoreList">
      <Scrollbars className="moreListWrapper">
        <div className="listItem" onClick={() => {}}>
          <img src={mlms} alt="" className="icon" />
          <span className="name">MLM’s</span>
          <span className="count">0</span>
        </div>
        <div className="listItem" onClick={() => {}}>
          <img src={compChains} alt="" className="icon" />
          <span className="name">CompChains</span>
          <span className="count">0</span>
        </div>
        <div className="listItem" onClick={() => {}}>
          <img src={compPlans} alt="" className="icon" />
          <span className="name">CompPlans</span>
          <span className="count">0</span>
        </div>
        <div className="listItem" onClick={() => {}}>
          <img src={compLaws} alt="" className="icon" />
          <span className="name">CompLaws</span>
          <span className="count">0</span>
        </div>
        <div className="listItem" onClick={() => {}}>
          <img src={compBlocks} alt="" className="icon" />
          <span className="name">CompBlocks</span>
          <span className="count">0</span>
        </div>
        <div className="listItem" onClick={() => {}}>
          <img src={compItems} alt="" className="icon" />
          <span className="name">CompItems</span>
          <span className="count">0</span>
        </div>
        <div
          className="listItem"
          onClick={() => history.push(`/viral/Endorsement Marketplace`)}
        >
          <img src={endorsement} alt="" className="icon" />
          <span className="name">Endorsement Marketplace</span>
          <span className="count">0</span>
        </div>
      </Scrollbars>
    </div>
  );
}

export default ViralMoreList;
