import React from "react";
import Scrollbars from "react-custom-scrollbars";
import appIcn from "../../static/images/refreshIcon/app.svg";
import pageIcn from "../../static/images/refreshIcon/page.svg";

function CryptoFilterSidebar({ ecr20, setEcr20, stable, setStable, onclose }) {
  return (
    <div className="godsEyeUserFiter cryptoFilterSidebar">
      <div className="headTabs">
        <div className="tab true">Filter</div>
        <div className="tab disable">TBD</div>
        <div className="tab disable">TBD</div>
      </div>
      <div className="godsEyeFilterCurrency">
        <div className="labelItm">Only Show</div>
        <Scrollbars className="coinList">
          <div className="coinItem true" onClick={() => {}}>
            <img src={pageIcn} alt="" className="coin" />
            <span>All Cryptocurrencies</span>
          </div>
          <div
            className={`coinItem ${stable}`}
            onClick={() => {
              setStable(!stable);
            }}
          >
            <img src={appIcn} alt="" className="coin" />
            <span>StableCoins</span>
          </div>
          <div
            className={`coinItem ${ecr20}`}
            onClick={() => {
              setEcr20(!ecr20);
            }}
          >
            <img src={appIcn} alt="" className="coin" />
            <span>ERC20 Coins</span>
          </div>
        </Scrollbars>
      </div>
      <div className="footerBtns">
        <div
          className="btnReset"
          onClick={() => {
            setStable(false);
            setEcr20(false);
          }}
        >
          Default
        </div>
        <div
          className="btnSave"
          onClick={() => {
            try {
              onclose();
            } catch (error) {}
          }}
        >
          Save
        </div>
      </div>
    </div>
  );
}

export default CryptoFilterSidebar;
