import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { MainContext } from "../context/MainContext";
import Sidebar from "./Sidebar/Sidebar";
import { MainLayout } from "../components/BrokerDashboard/index";
import GlobalSidebar from "../components/GlobalSidebar/GlobalSidebar";

function MainLayoutComponent({ children, active, hide }) {
  const { email } = useContext(MainContext);
  const [newSidebar, setNewSidebar] = useState(false);

  return (
    <>
      {!email ? <Redirect to="/" /> : ""}
      <MainLayout
        className={`${active}`}
        hide={hide}
        sidebar={<Sidebar onNew={() => setNewSidebar(true)} active={active} />}
        navbar={null}
        primaryColor="#464B4E"
      >
        <>
          {children}
          <GlobalSidebar
            onClose={() => setNewSidebar(false)}
            show={newSidebar}
          />
        </>
      </MainLayout>
    </>
  );
}

export default MainLayoutComponent;
