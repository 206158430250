import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IconsViewSwitcher, Navbar } from "../components/BrokerDashboard/index";
import { TabsContext } from "../context/TabsContext";
import MainLayout from "../layouts/MainLayout";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";
import CentralBankCoinItem from "../components/CentralBankCoinItem/CentralBankCoinItem";
import CentralBankSidebar from "../components/CentralBankSidebar/CentralBankSidebar";
import { ReactComponent as RefreshIcn } from "../static/images/clipIcons/refresh.svg";
import { ReactComponent as CloseIcn } from "../static/images/clipIcons/close.svg";
import GodsEyeRefreshSidebar from "../components/GodsEyeRefreshSidebar/GodsEyeRefreshSidebar";
import Converter from "../components/Converter/Converter";
import CryptoFilterSidebar from "../components/CryptoFilterSidebar/CryptoFilterSidebar";
import AssetsIoFed from "../components/AssetsIoFed/AssetsIoFed";

const tabs = ["Fiat", "Crypto", "Convertor", "Fed"];

function CentralBank({ hide }) {
  const { txnId } = useParams();
  const { navTabs } = useContext(TabsContext);
  const [tabSelected, setTabSelected] = useState("Fiat");
  const [coinsList, setCoinsList] = useState([]);
  const [coinSelected, setCoinSelected] = useState();
  const [refresh, setRefresh] = useState("");
  const [searchOn, setSearchOn] = useState(false);
  const [search, setSearch] = useState("");
  const [filterOn, setFilterOn] = useState(false);
  const [ecr20, setEcr20] = useState(false);
  const [stable, setStable] = useState(false);

  useEffect(() => {
    if (txnId === "crypto") {
      setTabSelected("Crypto");
    }
  }, [txnId]);

  useEffect(() => {
    Axios.get(`https://comms.globalxchange.io/coin/vault/get/all/coins`).then(
      ({ data }) => {
        if (data.status) {
          setCoinsList(data.coins);
        }
      }
    );
  }, []);

  function getContents() {
    switch (tabSelected) {
      case "Convertor":
        return <Converter refresh={refresh} coinsList={coinsList} />;
      case "Fed":
        return <AssetsIoFed />;
      default:
        return (
          <>
            {searchOn && (
              <div className="userCtrls">
                <input
                  className="serchInp"
                  type="text"
                  placeholder="Search Coin"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="moreFilter">More Filters</div>
              </div>
            )}
            <div className="centralBankContent">
              <Scrollbars
                renderTrackHorizontal={() => <div />}
                renderThumbHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
                renderThumbVertical={() => <div />}
                className="centralCoinsList"
              >
                {coinsList
                  .filter((coin) => coin.type === tabSelected.toLowerCase())
                  .filter((coin) => {
                    switch (true) {
                      case ecr20 && stable && tabSelected === "Crypto":
                        return (
                          coin?.asset_type === "crypto_erc20_stable" &&
                          tabSelected === "Crypto"
                        );
                      case ecr20:
                        return (
                          coin?.asset_type?.includes("crypto_erc20") &&
                          tabSelected === "Crypto"
                        );
                      case stable:
                        return coin?.stable_coin && tabSelected === "Crypto";
                      default:
                        return true;
                    }
                  })
                  .filter(
                    (coin) =>
                      coin.coinName
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      coin.coinSymbol
                        .toLowerCase()
                        .includes(search.toLowerCase())
                  )
                  .map((coin) => (
                    <CentralBankCoinItem
                      key={coin._id}
                      coin={coin}
                      onClick={() => {
                        setCoinSelected(coin);
                        setRefresh("");
                      }}
                      actionClick={() => {
                        setCoinSelected(coin);
                        setRefresh("");
                      }}
                      active={
                        !Boolean(coinSelected) ||
                        coinSelected.coinSymbol === coin.coinSymbol
                      }
                    />
                  ))}
              </Scrollbars>
              {coinSelected && (
                <CentralBankSidebar
                  coinSelected={coinSelected}
                  coinsList={coinsList}
                />
              )}
              {refresh && <GodsEyeRefreshSidebar />}
              {filterOn && (
                <CryptoFilterSidebar
                  ecr20={ecr20}
                  setEcr20={setEcr20}
                  stable={stable}
                  setStable={setStable}
                  onclose={() => setFilterOn(false)}
                />
              )}
            </div>
          </>
        );
    }
  }

  return (
    <>
      <MainLayout active="Central Bank" hide={hide}>
        <Navbar
          navTabs={navTabs}
          tabs={tabs}
          tabSelected={tabSelected}
          setTabSelected={(tab) => {
            setTabSelected("");
            setTimeout(() => {
              setTabSelected(tab);
            }, 100);
          }}
          search
          setSearch={() => {}}
          viewSwitcher={
            <IconsViewSwitcher
              boardIcn={<RefreshIcn />}
              board={refresh}
              onBoardClick={() => {
                if (refresh === "active") setRefresh("");
                else {
                  setRefresh("active");
                  setCoinSelected();
                }
              }}
              listIcn={Boolean(coinSelected) || filterOn ? <CloseIcn /> : ""}
              list={Boolean(coinSelected) || filterOn}
              onListClick={() => {
                if (Boolean(coinSelected) || filterOn) {
                  setCoinSelected();
                  setFilterOn(false);
                } else {
                  setFilterOn(true);
                }
              }}
              search={tabSelected !== "Convertor" && searchOn}
              onSearchClick={() => setSearchOn(!searchOn)}
            />
          }
          tabClick={() => {}}
          searchPlaceHolder=""
          button={
            <div className={`publication `}>
              <span>Nothing Selected</span>
            </div>
          }
        />
        {getContents()}
      </MainLayout>
    </>
  );
}

export default CentralBank;
