import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import Skeleton from "react-loading-skeleton";
import React, { useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { MainContext } from "../../context/MainContext";
import { IsValidURL } from "../../utils/FunctionTools";
import XIDSidebar from "./XIDSidebar";

function XIDApps({ search, list, setList }) {
  const { copyToClipboard } = useContext(MainContext);
  const [fieldGroups, setFieldGroups] = useState([]);
  const [groupSelected, setGroupSelected] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    Axios.get("https://comms.globalxchange.io/gxb/apps/field/groups/get")
      .then(({ data }) => {
        if (data.status) {
          setFieldGroups(data.field_groups);
        } else {
          setFieldGroups([]);
        }
      })
      .finally(() => setLoading(false));
  }, []);
  useEffect(() => {
    if (!groupSelected) {
      setList(false);
    }
    return () => {};
  }, [groupSelected, setList]);

  useEffect(() => {
    if (!list) {
      setGroupSelected();
    }
    return () => {};
  }, [list]);

  return (
    <>
      <div className="xidApps">
        <Scrollbars
          className="appsScrollbar"
          renderThumbHorizontal={() => <div />}
          renderThumbVertical={() => <div />}
          renderTrackHorizontal={() => <div />}
          renderTrackVertical={() => <div />}
        >
          {loading
            ? Array(10)
                .fill("")
                .map(() => (
                  <div className={`groupItem`}>
                    <div className="topItem">
                      <Skeleton className="icon" />
                      <div className="group">
                        <Skeleton className="grpName" width={400} />
                        <Skeleton className="grpFor" width={400} />
                      </div>
                      <div className="collection">
                        <Skeleton className="grpId" width={400} />
                        <Skeleton className="colectionName" width={400} />
                      </div>
                    </div>
                    <Skeleton className="desc" width={500} />
                  </div>
                ))
            : fieldGroups
                .filter((group) =>
                  group.name.toLowerCase().includes(search.toLowerCase())
                )
                .map((group) => (
                  <div
                    className={`groupItem ${Boolean(
                      !groupSelected || group === groupSelected
                    )}`}
                    key={group._id}
                    onClick={() => {
                      setGroupSelected(group);
                      setList(true);
                    }}
                  >
                    <div className="topItem">
                      <img
                        src={
                          IsValidURL(group.group_icon)
                            ? group.group_icon
                            : `https://avatars.dicebear.com/api/initials/${group.name}.svg?b=%23000000&chars=1&bold=1`
                        }
                        alt=""
                        className="icon"
                      />
                      <div className="group">
                        <div className="grpName">
                          {group.name}
                          <FontAwesomeIcon
                            icon={faCopy}
                            className="copyBtn"
                            onClick={() => copyToClipboard(group.name, "Name")}
                          />
                        </div>
                        <div className="grpFor">
                          The Group Is For {group.group_for}
                          <FontAwesomeIcon
                            icon={faCopy}
                            className="copyBtn"
                            onClick={() =>
                              copyToClipboard(group.group_for, "Group For")
                            }
                          />
                        </div>
                      </div>
                      <div className="collection">
                        <div className="grpId">
                          <FontAwesomeIcon
                            icon={faCopy}
                            className="copyBtn"
                            onClick={() =>
                              copyToClipboard(group.group_id, "Group Id")
                            }
                          />
                          {group.group_id}
                        </div>
                        <div className="colectionName">
                          <FontAwesomeIcon
                            icon={faCopy}
                            className="copyBtn"
                            onClick={() =>
                              copyToClipboard(group.collection, "Collection")
                            }
                          />
                          {group.collection}
                        </div>
                      </div>
                    </div>
                    <p className="desc">{group.description}</p>
                    <FontAwesomeIcon
                      icon={faCopy}
                      style={{ marginLeft: "auto" }}
                      className="copyBtn"
                      onClick={() =>
                        copyToClipboard(group.collection, "Collection")
                      }
                    />
                  </div>
                ))}
        </Scrollbars>
      </div>
      {groupSelected ? (
        <XIDSidebar
          groupSelected={groupSelected}
          onClose={() => setGroupSelected()}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default XIDApps;
