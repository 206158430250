import React, { Fragment, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { useConglomeratesList } from "../../queryHooks";

function ConglomerateList({ setGroup, onClose }) {
  const { data: groups, isLoading: groupLoading } = useConglomeratesList();
  const [search, setSearch] = useState("");
  return (
    <Fragment>
      <div className="titleOp">Select Parent Conglomerate</div>
      <div className="searchWrap">
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Group....|"
        />
      </div>
      <Scrollbars className="searchList">
        {groupLoading
          ? Array(6)
              .fill("")
              .map((_, i) => (
                <div className="user" key={i}>
                  <Skeleton className="dp" circle />
                  <div className="userDetail">
                    <Skeleton className="name" width={200} />
                    <Skeleton className="email" width={200} />
                  </div>
                </div>
              ))
          : groups
              .filter(
                (group) =>
                  group.groupname
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  group.email.toLowerCase().includes(search.toLowerCase())
              )
              .map((group) => (
                <div
                  className="user"
                  key={group._id}
                  onClick={() => {
                    setGroup(group);
                    onClose();
                  }}
                >
                  <img className="dp" src={group.icon} alt="" />
                  <div className="userDetail">
                    <div className="name">{group.groupname}</div>
                    <div className="email">{group.email}</div>
                  </div>
                </div>
              ))}
        <div className="space"></div>
      </Scrollbars>
      <div className="ftBtns">
        <div className="newField" onClick={() => onClose()}>
          Go Back
        </div>
        <div className="btnSubmit" onClick={() => {}}>
          Submit
        </div>
      </div>
    </Fragment>
  );
}

export default ConglomerateList;
