import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import guest from "../../static/images//guest.jpg";
import { ReactComponent as QrIcon } from "../../static/images/clipIcons/qr.svg";
import { MainContext } from "../../context/MainContext";
import LoadingAnim from "../LoadingAnim/LoadingAnim";
function NewAppRegister({ userSelected, onBack, onClose }) {
  const { appList } = useContext(MainContext);
  const [userApps, setUserApps] = useState([]);
  const [appSelected, setAppSelected] = useState();
  useEffect(() => {
    Axios.get(
      `https://comms.globalxchange.io/gxb/apps/registered/user?email=${userSelected?.userData?.email}`
    ).then((res) => {
      const { data } = res;
      if (data.status) {
        setUserApps(data.userApps);
      }
    });
    return () => {};
  }, [userSelected]);

  const [step, setStep] = useState("");
  const [profileId, setProfileId] = useState("");
  const [loading, setLoading] = useState(false);

  function addApp(from) {
    setLoading(true);
    Axios.post("https://comms.globalxchange.io/gxb/apps/register/user", {
      email: userSelected?.userData?.email,
      app_code: appSelected?.app_code,
      fromAppCreation: from,
    })
      .then(({ data }) => {
        if (data.status) {
          setStep("success");
          setProfileId(data.profile_id);
        }
      })
      .finally(() => setLoading(false));
  }

  function getContent() {
    switch (step) {
      case "success":
        return (
          <div className="godsEyeFilterCurrency ">
            <div className="labelItm mb-5">
              You Have Successfully Registered {userSelected?.userData?.name}{" "}
              For {appSelected?.app_name}
            </div>
            <Scrollbars className="coinList">
              <div className="labelItm">Profile ID</div>
              <div className="coinItem small">
                <span>{profileId}</span>
              </div>
            </Scrollbars>
          </div>
        );

      case "acType":
        return (
          <div className="godsEyeFilterCurrency ">
            <div className="labelItm">Current Registered Apps</div>
            <Scrollbars className="coinList">
              <div className="coinItem colorBlack" onClick={() => addApp(true)}>
                <QrIcon className="coin" />
                <span>With Crypto Address</span>
              </div>
              <div
                className="coinItem colorRed"
                onClick={() => addApp(undefined)}
              >
                <QrIcon className="coin" />
                <span>Without Crypto Address</span>
              </div>
            </Scrollbars>
          </div>
        );
      case "selectApp":
        return (
          <div className="godsEyeFilterCurrency ">
            <div className="labelItm">Select An App</div>
            <Scrollbars className="coinList">
              {appList
                ?.filter((app) => {
                  const appCodes = [];
                  userApps.forEach((appIn) => {
                    appCodes.push(appIn.app_code);
                  });
                  return !appCodes.includes(app.app_code);
                })
                .map((app) => (
                  <div
                    className="coinItem"
                    onClick={() => {
                      setAppSelected(app);
                      setStep("acType");
                    }}
                    key={app.app_code}
                  >
                    <img src={app.app_icon} alt="" className="coin" />
                    <div className="appDetail">
                      <span className="app">{app.app_name}</span>
                    </div>
                  </div>
                ))}
            </Scrollbars>
          </div>
        );
      default:
        return (
          <>
            <div className="godsEyeFilterCurrency ">
              <div className="labelItm">Current Registered Apps</div>
              <Scrollbars className="coinList">
                {userApps?.map((app) => (
                  <div className="coinItem" key={app.app_code}>
                    <img src={app.app_icon} alt="" className="coin" />
                    <div className="appDetail">
                      <span className="app">{app.app_name}</span>
                      <span className="profile">{app.profile_id}</span>
                    </div>
                  </div>
                ))}
              </Scrollbars>
            </div>
            <div className="addNew" onClick={() => setStep("selectApp")}>
              Register For New App
            </div>
          </>
        );
    }
  }
  return (
    <>
      {getContent()}
      {loading && <LoadingAnim />}
    </>
  );
}

export default NewAppRegister;
